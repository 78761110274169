import { SET_FORECASTING_ENGINE_RUN_INFO } from '../constants/actionTypes';

const initialState = {
  engineRunId: null,
  lastSynced: null,
  taskId: null
};

const setForecastingEngineRunInfo = (state, { engineRunData }) => ({
  ...state,
  engineRunId: engineRunData.run_id,
  lastSynced: engineRunData.created_at,
  taskId: engineRunData.task_id
});

const forecastingReducer = (state = initialState, action = null) => {
  if (!action) return state;
  switch (action.type) {
    case SET_FORECASTING_ENGINE_RUN_INFO:
      return setForecastingEngineRunInfo(state, action);
    default:
      return state;
  }
};

export default forecastingReducer;
