/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import moment from 'moment-timezone';
import React, { createContext, useMemo, useState } from 'react';

export const VIEW_DAY = 'view-select-day';
export const VIEW_SELECT_RANGE = 'view-select-range';
export const defaultStartDate = moment();
export const defaultEndDate = moment(defaultStartDate).add(14, 'days');

const initialFilterState = {
  currentDateFilter: VIEW_SELECT_RANGE,
  currentDayValue: defaultStartDate,
  startDate: defaultStartDate,
  endDate: defaultEndDate
};

export const DateFilterContext = createContext({
  dateFilterState: { ...initialFilterState },
  setDateFilterState: {},
});

export const DateFilterContextWrapper = (props) => {
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [currentDateFilter, setCurrentDateFilter] = useState(VIEW_SELECT_RANGE);
  const [currentDayValue, setCurrentDayValue] = useState(defaultStartDate);

  const memoDateFilterValue = useMemo(
    () => (
      { 
        currentDateFilter,
        currentDayValue,
        setDateFilterState: { setCurrentDateFilter, setCurrentDayValue },
        startDate, 
        endDate,
        setStartDate, 
        setEndDate
      }), 
    [startDate, endDate, currentDateFilter, currentDayValue]
  );

  return (
    <DateFilterContext.Provider
      value={memoDateFilterValue}
    >
      { props.children }
    </DateFilterContext.Provider>
  );
};

export const withDateFilterConsumerPropsHOC = (WrappedComponent) => (props) => (
  <DateFilterContext.Consumer>
    {({ 
      currentDateFilter,
      currentDayValue,
      setDateFilterState,
      startDate, 
      endDate,
      setStartDate, 
      setEndDate 
    }) => (
      <WrappedComponent
        {...props}
        currentDateFilter={currentDateFilter}
        currentDayValue={currentDayValue}
        setDateFilterState={setDateFilterState}
        startDate={startDate} 
        endDate={endDate}
        setStartDate={setStartDate} 
        setEndDate={setEndDate}
      >
        { props.children }
      </WrappedComponent>
    )}
  </DateFilterContext.Consumer>
);

export const withDateFilterCtxPropsHOC = (WrappedComponent) => (props) => (
  <DateFilterContextWrapper>
    <DateFilterContext.Consumer>
      {({ 
        currentDateFilter,
        currentDayValue,
        setDateFilterState,
        startDate, 
        endDate,
        setStartDate, 
        setEndDate 
      }) => (
        <WrappedComponent
          {...props}
          currentDateFilter={currentDateFilter}
          currentDayValue={currentDayValue}
          setDateFilterState={setDateFilterState}
          startDate={startDate} 
          endDate={endDate}
          setStartDate={setStartDate} 
          setEndDate={setEndDate}
        >
          { props.children }
        </WrappedComponent>
      )}
    </DateFilterContext.Consumer>
  </DateFilterContextWrapper>
);
