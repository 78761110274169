import {
  LOAD_SCORE,
  ASSIGNMENT_STATUS,
  TMS_ASSIGNMENT
} from '../components/loadAcceptance/helpers/useInformationModal';
import { DETAILS, REVIEW } from '../AppConstants';

export const defaultLoadHeaders = [
  {
    sort: true,
    sortKey: 'load_id',
    label: 'Load No.',
    column: 'load_id',
    visible: true,
    disabled: false,
    sideViewVisible: true,
  },
  {
    sort: true,
    sortKey: 'load_score',
    label: 'Load Score',
    visible: true,
    disabled: false,
    sideViewVisible: true,
  },
  {
    sort: true,
    sortKey: 'shipper_id',
    label: 'Shipper ID',
    visible: true,
    disabled: false,
    sideViewVisible: true,
  },
  {
    sort: true,
    sortKey: 'pickup_loc',
    label: 'Pick Up Location',
    column: ['origin_city', 'origin_state'],
    visible: true,
    disabled: false,
    sideViewVisible: false,
  },
  {
    sort: true,
    sortKey: 'pickup_start_date_time',
    label: 'Pick Up Window',
    isDate: true,
    visible: true,
    disabled: false,
    sideViewVisible: false,
  },
  {
    sort: true,
    sortKey: 'delivery_loc',
    label: 'Delivery Location',
    column: ['destination_city', 'destination_state'],
    visible: true,
    disabled: false,
    sideViewVisible: false,
  },
  {
    sort: true,
    sortKey: 'delivery_start_date_time',
    label: 'Delivery Window',
    isDate: true,
    visible: true,
    disabled: false,
    sideViewVisible: false,
  },
  {
    sort: true,
    sortKey: 'customer_status_datetime',
    label: 'Date Assigned',
    isDate: true,
    visible: true,
    disabled: true,
    sideViewVisible: false,
  },
  {
    sort: false,
    label: 'Settings',
    visible: true,
    disabled: false,
  },
];

export const TwoWayLoadHeaders = [
  {
    sort: true,
    sortKey: 'load_id',
    label: 'Load No.',
    column: 'load_id',
    visible: true,
    disabled: true,
    infoModal: '',
    parent: DETAILS
  },
  {
    sort: true,
    sortKey: 'shipper_id',
    label: 'Shipper ID',
    visible: true,
    disabled: false,
    infoModal: '',
    parent: DETAILS
  },
  {
    sort: true,
    sortKey: 'pickup_loc',
    label: 'Pick Up Location',
    column: ['origin_city', 'origin_state'],
    visible: true,
    disabled: false,
    infoModal: '',
    parent: DETAILS
  },
  {
    sort: true,
    sortKey: 'pickup_start_date_time',
    label: 'Pick Up Window',
    visible: true,
    disabled: false,
    infoModal: '',
    parent: DETAILS
  },
  {
    sort: true,
    sortKey: 'delivery_loc',
    label: 'Drop Off Location',
    column: ['destination_city', 'destination_state'],
    visible: true,
    disabled: false,
    infoModal: '',
    parent: DETAILS
  },
  {
    sort: true,
    sortKey: 'delivery_start_date_time',
    label: 'Drop Off Window',
    visible: true,
    disabled: false,
    infoModal: '',
    parent: DETAILS
  },
  { 
    sort: true,
    sortKey: 'mileage',
    label: 'Loaded Mileage',
    parent: DETAILS,
    visible: true,
    disabled: false,
    infoModal: ''
  },
  { 
    sort: true,
    label: 'Revenue',
    sortKey: 'line_haul_revenue',
    parent: DETAILS,
    visible: true,
    disabled: false,
    infoModal: '',
  },
  {
    sort: true,
    sortKey: 'load_score',
    label: 'Load Score',
    visible: true,
    disabled: false,
    infoModal: LOAD_SCORE,
    parent: REVIEW
  },
  {
    sort: true,
    sortKey: 'optimized_assignment',
    label: 'Optimized Asgmnt.',
    visible: true,
    disabled: false,
    infoModal: ASSIGNMENT_STATUS,
    parent: REVIEW
  },
  {
    sort: true,
    sortKey: 'internal_source',
    label: 'TMS Asgmnt.',
    visible: true,
    disabled: false,
    infoModal: TMS_ASSIGNMENT,
    parent: REVIEW
  },
  {
    sort: true,
    sortKey: 'user_email',
    label: 'User',
    visible: true,
    disabled: false,
    infoModal: '',
    parent: REVIEW
  },
  {
    sort: true,
    sortKey: 'customer_status_datetime',
    label: 'Date Assigned',
    visible: true,
    disabled: false,
    infoModal: '',
    parent: REVIEW
  },
];

export const defaultShipperHeaders = [
  {
    id: 'shipper_id',
    label: 'Shipper ID',
    visible: true,
    disabled: false,
  },
  {
    id: 'count',
    label: 'Load Count',
    visible: true,
    disabled: false,
  },
  {
    id: 'assets',
    label: 'Overall Assets',
    visible: true,
    disabled: false,
    align: 'left',
  },
  {
    id: 'brokerage',
    label: 'Overall Brokerage',
    visible: true,
    disabled: false,
    align: 'left',
  },
  {
    id: 'pickup_on_time',
    label: 'Avg. On Time Pick Up (%)',
    visible: true,
    disabled: false,
    align: 'left',
  },
  {
    id: 'delivery_on_time',
    label: 'Avg. On Time Delivery (%)',
    visible: true,
    disabled: false,
    align: 'left',
  },
];

export const getHeader = (headerName) => {
  if (!headerName) return;

  switch (headerName) {
    case 'defaultLoadHeaders':
      return defaultLoadHeaders;

    case 'defaultShipperHeaders':
      return defaultShipperHeaders;

    default:
      throw new Error('Invalid value entered for headerName');
  }
};

export const filterOutLAHeaders = ['Top Driver(s)', 'Advisories'];
export const filterLAKeys = [
  'loadsMaxTableHeader',
  'loads-max-table-header',
  'loadsMinTableHeader',
  'loadsTableHeaders'
];
