import React, { ReactNode } from 'react';
import { Chip } from '@mui/material';

interface SearchMatchHighlightProps {
  content: ReactNode;
  searchTerm: string;
  matchTerm: string;
  disabled?: boolean;
}

const SearchMatchHighlight = ({ content, searchTerm, matchTerm, disabled = false }: SearchMatchHighlightProps) => {
  if (!!searchTerm && matchTerm?.toLowerCase().indexOf(searchTerm?.toLowerCase()) > -1 && !disabled) {
    return (
      <Chip
        label={content}
        sx={{
          fontSize: '14px',
          height: 28,
          backgroundColor: 'colors.lightBlue2',
          position: 'relative',
          left: -10,
          '& .MuiChip-label': { px: '10px' }
        }}
      />
    );
  }
  return content;
};

export default SearchMatchHighlight;
