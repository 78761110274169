import React, { SyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import { Box, styled } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PersonIcon from '@mui/icons-material/Person';
import { ODLaneIcon, ODCloseButton } from '@OptimalDynamics/core-ai-common-ui';
import { ConfirmContainer } from '../../../common';
import { removeAssignByLoad, clearAssignByLoad } from '../../../store/actions/dispatchingAction';
import instance from '../../../utils/axios_instance';
import { RootState } from '../../../store/reducers';
import { AssignDriversDialogContainerProps, AssignDriversDialogProps } from '../types';

const LeftActionDiv = styled('div', {
  name: 'Left Action Div'
})(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  color: theme.palette.text.primary,
  fontSize: '14px',

  '.laneIconLeft': {
    color: theme.palette.text.primary,
    marginRight: '18px',
  },
  '.loadId': {
    paddingRight: '18px',
    fontWeight: 600,
  },
  '[data-testid="ArrowForwardIcon"]': {
    marginRight: '18px'
  },
  '.driverId': {
    marginLeft: '18px',
    fontWeight: 600,
  },
}));

function AssignDriversDialog(props: AssignDriversDialogProps) {
  const { 
    setTopDriverSlideoutOpen,
    open, 
    handleClose,
    setRefreshDashboard,
    clearSelectedRows,
    dialogOrigin, // slideout | main
    selectLoadIDs,
    selectDriverLoadTable,
    selectMovementTable,
    engineRunId
  } = props;

  const dispatch = useDispatch();

  const getAssignments = () => selectLoadIDs.map((load_id) => {
    const obj: { movement_option_id: string | number, load_id: string | number, driver_id?: string } = {
      movement_option_id: parseInt(selectMovementTable[load_id], 10),
      load_id
    };

    if (selectDriverLoadTable[load_id]?.length) {
      obj.driver_id = selectDriverLoadTable[load_id];
    }

    return obj;
  });

  const cancelAssignments = () => {
    handleClose();
    dispatch(clearAssignByLoad());
  };

  const confirmAssignments = async () => {
    const assignments = getAssignments();
    
    const response = await instance.post('/dispatching/drivers/loads-assignments/', { assignments, engine_run_id: engineRunId });

    handleClose();
    dispatch(clearAssignByLoad());

    if (response?.data?.success === false) {
      console.error(response.data.errors);
    }

    if (response && response.status === 200) {
      switch (dialogOrigin) {
        case 'slideout':
          setTopDriverSlideoutOpen(false);
          setRefreshDashboard(true);
          break;
          
        case 'main':
          clearSelectedRows();
          setRefreshDashboard(true);
          break;

        default:
          console.warn('Prop dialogOrigin had an unexpected value.');
          break;
      }
    }
  };

  const handleConfirmation = (willAssign: boolean) => {
    !!willAssign && confirmAssignments();
    !willAssign && cancelAssignments();
  };

  const handleCloseIcon = (loadId: string) => (evt: SyntheticEvent) => {
    evt.stopPropagation();

    if (selectLoadIDs.length === 1) {
      handleClose();
      dispatch(clearAssignByLoad());
    }

    dispatch(removeAssignByLoad(loadId));
  };

  const getAssignmentPill = (loadID: string) => {
    const currDriverID = selectDriverLoadTable[loadID];

    return (
      <Grid
        item
        key={currDriverID}
        xs={12}
        sx={{
          display: 'inline-flex',
          width: '100%',
          maxWidth: '392px',
          justifyContent: 'space-between', 
          marginTop: '2px', 
          borderRadius: '4px', 
          backgroundColor: 'level2',
          height: '40px',
          paddingLeft: '18px',
        }}
      >
        <LeftActionDiv>
          <ODLaneIcon className="laneIconLeft" />
          <span className="loadId">{loadID}</span>
          <ArrowForwardIcon />
          <PersonIcon />
          <span className="driverId">{currDriverID}</span>
        </LeftActionDiv>

        <ODCloseButton aria-label="remove" onClick={handleCloseIcon(loadID)} />
      </Grid>
    );
  };

  const confirmDynamicDescription = () => {
    if (!selectLoadIDs || !selectLoadIDs.length) return null;

    return (
      <>
        <Box sx={{ maxHeight: '146px', overflowY: 'auto', maxWidth: '392px' }}>
          {selectLoadIDs.map(getAssignmentPill)}
        </Box>
      </>
    );
  };

  return (
    <ConfirmContainer
      open={open}
      onAction={(bool) => handleConfirmation(bool)}
      title="Confirm Assignment"
      source="table"
    >
      {confirmDynamicDescription()}
    </ConfirmContainer>
  );
}

const AssignDriversDialogContainer = (props: AssignDriversDialogContainerProps) => {
  const { open } = props;
  const selectLoadIDs = useSelector((state: RootState) => state.dispatchingReducer?.selectLoadIDs);
  const selectDriverLoadTable = useSelector((state: RootState) => state.dispatchingReducer?.selectDriverLoadTable);
  const selectMovementTable = useSelector((state: RootState) => state.dispatchingReducer?.selectMovementTable);
  const engineRunId = useSelector((state: RootState) => state.dispatchingReducer?.engineRunId);
  
  if (!selectLoadIDs?.length || !selectDriverLoadTable || !selectMovementTable) return null;
  if (!open) return null;

  return (
    <AssignDriversDialog
      {...props}
      selectLoadIDs={selectLoadIDs}
      selectDriverLoadTable={selectDriverLoadTable}
      selectMovementTable={selectMovementTable}
      engineRunId={engineRunId}
    />
  );
};

export default AssignDriversDialogContainer;
