import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import ArrowForwardSharp from '@mui/icons-material/ArrowForwardSharp';
import PersonSharp from '@mui/icons-material/PersonSharp';
import { ODLaneIcon, ODCloseButton } from '@OptimalDynamics/core-ai-common-ui';
import { EmptyMoveIcon } from '../../../common/icons';
import { ConfirmContainer } from '../../../common/ConfirmContainer';
import { removeLoadAssignment, clearLoadAssignments } from '../../../store/actions/dispatchingAction';
import instance from '../../../utils/axios_instance';

export default function AssignLoadsDialog(props) {
  const { 
    open, 
    handleClose,
    setRefreshDashboard,
    setTopLoadSlideoutOpen,
    clearSelectedRows,
    dialogOrigin, // slideout | main
  } = props;

  const dispatch = useDispatch();
  const selectDriverIDs = useSelector((state) => state.dispatchingReducer?.selectDriverIDs);
  const selectDriverLoadTable = useSelector((state) => state.dispatchingReducer?.selectDriverLoadTable);
  const selectMovementTable = useSelector((state) => state.dispatchingReducer?.selectMovementTable);
  const engineRunId = useSelector((state) => state.dispatchingReducer?.engineRunId);

  const confirmAssignments = async () => {
    const assignments = getAssignments();
    const response = await instance.post('/dispatching/drivers/loads-assignments/', { assignments, engine_run_id: engineRunId });

    handleClose();
    dispatch(clearLoadAssignments());

    if (response?.data?.success === false) {
      console.error(response.data.errors);
    }
    
    if (response && response.status === 200) {
      switch (dialogOrigin) {
        case 'slideout':
          setTopLoadSlideoutOpen(false);
          setRefreshDashboard(true);
          break;

        case 'main':
          clearSelectedRows();
          setRefreshDashboard(true);
          break;

        default:
          console.warn('prop dialogOrigin as referenced in src/components/dispatching/AssignLoadsDialog#handleConfirmation is not an appropriate value.');
          break;
      }
    } else {
      console.warn('seems /dispatching/driver/load-assign/ endpoint is not working...');
      setTopLoadSlideoutOpen(false);
    }
  };

  const cancelAssignments = () => {
    handleClose();
    dispatch(clearLoadAssignments());
  };

  const getAssignments = () => selectDriverIDs.map((driver_id) => {
    const obj = {
      movement_option_id: parseInt(selectMovementTable[driver_id], 10),
      driver_id
    };
    if (selectDriverLoadTable[driver_id]?.length) {
      obj.load_id = selectDriverLoadTable[driver_id];
    }
    
    return obj;
  });

  const handleConfirmation = (willAssign) => {
    !!willAssign && confirmAssignments();
    !willAssign && cancelAssignments();
  };

  const handleCloseIcon = (driverID) => (evt) => {
    evt.stopPropagation();

    if (selectDriverIDs.length === 1) {
      handleClose();
    }
    
    dispatch(removeLoadAssignment(driverID));
  };

  const getAssignmentPill = (driverID) => {
    const currLoadID = selectDriverLoadTable[driverID];

    return (
      <Box
        key={currLoadID}
        sx={{
          display: 'inline-flex',
          width: '100%',
          maxWidth: '392px',
          justifyContent: 'space-between', 
          marginTop: '2px', 
          borderRadius: '4px', 
          backgroundColor: 'colors.lightBlue1',
          color: 'text.primary',
          height: '40px',
          paddingLeft: '18px',
        }}
      >
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            color: 'text.primary',
            fontSize: '14px',
          }}
        >
          <PersonSharp sx={{ color: 'text.primary', marginRight: '8px' }} />
          <strong className="id-label">{driverID}</strong>
          <ArrowForwardSharp sx={{ margin: '0 16px' }} />
          {!!currLoadID ? <ODLaneIcon /> : <EmptyMoveIcon /> }
          <strong style={{ marginLeft: '8px' }}>{currLoadID || 'Home Time'}</strong>
        </Box>

        <ODCloseButton aria-label="remove" onClick={handleCloseIcon(driverID)} />
      </Box>
    );
  };

  return (
    <ConfirmContainer
      open={open}
      onAction={(bool) => handleConfirmation(bool)}
      title="Confirm Assignment"
      confirmTxt="Confirm"
      source="table"
    >
      <Box sx={{ maxWidth: '392px' }}>
        <Typography sx={{ fontSize: 12, color: 'colors.neutral6' }}>{`${selectDriverIDs?.length} Assignment Selected`}</Typography>
        {!!selectDriverIDs?.length && (
          <Box
            sx={{
              maxHeight: '146px',
              overflowY: 'auto',
              maxWidth: '392px',
              border: '1px solid',
              borderColor: 'colors.neutral1',
              borderRadius: '4px',
              padding: '8px',
            }}
          >
            {selectDriverIDs.map(getAssignmentPill)}
          </Box>
        )}
      </Box>
    </ConfirmContainer>
  );
}
