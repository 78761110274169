import React, { createContext } from 'react';
import { GlobalAlertsDrawerContextWrapper } from './globalAlertCtx';
import { ViewPortContextWrapper } from './viewPortCtx';
import { LoadsCommitmentContextWrapper } from './loadsCommitmentCtx';
import { DateFilterContextWrapper } from './dateFilterContext';
import { LaneDetailRegionCtxWrapper } from './laneDetailRegionCtx';
import { LaneDetailTabCtxWrapper } from './laneDetailTabCtx';
import { LaneDetailViewCtxWrapper } from './laneDetailViewCtx';
import { FilterLAContextWrapper } from './filterLACtx';
import { DriverLoadDetailContextWrapper } from './driverLoadDetailContext';
import { TwoWayConfirmationCtxWrapper } from './twoWayConfirmationContext';
import { SearchContextWrapper } from './searchContext';
import { SessionContextWrapper } from './sessionCtx';
import { combineComponents } from '../helpers';

const DefaultContext = createContext();

export const DefaultContextWrapper = ({ children }) => (
  <DefaultContext.Provider value>
    <GlobalAlertsDrawerContextWrapper>
      { children }
    </GlobalAlertsDrawerContextWrapper>
  </DefaultContext.Provider>
);

export const LoadAcceptanceCtxWrapper = combineComponents(
  ViewPortContextWrapper,
  LoadsCommitmentContextWrapper,
  FilterLAContextWrapper,
  DateFilterContextWrapper,
  TwoWayConfirmationCtxWrapper
);

export const TacticalProcurementCtxWrapper = ({ children }) => (
  <LaneDetailRegionCtxWrapper>
    <GlobalAlertsDrawerContextWrapper>
      <LaneDetailTabCtxWrapper>
        <LaneDetailViewCtxWrapper>
          { children }
        </LaneDetailViewCtxWrapper>
      </LaneDetailTabCtxWrapper>
    </GlobalAlertsDrawerContextWrapper>
  </LaneDetailRegionCtxWrapper>
);

export const DispatchingCtxWrapper = ({ children }) => (
  <GlobalAlertsDrawerContextWrapper>
    <DriverLoadDetailContextWrapper>
      <SearchContextWrapper>
        <SessionContextWrapper>
          { children }
        </SessionContextWrapper>
      </SearchContextWrapper>
    </DriverLoadDetailContextWrapper>
  </GlobalAlertsDrawerContextWrapper>
);
