import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { ODAvatar } from '@OptimalDynamics/core-ai-common-ui';
import CloseSharp from '@mui/icons-material/CloseSharp';

interface ODInfoBlurbProps {
  icon: ReactElement;
  children: string | ReactElement | ReactElement[];
  variant?: 'error' | 'warning' | 'blue';
  onClose?: () => void;
}

const ODInfoBlurb = ({ icon, children, variant, onClose }: ODInfoBlurbProps) => {
  const [backgroundColor, textColor] = (() => {
    switch (variant) {
      case 'error':
        return ['alert.main', 'text.inverse'];
      case 'warning':
        return ['warning.main', 'text.primary'];
      case 'blue':
        return ['optimalblue.main', 'text.inverse'];
      default:
        return ['colors.lightBlue1', 'text.primary'];
    }
  })();

  return (
    <Box
      sx={{
        display: 'grid',
        columnGap: '16px',
        gridTemplateColumns: `18px auto${!!onClose ? ' 32px' : ''}`,
        fontSize: 14,
        backgroundColor,
        color: textColor,
        borderRadius: '4px',
        width: 'calc(100% - 32px)',
        minHeight: 32,
        padding: '4px 16px'
      }}
    >
      {icon && (
        <Box sx={{ alignSelf: 'start', mt: '7px' }}>
          {icon}
        </Box>
      )}
      <Typography sx={{ alignSelf: 'center', whiteSpace: 'normal', button: { verticalAlign: 'unset' } }}>{children}</Typography>
      {!!onClose && <ODAvatar variant="blue" size="small" onClick={onClose}><CloseSharp /></ODAvatar>}
    </Box>
  );
};

export default ODInfoBlurb;
