/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import instance from './axios_instance';
import { randomIntInterval } from './math';
import { env } from '../env';

/**
 * @param {[]} arrItems 
 * @param  {...function} fns 
 * @returns a new array mapped over all of the functions passed in the ...fns params;
 */
export const pipeMapFns = (arrItems, ...fns) => {
  let value = [...arrItems];

  fns.forEach((fn) => {
    value = value.map(fn);
  });

  return value;
};

export const pipeReduceFns = (arr, ...reducerArrays) => {
  let value = [...arr];

  reducerArrays.forEach((funcArr) => {
    const [func, initValue] = funcArr;
    value = !funcArr.length ? value.reduce(func) : value.reduce(func, initValue);
  });

  return value;
};

export const capitalize = (str) => str[0].toUpperCase() + str.slice(1);

/**
 * Function that takes a passed param and if it's an object, returns true if empty/null or false otherwise. 
 * All other typeof params will throw an error.
 * @param {object | []} obj 
 * @returns {boolean} 
 */
export const isEmptyOrNull = (obj) => {
  const objType = typeof obj;
  
  if (objType !== 'object') throw new Error(`Expected passed param to have typeof "object". Got typeof "${objType}" instead.`);

  if (obj === null) {
    return true;
  } 
  return Array.isArray(obj) ? obj.length === 0 : Object.keys(obj).length === 0;
};

export const convertStringTo = {
  intRoundDown: (num) => !!num ? Math.floor(+num) : !!num,
  intRoundUp: (num) => !!num ? Math.ceil(+num) : !!num,
};

export const objToQueryParams = (queryObj) => {
  const queryKeys = Object.keys(queryObj);
  return queryKeys.length > 0
    ? `?${queryKeys.map((key) => `${key}=${queryObj[key]}`).join('&')}`
    : '';
};

export const pickOne = (arr) => {
  const idx = randomIntInterval(0, arr.length - 1);
  return arr[idx];
};

export const cutByPaginatedIndexes = (rowsPerPage, pageNo) => {
  const minIndex = pageNo === 0 ? 0 : pageNo * rowsPerPage;
  const maxIndex = pageNo === 0 ? rowsPerPage : (pageNo + 1) * rowsPerPage;
  // console.info(`minIndex: ${minIndex}, maxIndex: ${maxIndex}`);
  return (_row, index) => minIndex <= index && index < maxIndex;
};

/**
 * @param { String } flag
 * @param {JSX.Element} Component 
 * @param {Boolean} forceRender 
 * @returns Passed Component if the flag evaluates true, else empty React.Fragment
 */
export const withFlagHOC = (flagFieldName, Component, forceRender = false) => ({ ...props }) => {
  const flags = useFlags();

  if (!!flags[flagFieldName] || !!forceRender) {
    const gatingFlag = { [flagFieldName]: flags[flagFieldName] };
    return (
      <Component 
        {...props} 
        gatingFlag={gatingFlag}
      />
    );
  }

  console.warn('Feature-Flag: "%s" was found to be either false or non-existent. Please consult whoever manages your team\'s launch-darkly rights.', flagFieldName);

  return <></>;
};

export const snakeToCamelCase = (snakeCaseStr) => snakeCaseStr.split('_').reduce((acc, next, index) => {
  acc += index === 0 ? next : next[0].toUpperCase() + next.slice(1);
  return acc;
}, '');

export const checkEnv = {
  isLocal: () => env.REACT_APP_ENV === 'local'
};

export const createKey = (keyPrefix, index, optionalSuffix = '') => `${keyPrefix}--${index < 10 ? '0' : ''}${index}==${Date.parse(new Date())}${!!optionalSuffix ? `--${optionalSuffix}` : ''}`;

export const WrapWithFlag = (props) => {
  const { flagName, children, showIfFalse = false } = props;
  const flags = useFlags();

  const flagValue = flags[flagName];

  const canShowChildren = (!!showIfFalse && !flagValue) || (!!flagValue && !showIfFalse);

  if (flagValue === undefined) {
    console.warn(`Flag with designation ${flagName} was undefined. Check useFlags() hook or launch-darkly.`);
    return <></>;
  }

  if (!!canShowChildren) {
    return (
      <>
        {children}
      </>
    );
  }

  return <></>;
};

/**
 * a JS copy-paste of await-to npm module
 * @param {Promise} promise 
 * @param {undefined | any} errorExt 
 * @returns a tuple/ array of length 2: @ index 0 -> the error object && @ index 1 -> the 2nd is the resolved object from the fulfilled promise
 */
export const to = (promise, errorExt = null) => promise
  .then((data) => [null, data])
  .catch((err) => {
    if (errorExt) {
      // eslint-disable-next-line
      const parsedError = Object.assign({}, err, errorExt);
      return [parsedError, undefined];
    }

    return [err, undefined];
  });

const consumeAPI = (url, verb, option = null) => {
  if (!!option) {
    return instance[verb](url, option);
  }

  return instance[verb](url);
};

export const getAwaitToUrl = async (url) => {
  const [error, resolved] = await to(consumeAPI(url, 'get'));
  return [error, resolved];
};

export const postAwaitToUrl = async (url, option) => {
  const [error, resolved] = await to(consumeAPI(url, 'post', option));
  return [error, resolved];
};

export const putAwaitToUrl = async (url, option) => {
  const [error, resolved] = await to(consumeAPI(url, 'put', option));
  return [error, resolved];
};

export const patchAwaitToUrl = async (url, option) => {
  const [error, resolved] = await to(consumeAPI(url, 'patch', option));
  return [error, resolved];
};

export const deleteAwaitToUrl = async (url, option = null) => {
  const [error, resolved] = await to(consumeAPI(url, 'delete', option));
  return [error, resolved];
};

/**
 * Function used to return a tuple (array length 2) that returns a var for action.type & an action creator (func)
 * @param {string} actionName 
 * @returns {[ string, function ]} an array of 2 items: action.type var & action creator
 */
export const createActionSet = (actionName) => {
  const newAction = { type: actionName };

  const actionCreator = (payload = null) => !!payload ? { ...newAction, payload } : { ...newAction };

  return [actionName, actionCreator];
};

export const removeByKeysValue = (targetKey, dict) => Object.entries(dict).reduce((table, pair) => {
  const [key, value] = pair;
  if (key !== targetKey) {
    table[key] = value;
  }
  return table;
}, {});

export const hasNoRepeats = (arr) => arr.map((x) => x).sort().every((item, index) => index === 0 ? true : JSON.stringify(item) !== JSON.stringify(arr[index - 1]));

export const hasRepeats = (arr) => !hasNoRepeats(arr);

/**
 * 
 * @param  {...Components} components 
 * @returns A single, nested Component composed of all components passed as params in the order of outermost to innermost (left -> right).
 */
export const combineComponents = (...components) => 
  components.reduceRight(
    (AccumulatedComponents, CurrentComponent) => 
      ({ children }) => (
        <CurrentComponent>
          <AccumulatedComponents>
            {children}
          </AccumulatedComponents>
        </CurrentComponent>
      ),
    ({ children }) => <>{children}</>
  );

export const withRenderCondition = (WrappedComponent, conditionCB) => (props) => {
  const { children, ...restProps } = props;

  return (
    <>
      {conditionCB() ? (
        <WrappedComponent {...restProps}>
          { children }
        </WrappedComponent>
      ) : null}
    </>
  );
};
 
export const pluralize = (word) => word.lastIndexOf('y') === word.length - 1 
  ? `${word.slice(0, word.length - 1)}ies`
  : `${word}s`;

export const singularize = (word) => word.indexOf('ies') === word.length - 3 ? `${word.slice(0, word.length - 3)}y` : `${word.slice(0, word.length - 1)}`;

export const RenderIf = ({ is, value, children }) => {
  if ((value === is)) {
    return (
      <>{ children }</>
    );
  }

  return null;
};

export const RenderWithWrapperIf = ({ children, Wrapper, WrapProps = {}, cond }) => {
  const conditionMet = typeof cond === 'function' ? cond() : cond;

  if (!!conditionMet) {
    return (
      <Wrapper {...WrapProps}>
        { children }
      </Wrapper>
    );
  }

  return (
    <>
      { children }
    </>
  );
};
