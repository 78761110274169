/* eslint-disable react/react-in-jsx-scope */
import { createContext, useMemo, useState } from 'react';

export const DriverLoadDetailContext = createContext({
  driverLoadDetailStatusObj: null,
  setDriverLoadDetailStatusObj: () => {},
  handleClickDriver: (_driver) => {},
});

export const DriverLoadDetailContextWrapper = ({ children }) => {
  const [driverLoadDetailStatusObj, setDriverLoadDetailStatusObj] = useState(null);

  const handleClickDriver = (driver, cb = () => {}) => {
    setDriverLoadDetailStatusObj(driver);
    cb();
  };

  const slideOutValues = useMemo(() => ({
    driverLoadDetailStatusObj,
    setDriverLoadDetailStatusObj,
    handleClickDriver
  }), [
    driverLoadDetailStatusObj, 
  ]);

  return <DriverLoadDetailContext.Provider value={slideOutValues}>{children}</DriverLoadDetailContext.Provider>;
};
