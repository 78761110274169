import React, { FC } from 'react';
import { Box, Link, Typography } from '@mui/material';

interface SlideoutLineItemProps {
  title: string;
  content: string;
  contentCallback?: () => void;
  multiline?: boolean;
}

const SlideoutLineItem: FC<SlideoutLineItemProps> = ({ title, content, contentCallback, multiline = false }) => (
  <Box
    sx={{
      borderTop: 1,
      borderTopColor: 'level3',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: '10px 16px',
      ...(!multiline ? { display: 'flex' } : {})
    }}
  >
    <Typography sx={{ alignSelf: 'start' }}><strong>{title}</strong></Typography>
    {multiline ? (
      <Box>{content}</Box>
    ) : (
      <Typography sx={{ maxWidth: '50%', textAlign: 'right' }}>
        <Link
          component="button"
          variant={!contentCallback ? 'disabled' : 'default'}
          onClick={contentCallback}
          disabled={!contentCallback}
        >
          {content}
        </Link>
      </Typography>
    )}
  </Box>
);

export default SlideoutLineItem;
