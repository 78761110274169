import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ODLaneIcon, ODSlideOut } from '@OptimalDynamics/core-ai-common-ui';
import SlideoutLineItem from '../../../common/SlideoutLineItem';
import instance from '../../../utils/axios_instance';
import { LoadDetail, LoadDetailsSlideoutProps } from '../types';

const LoadDetailsSlideout = ({ 
  open, 
  onClose,
  load,
}: LoadDetailsSlideoutProps) => {
  const [loadInfo, setLoadInfo] = useState<LoadDetail>();

  useEffect(() => {
    if (load?.load_id) {
      instance.get(`/dispatching/loads/${load.load_id}`)
        .then((res) => setLoadInfo(res.data.load_info))
        .catch((err) => console.error(err));
    }
  }, [load?.load_id]);

  return (
    <ODSlideOut title="Load Details" open={open} onClose={onClose} sx={{ zIndex: 1500 }}>
      <Box
        sx={{
          fontSize: 14,
          margin: '8px 0 16px 0',
          height: 40,
          width: '100%',
          borderRadius: '4px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'colors.lightBlue1'
        }}
      >
        <ODLaneIcon sx={{ mx: 2 }} />
        {load?.load_id}
      </Box>
      <Box sx={{ width: '100%' }}>
        <SlideoutLineItem title="Revenue" content={loadInfo?.revenue ? `$${loadInfo.revenue}` : '-'} />
        <SlideoutLineItem title="Load Weight" content={`${loadInfo?.weight} lbs` || '-'} />
        <SlideoutLineItem title="Shipper Name" multiline content={loadInfo?.shipper_name || '-'} />
        <SlideoutLineItem title="Receiver Name" multiline content={loadInfo?.consignee_name || '-'} />
        <SlideoutLineItem title="Customer Name" multiline content={loadInfo?.bill_to_name || '-'} />
        <SlideoutLineItem title="Notes" multiline content={loadInfo?.notes || 'None'} />
      </Box>
    </ODSlideOut>
  ); 
};

export default LoadDetailsSlideout;
