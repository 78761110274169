import React, { createContext, useMemo, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  MATCHES,
  KEEP_ASSET,
  KEEP_BROKERAGE,
  SWITCH_TO_ASSET,
  SWITCH_TO_BROKERAGE,
  KEEP_BULK,
  REJECT_LOAD,
  ACCEPT_ASSET
} from '../../components/loadAcceptance/loadAcceptanceConstants';
import instance from '../axios_instance';
import { LoadRow, TwoWayConfirmationContextInterface } from '../../components/loadAcceptance/types/types';

export const TwoWayConfirmationContext = createContext<TwoWayConfirmationContextInterface>({
  openConfirmationModal: false,
  openMatchesModal: false,
  selectedRow: null,
  confirmTxt: '',
  isRecommendation: false,
  isKeepTmsAssignment: false,
  isSubmitting: false,
  errorOnSubmission: null,
  closeConfirmationModal: () => {},
  setIsSubmitting: () => {},
  selectRowToConfirm: () => {},
  setSelectedRow: () => {},
  submitTwoWay: (_callback: () => void) => {},
  setIsKeepTmsAssignment: () => {}
});

export const TwoWayConfirmationCtxWrapper = ({ children = null }) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openMatchesModal, setOpenMatchesModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<LoadRow[] | null>(null);
  const [confirmTxt, setConfirmTxt] = useState('');
  const [isRecommendation, setIsRecommendation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorOnSubmission, setErrorOnSubmission] = useState<string[] | null>(null);
  const [isKeepTmsAssignment, setIsKeepTmsAssignment] = useState(false);
  const { orgUseTenderedLaWorkflow16, useKeepOnBrokerageButtonLa } = useFlags();

  const selectRowToConfirm = (row: LoadRow, action: string, isRec: boolean, isKeep: boolean) => {
    setSelectedRow(Array.isArray(row) ? row : [row]);
    setConfirmTxt(action);
    setIsRecommendation(isRec);
    setIsKeepTmsAssignment(isKeep);

    const considerTender = orgUseTenderedLaWorkflow16 && (Array.isArray(row) ? row.some((r) => r.is_tender_load) : row?.is_tender_load);
    if (!considerTender && !useKeepOnBrokerageButtonLa && row?.optimized_assignment === MATCHES && !action.includes('switch')) {
      setOpenMatchesModal(true);
    } else {
      setOpenConfirmationModal(true);
    }
  };

  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setOpenMatchesModal(false);
    setSelectedRow(null);
    setConfirmTxt('');
    setIsRecommendation(false);
    setIsKeepTmsAssignment(false);
  };

  const postSubmissionList = async (engineRunId: number, assetList: string[], brokerageList: string[], postSuccessCallback: () => void) => {
    setIsSubmitting(true);
    const submission = {
      engine_run_id: engineRunId,
      assignments: {
        ...(assetList.length > 0 ? { asset: assetList } : {}),
        ...(brokerageList.length > 0 ? { brokerage: brokerageList } : {})
      }
    };
    await instance
      .post('/load-acceptance/assignments/', submission)
      .then(() => {
        setIsSubmitting(false);
        closeConfirmationModal();
        postSuccessCallback();
      })
      .catch((err) => {
        console.error(err);
        if (err?.response?.data && err?.response?.data?.success === false) {
          setErrorOnSubmission(err?.response?.data?.errors);
          setTimeout(() => {
            setErrorOnSubmission(null);
          }, 10000);
        }
        setIsSubmitting(false);
      });
  };

  const buildSubmissionList = (load_id: string, action: string, assetList: string[], brokerageList: string[], tms_assignment: null | string = null) => {
    switch (action) {
      case KEEP_BULK: {
        if (tms_assignment === 'ASSET') assetList.push(load_id);
        if (tms_assignment === 'BROKERAGE') brokerageList.push(load_id);
        break;
      }
      case MATCHES:
      case REJECT_LOAD:
        if (!orgUseTenderedLaWorkflow16 && !useKeepOnBrokerageButtonLa) break;
        // eslint-disable-next-line no-fallthrough
      case KEEP_BROKERAGE:
      case SWITCH_TO_BROKERAGE: {
        brokerageList.push(load_id);
        break;
      }
      case ACCEPT_ASSET:
      case KEEP_ASSET:
      case SWITCH_TO_ASSET: {
        assetList.push(load_id);
        break;
      }
      default: {
        break;
      }
    }
  };

  const submitTwoWay = (postSuccessCallback = () => {}) => {
    // problematic if multiple loads have different engine run ids
    const engineRunId = selectedRow?.[0].engine_run;
    const assetList: string[] = [];
    const brokerageList: string[] = [];

    if (!selectedRow || !engineRunId) return;
    if (isRecommendation) {
      selectedRow.map(({ load_id, optimized_assignment }) => buildSubmissionList(load_id, optimized_assignment, assetList, brokerageList));
    } else {
      selectedRow.map(({ load_id, tms_assignment }) => buildSubmissionList(load_id, confirmTxt, assetList, brokerageList, tms_assignment));
    }
    postSubmissionList(engineRunId, assetList, brokerageList, postSuccessCallback);
  };

  const memoValue = useMemo(() => ({
    openMatchesModal,
    openConfirmationModal,
    selectedRow,
    confirmTxt,
    isRecommendation,
    isKeepTmsAssignment,
    isSubmitting,
    errorOnSubmission,
    closeConfirmationModal,
    setIsSubmitting,
    selectRowToConfirm,
    setSelectedRow,
    submitTwoWay,
    setIsKeepTmsAssignment
  }), [
    openConfirmationModal,
    openMatchesModal,
    selectedRow,
    confirmTxt,
    isRecommendation,
    isKeepTmsAssignment,
    isSubmitting,
    errorOnSubmission
  ]);

  return (
    <TwoWayConfirmationContext.Provider
      value={memoValue}
    >
      { children }
    </TwoWayConfirmationContext.Provider>
  );
};
