import React, { ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { ConfirmContainer } from '../../../common';
import { NEEDS_SOURCING } from '../helpers/constants';

interface AssignmentIntroProps {
  isBrokerage: boolean;
  pendingSourceRequest: boolean;
  isNewSourceRequest: boolean;
}

export const AssignmentIntro = ({ isBrokerage, isNewSourceRequest, pendingSourceRequest }: AssignmentIntroProps) => {
  const [searchParams] = useSearchParams();
  const onNeedsSourcingTab = searchParams.get('view_type') === NEEDS_SOURCING;
  const brokerageWarning = <> Loads currently assigned in your TMS as Brokerage will automatically be changed to asset.</>;

  if (pendingSourceRequest && isNewSourceRequest) {
    return (
      <>
        <strong>Are you sure you want to change the source request?&nbsp;</strong>
        This driver currently has a source request open and confirming this new source request will cancel the pending one.
        {isBrokerage && brokerageWarning}
      </>
    );
  }
  if (pendingSourceRequest) {
    if (onNeedsSourcingTab) return <>You are removing the source request on this driver. The driver will no longer be in the &lsquo;Needs Sourcing&rsquo; queue.</>;
    return (
      <>
        <strong>Are you sure you want to assign this load?&nbsp;</strong>
        This driver currently has a source request open and confirming the assignment will cancel the source request.
        {isBrokerage && brokerageWarning}
      </>
    );
  }
  if (isNewSourceRequest) {
    return (
      <>
        Sending this request will move the driver to the &lsquo;Needs Sourcing&rsquo; queue.
        {isBrokerage && brokerageWarning}
      </>
    );
  }
  if (isBrokerage) return <>This load is currently assigned in your TMS as a brokerage load. By assigning this load, it will automatically be changed from brokerage to asset.</>;
  return null;
};

interface ConfirmAssignmentProps {
  title: string;
  location: string;
  open: boolean;
  isBrokerage: boolean;
  pendingSourceRequest: boolean;
  isNewSourceRequest: boolean;
  assignment: ReactElement;
  handleClick: (_b: boolean) => void;
  conclusion?: string;
}

const ConfirmAssignment = ({ title, location, open, isBrokerage, pendingSourceRequest, isNewSourceRequest, assignment, handleClick, conclusion }: ConfirmAssignmentProps) => {
  const intro = <AssignmentIntro pendingSourceRequest={pendingSourceRequest} isNewSourceRequest={isNewSourceRequest} isBrokerage={isBrokerage} />;

  return (
    <ConfirmContainer
      title={title}
      open={open}
      onAction={handleClick}
      source={location}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
        {!!intro && <Typography>{intro}</Typography>}
        {assignment}
        {((!isBrokerage || !pendingSourceRequest) && !!conclusion) && <Typography>{conclusion}</Typography>}
      </Box>
    </ConfirmContainer>
  );
};

export default ConfirmAssignment;
