import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { minBy } from 'lodash';
import { ODLoader } from '@OptimalDynamics/core-ai-common-ui';
import ProtectedRoute from './auth/protectedRoute';
import PageNotFound from './components/404/page_not_found';
import DispatchingTableView from './components/dispatching/DispatchingTableView';
import Layout from './layouts/Layout';
import { LoadAcceptanceCtxWrapper, SettingsContextWrapper } from './utils/context';
import { DefaultContextWrapper, DispatchingCtxWrapper, TacticalProcurementCtxWrapper } from './utils/context/compositeWrappersCtx';
import Login from './components/login/login';
import { TRAILER_ASSIGNMENTS, DISPATCHING, LOAD_ACCEPTANCE, COMMITMENT_MANAGER, TACTICAL_PROCUREMENT, LOAD_DEMAND_FORECAST, APP_SUBPAGES } from './AppConstants';
import { BY_DRIVER, BY_LOAD } from './components/dispatching/helpers/constants';

const LoadAcceptance = lazy(() => import('./components/loadAcceptance/LoadAcceptance'));
const Dispatching = lazy(() => import('./components/dispatching/Dispatching'));
const TacticalProcurement = lazy(() => import('./components/tacticalProcurement/TacticalProcurement'));
const LoadAcceptanceDetailsView = lazy(() => import('./components/loadAcceptance/LoadAcceptanceDetailView'));
const CommitmentManager = lazy(() => import('./components/contracts/commitmentManager-view'));
const LoadDemandForecast = lazy(() => import('./components/forecasting/LoadDemandForecast'));
const TrailerAssignments = lazy(() => import('./components/trailerAssignments/TrailerAssignments'));
const TrailerAssignmentsViewMatches = lazy(() => import('./components/trailerAssignments/TAViewMatches/TrailerAssignmentsViewMatches'));

const ExecuteRoutes = ({ errorMessages, validOrg }) => {
  const allFlags = useFlags();
  const {
    showLaUiPage, 
    showDispatchingUiPage, 
    showCommitmentsUiPage, 
    showTpUiPage, 
    showForecastingUiPage, 
    showTrailerAssignmentsUiPage,
  } = allFlags;
  const launchDarklyUserIdentified = useSelector((state) => state.auth.launchDarklyUserIdentified);

  const getDefaultPage = () => {
    const defaultPage = minBy(Object.entries(APP_SUBPAGES), ([_pagePath, details]) => details.defaultRank * (allFlags[details.flag] ? 1 : 10))[0];
    return <Navigate to={`/${defaultPage}`} replace={true} />;
  };

  return (
    <Routes>
      <Route path="/login" element={<Login errorMessages={errorMessages} validOrg={validOrg} />} />
      <Route 
        element={(
          <SettingsContextWrapper>
            <Layout />
          </SettingsContextWrapper>
        )}
      >
        {launchDarklyUserIdentified ? (
          <Route
            path="/"
            element={getDefaultPage()}
          />
        ) : (
          <Route
            path="/"
            element={<ODLoader open />}
          />
        )}
        {showLaUiPage && (
          <>
            <Route
              path={`/${LOAD_ACCEPTANCE}`}
              element={(
                <ProtectedRoute>
                  <Suspense fallback={<ODLoader open />}>
                    <LoadAcceptanceCtxWrapper>
                      <LoadAcceptance />
                    </LoadAcceptanceCtxWrapper>
                  </Suspense>
                </ProtectedRoute>
              )}
            >
              <Route path=":loadId" />
            </Route>
            <Route
              path={`/${LOAD_ACCEPTANCE}-detail`}
              element={(
                <ProtectedRoute>
                  <Suspense fallback={<ODLoader open />}>
                    <LoadAcceptanceCtxWrapper>
                      <LoadAcceptanceDetailsView />
                    </LoadAcceptanceCtxWrapper>
                  </Suspense>
                </ProtectedRoute>
              )}
            />
          </>
        )}
        {showDispatchingUiPage && (
          <Route
            path={`/${DISPATCHING}`}
            element={(
              <ProtectedRoute>
                <Suspense fallback={<ODLoader open />}>
                  <DispatchingCtxWrapper>
                    <Dispatching />
                  </DispatchingCtxWrapper>
                </Suspense>
              </ProtectedRoute>
            )}
          >
            <Route
              path=""
              element={<Navigate to={BY_DRIVER} replace />}
            />
            <Route
              path={BY_DRIVER}
              element={<DispatchingTableView />}
            >
              <Route path=":driverId" />
            </Route>
            <Route
              path={BY_LOAD}
              element={<DispatchingTableView />}
            >
              <Route path=":loadId" />
            </Route>
          </Route>
        )}
        {showCommitmentsUiPage && (
          <Route
            path={`/${COMMITMENT_MANAGER}`}
            element={(
              <ProtectedRoute>
                <Suspense fallback={<ODLoader open />}>
                  <DefaultContextWrapper>
                    <CommitmentManager />
                  </DefaultContextWrapper>
                </Suspense>
              </ProtectedRoute>
            )}
          />
        )}
        {showTpUiPage && (
          <Route
            path={`/${TACTICAL_PROCUREMENT}`}
            element={(
              <ProtectedRoute>
                <Suspense fallback={<ODLoader open />}>
                  <TacticalProcurementCtxWrapper>
                    <TacticalProcurement />
                  </TacticalProcurementCtxWrapper>
                </Suspense>
              </ProtectedRoute>
            )}
          />
        )}
        {showForecastingUiPage && (
          <Route
            path={`/${LOAD_DEMAND_FORECAST}`}
            element={(
              <ProtectedRoute>
                <Suspense fallback={<ODLoader open />}>
                  <DefaultContextWrapper>
                    <LoadDemandForecast />
                  </DefaultContextWrapper>
                </Suspense>
              </ProtectedRoute>
            )}
          />
        )}
        {showTrailerAssignmentsUiPage && (
          <>
            <Route
              path={`/${TRAILER_ASSIGNMENTS}`}
              element={(
                <ProtectedRoute>
                  <Suspense fallback={<ODLoader open />}>
                    <DefaultContextWrapper>
                      <TrailerAssignments />
                    </DefaultContextWrapper>
                  </Suspense>
                </ProtectedRoute>
              )}
            >
              <Route path=":loadId" />
            </Route>
            <Route
              path={`/${TRAILER_ASSIGNMENTS}-detail/:loadId/`}
              element={(
                <ProtectedRoute>
                  <Suspense fallback={<ODLoader open />}>
                    <DefaultContextWrapper>
                      <TrailerAssignmentsViewMatches />
                    </DefaultContextWrapper>
                  </Suspense>
                </ProtectedRoute>
              )}
            />
          </>
        )}
        <Route
          path="*"
          element={<PageNotFound />}
        />
      </Route>
    </Routes>
  );
};

export default ExecuteRoutes;
