import { 
  SET_REGIONAL_LIST, 
  SET_CHILD_CITY_STATE_LIST, 
  ADD_SELECTED_LOAD_IDS,
  ADD_SELECTED_LOAD_ID,
  REMOVE_SELECTED_LOAD_IDS,
  REMOVE_SELECTED_LOAD_ID,
  REMOVE_ASSIGNED_LOAD_IDS,
  ADD_ASSIGNED_LOAD_IDS,
  CLEAR_ASSIGNED_LOAD_IDS,
  ADD_TO_LOAD_IDS_TABLE,
  ADD_ENTRIES_TO_LOAD_IDS_TABLE,
  CLEAR_LOAD_IDS_TABLE,
  ADD_TO_SELECTED_LOADS_AUDITOR,
  CLEAR_SELECTED_LOADS_AUDITOR,
} from '../constants/actionTypes';

const initialState = {
  regions: [],
  childCityStateList: [],
  selectedLoadIDs: [],
  assignedLoadIDs: [],
  loadIDsTable: {},
  selectedLoadsAuditor: {},
};

const addToSelectedLoadsAuditor = (state, { payload }) => {
  const { selectedLoadsAuditor: previousAuditor } = state;
  const selectedLoadsAuditor = { ...previousAuditor, ...payload };
  return { ...state, selectedLoadsAuditor };
};

const clearSelectedLoadsAuditor = (state) => (
  {
    ...state,
    selectedLoadsAuditor: {}
  }
);

const setRegionalList = (state, { regions }) => (
  {
    ...state,
    regions
  }
);

const setChildCityStateList = (state, { childCityStateList }) => (
  {
    ...state,
    childCityStateList
  }
);

const addLoadIds = (state, { payload }) => {
  const { selectedLoadIDs } = state;
  if (!!selectedLoadIDs.length) {
    const newLoadIDs = Array.from(new Set([...selectedLoadIDs, ...payload]).values());
    return {
      ...state,
      selectedLoadIDs: [...newLoadIDs],
    };
  }

  return {
    ...state,
    selectedLoadIDs: payload
  };
};

const addLoadId = (state, { payload }) => {
  const { selectedLoadIDs } = state;
  if (!selectedLoadIDs.includes(payload)) {
    return {
      ...state,
      selectedLoadIDs: [...selectedLoadIDs, payload]
    };
  }
  const newLoadIDs = Array.from(new Set([...selectedLoadIDs, payload]).values());
  return {
    ...state,
    selectedLoadIDs: [...newLoadIDs]
  };
};

const removeLoadIDs = (state, { payload }) => {
  const { selectedLoadIDs } = state; 
  const changedLoads = selectedLoadIDs.filter((loadID) => !payload.includes(loadID));
  return {
    ...state,
    selectedLoadIDs: [...changedLoads]
  };
};

const removeLoadID = (state, { payload }) => {
  const { selectedLoadIDs } = state;
  const changedLoads = selectedLoadIDs.filter((loadID) => loadID !== payload);
  return {
    ...state,
    selectedLoadIDs: [...changedLoads]
  };
};

const removeAssignedLoadIDs = (state, { payload }) => {
  const { assignedLoadIDs: prevAssignedLoadIDs } = state;
  const assignedLoadIDs = prevAssignedLoadIDs.filter((loadID) => !payload.includes(loadID));
  return {
    ...state,
    assignedLoadIDs
  };
};

const addAssignedLoadIds = (state, { payload }) => {
  const { assignedLoadIDs: prevAssignedLoadIDs } = state;
  const assignedLoadIDs = prevAssignedLoadIDs.concat(payload.filter((loadID) => !prevAssignedLoadIDs.includes(loadID)));
  return {
    ...state,
    assignedLoadIDs
  };
};

const clearAssignedLoadIds = (state) => (
  {
    ...state,
    assignedLoadIDs: []
  }
);

const clearLoadIdsTable = (state) => (
  {
    ...state,
    loadIDsTable: {}
  }
);

const addToLoadIDsTable = (state, { payload }) => {
  const { id, load_id, direction, region } = payload;
  const { loadIDsTable } = state;

  return {
    ...state,
    loadIDsTable: { ...loadIDsTable, [id]: { load_id, direction, region } }
  };
};

const addEntriesToLoadIDsTable = (state, { payload }) => {
  const { loadIDsTable } = state;
  const entriesTable = {};
  payload.forEach((entry) => {
    const { id, load_id, direction, region } = entry;
    entriesTable[id] = { load_id, direction, region };
  });
  // const { id, load_id } = payload;

  return {
    ...state,
    loadIDsTable: { ...loadIDsTable, ...entriesTable }
  };
};

const tacticalProcurementReducer = (state = initialState, action = null) => {
  if (!action) return state;
  switch (action.type) {
    case SET_REGIONAL_LIST:
      return setRegionalList(state, action);

    case SET_CHILD_CITY_STATE_LIST:
      return setChildCityStateList(state, action);

    case ADD_SELECTED_LOAD_IDS:
      return addLoadIds(state, action);

    case ADD_SELECTED_LOAD_ID:
      return addLoadId(state, action);

    case REMOVE_SELECTED_LOAD_IDS:
      return removeLoadIDs(state, action);

    case REMOVE_SELECTED_LOAD_ID:
      return removeLoadID(state, action);

    case REMOVE_ASSIGNED_LOAD_IDS:
      return removeAssignedLoadIDs(state, action);

    case ADD_ASSIGNED_LOAD_IDS:
      return addAssignedLoadIds(state, action);

    case CLEAR_ASSIGNED_LOAD_IDS:
      return clearAssignedLoadIds(state);

    case ADD_TO_LOAD_IDS_TABLE:
      return addToLoadIDsTable(state, action);

    case ADD_ENTRIES_TO_LOAD_IDS_TABLE:
      return addEntriesToLoadIDsTable(state, action);

    case CLEAR_LOAD_IDS_TABLE:
      return clearLoadIdsTable(state);
    
    case ADD_TO_SELECTED_LOADS_AUDITOR:
      return addToSelectedLoadsAuditor(state, action);

    case CLEAR_SELECTED_LOADS_AUDITOR:
      return clearSelectedLoadsAuditor(state);
        
    default:
      return state;
  }
};

export default tacticalProcurementReducer;
