import { Box, styled } from '@mui/material';

const openedMixin = (theme) => ({
  width: `calc(100% - 812px)`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  borderLeft: 'none'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  borderLeft: 'none'
});

const DispatchingContainer = styled(Box, { shouldForwardProp: (props) => !['open'].includes(props) })(
  ({ theme, open }) => ({
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '&.MuiBox-root': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '&.MuiBox-root': closedMixin(theme),
    })
  }));

export default DispatchingContainer;
