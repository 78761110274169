import React from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';

const ODLoader = ({ color }: { color?: string }) => {
  const theme = useTheme();

  return (
    <CircularProgress
      size={24}
      thickness={5}
      sx={{
        color: color ?? 'optimalblue.main',
        borderRadius: '100%',
        boxShadow: `inset 0 0 0px 3px ${theme.palette.colors.neutral2}`,
        backgroundColor: 'transparent'
      }}
    /> 
  );
};

interface ODLoaderProps {
  loadingText?: string;
  color?: string;
}

const ODLoaderLabeled = ({ loadingText, color }: ODLoaderProps) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '8px', alignItems: 'center' }}>
    <ODLoader color={color} />
    <Typography><strong>{loadingText ?? 'Loading...'}</strong></Typography>
  </Box>
);

export default ODLoaderLabeled;
