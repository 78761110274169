import React from 'react';
import { styled, Drawer as MuiDrawer } from '@mui/material';
import { useMatch } from 'react-router-dom';

const openedMixin = (theme, isSearchResult) => ({
  width: 812,
  right: 56,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  borderLeft: 'none',
  '& .MuiPaper-root': {
    borderLeft: `1px solid ${theme.palette.level3} !important`,
    height: isSearchResult ? 'calc(100vh - 129px)' : 'calc(100vh - 185px)',
    top: isSearchResult ? 130 : 186,
    backgroundColor: 'transparent',
  }
});

const closedMixin = (theme, isSearchResult) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  '& .MuiPaper-root': {
    borderLeft: 'none',
    height: isSearchResult ? 'calc(100vh - 129px)' : 'calc(100vh - 185px)',
    top: isSearchResult ? 130 : 186,
  }
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => !['open', 'isSearchResult'].includes(prop) })(
  ({ theme, open, isSearchResult }) => ({
    width: 56,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiPaper-root': {
      height: isSearchResult ? 'calc(100vh - 129px)' : 'calc(100vh - 185px)',
      top: 186,
    },
    borderLeft: 'none',
    ...(open && {
      ...openedMixin(theme, isSearchResult),
      '.MuiDrawer-paper': { ...openedMixin(theme, isSearchResult) },
    }),
    ...(!open && {
      ...closedMixin(theme, isSearchResult),
      '& .MuiDrawer-paper': { ...closedMixin(theme, isSearchResult), width: 56, },
    }),
  }));

const RightDrawer = ({ open, children }) => {
  const matchesDriver = useMatch('/dispatching/by-driver/:driverId');
  const matchesLoad = useMatch('/dispatching/by-load/:loadId');

  return (
    <Drawer
      open={open}
      variant="permanent"
      anchor="right"
      isSearchResult={matchesDriver || matchesLoad}
    >
      {children}
    </Drawer>
  );
};

export default RightDrawer;
