/* eslint-disable no-restricted-syntax */
import moment from 'moment-timezone';
import { DEFAULT_TIMEZONE, getWindowDayDiff } from './datetimes';

const notificationTable = {
  hasCall: false,
  iconCount: 1,
  alert: 0,
  information: 0,
};

/**
 * 
 * @param {*} load 
 * @returns load with newly added "advisory" object used to more optimally account for advisories (notifications, alerts, etc.)
 */
export const mapLoadsNotificationsData = (load) => {
  const { notification, nonasset_permission_required } = load;
  load = { ...load, ...notificationTable };

  if (notification.length > 0) {
    for (const advisory of notification) {
      const { notification_read, notification_level } = advisory;
    
      if (notification_read === false && load[notification_level] === 0) {
        load[notification_level] += 1;
        load.iconCount += 1;
      }
    }
  }

  if (nonasset_permission_required === true) {
    load.hasCall = true;
    load.iconCount += 1;
  }

  return load;
};

/**
 * 
 * @param {*} load 
 * @returns load(s) with new "pickup_window" && "delivery_window" key-value pairs in MM/DD HH:MM-HH:MM (+day_diff) format 
 */
export const mapLoadsTimeWindows = (load) => {
  const {
    pickup_start_date_time,
    pickup_end_date_time,
    pickup_timezone = DEFAULT_TIMEZONE,
    delivery_start_date_time,
    delivery_end_date_time,
    delivery_timezone = DEFAULT_TIMEZONE
  } = load;
  load.pickup_window = getWindowDayDiff(moment.tz(pickup_start_date_time, pickup_timezone), moment.tz(pickup_end_date_time, pickup_timezone));
  load.delivery_window = getWindowDayDiff(moment.tz(delivery_start_date_time, delivery_timezone), moment.tz(delivery_end_date_time, delivery_timezone));
  return load;
};

const initAdvisoryTable = (load, _debugging = false) => {
  const engineLoadStatus = load?.engine_load_status || 'N/A';

  if (!!_debugging && engineLoadStatus === 'N/A') { 
    console.warn(`load with load_id: ${load.load_id} has indeterminate engine_load_status.`);
  }

  return {
    alertCount: 0,
    information: 0,
    engineLoadStatus
  };
};

const getAdvisoriesTable = (table, load) => {
  const { alert, information } = load;
  table.alertCount += alert;
  table.information += information;
  return table;
};

export const reduceToAdvisoryTable = (loads) => (
  loads?.filter((load) => load.iconCount >= 2)
    .reduce(getAdvisoriesTable, initAdvisoryTable(loads[0] || []))
);

const sortStringAttrs = (sortKey, isAsc, loads) => 
  loads.sort((a, b) => isAsc ? a[sortKey].localeCompare(b[sortKey]) : b[sortKey].localeCompare(a[sortKey]));

const sortByCity = (sortKey, isAsc, loads) => {
  const stateKey = (sortKey === 'origin_city' || sortKey === 'pickup_city') ? 'origin_state' : 'destination_state';
  const cityKey = (sortKey === 'origin_city' || sortKey === 'pickup_city') ? 'origin_city' : 'destination_city';
 
  return loads.sort((a, b) => 
    isAsc 
      ? a[stateKey].localeCompare(b[stateKey])
      : b[stateKey].localeCompare(a[stateKey]))
    .sort((c, d) =>
      isAsc
        ? c[cityKey].localeCompare(d[cityKey])
        : d[cityKey].localeCompare(c[cityKey]));
};

const sortByStartDateTime = (sortKey, isAsc, loads) => 
  loads.sort((a, b) => 
    isAsc
      ? new Date(a[sortKey]) - new Date(b[sortKey])
      : new Date(b[sortKey]) - new Date(a[sortKey]));

const sortByRangeArray = (sortKey, isAsc, loads) => 
  loads.sort((a, b) => 
    isAsc
      ? a[sortKey][1].localeCompare(b[sortKey][1])
      : b[sortKey][1].localeCompare(a[sortKey][1]))
    .sort((c, d) =>
      isAsc
        ? c[sortKey][0].localeCompare(d[sortKey][0])
        : d[sortKey][0].localeCompare(c[sortKey][0]));

const splitRangeNumToArray = (sortKey) => (load) => {
  const [lowerRange, upperRange] = load[sortKey].split('-');
  load[sortKey] = [lowerRange, upperRange ?? lowerRange];
  return load;
};

const mergeArrayToRangeNum = (sortKey) => (load) => {
  const [lowerRange, upperRange] = load[sortKey];
  load[sortKey] = lowerRange === upperRange ? lowerRange : `${lowerRange}-${upperRange}`;
  return load;
};

const defaultSortByKey = (sortKey, isAsc, loads) => 
  loads.sort((a, b) => 
    isAsc
      ? a[sortKey] - b[sortKey]
      : b[sortKey] - a[sortKey]);

const sortByRange = (sortKey, isAsc, loads) => {
  const splitLoads = loads.map(splitRangeNumToArray(sortKey));
  const sortedLoads = sortByRangeArray(sortKey, isAsc, splitLoads);
  return sortedLoads.map(mergeArrayToRangeNum(sortKey));
};

/**
 * 
 * @param {object} param
 * @param {string} param.sortKey
 * @param {'asc'|'desc'} param.sortOrder
 * @param {Array.<Object>} param.loads
 * @returns loads sorted by sortKey and in the order indicated by sortOrder
 */
export const sortLoadsByKey = ({ sortKey, sortOrder, loads }, DEBUGGING = false) => {
  const isAsc = sortOrder === 'asc';

  switch (sortKey) {
    case 'load_id':
    case 'shipper_id':
    // case 'active':
      return sortStringAttrs(sortKey, isAsc, loads);

    case 'recomm_forecasting':
      return sortByRange(sortKey, isAsc, loads);

    case 'origin_city':
    case 'destination_city':
    case 'pickup_city':
      return sortByCity(sortKey, isAsc, loads);

    case 'pickup_start_date_time':
    case 'delivery_start_date_time':
      return sortByStartDateTime(sortKey, isAsc, loads);

    default:
      DEBUGGING && console.info(`sortKey passed was: ${sortKey}`);
      return defaultSortByKey(sortKey, isAsc, loads);
  }
};
