import React, { useState, SyntheticEvent, ReactNode } from 'react';
import { Box } from '@mui/material';
import ContentCopySharp from '@mui/icons-material/ContentCopySharp';
import TaskAltSharp from '@mui/icons-material/TaskAltSharp';
import { ODTooltip } from '@OptimalDynamics/core-ai-common-ui';

interface CopyClickProps {
  copyText: string;
  children: ReactNode;
}

export const CopyClick = ({ copyText, children }: CopyClickProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const onCopy = (event: SyntheticEvent) => {
    event.stopPropagation();
    navigator.clipboard.writeText(copyText);
    setShowConfirmation(true);
    setTimeout(() => setShowConfirmation(false), 3000);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: '8px',
        alignItems: 'center',
        svg: { opacity: 0 },
        ':hover': {
          transition: '0.5s',
          svg: { opacity: 1 }
        }
      }}
    >
      {children}
      <ODTooltip title={showConfirmation ? 'Copied' : 'Copy ID'}>
        {showConfirmation
          ? <TaskAltSharp sx={{ color: 'success.main' }} />
          : (
            <ContentCopySharp
              className="copy-id-icon"
              sx={{ ':hover': { color: 'colors.neutral8', cursor: 'pointer' } }}
              onClick={(event) => onCopy(event)}
            />
          )}
      </ODTooltip>
    </Box>
  );
};
