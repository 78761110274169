import React from 'react';
import { styled } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

const FilterStyledAccordion = styled(Accordion, {
  shouldForwardProp: (props) => props,
})(({ theme }) => ({
  borderRadius: '4px',
  boxShadow: 'none',

  '&.MuiAccordion-root:before': {
    backgroundColor: 'transparent'
  },

  '& .MuiAccordionSummary-root': {
    backgroundColor: theme.palette.level2,
    borderRadius: '4px',
    marginBottom: '2px',
    minHeight: '40px !important',

    '& .MuiAccordionSummary-content': {
      margin: '10px 0'
    },

    '& .MuiTypography-root': {
      fontWeight: 600,
      fontSize: '14px',
    },
  },

  '& .MuiAccordionDetails-root': {
    padding: '14px 0 0 0 !important',
  },
}));

const FilterAccordion = (props) => {
  const { 
    title, 
    handleAccordionClick, 
    expanded, 
    expandIcon, 
    sx = {},
    SummaryProps = {},
    children,
    ...rest
  } = props;

  return (
    <FilterStyledAccordion expanded={expanded} sx={sx} {...rest}>
      <AccordionSummary
        expandIcon={expandIcon || <ExpandMore style={{ color: '#000000' }} />}
        onClick={handleAccordionClick}
        {...SummaryProps}
      >
        <Typography>{ title }</Typography>
      </AccordionSummary>
      <AccordionDetails className="accordion-details-override">
        { children }
      </AccordionDetails>
    </FilterStyledAccordion>
  );
};

export default FilterAccordion;
