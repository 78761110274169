const loadDetailCommitmentData = {
  id: 512,
  current_volume: '24.00',
  current_offered_loads: '24.00',
  lanes: [
    {
      id: 541,
      origin: '389',
      destination: '760',
      current_volume: '24.00',
      current_offered_loads: '24.00',
      created_at: '08-09-2021 19:47:43',
      updated_at: '12-09-2021 16:43:53',
      account_commitment: 512
    }
  ],
  account_commitment_day_volume: [],
  account_id: {
    id: 114,
    shipper_account: {
      id: 93,
      organization: {
        subdomain: 'gulfrelay'
      },
      name: 'RESOMOCA',
      created_at: '08-09-2021 19:47:43',
      updated_at: '08-09-2021 19:47:43'
    },
    application: 'operational',
    account_name: 'RESOMOCA',
    created_at: '08-09-2021 19:47:43',
    updated_at: '08-09-2021 19:47:43'
  },
  commitment_start_date: '01-01-2021 00:00:00',
  commitment_end_date: '12-31-2021 00:00:00',
  last_reset_at: '12-05-2021 00:00:00',
  rate: null,
  rate_type: 'flat_rate',
  can_use_brokerage: 'N',
  time_span: 'weekly',
  time_span_numeric: '7.00',
  reset_detail: '1',
  reset_detail_choice: null,
  notes: null,
  status: 'ACTIVE',
  volume: '10000.00',
  created_at: '08-09-2021 19:47:43',
  updated_at: '12-05-2021 00:59:05'
};

export {
  loadDetailCommitmentData,
};
