import { SET_ALL_STATES } from '../constants/actionTypes';
import instance from '../../utils/axios_instance';

export const setAllStates = (allStates) => ({
  type: SET_ALL_STATES,
  allStates,
});

export const fetchAllStates = () => async (dispatch) => {
  await instance
    .get('/locations/usa/states')
    .then((res) => dispatch(setAllStates(res.data.usa_states)));
};
