import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import BrokenImage from '@mui/icons-material/BrokenImage';

const PageNotFound = () => (
  <div style={{ width: '100%' }}>
    <AppBar color="default">
      <Toolbar style={{ backgroundColor: '#312e3a' }}>
        <div>
          <img
            src="/assets/images/New_ODLogo_Black.png"
            alt="Optimal Dynamics logo"
            style={{ height: '25px', paddingLeft: `60px` }}
          />
        </div>
      </Toolbar>
    </AppBar>
    <Typography style={{ textAlign: 'center', paddingTop: '200px' }}>
      <BrokenImage style={{ fontSize: '70px' }} />
      <Typography style={{ fontSize: '20px' }}>
        This item cannot be found
      </Typography>
    </Typography>
  </div>
);

export default PageNotFound;
