import React, { ReactElement, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import SyncAltSharp from '@mui/icons-material/SyncAltSharp';
import EventNoteSharp from '@mui/icons-material/EventNoteSharp';
import StickyNote2Sharp from '@mui/icons-material/StickyNote2Sharp';
import SubjectSharp from '@mui/icons-material/SubjectSharp';
import ConnectWithoutContactSharp from '@mui/icons-material/ConnectWithoutContactSharp';
import { ODLaneSearchIcon } from '@OptimalDynamics/core-ai-common-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TuneOffIcon } from '../../../common/icons';
import { TMS, LOAD_SEARCH, EVENTS, SOURCE } from '../helpers/constants';
import { getStarLabel } from '../helpers/dispatchingScripts';

export interface DetailCardTitleProps {
  id: string;
  rating: string | number | undefined;
  secondary_rating?: string | number | undefined;
  title?: string;
  location?: string;
  active: boolean;
  hasSourceRequest?: boolean;
  selected: boolean | undefined;
  handleClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  isRelaxed?: boolean;
  isTourWithConflict?: boolean;
  isCurrentRow?: boolean;
  isSource?: boolean;
  relativeTime?: ReactElement;
  sourceHours?: number | undefined;
  children: ReactNode;
}

export const DetailCardTitle = React.forwardRef(({
  id,
  rating,
  secondary_rating,
  title,
  location,
  active,
  selected,
  hasSourceRequest,
  handleClick,
  isTourWithConflict,
  isRelaxed,
  isCurrentRow,
  isSource,
  relativeTime,
  sourceHours,
  children
}: DetailCardTitleProps, ref) => {
  const { showSourceSuggestions } = useFlags();
  const baseTitle = `${isSource ? 'Source ' : ''}${title}`;
  let constructedTitle = '';
  let icon = <></>;
  if (rating || rating === 0) {
    const { star, label } = getStarLabel(rating, parseInt(secondary_rating as string, 10));
    icon = star;
    constructedTitle = `${label} ${baseTitle}`;
  } else if (location === EVENTS) {
    constructedTitle = 'Scheduled Event';
    icon = <EventNoteSharp />;
  } else if (location === SOURCE) {
    constructedTitle = 'Source Request Details';
    icon = <SubjectSharp />;
  } else {
    constructedTitle = `From ${location}`;
    if (location === TMS) icon = <SyncAltSharp />;
    if (location === LOAD_SEARCH) icon = <ODLaneSearchIcon />;
  }
  if (isTourWithConflict) icon = <InfoOutlined />;
  if (isCurrentRow && location !== SOURCE) {
    constructedTitle = `Selected ${title} Details`;
    icon = <StickyNote2Sharp />;
  }
  if (isRelaxed) {
    constructedTitle = `Exception ${baseTitle}`;
    icon = <TuneOffIcon />;
  }

  const headerColor = () => {
    if (isTourWithConflict) return 'warning.main';

    if (!active && selected) return 'colors.neutral1';
    if (!active) return 'colors.neutral0';
    if (selected) return 'colors.lightBlue1';
    return 'colors.white';
  };

  return (
    <Box
      id={id}
      ref={ref}
      sx={{
        border: '1px solid',
        borderColor: 'level3',
        borderRadius: '4px',
        scrollMargin: '16px 0 16px 0',
        backgroundColor: active ? 'background.default' : 'colors.neutral0',
        color: 'text.primary'
      }}
    >
      <Box
        className="detail-card-title-bar"
        onClick={handleClick}
        sx={{
          backgroundColor: headerColor(),
          borderRadius: '4px 4px 0 0',
          color: 'text.primary',
          height: 40,
          padding: '0px 16px',
          display: 'flex',
          alignItems: 'center',
          columnGap: '16px',
          fontWeight: 'bold',
          ...!!handleClick && { cursor: 'pointer' }
        }}
      >
        <Box sx={{ height: '32px', width: '32px', '> svg': { margin: '7px' } }}>{icon}</Box>
        <Typography sx={{ flexGrow: '1' }}><strong>{constructedTitle}</strong></Typography>
        {!!relativeTime && relativeTime}
        {(Number.isInteger(sourceHours) || hasSourceRequest) && showSourceSuggestions && (
          <Box className="source-soonness" sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <ConnectWithoutContactSharp />
            <Typography>{isCurrentRow ? `${sourceHours} HRS TO SOURCE` : 'PENDING SOURCE'}</Typography>
          </Box>
        )}
      </Box>
      {children}
    </Box>
  );
});
