import shuffle from 'lodash/shuffle';

const listOfCityStates = [
  'Birmingham, AL',
  'Huntsville, AL',
  'Mobile, AL',
  'Montgomery, AL',
  'Tuscaloosa, AL',
  'Auburn, AL',
  'Hoover, AL',
  'Florence, AL',
  'Anniston, AL',
  'Dothan, AL',
  'Daphne, AL',
  'Decatur, AL',
  'Gadsden, AL',
  'Madison, AL',
  'Enterprise, AL',
  'Albertville, AL',
  'Little Rock, AR',
  'Fayetteville, AR',
  'Fort Smith, AR',
  'Springdale, AR',
  'Jonesboro, AR',
  'Conway, AR',
  'Rogers, AR',
  'North Little Rock, AR',
  'Hot Springs, AR',
  'Bentonville, AR',
  'Pine Bluff, AR',
  'Benton, AR',
  'Russellville, AR',
  'Sherwood, AR',
  'Texarkana, AR',
  'Paragould, AR',
  'Phoenix, AZ',
  'Tucson, AZ',
  'Mesa, AZ',
  'Chandler, AZ',
  'Scottsdale, AZ',
  'Gilbert, AZ',
  'Glendale, AZ',
  'Avondale, AZ',
  'Tempe, AZ',
  'Peoria, AZ',
  'Yuma, AZ',
  'Surprise, AZ',
  'Prescott Valley, AZ',
  'San Tan Valley, AZ',
  'Goodyear, AZ',
  'Flagstaff, AZ',
  'Los Angeles, CA',
  'San Francisco, CA',
  'San Diego, CA',
  'Riverside, CA',
  'Sacramento, CA',
  'San Jose, CA',
  'Fresno, CA',
  'Concord, CA',
  'Mission Viejo, CA',
  'Bakersfield, CA',
  'Murrieta, CA',
  'Long Beach, CA',
  'Oakland, CA',
  'Indio, CA',
  'Stockton, CA',
  'Oxnard, CA',
  'Denver, CO',
  'Colorado Springs, CO',
  'Aurora, CO',
  'Fort Collins, CO',
  'Lakewood, CO',
  'Pueblo, CO',
  'Thornton, CO',
  'Grand Junction, CO',
  'Greeley, CO',
  'Boulder, CO',
  'Arvada, CO',
  'Westminster, CO',
  'Centennial, CO',
  'Highlands Ranch, CO',
  'Longmont, CO',
  'Lafayette, CO',
  'Bridgeport, CT',
  'Hartford, CT',
  'New Haven, CT',
  'Norwich, CT',
  'Waterbury, CT',
  'Danbury, CT',
  'Stamford, CT',
  'Norwalk, CT',
  'New Britain, CT',
  'Bristol, CT',
  'Meriden, CT',
  'West Haven, CT',
  'Milford, CT',
  'Milford city , CT',
  'Middletown, CT',
  'Shelton, CT',
  'Washington, DC',
  'Dover, DE',
  'Wilmington, DE',
  'Middletown, DE',
  'Newark, DE',
  'Lewes, DE',
  'Milford, DE',
  'Bear, DE',
  'Ocean View, DE',
  'Glasgow, DE',
  'Brookside, DE',
  'Hockessin, DE',
  'Smyrna, DE',
  'Pike Creek Valley, DE',
  'Millsboro, DE',
  'Georgetown, DE',
  'Claymont, DE',
  'Miami, FL',
  'Tampa, FL',
  'Orlando, FL',
  'Jacksonville, FL',
  'Sarasota, FL',
  'Cape Coral, FL',
  'Palm Bay, FL',
  'Port St. Lucie, FL',
  'Bonita Springs, FL',
  'Palm Coast, FL',
  'Kissimmee, FL',
  'Pensacola, FL',
  'Lakeland, FL',
  'Winter Haven, FL',
  'St. Petersburg, FL',
  'Tallahassee, FL',
  'Atlanta, GA',
  'Augusta, GA',
  'Savannah, GA',
  'Columbus, GA',
  'Gainesville, GA',
  'Warner Robins, GA',
  'Macon, GA',
  'Athens, GA',
  'Sandy Springs, GA',
  'South Fulton, GA',
  'Roswell, GA',
  'Albany, GA',
  'Dalton, GA',
  'Johns Creek, GA',
  'Valdosta, GA',
  'Alpharetta, GA',
  'Des Moines, IA',
  'Davenport, IA',
  'Cedar Rapids, IA',
  'Iowa City, IA',
  'Waterloo, IA',
  'Sioux City, IA',
  'Dubuque, IA',
  'Ames, IA',
  'West Des Moines, IA',
  'Ankeny, IA',
  'Council Bluffs, IA',
  'Urbandale, IA',
  'Cedar Falls, IA',
  'Marion, IA',
  'Bettendorf, IA',
  'Clinton, IA',
  'Boise, ID',
  'Nampa, ID',
  'Meridian, ID',
  'Idaho Falls, ID',
  'Pocatello, ID',
  'Caldwell, ID',
  'Twin Falls, ID',
  'Lewiston, ID',
  'Post Falls, ID',
  'Rexburg, ID',
  'Eagle, ID',
  'Moscow, ID',
  'Kuna, ID',
  'Ammon, ID',
  'Mountain Home, ID',
  'Burley, ID',
  'Chicago, IL',
  'Rockford, IL',
  'Round Lake Beach, IL',
  'Peoria, IL',
  'Aurora, IL',
  'Champaign, IL',
  'Springfield, IL',
  'Naperville, IL',
  'Joliet, IL',
  'Bloomington, IL',
  'Elgin, IL',
  'Decatur, IL',
  'Waukegan, IL',
  'Cicero, IL',
  'Alton, IL',
  'Kankakee, IL',
  'Indianapolis, IN',
  'Fort Wayne, IN',
  'South Bend, IN',
  'Evansville, IN',
  'Lafayette, IN',
  'Elkhart, IN',
  'Bloomington, IN',
  'Carmel, IN',
  'Fishers, IN',
  'Terre Haute, IN',
  'Muncie, IN',
  'Anderson, IN',
  'Kokomo, IN',
  'Hammond, IN',
  'Gary, IN',
  'Michigan City, IN',
  'Wichita, KS',
  'Overland Park, KS',
  'Kansas City, KS',
  'Topeka, KS',
  'Olathe, KS',
  'Lawrence, KS',
  'Shawnee, KS',
  'Manhattan, KS',
  'Lenexa, KS',
  'Salina, KS',
  'Leavenworth, KS',
  'Hutchinson, KS',
  'Junction City, KS',
  'Leawood, KS',
  'Garden City, KS',
  'Dodge City, KS',
  'Louisville, KY',
  'Lexington, KY',
  'Bowling Green, KY',
  'Elizabethtown, KY',
  'Owensboro, KY',
  'Paducah, KY',
  'Richmond, KY',
  'Covington, KY',
  'Frankfort, KY',
  'London, KY',
  'Georgetown, KY',
  'Florence, KY',
  'Hopkinsville, KY',
  'Nicholasville, KY',
  'Somerset, KY',
  'Independence, KY',
  'New Orleans, LA',
  'Baton Rouge, LA',
  'Shreveport, LA',
  'Lafayette, LA',
  'Lake Charles, LA',
  'Metairie, LA',
  'Houma, LA',
  'Monroe, LA',
  'Mandeville, LA',
  'Slidell, LA',
  'Alexandria, LA',
  'Hammond, LA',
  'Bossier City, LA',
  'Kenner, LA',
  'Prairieville, LA',
  'Marrero, LA',
  'Boston, MA',
  'Springfield, MA',
  'Worcester, MA',
  'Barnstable, MA',
  'New Bedford, MA',
  'Leominster, MA',
  'Cambridge, MA',
  'Lowell, MA',
  'Brockton, MA',
  'Quincy, MA',
  'Lynn, MA',
  'Fall River, MA',
  'Newton, MA',
  'Somerville, MA',
  'Lawrence, MA',
  'Framingham, MA',
  'Baltimore, MD',
  'Hagerstown, MD',
  'Frederick, MD',
  'Salisbury, MD',
  'Columbia, MD',
  'Germantown, MD',
  'Silver Spring, MD',
  'Waldorf, MD',
  'Ellicott City, MD',
  'Glen Burnie, MD',
  'Rockville, MD',
  'Gaithersburg, MD',
  'Bethesda, MD',
  'Dundalk, MD',
  'Bowie, MD',
  'Towson, MD',
  'Portland, ME',
  'Bangor, ME',
  'Lewiston, ME',
  'Sanford, ME',
  'Waterville, ME',
  'South Portland, ME',
  'Auburn, ME',
  'Augusta, ME',
  'Biddeford, ME',
  'Saco, ME',
  'Westbrook, ME',
  'Brewer, ME',
  'Presque Isle, ME',
  'Rockland, ME',
  'Bath, ME',
  'Ellsworth, ME',
  'Detroit, MI',
  'Grand Rapids, MI',
  'Flint, MI',
  'Lansing, MI',
  'Ann Arbor, MI',
  'Kalamazoo, MI',
  'Muskegon, MI',
  'Warren, MI',
  'Sterling Heights, MI',
  'South Lyon, MI',
  'Saginaw, MI',
  'Holland, MI',
  'Dearborn, MI',
  'Livonia, MI',
  'Jackson, MI',
  'Troy, MI',
  'Minneapolis, MN',
  'St. Paul, MN',
  'Rochester, MN',
  'Duluth, MN',
  'St. Cloud, MN',
  'Bloomington, MN',
  'Brooklyn Park, MN',
  'Plymouth, MN',
  'Woodbury, MN',
  'Maple Grove, MN',
  'Lakeville, MN',
  'Eagan, MN',
  'Blaine, MN',
  'Eden Prairie, MN',
  'Coon Rapids, MN',
  'Mankato, MN',
  'St. Louis, MO',
  'Kansas City, MO',
  'Springfield, MO',
  'Columbia, MO',
  'Independence, MO',
  'Joplin, MO',
  'St. Joseph, MO',
  'St. Charles, MO',
  'St. Peters, MO',
  'Jefferson City, MO',
  'Cape Girardeau, MO',
  'Blue Springs, MO',
  'Florissant, MO',
  'Chesterfield, MO',
  'Farmington, MO',
  'Wentzville, MO',
  'Jackson, MS',
  'Gulfport, MS',
  'Hattiesburg, MS',
  'Southaven, MS',
  'Pascagoula, MS',
  'Biloxi, MS',
  'Tupelo, MS',
  'Olive Branch, MS',
  'Oxford, MS',
  'Meridian, MS',
  'Starkville, MS',
  'Columbus, MS',
  'Greenville, MS',
  'Horn Lake, MS',
  'Pearl, MS',
  'Vicksburg, MS',
  'Billings, MT',
  'Missoula, MT',
  'Great Falls, MT',
  'Bozeman, MT',
  'Helena, MT',
  'Kalispell, MT',
  'Butte, MT',
  'Belgrade, MT',
  'Havre, MT',
  'Miles City, MT',
  'Anaconda, MT',
  'Livingston, MT',
  'Laurel, MT',
  'Helena Valley Southeast, MT',
  'Whitefish, MT',
  'Helena Valley West Central, MT',
  'Charlotte, NC',
  'Raleigh, NC',
  'Durham, NC',
  'Winston-Salem, NC',
  'Greensboro, NC',
  'Fayetteville, NC',
  'Asheville, NC',
  'Concord, NC',
  'Wilmington, NC',
  'Hickory, NC',
  'Gastonia, NC',
  'High Point, NC',
  'Cary, NC',
  'Burlington, NC',
  'Greenville, NC',
  'Jacksonville, NC',
  'Fargo, ND',
  'Bismarck, ND',
  'Grand Forks, ND',
  'Minot, ND',
  'West Fargo, ND',
  'Williston, ND',
  'Dickinson, ND',
  'Mandan, ND',
  'Jamestown, ND',
  'Wahpeton, ND',
  'Watford City, ND',
  'Devils Lake, ND',
  'Valley City, ND',
  'Minot AFB, ND',
  'Lincoln, ND',
  'Grafton, ND',
  'Omaha, NE',
  'Lincoln, NE',
  'Bellevue, NE',
  'Grand Island, NE',
  'Kearney, NE',
  'Fremont, NE',
  'Norfolk, NE',
  'Scottsbluff, NE',
  'Hastings, NE',
  'North Platte, NE',
  'Columbus, NE',
  'Papillion, NE',
  'La Vista, NE',
  'South Sioux City, NE',
  'Beatrice, NE',
  'Chalco, NE',
  'Nashua, NH',
  'Manchester, NH',
  'Dover, NH',
  'Portsmouth, NH',
  'Concord, NH',
  'Rochester, NH',
  'Lebanon, NH',
  'Keene, NH',
  'Laconia, NH',
  'Claremont, NH',
  'Somersworth, NH',
  'Berlin, NH',
  'Franklin, NH',
  'South Hooksett, NH',
  'Pinardville, NH',
  'Suncook, NH',
  'Trenton, NJ',
  'Newark, NJ',
  'Jersey City, NJ',
  'Atlantic City, NJ',
  'Paterson, NJ',
  'Elizabeth, NJ',
  'Vineland, NJ',
  'Clifton, NJ',
  'Camden, NJ',
  'Passaic, NJ',
  'Union City, NJ',
  'Bayonne, NJ',
  'East Orange, NJ',
  'New Brunswick, NJ',
  'West New York, NJ',
  'Hoboken, NJ',
  'Albuquerque, NM',
  'Las Cruces, NM',
  'Santa Fe, NM',
  'Rio Rancho, NM',
  'Los Lunas, NM',
  'Farmington, NM',
  'Roswell, NM',
  'Clovis, NM',
  'Hobbs, NM',
  'South Valley, NM',
  'Carlsbad, NM',
  'Alamogordo, NM',
  'Española, NM',
  'Gallup, NM',
  'Sunland Park, NM',
  'Las Vegas, NM',
  'Las Vegas, NV',
  'Reno, NV',
  'Henderson, NV',
  'North Las Vegas, NV',
  'Paradise, NV',
  'Spring Valley, NV',
  'Sunrise Manor, NV',
  'Enterprise, NV',
  'Sparks, NV',
  'Carson City, NV',
  'Whitney, NV',
  'Pahrump, NV',
  'Winchester, NV',
  'Summerlin South, NV',
  'Fernley, NV',
  'Elko, NV',
  'New York, NY',
  'Brooklyn, NY',
  'Queens, NY',
  'Manhattan, NY',
  'Bronx, NY',
  'Buffalo, NY',
  'Rochester, NY',
  'Albany, NY',
  'Staten Island, NY',
  'Syracuse, NY',
  'Poughkeepsie, NY',
  'Yonkers, NY',
  'Binghamton, NY',
  'Utica, NY',
  'New Rochelle, NY',
  'Saratoga Springs, NY',
  'Cleveland, OH',
  'Cincinnati, OH',
  'Columbus, OH',
  'Dayton, OH',
  'Akron, OH',
  'Toledo, OH',
  'Youngstown, OH',
  'Canton, OH',
  'Lorain, OH',
  'Middletown, OH',
  'Springfield, OH',
  'Newark, OH',
  'Parma, OH',
  'Mansfield, OH',
  'Lima, OH',
  'Hamilton, OH',
  'Oklahoma City, OK',
  'Tulsa, OK',
  'Norman, OK',
  'Broken Arrow, OK',
  'Edmond, OK',
  'Lawton, OK',
  'Moore, OK',
  'Midwest City, OK',
  'Stillwater, OK',
  'Enid, OK',
  'Muskogee, OK',
  'Bartlesville, OK',
  'Owasso, OK',
  'Shawnee, OK',
  'Yukon, OK',
  'Bixby, OK',
  'Portland, OR',
  'Eugene, OR',
  'Salem, OR',
  'Medford, OR',
  'Bend, OR',
  'Gresham, OR',
  'Hillsboro, OR',
  'Beaverton, OR',
  'Corvallis, OR',
  'Springfield, OR',
  'Albany, OR',
  'Grants Pass, OR',
  'Tigard, OR',
  'Aloha, OR',
  'Roseburg, OR',
  'Klamath Falls, OR',
  'Philadelphia, PA',
  'Pittsburgh, PA',
  'Allentown, PA',
  'Harrisburg, PA',
  'Lancaster, PA',
  'Scranton, PA',
  'Reading, PA',
  'York, PA',
  'Erie, PA',
  'Pottstown, PA',
  'State College, PA',
  'Lebanon, PA',
  'Bethlehem, PA',
  'Altoona, PA',
  'Hanover, PA',
  'Johnstown, PA',
  'Providence, RI',
  'Cranston, RI',
  'Warwick, RI',
  'Pawtucket, RI',
  'East Providence, RI',
  'Woonsocket, RI',
  'Newport, RI',
  'Central Falls, RI',
  'Newport East, RI',
  'Valley Falls, RI',
  'Wakefield-Peacedale, RI',
  'Greenville, RI',
  'Cumberland Hill, RI',
  'Kingston, RI',
  'Pascoag, RI',
  'Narragansett Pier, RI',
  'Charleston, SC',
  'Columbia, SC',
  'Greenville, SC',
  'Myrtle Beach, SC',
  'Spartanburg, SC',
  'Mauldin, SC',
  'Rock Hill, SC',
  'North Charleston, SC',
  'Florence, SC',
  'Mount Pleasant, SC',
  'Anderson, SC',
  'Hilton Head Island, SC',
  'Sumter, SC',
  'Beaufort, SC',
  'Summerville, SC',
  'Goose Creek, SC',
  'Sioux Falls, SD',
  'Rapid City, SD',
  'Aberdeen, SD',
  'Brookings, SD',
  'Watertown, SD',
  'Mitchell, SD',
  'Yankton, SD',
  'Pierre, SD',
  'Huron, SD',
  'Spearfish, SD',
  'Vermillion, SD',
  'Box Elder, SD',
  'Brandon, SD',
  'Rapid Valley, SD',
  'Madison, SD',
  'Sturgis, SD',
  'Nashville, TN',
  'Memphis, TN',
  'Knoxville, TN',
  'Chattanooga, TN',
  'Clarksville, TN',
  'Murfreesboro, TN',
  'Johnson City, TN',
  'Kingsport, TN',
  'Franklin, TN',
  'Jackson, TN',
  'Cleveland, TN',
  'Bristol, TN',
  'Morristown, TN',
  'Bartlett, TN',
  'Hendersonville, TN',
  'Smyrna, TN',
  'Dallas, TX',
  'Houston, TX',
  'San Antonio, TX',
  'Austin, TX',
  'Fort Worth, TX',
  'El Paso, TX',
  'McAllen, TX',
  'Denton, TX',
  'Arlington, TX',
  'Corpus Christi, TX',
  'Plano, TX',
  'Lubbock, TX',
  'Laredo, TX',
  'McKinney, TX',
  'Killeen, TX',
  'Garland, TX',
  'Salt Lake City, UT',
  'Ogden, UT',
  'Provo, UT',
  'West Valley City, UT',
  'St. George, UT',
  'West Jordan, UT',
  'Logan, UT',
  'Orem, UT',
  'Sandy, UT',
  'Layton, UT',
  'South Jordan, UT',
  'Lehi, UT',
  'Millcreek, UT',
  'Taylorsville, UT',
  'Herriman, UT',
  'Murray, UT',
  'Virginia Beach, VA',
  'Richmond, VA',
  'Chesapeake, VA',
  'Norfolk, VA',
  'Arlington, VA',
  'Roanoke, VA',
  'Newport News, VA',
  'Fredericksburg, VA',
  'Alexandria, VA',
  'Hampton, VA',
  'Lynchburg, VA',
  'Charlottesville, VA',
  'Portsmouth, VA',
  'Suffolk, VA',
  'Blacksburg, VA',
  'Williamsburg, VA',
  'Burlington, VT',
  'Barre, VT',
  'South Burlington, VT',
  'Rutland, VT',
  'Essex Junction, VT',
  'St. Albans, VT',
  'Montpelier, VT',
  'Winooski, VT',
  'Bellows Falls, VT',
  'Newport, VT',
  'Lyndonville, VT',
  'West Brattleboro, VT',
  'Vergennes, VT',
  'White River Junction, VT',
  'Morrisville, VT',
  'Manchester Center, VT',
  'Seattle, WA',
  'Spokane, WA',
  'Kennewick, WA',
  'Bremerton, WA',
  'Tacoma, WA',
  'Olympia, WA',
  'Vancouver, WA',
  'Marysville, WA',
  'Bellevue, WA',
  'Yakima, WA',
  'Kent, WA',
  'Bellingham, WA',
  'Everett, WA',
  'Renton, WA',
  'Spokane Valley, WA',
  'Federal Way, WA',
  'Milwaukee, WI',
  'Madison, WI',
  'Appleton, WI',
  'Green Bay, WI',
  'Racine, WI',
  'Kenosha, WI',
  'Eau Claire, WI',
  'La Crosse, WI',
  'Oshkosh, WI',
  'Wausau, WI',
  'Waukesha, WI',
  'Janesville, WI',
  'West Bend, WI',
  'Sheboygan, WI',
  'Beloit, WI',
  'West Allis, WI',
  'Huntington, WV',
  'Charleston, WV',
  'Wheeling, WV',
  'Morgantown, WV',
  'Weirton, WV',
  'Parkersburg, WV',
  'Beckley, WV',
  'Bluefield, WV',
  'Clarksburg, WV',
  'Fairmont, WV',
  'Charles Town, WV',
  'Martinsburg, WV',
  'Teays Valley, WV',
  'South Charleston, WV',
  'Elkins, WV',
  'Point Pleasant, WV',
  'Cheyenne, WY',
  'Casper, WY',
  'Gillette, WY',
  'Laramie, WY',
  'Rock Springs, WY',
  'Sheridan, WY',
  'Jackson, WY',
  'Riverton, WY',
  'Green River, WY',
  'Evanston, WY',
  'Cody, WY',
  'Rawlins, WY',
  'Lander, WY',
  'Torrington, WY',
  'Douglas, WY',
  'Ranchettes, WY',
];

const cityStatesTruncated = (limit = 5) => {
  const counter = {};

  return listOfCityStates.reduce((curatedCityList, city) => {
    const state = city.slice(city.length - 2);
    counter[state] = counter[state] || 1;
    
    if (counter[state] < limit) {
      curatedCityList.push(city);
      counter[state] += 1;
    }
    return curatedCityList;
  }, []);
};

const defaultLimit = Math.floor(listOfCityStates.length / 3);

const getShuffledCityStates = ({ limit = defaultLimit, ascending = true }) => shuffle(listOfCityStates)
  .slice(0, limit)
  .sort((a, b) => ascending 
    ? a[a.length - 1].charCodeAt() - b[b.length - 1].charCodeAt() // A-Z
    : b[b.length - 1].charCodeAt() - a[a.length - 1].charCodeAt()) // Z-A
  .sort((a, b) => ascending 
    ? a[a.length - 2].charCodeAt() - b[b.length - 2].charCodeAt() 
    : b[b.length - 2].charCodeAt() - a[a.length - 2].charCodeAt()); 

const cityStates = cityStatesTruncated();

export { 
  getShuffledCityStates,
};

export default cityStates;
