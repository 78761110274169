import cookies from '../../utils/cookies';
import {
  SIGNIN_START,
  SIGNIN_SUCCESS,
  SIGNIN_FAIL,
  SIGNOUT,
  USER_DATA,
  LAUNCH_DARKLY_USER_IDENTIFIED
} from '../constants/actionTypes';

const initialState = {
  token: null,
  error: null,
  loading: false,
  type: null,
  isAdminRole: false,
  userId: null,
  userEmail: null,
  organization: null,
  defaultHorizonHours: null,
  launchDarklyUserIdentified: null
};

const signInStart = (state, action) => ({ ...state, error: null, loading: true, type: action.type });

const signInSuccess = (state, action) => ({
  ...state,
  token: action.token,
  loading: false,
  error: null,
  type: action.type,
});

const signInFail = (state, action) => ({
  ...state,
  token: null,
  loading: false,
  error: action.error,
  type: action.type,
});

const signInClear = (state, _action) => ({ ...state, ...initialState });

const userData = (state, action) => {
  const isAdminRole = action.userData.roles.includes('super-admin');
  cookies.set('org_id', action.userData.organization_subdomain);
  return {
    ...state,
    userId: action.userData.id,
    userEmail: action.userData.email,
    organization: action.userData.organization_subdomain,
    roles: action.userData.roles,
    defaultHorizonHours: action.userData.default_horizon_hours,
    isAdminRole
  };
};

const launchDarklyUserIdentified = (state, action) => ({
  ...state,
  launchDarklyUserIdentified: action.isIdentified
});

const reducer = (state = initialState, action = null) => {
  if (!action) return state;
  switch (action.type) {
    case SIGNIN_START:
      return signInStart(state, action);
    case SIGNIN_SUCCESS:
      return signInSuccess(state, action);
    case SIGNIN_FAIL:
      return signInFail(state, action);
    case SIGNOUT:
      return signInClear(undefined, action);
    case USER_DATA:
      return userData(state, action);
    case LAUNCH_DARKLY_USER_IDENTIFIED:
      return launchDarklyUserIdentified(state, action);
    default:
      return state;
  }
};

export default reducer;
