const set = (key, value) => localStorage.setItem(key, JSON.stringify(value));
const remove = (key) => localStorage.removeItem(key);
const get = (key) => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : null;
};

const clear = () => localStorage.clear();

const setSession = (key, value) =>
  sessionStorage.setItem(key, JSON.stringify(value));
const removeSession = (key) => sessionStorage.removeItem(key);
const getSession = (key) => {
  const value = sessionStorage.getItem(key);
  return value ? JSON.parse(value) : null;
};

const clearSession = () => sessionStorage.clear();

const getAndHasKey = (mainKey) => (innerKey) => get(mainKey) === null ? null : get(mainKey)[innerKey];

const getSessionAndHasKey = (mainKey) => (innerKey) => getSession(mainKey) === null ? null : getSession(mainKey)[innerKey];

export default {
  set,
  getAndHasKey,
  getSessionAndHasKey,
  remove,
  get,
  clear,
  setSession,
  getSession,
  removeSession,
  clearSession,
};
