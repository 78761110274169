import React from 'react';
import moment from 'moment-timezone';
import { styled } from '@mui/system';
import { TextField } from '@mui/material';
import { DateTimePicker, DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers'; 
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CalendarTodaySharp from '@mui/icons-material/CalendarTodaySharp';
import EventBusySharp from '@mui/icons-material/EventBusySharp';
import ChevronLeftSharp from '@mui/icons-material/ChevronLeftSharp';
import ChevronRightSharp from '@mui/icons-material/ChevronRightSharp';
import ExpandMoreSharp from '@mui/icons-material/ExpandMoreSharp';
import TimelapseSharp from '@mui/icons-material/TimelapseSharp';
import CloseSharp from '@mui/icons-material/CloseSharp';
import { DEFAULT_TIMEZONE } from '../utils/datetimes';

export const BUTTON_VARIANT = 'filled';
export const FORM_VARIANT = 'standard';

const ODStyledDatePickerTextField = styled(TextField, {
  shouldForwardProp: (prop) => !['extraStyles', 'isErrored', 'variant'].includes(prop),
  name: 'ODDatePickerTextField'
})(({ theme, extraStyles, isErrored, variant, value }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  input: {
    ...theme.typography
  },
  '.MuiInputBase-root': {
    flexDirection: variant === BUTTON_VARIANT ? 'row-reverse' : 'row',
    padding: variant === FORM_VARIANT ? '0 4px 0 16px' : 0,
    width: variant === BUTTON_VARIANT ? '140px' : '100%',
    backgroundColor: variant === BUTTON_VARIANT ? (isErrored ? theme.palette.alert.main : theme.palette.level2) : theme.palette.level1,
    color: isErrored ? (variant === FORM_VARIANT ? theme.palette.alert.main : theme.palette.text.inverse) : theme.palette.text.primary,
    '&:before, &:after': {
      display: 'none'
    },
    '.MuiInputBase-input': {
      padding: variant === BUTTON_VARIANT ? '4px' : '10px 0'
    },
    '&:hover': {
      backgroundColor: (variant === BUTTON_VARIANT && isErrored) ? theme.palette.alert.secondary : theme.palette.level3,
    },
    ...(variant === FORM_VARIANT && {
      borderRadius: 0,
      borderBottom: '1px solid',
      borderBottomColor: isErrored ? theme.palette.alert.main : theme.palette.level3,
    }),
    ...extraStyles,
  },
  '.MuiIconButton-root': {
    marginRight: '0px',
    borderRadius: '4px',
    padding: '7px',
    '&:hover': {
      backgroundColor: (isErrored && variant === BUTTON_VARIANT) ? theme.palette.alert.tertiary : theme.palette.level4,
    },
  },
  '.MuiSvgIcon-root': {
    color: isErrored
      ? (variant === BUTTON_VARIANT ? theme.palette.text.inverse : theme.palette.alert.main)
      : (!value ? theme.palette.text.secondary : theme.palette.text.primary)
  },
}));

export const PopperSlotProps = {
  placement: 'bottom-start',
  sx: { 
    'button.MuiIconButton-root': {
      svg: { color: 'colors.neutral6' },
      '&.Mui-disabled svg': { color: 'colors.neutral4' }
    },
    '.MuiPaper-outlined.MuiPickersPopper-paper': { 
      borderColor: 'level3',
      borderWidth: '1px',
      borderRadius: '4px'
    },
    '.MuiDateCalendar-root': {
      height: 'unset'
    },
    '.MuiPickersSlideTransition-root': {
      minHeight: '200px'
    },
    '.MuiButtonBase-root.MuiPickersDay-root, .MuiPickersMonth-monthButton, .MuiPickersYear-yearButton, .MuiMultiSectionDigitalClockSection-item': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'level3'
      },
      '&.Mui-selected, &.Mui-selected:focus': {
        backgroundColor: 'optimalblue.main',
        color: 'text.inverse',
        '&:hover': {
          backgroundColor: 'optimalblue.secondary',
        }
      },
    },
    '.MuiPickersMonth-monthButton, .MuiPickersYear-yearButton, .MuiMultiSectionDigitalClockSection-item': {
      borderRadius: '4px'
    }
  }
};

const EventBusyAdjusted = () => (
  <EventBusySharp sx={{ transform: 'scale(1.11111, 1.1) translateY(1px)' }} />
);

export const OverallDateTimePicker = ({
  views,
  defaultView,
  currentValue,
  changeValue,
  dateRangeStart,
  dateRangeEnd,
  inputId,
  variant,
  extraStyles = {},
  isErrored = false,
  isTableHeader = false,
  timezone = DEFAULT_TIMEZONE
}) => (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <DateTimePicker
      ampm={false}
      openTo={defaultView}
      views={views}
      value={currentValue}
      timezone={timezone}
      onChange={(newValue) => changeValue(newValue)}
      slotProps={{
        desktopPaper: {
          square: true,
          variant: 'outlined'
        },
        popper: {
          ...PopperSlotProps,
          disablePortal: !isTableHeader,
        },
        textField: {
          id: inputId,
          hiddenLabel: true,
          size: 'small',
          variant,
          label: '',
          extraStyles,
          isErrored,
          clearable: variant === FORM_VARIANT
        }
      }}
      slots={{
        clearIcon: CloseSharp,
        openPickerIcon: isErrored ? EventBusyAdjusted : CalendarTodaySharp,
        leftArrowIcon: ChevronLeftSharp,
        rightArrowIcon: ChevronRightSharp,
        switchViewIcon: ExpandMoreSharp,
        textField: ODStyledDatePickerTextField
      }}
      minDate={!!dateRangeStart && moment(dateRangeStart)}
      maxDate={!!dateRangeEnd && moment(dateRangeEnd)}
    />
  </LocalizationProvider>
);

export const ODDatePicker = (props) => (
  <OverallDateTimePicker defaultView="day" views={['year', 'month', 'day']} {...props} />
);

export const ODDateTimePicker = (props) => (
  <OverallDateTimePicker
    defaultView="day"
    views={['year', 'month', 'day', 'hours', 'minutes']}
    minutesStep={15}
    {...props}
  />
);

export const ODTimePicker = ({ inputId, currentValue, changeValue, extraStyles = {} }) => (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <DesktopTimePicker
      ampm={false}
      openTo="hours"
      views={['hours', 'minutes']}
      value={currentValue}
      onChange={(newValue) => changeValue(newValue)}
      slotProps={{
        desktopPaper: {
          square: true,
          variant: 'outlined',
          elevation: 2
        },
        popper: PopperSlotProps,
        textField: {
          id: inputId,
          hiddenLabel: true,
          size: 'small',
          variant: 'filled',
          label: '',
          extraStyles
        }
      }}
      slots={{
        openPickerIcon: TimelapseSharp,
        textField: ODStyledDatePickerTextField
      }}
    />
  </LocalizationProvider>
);
