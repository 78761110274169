const laneDetailDrawerLoads = [
  {
    id: 3939,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2020/06/10 00:00',
    pickup_end_date_time: '2020/06/10 12:00',
    delivery_start_date_time: '2020/06/10 05:00',
    delivery_end_date_time: '2020/06/10 23:55',
    coverage_probability: 100,
    load_status_change_datetime: null,
    call_date_time: '2020/05/27 00:00',
    cancel_date_time: null,
    booking_date_time: '2020/05/27 00:00',
    customer_status_datetime: null,
    load_id: 'VL99794',
    shipper_id: 'RegularShipper',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: true,
    origin_zipcode: '32808',
    origin_city: 'Orlando',
    origin_state: 'FL',
    origin_lat: '28.580300',
    origin_lng: '-81.439600',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '32615',
    destination_city: 'Alachua',
    destination_state: 'FL',
    destination_lat: '29.813500',
    destination_lng: '-82.472000',
    delivery_appt_type: 'BY',
    destination_drop: true,
    line_haul_revenue: 441.4,
    other_revenue: 0,
    expedite: true,
    driver_id: null,
    static_cost: 0,
    mileage: 124,
    source: 'REGULAR',
    load_type: 'REAL',
    load_score: 20,
    load_status: 'RECOM',
    load_score_percentage: '100',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '02-11-2021 14:38:41',
    updated_at: '01-04-2022 16:59:55',
    num_stops: null,
    internal_source: 'NONE',
    external_source: 'NONE',
    tms_load_status: null,
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872834,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/30 08:00',
    pickup_end_date_time: '2021/09/30 08:00',
    delivery_start_date_time: '2021/10/01 08:00',
    delivery_end_date_time: '2021/10/01 08:00',
    coverage_probability: 85,
    load_status_change_datetime: null,
    call_date_time: '2021/09/22 16:40',
    cancel_date_time: null,
    booking_date_time: '2021/09/22 16:40',
    customer_status_datetime: null,
    load_id: '0257596',
    shipper_id: 'JOHN GREENE LOGISTICS, INC.',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '32615',
    origin_city: 'Alachua',
    origin_state: 'FL',
    origin_lat: '29.813500',
    origin_lng: '-82.472000',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '37921',
    destination_city: 'Knoxville',
    destination_state: 'TN',
    destination_lat: '35.979300',
    destination_lng: '-84.003000',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 1400,
    other_revenue: 168.63,
    expedite: false,
    driver_id: null,
    static_cost: 0,
    mileage: 511,
    source: null,
    load_type: 'REAL',
    load_score: 17,
    load_status: 'RECOM',
    load_score_percentage: '85',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:22',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872822,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/30 08:00',
    pickup_end_date_time: '2021/09/30 08:00',
    delivery_start_date_time: '2021/09/30 12:00',
    delivery_end_date_time: '2021/09/30 12:00',
    coverage_probability: 100,
    load_status_change_datetime: null,
    call_date_time: '2021/09/22 16:17',
    cancel_date_time: null,
    booking_date_time: '2021/09/22 16:17',
    customer_status_datetime: null,
    load_id: '0257582',
    shipper_id: 'JOHN GREENE LOGISTICS, INC.',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '32615',
    origin_city: 'Alachua',
    origin_state: 'FL',
    origin_lat: '29.813500',
    origin_lng: '-82.472000',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '34220',
    destination_city: 'Palmetto',
    destination_state: 'FL',
    destination_lat: '27.521400',
    destination_lng: '-82.572300',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 625,
    other_revenue: 61.74,
    expedite: false,
    driver_id: null,
    static_cost: 0,
    mileage: 180,
    source: null,
    load_type: 'REAL',
    load_score: 20,
    load_status: 'RECOM',
    load_score_percentage: '100',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:23',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872819,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/29 08:00',
    pickup_end_date_time: '2021/09/29 08:00',
    delivery_start_date_time: '2021/09/29 12:00',
    delivery_end_date_time: '2021/09/29 12:00',
    coverage_probability: 100,
    load_status_change_datetime: null,
    call_date_time: '2021/09/22 16:17',
    cancel_date_time: null,
    booking_date_time: '2021/09/22 16:17',
    customer_status_datetime: '2021/10/18 06:32',
    load_id: '0257579',
    shipper_id: 'JOHN GREENE LOGISTICS, INC.',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '32615',
    origin_city: 'Alachua',
    origin_state: 'FL',
    origin_lat: '29.813500',
    origin_lng: '-82.472000',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '34220',
    destination_city: 'Palmetto',
    destination_state: 'FL',
    destination_lat: '27.521400',
    destination_lng: '-82.572300',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 625,
    other_revenue: 61.74,
    expedite: false,
    driver_id: null,
    static_cost: 0,
    mileage: 180,
    source: null,
    load_type: 'REAL',
    load_score: 20,
    load_status: 'RECOM',
    load_score_percentage: '100',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:23',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872817,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/28 08:00',
    pickup_end_date_time: '2021/09/28 08:00',
    delivery_start_date_time: '2021/09/28 12:00',
    delivery_end_date_time: '2021/09/28 12:00',
    coverage_probability: 85,
    load_status_change_datetime: null,
    call_date_time: '2021/09/22 16:16',
    cancel_date_time: null,
    booking_date_time: '2021/09/22 16:16',
    customer_status_datetime: null,
    load_id: '0257577',
    shipper_id: 'JOHN GREENE LOGISTICS, INC.',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '32615',
    origin_city: 'Alachua',
    origin_state: 'FL',
    origin_lat: '29.813500',
    origin_lng: '-82.472000',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '34220',
    destination_city: 'Palmetto',
    destination_state: 'FL',
    destination_lat: '27.521400',
    destination_lng: '-82.572300',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 625,
    other_revenue: 61.74,
    expedite: false,
    driver_id: null,
    static_cost: 0,
    mileage: 180,
    source: null,
    load_type: 'REAL',
    load_score: 17,
    load_status: 'RECOM',
    load_score_percentage: '85',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:24',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872815,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/27 08:00',
    pickup_end_date_time: '2021/09/27 08:00',
    delivery_start_date_time: '2021/09/27 12:00',
    delivery_end_date_time: '2021/09/27 12:00',
    coverage_probability: 100,
    load_status_change_datetime: null,
    call_date_time: '2021/09/22 16:15',
    cancel_date_time: null,
    booking_date_time: '2021/09/22 16:15',
    customer_status_datetime: '2021/10/18 06:32',
    load_id: '0257575',
    shipper_id: 'JOHN GREENE LOGISTICS, INC.',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '32615',
    origin_city: 'Alachua',
    origin_state: 'FL',
    origin_lat: '29.813500',
    origin_lng: '-82.472000',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '34220',
    destination_city: 'Palmetto',
    destination_state: 'FL',
    destination_lat: '27.521400',
    destination_lng: '-82.572300',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 625,
    other_revenue: 61.74,
    expedite: false,
    driver_id: null,
    static_cost: 0,
    mileage: 180,
    source: null,
    load_type: 'REAL',
    load_score: 20,
    load_status: 'RECOM',
    load_score_percentage: '100',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:24',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872802,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/29 08:00',
    pickup_end_date_time: '2021/09/29 08:00',
    delivery_start_date_time: '2021/09/29 19:45',
    delivery_end_date_time: '2021/09/29 19:45',
    coverage_probability: 75,
    load_status_change_datetime: null,
    call_date_time: '2021/09/22 14:18',
    cancel_date_time: null,
    booking_date_time: '2021/09/22 14:18',
    customer_status_datetime: '2021/12/08 06:50',
    load_id: '0257536',
    shipper_id: 'Trinity Logistics, Inc.',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '33844',
    origin_city: 'Haines City',
    origin_state: 'FL',
    origin_lat: '28.114500',
    origin_lng: '-81.620100',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '34269',
    destination_city: 'Arcadia',
    destination_state: 'FL',
    destination_lat: '27.067500',
    destination_lng: '-81.985500',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 570,
    other_revenue: 29.67,
    expedite: false,
    driver_id: null,
    static_cost: 0,
    mileage: 72,
    source: null,
    load_type: 'REAL',
    load_score: 15,
    load_status: 'RECOM',
    load_score_percentage: '75',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:25',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872766,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/24 15:00',
    pickup_end_date_time: '2021/09/25 15:00',
    delivery_start_date_time: '2021/09/26 21:10',
    delivery_end_date_time: '2021/09/26 21:10',
    coverage_probability: 100,
    load_status_change_datetime: null,
    call_date_time: '2021/09/21 12:41',
    cancel_date_time: null,
    booking_date_time: '2021/09/21 12:41',
    customer_status_datetime: null,
    load_id: '0257465',
    shipper_id: 'COUNTRY PURE FOODS',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '34737',
    origin_city: 'Howey-In-The-Hills',
    origin_state: 'FL',
    origin_lat: '28.697100',
    origin_lng: '-81.797600',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '36801',
    destination_city: 'Opelika',
    destination_state: 'AL',
    destination_lat: '32.645400',
    destination_lng: '-85.378300',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 1005.98,
    other_revenue: 144.02,
    expedite: false,
    driver_id: null,
    static_cost: 5,
    mileage: 379,
    source: null,
    load_type: 'REAL',
    load_score: 20,
    load_status: 'RECOM',
    load_score_percentage: '100',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:30',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872762,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/28 15:00',
    pickup_end_date_time: '2021/09/28 23:00',
    delivery_start_date_time: '2021/09/29 08:00',
    delivery_end_date_time: '2021/09/29 08:00',
    coverage_probability: 100,
    load_status_change_datetime: null,
    call_date_time: '2021/09/21 12:31',
    cancel_date_time: null,
    booking_date_time: '2021/09/21 12:31',
    customer_status_datetime: null,
    load_id: '0257461',
    shipper_id: 'COUNTRY PURE FOODS',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '34737',
    origin_city: 'Howey-In-The-Hills',
    origin_state: 'FL',
    origin_lat: '28.697100',
    origin_lng: '-81.797600',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '33069',
    destination_city: 'Pompano Beach',
    destination_state: 'FL',
    destination_lat: '26.228800',
    destination_lng: '-80.163500',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 963.74,
    other_revenue: 86.26,
    expedite: false,
    driver_id: null,
    static_cost: 39,
    mileage: 227,
    source: null,
    load_type: 'REAL',
    load_score: 20,
    load_status: 'RECOM',
    load_score_percentage: '100',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:30',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872760,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/27 15:00',
    pickup_end_date_time: '2021/09/28 08:00',
    delivery_start_date_time: '2021/09/28 15:00',
    delivery_end_date_time: '2021/09/28 15:00',
    coverage_probability: 100,
    load_status_change_datetime: null,
    call_date_time: '2021/09/21 12:26',
    cancel_date_time: null,
    booking_date_time: '2021/09/21 12:26',
    customer_status_datetime: null,
    load_id: '0257459',
    shipper_id: 'COUNTRY PURE FOODS',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '34737',
    origin_city: 'Howey-In-The-Hills',
    origin_state: 'FL',
    origin_lat: '28.697100',
    origin_lng: '-81.797600',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '32922',
    destination_city: 'Cocoa',
    destination_state: 'FL',
    destination_lat: '28.367200',
    destination_lng: '-80.746500',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 679.98,
    other_revenue: 30.02,
    expedite: false,
    driver_id: null,
    static_cost: 18,
    mileage: 79,
    source: null,
    load_type: 'REAL',
    load_score: 20,
    load_status: 'RECOM',
    load_score_percentage: '100',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:30',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872762,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/28 15:00',
    pickup_end_date_time: '2021/09/28 23:00',
    delivery_start_date_time: '2021/09/29 08:00',
    delivery_end_date_time: '2021/09/29 08:00',
    coverage_probability: 100,
    load_status_change_datetime: null,
    call_date_time: '2021/09/21 12:31',
    cancel_date_time: null,
    booking_date_time: '2021/09/21 12:31',
    customer_status_datetime: null,
    load_id: '0257461',
    shipper_id: 'COUNTRY PURE FOODS',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '34737',
    origin_city: 'Howey-In-The-Hills',
    origin_state: 'FL',
    origin_lat: '28.697100',
    origin_lng: '-81.797600',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '33069',
    destination_city: 'Pompano Beach',
    destination_state: 'FL',
    destination_lat: '26.228800',
    destination_lng: '-80.163500',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 963.74,
    other_revenue: 86.26,
    expedite: false,
    driver_id: null,
    static_cost: 39.0,
    mileage: 227,
    source: null,
    load_type: 'REAL',
    load_score: 20,
    load_status: 'RECOM',
    load_score_percentage: '100',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:30',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872761,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/29 15:00',
    pickup_end_date_time: '2021/09/29 15:00',
    delivery_start_date_time: '2021/09/30 20:00',
    delivery_end_date_time: '2021/09/30 20:00',
    coverage_probability: 0,
    load_status_change_datetime: null,
    call_date_time: '2021/09/21 12:28',
    cancel_date_time: null,
    booking_date_time: '2021/09/21 12:28',
    customer_status_datetime: null,
    load_id: '0257460',
    shipper_id: 'SAPUTO DAIRY FOODS USA',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '42071',
    origin_city: 'Murray',
    origin_state: 'KY',
    origin_lat: '36.609900',
    origin_lng: '-88.303200',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '60163',
    destination_city: 'Berkeley',
    destination_state: 'IL',
    destination_lat: '41.888800',
    destination_lng: '-87.909000',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 1024.8,
    other_revenue: 0.0,
    expedite: false,
    driver_id: null,
    static_cost: 20.0,
    mileage: 417,
    source: null,
    load_type: 'REAL',
    load_score: 0,
    load_status: 'NOT_RECOMMENDED',
    load_score_percentage: '0',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:30',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872760,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/27 15:00',
    pickup_end_date_time: '2021/09/28 08:00',
    delivery_start_date_time: '2021/09/28 15:00',
    delivery_end_date_time: '2021/09/28 15:00',
    coverage_probability: 100,
    load_status_change_datetime: null,
    call_date_time: '2021/09/21 12:26',
    cancel_date_time: null,
    booking_date_time: '2021/09/21 12:26',
    customer_status_datetime: null,
    load_id: '0257459',
    shipper_id: 'COUNTRY PURE FOODS',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '34737',
    origin_city: 'Howey-In-The-Hills',
    origin_state: 'FL',
    origin_lat: '28.697100',
    origin_lng: '-81.797600',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '32922',
    destination_city: 'Cocoa',
    destination_state: 'FL',
    destination_lat: '28.367200',
    destination_lng: '-80.746500',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 679.98,
    other_revenue: 30.02,
    expedite: false,
    driver_id: null,
    static_cost: 18.0,
    mileage: 79,
    source: null,
    load_type: 'REAL',
    load_score: 20,
    load_status: 'RECOM',
    load_score_percentage: '100',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:30',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872759,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/27 15:00',
    pickup_end_date_time: '2021/09/28 08:00',
    delivery_start_date_time: '2021/09/28 17:00',
    delivery_end_date_time: '2021/09/28 17:00',
    coverage_probability: 100,
    load_status_change_datetime: null,
    call_date_time: '2021/09/21 12:22',
    cancel_date_time: null,
    booking_date_time: '2021/09/21 12:22',
    customer_status_datetime: '2021/09/28 14:08',
    load_id: '0257458',
    shipper_id: 'COUNTRY PURE FOODS',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '32720',
    origin_city: 'DeLand',
    origin_state: 'FL',
    origin_lat: '29.026600',
    origin_lng: '-81.334900',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '32922',
    destination_city: 'Cocoa',
    destination_state: 'FL',
    destination_lat: '28.367200',
    destination_lng: '-80.746500',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 679.98,
    other_revenue: 26.6,
    expedite: false,
    driver_id: null,
    static_cost: 18.0,
    mileage: 70,
    source: null,
    load_type: 'REAL',
    load_score: 20,
    load_status: 'RECOM',
    load_score_percentage: '100',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:30',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872758,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/28 22:30',
    pickup_end_date_time: '2021/09/28 22:30',
    delivery_start_date_time: '2021/09/30 04:00',
    delivery_end_date_time: '2021/09/30 04:00',
    coverage_probability: 0,
    load_status_change_datetime: null,
    call_date_time: '2021/09/21 12:15',
    cancel_date_time: null,
    booking_date_time: '2021/09/21 12:15',
    customer_status_datetime: null,
    load_id: '0257457',
    shipper_id: 'Meijers\'  Global Logistics',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '61068',
    origin_city: 'Rochelle',
    origin_state: 'IL',
    origin_lat: '41.928200',
    origin_lng: '-89.071000',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '48166',
    destination_city: 'Newport',
    destination_state: 'MI',
    destination_lat: '41.976600',
    destination_lng: '-83.280400',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 1404.94,
    other_revenue: 158.85,
    expedite: false,
    driver_id: null,
    static_cost: 40.0,
    mileage: 333,
    source: null,
    load_type: 'REAL',
    load_score: 0,
    load_status: 'NOT_RECOMMENDED',
    load_score_percentage: '0',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:31',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872757,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/27 15:00',
    pickup_end_date_time: '2021/09/28 08:00',
    delivery_start_date_time: '2021/09/28 17:00',
    delivery_end_date_time: '2021/09/28 17:00',
    coverage_probability: 100,
    load_status_change_datetime: null,
    call_date_time: '2021/09/21 12:14',
    cancel_date_time: null,
    booking_date_time: '2021/09/21 12:14',
    customer_status_datetime: '2021/09/28 14:08',
    load_id: '0257456',
    shipper_id: 'COUNTRY PURE FOODS',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '32720',
    origin_city: 'DeLand',
    origin_state: 'FL',
    origin_lat: '29.026600',
    origin_lng: '-81.334900',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '32922',
    destination_city: 'Cocoa',
    destination_state: 'FL',
    destination_lat: '28.367200',
    destination_lng: '-80.746500',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 679.98,
    other_revenue: 26.6,
    expedite: false,
    driver_id: null,
    static_cost: 18.0,
    mileage: 70,
    source: null,
    load_type: 'REAL',
    load_score: 20,
    load_status: 'RECOM',
    load_score_percentage: '100',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:31',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872756,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/27 15:00',
    pickup_end_date_time: '2021/09/28 08:00',
    delivery_start_date_time: '2021/09/28 15:00',
    delivery_end_date_time: '2021/09/28 15:00',
    coverage_probability: 100,
    load_status_change_datetime: null,
    call_date_time: '2021/09/21 12:10',
    cancel_date_time: null,
    booking_date_time: '2021/09/21 12:10',
    customer_status_datetime: null,
    load_id: '0257455',
    shipper_id: 'COUNTRY PURE FOODS',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '34737',
    origin_city: 'Howey-In-The-Hills',
    origin_state: 'FL',
    origin_lat: '28.697100',
    origin_lng: '-81.797600',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '32922',
    destination_city: 'Cocoa',
    destination_state: 'FL',
    destination_lat: '28.367200',
    destination_lng: '-80.746500',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 679.98,
    other_revenue: 30.02,
    expedite: false,
    driver_id: null,
    static_cost: 18.0,
    mileage: 79,
    source: null,
    load_type: 'REAL',
    load_score: 20,
    load_status: 'RECOM',
    load_score_percentage: '100',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:31',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872755,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/29 15:00',
    pickup_end_date_time: '2021/09/30 08:00',
    delivery_start_date_time: '2021/09/30 18:30',
    delivery_end_date_time: '2021/09/30 18:30',
    coverage_probability: 90,
    load_status_change_datetime: null,
    call_date_time: '2021/09/21 12:04',
    cancel_date_time: null,
    booking_date_time: '2021/09/21 12:04',
    customer_status_datetime: null,
    load_id: '0257454',
    shipper_id: 'COUNTRY PURE FOODS',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '34737',
    origin_city: 'Howey-In-The-Hills',
    origin_state: 'FL',
    origin_lat: '28.697100',
    origin_lng: '-81.797600',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '33881',
    destination_city: 'Winter Haven',
    destination_state: 'FL',
    destination_lat: '28.045200',
    destination_lng: '-81.732500',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 629.1,
    other_revenue: 20.9,
    expedite: false,
    driver_id: null,
    static_cost: 0.0,
    mileage: 55,
    source: null,
    load_type: 'REAL',
    load_score: 18,
    load_status: 'RECOM',
    load_score_percentage: '90',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:31',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872754,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/29 15:00',
    pickup_end_date_time: '2021/09/30 08:00',
    delivery_start_date_time: '2021/09/30 15:30',
    delivery_end_date_time: '2021/09/30 15:30',
    coverage_probability: 95,
    load_status_change_datetime: null,
    call_date_time: '2021/09/21 11:56',
    cancel_date_time: null,
    booking_date_time: '2021/09/21 11:56',
    customer_status_datetime: null,
    load_id: '0257453',
    shipper_id: 'COUNTRY PURE FOODS',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '34737',
    origin_city: 'Howey-In-The-Hills',
    origin_state: 'FL',
    origin_lat: '28.697100',
    origin_lng: '-81.797600',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '33881',
    destination_city: 'Winter Haven',
    destination_state: 'FL',
    destination_lat: '28.045200',
    destination_lng: '-81.732500',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 629.1,
    other_revenue: 20.9,
    expedite: false,
    driver_id: null,
    static_cost: 0.0,
    mileage: 55,
    source: null,
    load_type: 'REAL',
    load_score: 19,
    load_status: 'RECOM',
    load_score_percentage: '95',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:31',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872753,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/26 08:30',
    pickup_end_date_time: '2021/09/26 08:30',
    delivery_start_date_time: '2021/09/27 03:00',
    delivery_end_date_time: '2021/09/27 03:00',
    coverage_probability: 0,
    load_status_change_datetime: null,
    call_date_time: '2021/09/21 11:52',
    cancel_date_time: null,
    booking_date_time: '2021/09/21 11:52',
    customer_status_datetime: null,
    load_id: '0257452',
    shipper_id: 'MONDELEZ - TEAC',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '30213',
    origin_city: 'Fairburn',
    origin_state: 'GA',
    origin_lat: '33.564800',
    origin_lng: '-84.580900',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '32824',
    destination_city: 'Orlando',
    destination_state: 'FL',
    destination_lat: '28.393200',
    destination_lng: '-81.362200',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 1594.0,
    other_revenue: 0.0,
    expedite: false,
    driver_id: null,
    static_cost: 21.0,
    mileage: 436,
    source: null,
    load_type: 'REAL',
    load_score: 0,
    load_status: 'NOT_RECOMMENDED',
    load_score_percentage: '0',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:31',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
  {
    id: 872752,
    engine_run: {
      id: 1,
      created_at: '2021/02/11 14:36',
      updated_at: '2021/02/11 14:36',
    },
    notification: [],
    pickup_start_date_time: '2021/09/29 15:00',
    pickup_end_date_time: '2021/09/30 08:00',
    delivery_start_date_time: '2021/09/30 20:30',
    delivery_end_date_time: '2021/09/30 20:30',
    coverage_probability: 90,
    load_status_change_datetime: null,
    call_date_time: '2021/09/21 11:51',
    cancel_date_time: null,
    booking_date_time: '2021/09/21 11:51',
    customer_status_datetime: null,
    load_id: '0257451',
    shipper_id: 'COUNTRY PURE FOODS',
    type_of_shipper: 'R',
    equipment: null,
    required_permit: null,
    committed_flag: false,
    origin_zipcode: '34737',
    origin_city: 'Howey-In-The-Hills',
    origin_state: 'FL',
    origin_lat: '28.697100',
    origin_lng: '-81.797600',
    pickup_appt_type: 'BY',
    origin_live: true,
    destination_zipcode: '33881',
    destination_city: 'Winter Haven',
    destination_state: 'FL',
    destination_lat: '28.045200',
    destination_lng: '-81.732500',
    delivery_appt_type: 'BY',
    destination_drop: false,
    line_haul_revenue: 629.1,
    other_revenue: 20.9,
    expedite: false,
    driver_id: null,
    static_cost: 0.0,
    mileage: 55,
    source: null,
    load_type: 'REAL',
    load_score: 18,
    load_status: 'RECOM',
    load_score_percentage: '90',
    carrier_id: 'RegularCarrier',
    subnetwork_id: null,
    weight: 0,
    customer_status: null,
    nonasset_permission_required: false,
    created_at: '09-23-2021 20:54:20',
    updated_at: '01-04-2022 16:59:31',
    num_stops: 0,
    internal_source: 'ASSET',
    external_source: 'REGULAR',
    tms_load_status: 'TENDERED',
    decision_rule_id: null,
    system_rule_id: null,
    driver_recommendations: null,
    engine_load_status: null,
    show_hand_icon: false,
    contract_id: null,
  },
];

export { laneDetailDrawerLoads };
