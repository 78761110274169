import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import NotificationsSharp from '@mui/icons-material/NotificationsSharp';
import ArrowForwardSharp from '@mui/icons-material/ArrowForwardSharp';
import { ODSlideOut, ODSelect, ODCloseButton } from '@OptimalDynamics/core-ai-common-ui';
import { useDispatch, useSelector } from 'react-redux';
import {
  ClearNotifications,
  GetAlerts,
  GetNotificationByFilter,
  SetNotificationStatus,
} from '../store/actions';
import { NavTriangleIcon } from './icons';
import { getBrowserizedTime, AMERICAN_DATE_TIME, SHORT_DATE_TIME_TZ } from '../utils/datetimes';

const NOTIFICATION_CATEGORY_OPTIONS = [
  { label: 'Data Change', value: 'data_change' },
  { label: 'Integration', value: 'integration' },
  { label: 'Run Status', value: 'run_status' },
  { label: 'ALL', value: 'ALL' },
];

// courtesy of Stack Overflow https://stackoverflow.com/a/64489760
const snakeToTitleCase = (s) => (
  s.replace(/^_*(.)/, (_, c) => c.toUpperCase())
    .replace(/_+(.)/g, (_, c) => ` ${c.toUpperCase()}`)
);

const GlobalNotification = (props) => {
  const { show, onClose, title, type, selectedLoadId, refreshData } = props;
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notificationReducer);

  const [selectedNotificationType, setselectedNotificationType] = useState('ALL');

  const handleNotificationTypeChange = (e) => {
    setselectedNotificationType(e.target.value);
  };

  const markNotificationStatus = (id) => {
    dispatch(SetNotificationStatus(id));
    setTimeout(() => {
      filterNotification();
      dispatch(GetAlerts(notification.engineRun));
      if (refreshData) {
        refreshData();
      }
    }, 200);
  };

  useEffect(() => {
    filterNotification();
    return () => {
      // cleanup
      dispatch(ClearNotifications());
    };
  }, [selectedNotificationType]);

  const filterNotification = () => {
    const filter = {
      notification_type: selectedNotificationType,
      notification_read: false,
      engine_run: notification.engineRun,
      load_id: selectedLoadId,
    };
    if (!selectedLoadId) {
      delete filter.load_id;
    }
    if (type) {
      filter['notification_level'] = type;
    }
    if (selectedNotificationType === 'ALL') delete filter.notification_type;
    dispatch(GetNotificationByFilter(filter));
  };

  return (
    <ODSlideOut
      anchor="right" 
      open={show} 
      onClose={(e) => onClose(e)}
      title={title}
    >
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
        <Box>
          <ODSelect
            label="Type of Notifications"
            onChange={(e) => handleNotificationTypeChange(e)}
            value={selectedNotificationType}
            menuOptions={NOTIFICATION_CATEGORY_OPTIONS}
            sx={{ width: '100%' }}
          />
        </Box>
        <Alerts
          notifications={notification.notifications}
          title={title}
          markRead={markNotificationStatus}
        />
      </Box>
    </ODSlideOut>
  );
};

const Alerts = ({ notifications, title, markRead }) => {
  if (!notifications || notifications.length === 0) {
    return (
      <Typography
        sx={{
          backgroundColor: 'accent',
          padding: '10px',
          marginBottom: '15px',
          textAlign: 'center',
        }}
      >
        {`${title} not found.`}
      </Typography>
    );
  }
  return notifications.map((notification) => {
    const [oldValue, newValue] = notification.extra_data.split('|');

    return (
      <div key={notification.id}>
        <Typography
          sx={{
            fontSize: '12px',
            lineHeight: '18px',
            display: 'flex',
            alignItems: 'center',
            color: 'text.unselected',
            marginBottom: '2px'
          }}
        >
          {getBrowserizedTime(notification.updated_at, AMERICAN_DATE_TIME, SHORT_DATE_TIME_TZ)}
        </Typography>
        <Box
          sx={{
            display: 'grid',
            columnGap: '16px',
            rowGap: '8px',
            gridTemplateColumns: '18px auto 32px',
            fontSize: 14,
            padding: '10px 0 10px 16px',
            backgroundColor: 'accent',
            borderRadius: '4px',
            alignItems: 'center'
          }}
        >
          <Box>
            {title.toLowerCase() === 'notifications' && <NotificationsSharp />}
            {title.toLowerCase() === 'alerts' && <NavTriangleIcon />}
          </Box>
          <Typography>
            <strong>Load ID:&nbsp;</strong>
            {notification.load_id}
          </Typography>
          <ODCloseButton
            className="notification-read"
            variant="lightBlue"
            size="small"
            sx={{ marginTop: '-10px' }}
            onClick={() => markRead(notification.id)}
          />
          <Typography sx={{ gridColumnStart: '2', gridRowStart: '2' }}>
            <strong>{`${snakeToTitleCase(notification.property_name)}: `}</strong>
            <strike>
              {oldValue}
            </strike>
          </Typography>
          <ArrowForwardSharp sx={{ gridRowStart: '3' }} />
          <Typography sx={{ gridColumnStart: '2', gridRowStart: '3' }}>
            <strong>Change:&nbsp;</strong>
            {newValue}
          </Typography>
        </Box>
      </div>
    );
  });
};

export default GlobalNotification;
