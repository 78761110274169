import {
  CLEAR_NOTIFICATION,
  GET_ALERT,
  GET_ENGINE_RUN_UPDATE,
  CLEAR_ENGINE_RUN_UPDATE_LOADING,
  GET_LOADS_NOTIFICATION_BY_FILTER,
  GET_NOTIFICATION,
  GET_NOTIFICATION_BY_FILTER,
  SET_ENGINE_RUN,
  SET_ENGINE_RUN_UPDATE,
  SET_LA_VIEW,
} from '../constants/actionTypes';

const initialState = {
  notificationCount: 0,
  loadAcceptanceNotificationCount: null,
  alertCount: 0,
  notifications: [],
  engineRun: 0,
  engineRunUpdate: null,
  isEngineRunUpdating: false,
  colorMode: 'light',
};

const setNotification = (state, action) => ({
  ...state,
  notificationCount: action.notification.count,
});

const setNotificationByFilter = (state, action) => ({
  ...state,
  notifications: action.notification.results,
});

const setAlert = (state, action) => ({
  ...state,
  alertCount: action.alert.count,
});

const setGetEngineRunUpdating = (state) => ({
  ...state,
  isEngineRunUpdating: true,
});

const clearEngineRunUpdatingLoading = (state) => ({
  ...state,
  isEngineRunUpdating: false,
});

const clearNotifications = (state) => ({
  ...state,
  notifications: [],
});
const setEngineRun = (state, action) => ({
  ...state,
  engineRun: action.engineRun,
  isEngineRunUpdating: false,
});
const setEngineRunUpdate = (state, action) => ({
  ...state,
  engineRunUpdate: action.engineRunUpdate,
  isEngineRunUpdating: false,
});
const setloadAcceptanceView = (state, action) => ({
  ...state,
  colorMode: action.colorMode,
});
const setLoadsNotification = (state, action) => ({
  ...state,
  loadAcceptanceNotificationCount: action.notification.count,
});

// eslint-disable-next-line default-param-last
const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION:
      return setNotification(state, action);
    case GET_ALERT:
      return setAlert(state, action);
    case GET_NOTIFICATION_BY_FILTER:
      return setNotificationByFilter(state, action);
    case GET_ENGINE_RUN_UPDATE:
      return setGetEngineRunUpdating(state, action);
    case CLEAR_ENGINE_RUN_UPDATE_LOADING:
      return clearEngineRunUpdatingLoading(state, action);
    case CLEAR_NOTIFICATION:
      return clearNotifications(state, action);
    case SET_ENGINE_RUN:
      return setEngineRun(state, action);
    case SET_ENGINE_RUN_UPDATE:
      return setEngineRunUpdate(state, action);
    case SET_LA_VIEW:
      return setloadAcceptanceView(state, action);
    case GET_LOADS_NOTIFICATION_BY_FILTER:
      return setLoadsNotification(state, action);
    default:
      return state;
  }
};

export default notificationReducer;
