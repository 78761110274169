/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useState, useMemo, ReactNode } from 'react';

/**
 * @constant ['view-all', 'outbound', 'inbound']
 */
export const LANE_DETAIL_VIEWS = Object.freeze(['view-all', 'outbound', 'inbound']);

export const LaneDetailViewCtx = createContext({
  viewValue: '',
  setViewValue: {}
});

export const LaneDetailViewCtxWrapper = ({ children }: { children: ReactNode }) => {
  const [viewValue, _setViewValue] = useState(LANE_DETAIL_VIEWS[0]);

  const setViewValue = ({
    [LANE_DETAIL_VIEWS[0]]: () => _setViewValue(LANE_DETAIL_VIEWS[0]),
    [LANE_DETAIL_VIEWS[1]]: () => _setViewValue(LANE_DETAIL_VIEWS[1]),
    [LANE_DETAIL_VIEWS[2]]: () => _setViewValue(LANE_DETAIL_VIEWS[2]),
  });

  const memoLaneDetailViewCtxValue = useMemo(() => ({ viewValue, setViewValue }), [viewValue]);

  return (
    <LaneDetailViewCtx.Provider
      value={memoLaneDetailViewCtxValue}
    >
      { children }
    </LaneDetailViewCtx.Provider>
  );
};
