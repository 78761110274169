// SignIn
export const SIGNIN_START = 'SIGNIN_START';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';
export const SIGNOUT = 'SIGNOUT';
export const USER_ROLES = 'USER_ROLES';
export const USER_SETTINGS = 'USER_SETTINGS';
export const USER_DATA = 'USER_DATA';
export const LAUNCH_DARKLY_USER_IDENTIFIED = 'LAUNCH_DARKLY_USER_IDENTIFIED';

// Dispatch
export const LOADS_SUCCESS = 'LOADS_SUCCESS';
export const LOADS_CLEAR = 'LOADS_CLEAR';

// Driver
export const DRIVER_START = 'DRIVER_START';
export const DRIVER_FAIL = 'DRIVER_FAIL';
export const DRIVER_CLEAR = 'DRIVER_CLEAR';
export const DRIVER_LOADING = 'DRIVER_LOADING';

// ParameterView
export const ORGANIZATION_SUCCESS = 'ORGANIZATION_SUCCESS';
export const ORGANIZATION_FAIL = 'ORGANIZATION_FAIL';
export const PARAMETER_SET_SUCCESS = 'PARAMETER_SET_SUCCESS';
export const PARAMETER_SET_FAIL = 'PARAMETER_SET_FAIL';
export const PARAMETER_VERSION_SUCCESS = 'PARAMETER_VERSION_SUCCESS';
export const PARAMETER_VERSION__FAIL = 'PARAMETER_VERSION__FAIL';
export const PARAMETER_SCHEMA_SUCCESS = 'PARAMETER_SCHEMA_SUCCESS';
export const PARAMETER_SCHEMA_FAIL = 'PARAMETER_SCHEMA_FAIL';
export const SCHEMA_EDITOR_SUCCESS = 'SCHEMA_EDITOR_SUCCESS';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const SET_PARAMETER_SET = 'SET_PARAMETER_SET';
export const SET_PARAMETER_VERSION = 'SET_PARAMETER_VERSION';
export const RESET_PARAMETER_VERSION = 'RESET_PARAMETER_VERSION';
export const RESET_PARAMETER_VIEW = 'RESET_PARAMETER_VIEW';
export const SAVE_PARAMETER_SCHEMA_SUCCESS = 'SAVE_PARAMETER_SCHEMA_SUCCESS';
export const SAVE_PARAMETER_SCHEMA_FAIL = 'SAVE_PARAMETER_SCHEMA_FAIL';
export const UPDATE_PARAMETER_SCHEMA = 'UPDATE_PARAMETER_SCHEMA';
export const COPY_PARAMETER_VERSION_SUCCESS = 'COPY_PARAMETER_VERSION_SUCCESS';
export const COPY_PARAMETER_VERSION_FAIL = 'COPY_PARAMETER_VERSION_FAIL';
export const RESET_PARAMETER_ON_COPY = 'RESET_PARAMETER_ON_COPY';
export const SAVE_USER_FAVOURITES_SUCCESS = 'SAVE_USER_FAVOURITES_SUCCESS';
export const SAVE_USER_FAVOURITES_FAIL = 'SAVE_USER_FAVOURITES_FAIL';
export const GET_USER_FAVOURITES = 'GET_USER_FAVOURITES';
export const SET_SELECTED_FAVOURITES = 'SET_SELECTED_FAVOURITES';
export const SHOW_SELECTED_FAVOURITES = 'SHOW_SELECTED_FAVOURITES';

// Load Acceptance
export const GET_LOAD_BY_ID_SUCCESS = 'GET_LOAD_BY_ID_SUCCESS';
export const GET_LOAD_BY_ID_FAILURE = 'GET_LOAD_BY_ID_FAILURE';
export const CLEAR_LOAD_DETAI_BY_ID = 'CLEAR_LOAD_DETAI_BY_ID';
export const GET_PICKUP_TYPE = 'GET_PICKUP_TYPE';
export const GET_DELIVERY_TYPE = 'GET_DELIVERY_TYPE';
export const GET_CUSTOMER_ID = 'GET_CUSTOMER_ID';
export const GET_LOAD_TYPE = 'GET_LOAD_TYPE';
export const GET_SCENARIO_LIST = 'GET_SCENARIO_LIST';
export const GET_LAZY_LOAD_ACCEPTANCE = 'GET_LAZY_LOAD_ACCEPTANCE';
export const GET_ENGINE_RULES = 'GET_ENGINE_RULES';
export const UPDATE_ENGINE_RULE_ERROR = 'UPDATE_ENGINE_RULE_ERROR';
export const GET_ENGINE_RULE_BY_RULE_ID = 'GET_ENGINE_RULE_BY_RULE_ID';
export const SET_DRIVER_TOUR = 'SET_DRIVER_TOUR';
export const SET_SHOW_DRIVER_MOVEMENT = 'SET_SHOW_DRIVER_MOVEMENT';
export const GET_PICKUP_STATE_LIST = 'GET_PICKUP_STATE_LIST';
export const GET_DESTINATION_STATE_LIST = 'GET_DESTINATION_STATE_LIST';
export const SHOW_CREATE_RULE = 'SHOW_CREATE_RULE';
export const SET_EXTENDED_TOUR_DATA = 'SET_EXTENDED_TOUR_DATA';
export const LA_GET_DROPDOWN_FILTERS_INIT = 'LA_GET_DROPDOWN_FILTERS_INIT';
export const GET_FILTER_OPTIONS_LOAD_IDS = 'GET_FILTER_OPTIONS_LOAD_IDS';
export const GET_FILTER_OPTIONS_REGION_CODES = 'GET_FILTER_OPTIONS_REGION_CODES';
export const LOAD_ACCEPTANCE_POLLING = 'LOAD_ACCEPTANCE_POLLING';
export const CHECK_OR_SET_LA_DATA = 'CHECK_OR_SET_LA_DATA';
export const STOP_POLLING_LOAD_ACCEPTANCE = 'STOP_POLLING_LOAD_ACCEPTANCE';
export const SET_IS_FETCHING_LOAD_COUNTS = 'SET_IS_FETCHING_LOAD_COUNTS';
export const SET_LA_VIEW_TYPES = 'SET_LA_VIEW_TYPES';
export const SET_OPERATIONS_USER_FILTER_OPTIONS = 'SET_OPERATIONS_USER_FILTER_OPTIONS';

// Notification
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_ALERT = 'GET_ALERT';
export const GET_NOTIFICATION_BY_FILTER = 'GET_NOTIFICATION_BY_FILTER';
export const GET_ENGINE_RUN_UPDATE = 'GET_ENGINE_RUN_UPDATE';
export const CLEAR_ENGINE_RUN_UPDATE_LOADING = 'CLEAR_ENGINE_RUN_UPDATE_LOADING';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const SET_ENGINE_RUN = 'SET_ENGINE_RUN';
export const SET_ENGINE_RUN_UPDATE = 'SET_ENGINE_RUN_UPDATE';
export const SET_LA_VIEW = 'SET_LA_VIEW';
export const GET_LOADS_NOTIFICATION_BY_FILTER = 'GET_LOADS_NOTIFICATION_BY_FILTER';

/// Contracts
export const GET_CONTRACTS = 'GET_CONTRACTS';
export const UPDATE_CONTRACTS_VIEW_SELECTION = 'UPDATE_CONTRACTS_VIEW_SELECTION';
export const SET_COMMITMENT_MANAGER_VIEW = 'SET_COMMITMENT_MANAGER_VIEW';
export const GET_SHIPPER_ACCOUNT_LIST = 'GET_SHIPPER_ACCOUNT_LIST';
export const GET_SHIPPER_ACCOUNT_ID = 'GET_SHIPPER_ACCOUNT_ID';
export const GET_AGGREGATED_SHIPPER_ACCOUNT = 'GET_AGGREGATED_SHIPPER_ACCOUNT';
export const GET_AGGREGATED_COMMITMENT_ACCOUNTID = 'GET_AGGREGATED_COMMITMENT_ACCOUNTID';
export const GET_AGGREGATED_COMMITMENT_LANES = 'GET_AGGREGATED_COMMITMENT_LANES';
export const SET_DEFAULT_CONTRACT = 'SET_DEFAULT_CONTRACT';
export const SET_CURRENT_CONTRACT_INFO = 'SET_CURRENT_CONTRACT_INFO';
export const SET_CURRENT_CONTRACT_COMMITMENTS = 'SET_CURRENT_CONTRACT_COMMITMENTS';
export const CREATE_COMMITMENT_SUCCESS = 'CREATE_COMMITMENT_SUCCESS';
export const CREATE_COMMITMENT_FAILURE = 'CREATE_COMMITMENT_FAILURE';
export const EDIT_ACCOUNT = 'EDIT_ACCOUNT';
export const UPDATE_COMMITMENT_SUCCESS = 'UPDATE_COMMITMENT_SUCCESS';
export const UPDATE_COMMITMENT_FAILURE = 'UPDATE_COMMITMENT_FAILURE';
export const SHOW_CREATE_FORM = 'SHOW_CREATE_FORM';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const DELETE_LANE = 'DELETE_LANE';

// Tactical Procurement
export const SET_REGIONAL_LIST = 'SET_REGIONAL_LIST';
export const SET_CHILD_CITY_STATE_LIST = 'SET_CHILD_CITY_STATE_LIST';
export const SET_SELECTED_LOAD_IDS = 'SET_SELECTED_LOAD_IDS';
export const ADD_SELECTED_LOAD_IDS = 'ADD_SELECTED_LOAD_IDS';
export const REMOVE_SELECTED_LOAD_IDS = 'REMOVE_SELECTED_LOAD_IDS';
export const ADD_SELECTED_LOAD_ID = 'ADD_SELECTED_LOAD_ID';
export const REMOVE_SELECTED_LOAD_ID = 'REMOVE_SELECTED_LOAD_ID';
export const REMOVE_ASSIGNED_LOAD_IDS = 'REMOVE_ASSIGNED_LOAD_IDS';
export const ADD_ASSIGNED_LOAD_IDS = 'ADD_ASSIGNED_LOAD_IDS';
export const CLEAR_ASSIGNED_LOAD_IDS = 'CLEAR_ASSIGNED_LOAD_IDS';
export const ADD_TO_LOAD_IDS_TABLE = 'ADD_TO_LOAD_IDS_TABLE';
export const ADD_ENTRIES_TO_LOAD_IDS_TABLE = 'ADD_ENTRIES_TO_LOAD_IDS_TABLE';
export const CLEAR_LOAD_IDS_TABLE = 'CLEAR_LOAD_IDS_TABLE';
export const ADD_TO_SELECTED_LOADS_AUDITOR = 'ADD_TO_SELECTED_LOADS_AUDITOR';
export const CLEAR_SELECTED_LOADS_AUDITOR = 'CLEAR_SELECTED_LOADS_AUDITOR';

// General
export const SET_ALL_STATES = 'SET_ALL_STATES';
export const SHOW_SCENARIO_LOADER = 'SHOW_SCENARIO_LOADER';

// Dispatching (new)
export const SET_SOMETHING_HERE = 'SET_SOMETHING_HERE';
export const SET_DRIVERS_DISPATCHING_LIST = 'SET_DRIVERS_DISPATCHING_LIST';
export const SET_DRIVERS_DISPATCHING_LIST_COUNT = 'SET_DRIVERS_DISPATCHING_LIST_COUNT';
export const SET_LOADS_DISPATCHING_LIST = 'SET_LOADS_DISPATCHING_LIST';
export const SET_LOADS_DISPATCHING_LIST_COUNT = 'SET_LOADS_DISPATCHING_LIST_COUNT';
export const SET_DRIVER_STATUS = 'SET_DRIVER_STATUS';
export const SET_DRIVER_DISPATCHING_FILTER_OPTIONS = 'SET_DRIVER_DISPATCHING_FILTER_OPTIONS';
export const ADD_SELECTED_LOAD_ASSIGNMENT = 'ADD_SELECTED_LOAD_ASSIGNMENT';
export const REMOVE_SELECTED_LOAD_ASSIGNMENT = 'REMOVE_SELECTED_LOAD_ASSIGNMENT';
export const CLEAR_SELECTED_LOAD_ASSIGNMENT = 'CLEAR_SELECTED_LOAD_ASSIGNMENT';
export const SET_TOP_LOAD_OPTIONS = 'SET_TOP_LOAD_OPTIONS';
export const CLEAR_TOP_LOAD_OPTIONS = 'CLEAR_TOP_LOAD_OPTIONS';
export const REMOVE_TOP_LOAD_OPTION = 'REMOVE_TOP_LOAD_OPTION';
export const SET_OFFERED_LOAD_DETAILS = 'SET_OFFERED_LOAD_DETAILS';
export const SET_DISPATCHING_ASSIGNMENT_LIST = 'SET_DISPATCHING_ASSIGNMENT_LIST';
export const SET_DISPATCHING_ASSIGNMENT_LIST_COUNT = 'SET_DISPATCHING_ASSIGNMENT_LIST_COUNT';
export const POLLING_UPDATE = 'POLLING_UPDATE';
export const CHECK_OR_SET_DATA_UPDATE = 'CHECK_OR_SET_DATA_UPDATE';
export const STOP_POLLING_DISPATCHING = 'STOP_POLLING_DISPATCHING';
export const SET_DISPATCHING_ASSIGNMENT_HISTORY = 'SET_DISPATCHING_ASSIGNMENT_HISTORY';
export const SET_DISPATCHING_ASSIGNMENT_HISTORY_COUNT = 'SET_DISPATCHING_ASSIGNMENT_HISTORY_COUNT';
export const SET_DISPATCHING_HISTORY_ACTIVE = 'SET_DISPATCHING_HISTORY_ACTIVE';
export const SET_DISPATCHING_HISTORY_EXPIRATION_TIMING = 'SET_DISPATCHING_HISTORY_EXPIRATION_TIMING';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const SET_LOAD_DISPATCHING_FILTER_OPTIONS = 'SET_LOAD_DISPATCHING_FILTER_OPTIONS';
export const SET_DISPATCHING_ENGINE_RUN_ID = 'SET_DISPATCHING_ENGINE_RUN_ID';
export const SET_DISPATCHING_PLANNING_HORIZON = 'SET_DISPATCHING_PLANNING_HORIZON';
export const SET_DRIVERS_LIST = 'SET_DRIVERS_LIST';
export {
  SET_ONE_ASSIGN_BY_LOAD, 
  ADD_ASSIGN_BY_LOAD,
  REMOVE_ASSIGN_BY_LOAD, 
  CLEAR_ASSIGN_BY_LOAD, 
  BATCH_ADD_ASSIGN_BY_LOAD,
} from '../actions/dispatchingAction';
export const SET_DISPATCHING_LOAD_OPTIONS = 'SET_DISPATCHING_LOAD_OPTIONS';
export const SET_DISPATCHING_DRIVER_MANAGER_FILTER_OPTIONS = 'SET_DISPATCHING_DRIVER_MANAGER_FILTER_OPTIONS';
export const SET_DRIVER_AVAILABILITY_DATA = 'SET_DRIVER_AVAILABILITY_DATA';
export const SET_LOAD_AVAILABILITY_DATA = 'SET_LOAD_AVAILABILITY_DATA';
export const SET_KPI_CHARTS_DATA = 'SET_KPI_CHARTS_DATA';
export const SHOW_KPI_LOADER = 'SHOW_KPI_LOADER';

// User Settings
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const GET_USER_SETTINGS = 'GET_USER_SETTINGS';

// Forecasting
export const SET_FORECASTING_ENGINE_RUN_INFO = 'SET_FORECASTING_ENGINE_RUN_INFO';

// Trailer Assignment
export const UPDATE_TRAILER_ASSIGNMENT_REDUCER = 'UPDATE_TRAILER_ASSIGNMENT_REDUCER';
export const CHECK_OR_SET_TRAILER_DATA_UPDATE = 'CHECK_OR_SET_TRAILER_DATA_UPDATE';
export const TRAILER_POLLING_UPDATE = 'TRAILER_POLLING_UPDATE';
export const STOP_POLLING_TRAILER = 'STOP_POLLING_TRAILER';
