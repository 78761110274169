/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { ODDialog } from '@OptimalDynamics/core-ai-common-ui';
import { CircularProgress, Grid, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import AddSharp from '@mui/icons-material/AddSharp';
import CheckSharp from '@mui/icons-material/CheckSharp';
import PriorityHighSharp from '@mui/icons-material/PriorityHighSharp';
import Rule from '@mui/icons-material/Rule';
import SwapHorizSharp from '@mui/icons-material/SwapHorizSharp';

// TODO: Explore using Internal Admin to fetch dictionary of values. 

export const LOAD_SCORE = 'load-score';
export const ASSIGNMENT_STATUS = 'assignment-status';
export const TMS_ASSIGNMENT = 'tms-assignment';

const INFO_DICT = {
  [LOAD_SCORE]: {
    headerTitle: 'Load Score',
    headerCopy: [
      `The higher the Load Score, the better quality the load is, and therefore the better bet it is to take it on Asset.`, 
      `While this quality score is determined by numerous complex factors and calculations within the engine, it can be summarized as a mix of:`
    ],
    bodyTitle: '',
    body: [
      { 
        header: '',
        content: `The value of the load`,
        icon: ''
      },
      { 
        header: '',
        content: `The likelihood that a driver will be available to take it`,
        icon: '' 
      },
      { 
        header: '',
        content: `The forecasted future location of drivers who take it`,
        icon: ''
      },
    ]
  },
  [ASSIGNMENT_STATUS]: {
    headerTitle: 'Optimized Assignment',
    headerCopy: [`The Optimized Assignment provides how to keep the highest valued loads on your asset and move the rest that are not needed for your fleet to a brokerage.`],
    bodyTitle: 'Optimized Assignment Guide',
    body: [
      { 
        header: 'Assign:',
        content: `This status represents loads that we have recommended for you to assign that currently don't have a brokerage or asset status in your TMS.`,
        icon: () => <AddSharp sx={{ color: 'colors.semanticBlue' }} />
      },
      { 
        header: 'Switch:',
        content: `This status represents loads that currently are assigned to the opposite of the recommendation in your TMS and we recommend switching over.`,
        icon: () => <SwapHorizSharp sx={{ color: 'colors.semanticBlue' }} />
      },
      { 
        header: 'Syncing:',
        content: `This status appears when a   recommendation has been acted on and the load is currently in the process of syncing with your TMS.`,
        icon: () => (
          <CircularProgress
            sx={{ 
              display: 'inline-flex',
              color: 'success.main'
            }}
            size="18px"
          />
        )
      },
      { 
        header: 'Matches TMS:',
        content: `This status represents loads that have been assigned in your TMS and match the optimization assignment recommendation.`,
        icon: () => <CheckSharp sx={{ color: 'success.main' }} />
      },
      { 
        header: 'Rules:',
        content: `This icon represents a load assignment that has a rule applied to it and is the deciding factor on the optimized assignment action label.`,
        icon: () => <Rule sx={{ color: 'colors.black' }} />
      },
      { 
        header: 'Error Message:',
        content: `This icon represents either current or historic errors that have occurred to this load while syncing with your TMS.`,
        icon: () => <PriorityHighSharp sx={{ color: 'alert.main' }} />
      },
    ]
  },
  [TMS_ASSIGNMENT]: {
    headerTitle: 'TMS Assignment',
    headerCopy: [`This column represents the current status of your loads in your TMS since the last sync update. Optimized Assignment column will help you know what action to take if it doesn’t match our recommendation.`],
    bodyTitle: 'TMS Assignment Guide',
    body: [
      { 
        header: 'Unassigned: ',
        content: `This status represents loads that aren’t allocated to either asset or brokerage in your TMS.`,
        icon: null,
      },
      { 
        header: 'Brokerage: ',
        content: `This status represents loads that you have assigned as Brokerage in your TMS`,
        icon: null,
      },
      { 
        header: 'Asset: ',
        content: `This status represents loads that you have assigned as Asset in your TMS`,
        icon: null,
      },
    ]
  }
};

export default function useInformationModal() {
  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (title !== '') {
      setOpenModal(true);
    }
  }, [title]);

  const setInformationContent = ({ 
    headerCopy, 
    bodyTitle, 
    body
  }) => (
    <Box>
      <Grid
        item
        xs={12}
      >
        {headerCopy.map((hCopy, hInd) => 
          <Typography sx={{ mb: ' 20px' }} key={`hcopy-${hInd}`}>{hCopy}</Typography>)}
      </Grid>
      {bodyTitle !== '' ? (
        <Grid
          item
          xs={12}
          height="40px"
          sx={{
            backgroundColor: 'colors.neutral0',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            margin: '16px 0 0'
          }}
        >
          <Typography><strong>{bodyTitle}</strong></Typography>
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          sx={{ 
            marginTop: '16px',
            borderBottom: '1px solid',
            borderColor: 'level2' 
          }}
        />
      )}
      {body.map(({
        header,
        content,
        icon,
      }, bInd) => (
        <Grid
          item
          xs={12}
          sx={{ display: 'flex' }}
          key={`body-${bInd}`}
        >
          <Grid 
            item
            xs={12}
            sx={{
              borderBottom: '1px solid',
              borderBottomColor: 'level2',
              display: 'flex',
              alignItems: 'center',
              padding: '10px 16px',
              minHeight: 40,
            }}
          >
            {icon && (
              <Grid
                item   
                xs={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '100%',
                  paddingRight: '16px'
                }}
              >
                {icon()}
              </Grid>
            )}
            <Typography>
              <strong>{header}</strong> 
              {' '}
              {content}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Box>
  );

  const InformationModal = ({ onClose }) => {
    if (!title) return <></>;

    return (
      <ODDialog
        title={INFO_DICT[title].headerTitle}
        open={openModal}
        onClose={onClose}
        sx={{ '& .MuiPaper-root': { width: '456px !important' } }}
      >
        {setInformationContent(INFO_DICT[title])}
      </ODDialog>
    );
  };

  return {
    InformationModal,
    setTitle
  };
}
