import React, { ReactElement } from 'react';
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreSharp from '@mui/icons-material/ExpandMoreSharp';

declare interface ODAccordionProps extends AccordionProps {
  summary: string | ReactElement;
  summaryControls?: string;
  summaryId?: string;
  fieldLabel?: string;
}

const ODAccordion = ({ summary, fieldLabel, summaryControls, summaryId, children, ...rest }: ODAccordionProps) => (
  <Accordion
    sx={{
      width: '100%',
      mb: '2px',
      '&.MuiPaper-root.MuiAccordion-root.Mui-expanded': { m: 0 },
      '&.MuiPaper-root.MuiAccordion-root:before': { top: 0, backgroundColor: 'unset' }
    }}
    elevation={0}
    {...rest}
  >
    <AccordionSummary
      aria-controls={summaryControls}
      id={summaryId}
      sx={{
        backgroundColor: 'level2',
        borderRadius: '4px',
        minHeight: '40px',
        '&.Mui-expanded': { minHeight: 40 },
        '& .MuiAccordionSummary-content': { my: '0 !important' },
        '& .MuiAccordionDetails-root': { p: 0 }
      }}
      expandIcon={<ExpandMoreSharp sx={{ color: 'black' }} />}
    >
      {typeof summary === 'string' ? <Typography><strong>{summary}</strong></Typography> : summary}
    </AccordionSummary>
    <AccordionDetails sx={{ p: '16px 0' }}>
      {!!fieldLabel && (
        <Typography sx={{ fontSize: '12px', color: 'text.secondary', pb: 1 }}>
          { fieldLabel }
        </Typography>
      )}
      {children}
    </AccordionDetails>
  </Accordion>
);

export default ODAccordion;
