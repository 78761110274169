import { UPDATE_TRAILER_ASSIGNMENT_REDUCER, CHECK_OR_SET_TRAILER_DATA_UPDATE, TRAILER_POLLING_UPDATE } from '../constants/actionTypes';

export const updateTrailerAssignment = (payload) => ({
  type: UPDATE_TRAILER_ASSIGNMENT_REDUCER,
  payload
});

export const pollTrailerUpdate = () => ({
  type: TRAILER_POLLING_UPDATE,
});

export const checkForNewTrailerData = (dataUpdated, taskId, latestRunUpdated) => ({
  type: CHECK_OR_SET_TRAILER_DATA_UPDATE,
  payload: {
    dataUpdated,
    taskId,
    latestRunUpdated
  }
});
