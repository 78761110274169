import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Typography } from '@mui/material';
import PublishedWithChanges from '@mui/icons-material/PublishedWithChanges';
import { ODButton, ODDialog, ODLaneIcon } from '@OptimalDynamics/core-ai-common-ui';

const StepLineItem = ({ index, description }) => (
  <Box
    sx={{
      padding: '8px 16px',
      borderTop: '1px solid',
      borderColor: 'level2',
      display: 'grid',
      gridTemplateColumns: '24px 1fr',
      columnGap: '16px',
      alignItems: 'center'
    }}
  >
    <Avatar sx={{ alignSelf: 'start', width: 24, height: 24, fontSize: 12, backgroundColor: 'accent', color: 'text.primary' }}>
      <strong>{index}</strong>
    </Avatar>
    <Typography>{description}</Typography>
  </Box>
);

const BrokerageLoadDialog = ({ loadId, onClose }) => {
  const navigate = useNavigate();

  return (
    <ODDialog title="Brokerage Load" open={!!loadId} onClose={onClose}>
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
        <Typography>
          Loads assigned to Brokerage cannot be dispatched. To be able to assign this load:
        </Typography>
        <Box
          sx={{
            height: '40px',
            backgroundColor: 'accent',
            display: 'flex',
            alignItems: 'center',
            columnGap: '16px',
            px: 2,
            borderRadius: '4px'
          }}
        >
          <ODLaneIcon />
          <Typography>{loadId}</Typography>
        </Box>
        <Box>
          <StepLineItem index={1} description="Click Go to Load Acceptance" />
          <StepLineItem index={2} description="Switch from Brokerage to Asset" />
          <StepLineItem
            index={3}
            description={(
              <>
                Wait for the next update (noted with&nbsp;
                <PublishedWithChanges sx={{ height: '14px !important', width: '14px !important' }} />
                &nbsp;at the bottom of the page)
              </>
            )}
          />
        </Box>
        <Box sx={{ mt: 2, display: 'flex', columnGap: '16px' }}>
          <ODButton
            sx={{ width: '50%' }}
            data-testid="title:brokerage-load-cancel-secondary-action-button"
            onClick={onClose}
          >
            Close
          </ODButton>
          <ODButton
            sx={{ width: '50%' }}
            variant="blue"
            data-testid="title:brokerage-load-go-to-la-primary-action-button"
            onClick={() => navigate(`/load-acceptance/${loadId}`)}
          >
            Go to Load Acceptance
          </ODButton>
        </Box>
      </Box>
    </ODDialog>
  );
};

export default BrokerageLoadDialog;
