import React, { useEffect, useMemo, useState } from 'react';
import './App.css';
import { Helmet } from 'react-helmet';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { createCommonUITheme } from '@OptimalDynamics/core-ai-common-ui';
import { Provider } from 'react-redux';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import store from './store/configureStore';
import EnvConfig from './config/config';
import LDUserConfig from './config/LDUserConfig';
import { getDesignTokens } from './Theme';
import instance from './utils/axios_instance';
import Auth, { connection } from './auth/auth';
import { env } from './env';
import Routes from './Routes';

const subdomain = window.location.host.includes('localhost')
  ? env.REACT_APP_CONNECTION
  : window.location.host.split('.')[0];

const App = () => {
  const [organization, setOrganization] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    const fetchAuthType = async () => {
      const response = await instance.get(`/auth-type/${subdomain}/`);
      return response;
    };
    fetchAuthType()
      .then((res) => setOrganization(res.data))
      .catch((err) => setErrorMessages(err.response.data.errors));
  }, []);

  const theme = useMemo(() => {
    document.body.style.backgroundColor = '#FFFFFF';
    return createCommonUITheme(getDesignTokens('light'));
  }, []);

  return (
    <Auth organization={organization} errorMessages={errorMessages}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <div id="operational-application" className="light-mode">
              <LDUserConfig />
              <Helmet>
                <meta charSet="utf-8" />
                <title>Execute</title>
              </Helmet>
              <Routes errorMessages={errorMessages} validOrg={organization?.success} />
            </div>
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Auth>
  );
};

export default withLDProvider({
  clientSideID: EnvConfig.getConfig().clientSideId,
  context: {
    kind: 'user',
    key: EnvConfig.getConfig().sdkKey,
    custom: { organization: connection() },
  },
})(App);
