import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ODTooltip } from '@OptimalDynamics/core-ai-common-ui';
import { Box, Typography } from '@mui/material';
import AlarmOnSharp from '@mui/icons-material/AlarmOnSharp';
import CheckCircleSharp from '@mui/icons-material/CheckCircleSharp';
import PersonOffSharp from '@mui/icons-material/PersonOffSharp';
import { TuneOffIcon } from '../../../common/icons';
import { NO_AVAIL_MATCHES } from '../helpers/constants';
import { DispatchingLoad, MatchSpecifics } from '../types';
import { DriverIdentifier } from '../helpers/dynamicDescription';
import { getStarLabel } from '../helpers/dispatchingScripts';

const LoadDispatchMatchCell = ({ row, pta }: { row: DispatchingLoad, pta: number }) => {
  const { showRelaxedMatches, hideMatchActionColumn } = useFlags();
  const { overall_rating, assigned, driver, is_relaxed, movement_option_rank } = row;

  let specifics = {} as MatchSpecifics;
  let availableNow = false;

  const noAvailMatchesSpecifics = {
    icon: <PersonOffSharp />,
    text: NO_AVAIL_MATCHES,
    tooltip: 'No Available Matches'
  };

  switch (true) {
    case !!assigned:
      specifics = {
        icon: <CheckCircleSharp sx={{ color: 'success.main' }} />,
        text: 'ASSIGNED',
        tooltip: 'Assigned'
      };
      break;
    case !driver.driver_id || (!showRelaxedMatches && is_relaxed):
      specifics = noAvailMatchesSpecifics;
      break;
    case showRelaxedMatches && is_relaxed:
      specifics = {
        icon: <TuneOffIcon />,
        text: <DriverIdentifier driver={driver} />,
        tooltip: 'Exception Match'
      };
      break;
    case pta <= 0:
      availableNow = true;
      // fall through
    case pta > 0:
      const starSpecs = getStarLabel(overall_rating, movement_option_rank);
      specifics = {
        icon: starSpecs.star,
        text: <DriverIdentifier driver={driver} />,
        tooltip: `${starSpecs.label} Driver Match`
      };
      break;
    default:
      return <></>;
  }

  if (Object.keys(specifics).length === 0) return <></>;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
      {availableNow && (
        <ODTooltip title="Available for Dispatch" placement="right">
          <AlarmOnSharp />
        </ODTooltip>
      )}
      <ODTooltip title={specifics.tooltip} placement="right">
        {specifics.icon}
      </ODTooltip>
      <Typography sx={{ ...(!hideMatchActionColumn && { textDecoration: 'underline' }) }}>{specifics.text}</Typography>
    </Box>
  );
};

export default LoadDispatchMatchCell;
