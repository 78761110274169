/* eslint-disable no-underscore-dangle */
import instance from '../../utils/axios_instance';
import { 
  GetPickupStates, 
  GetCustomerIds, 
  GetDestinationStates, 
  GetLoadType, GetPickupType, 
  GetDeliveryType, fetchAllStates,
  GetRegionCodes,
} from '../actions';
import { checkForNewLoadAcceptanceData } from '../actions/loads';
import { LA_GET_DROPDOWN_FILTERS_INIT, LOAD_ACCEPTANCE_POLLING } from '../constants/actionTypes';

const loadAcceptanceWare = (store) => (next) => (action) => {
  const defaultResult = next(action);
  const __debugging = action?.payload?.debugging;
  
  switch (action.type) {
    case LA_GET_DROPDOWN_FILTERS_INIT:
      Promise.all([
        store.dispatch(GetPickupStates()),
        store.dispatch(GetCustomerIds()),
        store.dispatch(GetDestinationStates()),
        store.dispatch(GetLoadType()),
        store.dispatch(GetPickupType()),
        store.dispatch(GetDeliveryType()),
        store.dispatch(GetRegionCodes()),
        store.dispatch(fetchAllStates()),
      ]).then(() => !!__debugging && console.info(`All async thunks associated w/ action type: ${LA_GET_DROPDOWN_FILTERS_INIT} dispatched...`));
      break;

    case LOAD_ACCEPTANCE_POLLING:
      instance
        .get('/load-acceptance/data-updated')
        .then((res) => store.dispatch(checkForNewLoadAcceptanceData(res.data.result)));
      break;

    default:
      return defaultResult;
  }
};

export default loadAcceptanceWare;
