import React from 'react';
import { Box, Snackbar, Typography } from '@mui/material';
import { ODButton } from '@OptimalDynamics/core-ai-common-ui';
import ConnectWithoutContactSharp from '@mui/icons-material/ConnectWithoutContactSharp';

const SuccessSnackBar = ({
  open,
  onCloseHandler,
  message,
  type,
  buttons,
}: {
  open: boolean;
  onCloseHandler: () => void;
  message: string;
  type: string;
  buttons?: React.ReactNode;
}) => {
  let backgroundColor: string;
  let icon;
  let textColor: string;

  switch (type) {
    case 'success':
      backgroundColor = 'optimalblue.main';
      icon = <ConnectWithoutContactSharp fill="white" sx={{ paddingBottom: '2px' }} />;
      textColor = 'white';
      break;
    default:
      backgroundColor = 'white';
      textColor = 'colors.black';
      break;
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      onClose={onCloseHandler}
      sx={{ '@media (min-width: 600px)': { right: '73px', bottom: '73px' } }}
    >
      <Box
        sx={{
          backgroundColor,
          color: textColor,
          width: '640px',
          padding: `8px 16px 16px 16px`,
          borderRadius: '4px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            gap: '16px',
            paddingTop: '8px',
            paddingBottom: '8px',
            fontSize: '14px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', height: '1.43rem' }}>
            {icon}
          </Box>
          <Typography sx={{ textWrap: 'wrap' }}>{message}</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'end', button: { fontWeight: 'bold' } }}>
          <ODButton onClick={onCloseHandler} variant="blue">
            DISMISS
          </ODButton>
          {buttons}
        </Box>
      </Box>
    </Snackbar>
  );
};

export default SuccessSnackBar;
