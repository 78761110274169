import { LinearProgress, styled } from '@mui/material';

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.level3
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.inverse.level2
  },
}));
