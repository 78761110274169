export const parseFloatCoords = (load) => {
  const {
    destination_lat, destination_lng, origin_lat, origin_lng 
  } = load;
  const startLatitude = parseFloat(origin_lat);
  const startLongitude = parseFloat(origin_lng);
  const stopLatitude = parseFloat(destination_lat);
  const stopLongitude = parseFloat(destination_lng);
  return {
    startLatitude, stopLatitude, startLongitude, stopLongitude 
  };
};

export const selectedLoadCalcData = (load) => {
  const {
    startLatitude, stopLatitude, startLongitude, stopLongitude 
  } = parseFloatCoords(load);
  
  const mx = stopLatitude - startLatitude;
  const my = stopLongitude - startLongitude;
  const angle = (Math.atan2(mx, my) * 180) / Math.PI;
  const midPoint = [
    (startLongitude + stopLongitude) / 2,
    (startLatitude + stopLatitude) / 2
  ];

  return { angle, midPoint };
};

export const selectedLoadMidPoint = (load) => selectedLoadCalcData(load).midPoint;
export const selectedLoadAngle = (load) => selectedLoadCalcData(load).angle;

/** Function takes a load and returns an array of 2 [lng, lat] pairs, the southwestern and northeastern
corners of the specified geographical bounds provided by the load object. */
export const getBoundingBox = (load) => {
  const {
    startLatitude, stopLatitude, startLongitude, stopLongitude 
  } = parseFloatCoords(load);
  return [[Math.min(startLongitude, stopLongitude), Math.min(startLatitude, stopLatitude)], [Math.max(startLongitude, stopLongitude), Math.max(startLatitude, stopLatitude)]];
};

export const randomHex = () => Math.floor(Math.random() * 16777215).toString(16);

export const randomIntInterval = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const formatWithUnit = (value, unit) => {
  const formattedNumber = new Intl.NumberFormat('en').format(value);
  return `${formattedNumber} ${unit}`;
};
