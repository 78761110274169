import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { startCase } from 'lodash';
import { Box, SxProps, Typography, useTheme } from '@mui/material';
import CheckCircleSharp from '@mui/icons-material/CheckCircleSharp';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import InsightsSharp from '@mui/icons-material/InsightsSharp';
import StarsSharp from '@mui/icons-material/StarsSharp';
import VisibilitySharp from '@mui/icons-material/VisibilitySharp';
import SearchSharp from '@mui/icons-material/SearchSharp';
import { ODAvatar, ODTooltip } from '@OptimalDynamics/core-ai-common-ui';
import { TabData } from '../../common/ODTabs';
import { RootState } from '../../store/reducers';
import ODLoaderLabeled from './components/ODLoader';
import { ASSIGNED, FORECAST, OPTIMAL, PLANNED, SOURCE_REQUESTS } from './helpers/constants';

interface ChartCardProps {
  title: string;
  onClickInfo: () => void;
  footer?: ReactElement;
  children?: ReactElement;
}

export const ChartCard = ({ title, onClickInfo, footer, children }: ChartCardProps) => {
  const isLoading = useSelector((state: RootState) => state.dispatchingReducer?.showKpiLoad);

  return (
    <Box
      sx={{
        height: '280px',
        minWidth: '340px',
        border: '1px solid',
        borderColor: 'level3',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column-reverse'
      }}
    >
      {footer && (
        <Box sx={{ height: '40px', px: 2, borderTop: '1px solid', borderColor: 'inherit', svg: { height: '16px', width: '16px' } }}>
          {footer}
        </Box>
      )}
      <Box sx={{ px: 2, pb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1 }}>
          <Typography><strong>{title}</strong></Typography>
          <ODAvatar variant="transparent" size="small" onClick={onClickInfo}>
            <InfoOutlined />
          </ODAvatar>
        </Box>
        {isLoading ? (
          <Box sx={{ height: '176px', display: 'flex', alignItems: 'center', justifyContent: 'center', pb: !!footer ? 0 : '40px' }}>
            <ODLoaderLabeled loadingText="Updating..." />
          </Box>
        ) : children}
      </Box>
    </Box>
  );
};

interface LegendProps {
  datasets: string[];
}

export const AvailabilityLegend = ({ datasets }: LegendProps) => {
  const engineRunId = useSelector((state: RootState) => state.dispatchingReducer?.engineRunId);
  const { fixDriverAvailabilitySummary, showSourceSuggestions } = useFlags();

  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: '12px',
        height: '100%',
        alignItems: 'center',
        '> div': {
          display: 'flex',
          columnGap: '6px',
          alignItems: 'center',
          '> p': { fontSize: '12px', color: 'text.secondary' }
        }
      }}
    >
      {datasets.map((setName, index) => {
        switch (setName) {
          case PLANNED:
          case ASSIGNED:
            return (
              <Box key={`availability-legend-${setName}-${engineRunId}-${index}`}>
                <CheckCircleSharp sx={{ color: 'success.main' }} />
                <Typography>{startCase(setName)}</Typography>
              </Box>
            );
          case OPTIMAL:
            return (
              <Box key={`availability-legend-${setName}-${engineRunId}-${index}`}>
                <StarsSharp sx={{ color: 'optimalblue.main' }} />
                <Typography>{fixDriverAvailabilitySummary && showSourceSuggestions ? 'Optimal' : 'Optimal Matches'}</Typography>
              </Box>
            );
          case SOURCE_REQUESTS:
            return (
              <Box key={`availability-legend-${setName}-${engineRunId}-${index}`}>
                <SearchSharp sx={{ color: 'colors.neutral6' }} />
                <Typography>Source</Typography>
              </Box>
            );
          default:
            return (
              <Box key={`availability-legend-${setName}-${engineRunId}-${index}`}>
                <VisibilitySharp sx={{ color: 'colors.neutral4' }} />
                <Typography>{fixDriverAvailabilitySummary && showSourceSuggestions ? 'Review' : 'Needs Review'}</Typography>
              </Box>
            );
        }
      })}
    </Box>
  );
};

interface MetricData extends TabData {
  bgColor?: string;
  fgColor?: string;
}

interface MetricBoxProps extends MetricData {
  sx?: SxProps;
}

const MetricBox = ({ value, label, icon, bgColor, fgColor, sx }: MetricBoxProps) => (
  <Box
    sx={{
      display: 'grid',
      height: '85px',
      border: '1px solid',
      borderColor: bgColor ?? 'level3',
      borderRadius: '4px',
      backgroundColor: bgColor ?? 'colors.white',
      color: fgColor ?? 'text.primary',
      ...sx
    }}
  >
    <Box
      className="iconic"
      sx={{ gridColumn: 1, gridRow: 1, p: 2, alignSelf: 'start', justifySelf: 'end', color: fgColor ?? 'colors.neutral4' }}
    >
      {icon}
    </Box>
    <Box className="textbox" sx={{ gridColumn: 1, gridRow: 1, pl: 2, display: 'inline-flex' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
        <Typography sx={{ fontSize: '24px' }}><strong>{value}</strong></Typography>
        <Typography>{label}</Typography>
      </Box>
    </Box>
  </Box>
);

interface PositiveMetricsProps {
  title: string;
  onClickInfo: () => void;
  value: string;
  plusOptimal: string;
  forecast: string;
  highlightOptimal: boolean;
  dispatchMethod: string;
}

interface StackedMetricsCardProps {
  topMetrics: MetricBoxProps;
  bottomMetrics: MetricBoxProps;
}

export const StackedMetricsCard = ({ topMetrics, bottomMetrics }: StackedMetricsCardProps) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '2px' }}>
    <MetricBox {...topMetrics} />
    <MetricBox {...bottomMetrics} />
  </Box>
);

interface TripartiteMetricsCardProps {
  leftMetrics: MetricBoxProps;
  rightMetrics: MetricBoxProps;
  bottomMetrics: MetricBoxProps;
  highlightRight: boolean;
}

export const TripartiteMetricsCard = ({ leftMetrics, rightMetrics, bottomMetrics, highlightRight }: TripartiteMetricsCardProps) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '2px' }}>
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '2px' }}>
      <MetricBox
        {...leftMetrics}
        sx={!highlightRight
          ? { gridColumn: 1, gridRow: 1, order: 2 }
          : {
            gridColumn: '1 / span 2',
            gridRow: 1,
            '.iconic': { transform: 'translate(-151px)' }
          }}
      />
      <MetricBox
        {...rightMetrics}
        sx={highlightRight
          ? { gridColumn: 2, gridRow: 1 }
          : {
            gridColumn: '1 / span 2',
            gridRow: 1,
            '.textbox': { width: '50%', transform: 'translate(151px)', overflowX: 'hidden' }
          }}
      />
    </Box>
    <MetricBox
      {...bottomMetrics}
      sx={{ gridColumn: 'span 2' }}
    />
  </Box>
);

export const PositiveMetricsCard = (
  { title, onClickInfo, value, plusOptimal, forecast, highlightOptimal, dispatchMethod }: PositiveMetricsProps
) => {
  const { dispatchingMetricVariations } = useFlags();
  const theme = useTheme();

  const relevantMetrics = dispatchingMetricVariations[dispatchMethod];
  if (!relevantMetrics || relevantMetrics.length === 0) return <></>; // Sanity Check

  const showAssigned = relevantMetrics[0] === ASSIGNED;

  const allMetrics = {
    [ASSIGNED]: {
      value,
      label: 'Assigned',
      icon: <CheckCircleSharp />,
      ...(!highlightOptimal && { bgColor: 'success.main', fgColor: 'colors.white' })
    },
    [OPTIMAL]: {
      value: plusOptimal,
      label: showAssigned ? 'Assigned + Optimal' : 'Optimal Matches',
      icon: <StarsSharp sx={{ color: 'optimalblue.main' }} />,
      ...(highlightOptimal && { bgColor: 'optimalblue.main', fgColor: 'colors.white' })
    },
    [FORECAST]: {
      value: forecast,
      label: 'Rolling 7 Day Forecast',
      icon: <InsightsSharp sx={{ color: 'optimalblue.main' }} />
    }
  };

  // @ts-expect-error Retrieve only the values that'll actually be used
  const metricsSets = relevantMetrics?.map((m) => allMetrics[m]);

  if (metricsSets.length === 1) {
    const isForecast = relevantMetrics[0] === FORECAST;
    return (
      <ChartCard
        title={title}
        onClickInfo={onClickInfo}
      >
        <Box
          sx={{
            height: isForecast ? '200px' : '192px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            pb: isForecast ? 2 : 3
          }}
        >
          <Typography sx={{ fontSize: '64px', whiteSpace: 'wrap', lineHeight: '70px', textAlign: 'center' }}>
            {metricsSets[0].value}
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', columnGap: '8px', svg: { color: showAssigned ? 'success.main' : 'optimalblue.main' } }}>
            {metricsSets[0].icon}
            {metricsSets[0].label}
          </Box>
        </Box>
      </ChartCard>
    );
  }

  if (metricsSets.length === 2) {
    let backgroundImage = '';
    let colors = ['optimalblue.main', 'optimalblue.main'];
    if (showAssigned) {
      if (highlightOptimal) {
        backgroundImage = `linear-gradient(to right, ${theme.palette.level4}, 45%, ${theme.palette.optimalblue.main} 55% 100%)`;
        colors[0] = 'level4';
      } else {
        backgroundImage = `linear-gradient(to right, ${theme.palette.success.main} 0 35%, 45%, ${theme.palette.level4})`;
        colors = ['success.main', 'level4'];
      }
    } else {
      backgroundImage = `repeating-linear-gradient(to right, transparent 0 2px, ${theme.palette.colors.white} 2px 4px), linear-gradient(to right, transparent, ${theme.palette.optimalblue.main})`;
    }

    return (
      <ChartCard
        title={title}
        onClickInfo={onClickInfo}
        footer={(
          <Box
            sx={{
              height: '100%',
              display: 'grid',
              alignItems: 'center',
              columnGap: '4px',
              gridTemplateColumns: '18px auto 18px',
              '> :first-child': { color: colors[0] },
              '> :last-child': { color: colors[1] },
            }}
          >
            <ODTooltip title={metricsSets[0].label}>
              {metricsSets[0].icon}
            </ODTooltip>
            <Box
              sx={{
                height: '1px',
                backgroundImage
              }}
            />
            <ODTooltip title={metricsSets[1].label}>
              {metricsSets[1].icon}
            </ODTooltip>
          </Box>
        )}
      >
        <StackedMetricsCard topMetrics={metricsSets[0]} bottomMetrics={metricsSets[1]} />
      </ChartCard>
    );
  }

  return (
    <ChartCard
      title={title}
      onClickInfo={onClickInfo}
      footer={(
        <Box
          sx={{
            height: '100%',
            display: 'grid',
            alignItems: 'center',
            columnGap: '4px',
            gridTemplateColumns: '18px 2fr 18px 3fr 18px',
            '> :nth-child(n+3)': { color: highlightOptimal ? 'optimalblue.main' : 'colors.neutral4' },
            '> :nth-child(-n+2)': { color: highlightOptimal ? 'colors.neutral4' : 'success.main' },
            '> div': { height: 0, borderWidth: 0, borderTopWidth: '1px' }
          }}
        >
          <ODTooltip title="Assigned">
            <CheckCircleSharp />
          </ODTooltip>
          <Box sx={{ borderStyle: 'solid' }} />
          <ODTooltip title="Assigned + Optimal">
            <StarsSharp sx={{ color: 'optimalblue.main' }} />
          </ODTooltip>
          <Box sx={{ borderStyle: 'dashed' }} />
          <ODTooltip title="Rolling 7 Day Forecast">
            <InsightsSharp />
          </ODTooltip>
        </Box>
      )}
    >
      <TripartiteMetricsCard
        leftMetrics={metricsSets[0]}
        rightMetrics={metricsSets[1]}
        bottomMetrics={metricsSets[2]}
        highlightRight={highlightOptimal}
      />
    </ChartCard>
  );
};
