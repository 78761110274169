/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */

import React, { createContext, useReducer, useMemo } from 'react';
import { createReducer, createAction } from '@reduxjs/toolkit';

export const initialState = {
  offset: 0,
  limit: 100,
  load_status: null,
  customer_status: null,
  ordering: '-load_id',
};

const updateMainFilter = createAction('routeLAFilter/updateMainFilter');
const removeFilterByName = createAction('routeLAFilter/removeFilterByName');
const assignFilterByName = createAction('routeLAFilter/assignFilterByName');
const resetFilter = createAction('routeLAFilter/resetFilter');

const routeLAFilterReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateMainFilter, (state, action) => {
      const { payload } = action;
      Object.entries(payload)
        .forEach(([key, value]) => {
          state[key] = value;
        });
    })
    .addCase(removeFilterByName, (state, action) => {
      const { payload: filterName } = action;
      delete state[filterName];
    })
    .addCase(assignFilterByName, (state, action) => {
      const { payload } = action;
      state[payload[0]] = payload[1];
    })
    .addCase(resetFilter, (state) => {
      state = {};
      Object.entries(initialState)
        .forEach(([key, value]) => {
          state[key] = value;
        });
    });
});

export const FilterLAContext = createContext({
  routeLAFilter: { ...initialState },
  selectLAFilter: {},
  setRouteLAFilter: () => {},
});

export const FilterLAContextWrapper = ({ children }) => {
  const [state, dispatch] = useReducer(routeLAFilterReducer, initialState);

  const removalMap = new Map();
  ['__default_filter__'] // LIST OF PROPERTIES TO REMOVE FROM STATE TO PREP FOR API CALLS
    .reduce((acc, next) => {
      acc[next] = next;
      return acc;
    }, removalMap);

  const selectFrom = (cb) => cb(state);
  const getDefaultState = () => selectFrom((myState) => myState.__default_filter__);
  const getPreppedState = () => selectFrom((myState) => 
    Object.entries(myState)
      .reduce((trimDict, [key, val]) => {
        if (!removalMap.has(key)) {
          trimDict[key] = val;
        }
        return trimDict;
      }, {}));

  const selectLAFilter = {
    getDefaultState: () => getDefaultState(),
    getPreppedState: () => getPreppedState(),
  };

  const setRouteLAFilter = () => ({ 
    updateMainFilter: (payload) => dispatch(updateMainFilter(payload)),
    removeFilterByName: (payload) => dispatch(removeFilterByName(payload)),
    assignFilterByName: (payload) => dispatch(assignFilterByName(payload)),
    resetFilter: () => dispatch(resetFilter()),
    // getDefaultState: () => getDefaultState(),
  });
   
  const memoValue = useMemo(() => ({ routeLAFilter: state, setRouteLAFilter, selectLAFilter }), [state]);

  return (
    <FilterLAContext.Provider
      value={memoValue}
    >
      { children }
    </FilterLAContext.Provider>
  );
};
