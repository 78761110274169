import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import { Box, Link, Typography } from '@mui/material';
import OpenInNewSharp from '@mui/icons-material/OpenInNewSharp';
import ViewListSharp from '@mui/icons-material/ViewListSharp';
import MailOutlineSharp from '@mui/icons-material/MailOutlineSharp';
import ArrowForward from '@mui/icons-material/ArrowForward';
import PeopleSharp from '@mui/icons-material/PeopleSharp';
import PersonSharp from '@mui/icons-material/PersonSharp';
import SearchSharp from '@mui/icons-material/SearchSharp';
import ConnectWithoutContactSharp from '@mui/icons-material/ConnectWithoutContactSharp';
import BadgeSharp from '@mui/icons-material/BadgeSharp';
import {
  ODAvatar,
  ODDialog,
  ODButton,
  ODRadio,
  ODTable,
  ODTableBody,
  ODTableCell,
  ODTableContainer,
  ODTableHead,
  ODTableRow,
  ODTooltip
} from '@OptimalDynamics/core-ai-common-ui';
import { useDispatch, useSelector } from 'react-redux';
import { CopyClick } from '../../../common/CopyClick';
import { getLocalizedIsoTimeRange, SHORT_DATE_TIME_TZ } from '../../../utils/datetimes';
import instance from '../../../utils/axios_instance';
import { SearchContext } from '../../../utils/context/searchContext';
import { formatWithUnit } from '../../../utils/math';
import ColumnHeaderSettings from '../../../common/ColumnHeaderSettings';
import { DispatchingDriver, DispatchingHeader } from '../types';
import { DRIVER, LOAD, LOAD_ID, initialSourceColumns, PICKUP_RADIUS, DROPOFF_PREFIX, DROPOFF_RADIUS, WEIGHT, LENGTH, RATE_PER_MILES, DEADHEAD, AGE_IN_MINUTES, SOURCE_ACTION, SOURCE_DEETS, PICKUP_PREFIX, } from '../helpers/constants';
import { RootState } from '../../../store/reducers';
import { UpdateUserSettings } from '../../../store/actions';
import { SessionContext } from '../../../utils/context/sessionCtx';
import { DriverIdentifier, dynamicSourceBrokerageDescription } from '../helpers/dynamicSourceBrokerageDescription';
import { ConfirmSearchAssignment } from '../shared/ConfirmSearchAssignment';
import PermitsAndRequirementsDialog from '../shared/PermitsAndRequirementsDialog';
import { dynamicDescription } from '../helpers/dynamicDescription';
import EmailViewer from '../shared/EmailViewer';

const divmod = (dividend: number, divisor: number) => [Math.floor(dividend / divisor), dividend % divisor];

interface ExternalSearchTableColumn {
  label: string;
  value: string;
  parent: string;
  visible: boolean;
  selectionDisabled: boolean;
  sortable: boolean;
  width: number;
  useCellTooltip: boolean;
}

interface ExternalLoad {
  accessorials: [];
  book_link: string;
  age_in_minutes: number;
  commodity: string;
  destination_to_dropoff_miles: number;
  dropoff_end: string;
  dropoff_location: string;
  dropoff_start: string;
  dropoff_timezone: string;
  dropoff_zip: string;
  email_uri?: string;
  empty_miles?: number;
  empty_miles_2: number;
  equipment_lengths: number[];
  equipment_types: string[];
  is_email: boolean;
  last_location_to_pickup_miles: string;
  last_seen_in_minutes: number;
  load_id: string;
  loaded_miles: number;
  origin_to_pickup_miles: number;
  pickup_end: string;
  pickup_location: string;
  pickup_start: string;
  pickup_timezone: string;
  pickup_zip: string;
  pta_to_pickup_miles: number;
  rate_per_miles: number | null;
  shipper_name: string;
  source: string;
  stops: number;
  total_dropoff: number;
  total_other_types: number;
  total_pickup: number;
  total_stops: number;
  weight: number;
}

interface SearchParams {
  pickUpCity: string;
  pickUpRadius?: number;
  pickUpStart: Moment;
  pickUpEnd: Moment;
  dropOffCity?: string;
  dropOffRadius?: number;
  dropOffStart?: Moment;
  dropOffEnd?: Moment;
  equipmentTypes: string[];
  weight: number;
  ageInDays: number;
  minRatePerMile: number;
}

interface Search {
  driverPk: number;
  driverId: string;
  engineRunId: number;
  keyword?: string;
  movementId: number;
  params: SearchParams;
  submoveId: number;
}

interface PermitsDialogInfo {
  equipment?: string;
  req_permits?: string;
}

const formatData = (load: ExternalLoad, field: string, setEmailUrl: Dispatch<SetStateAction<string | null | undefined>>, emailInSourceLoads: boolean) => {
  // @ts-expect-error TS objects to using a string as an index key
  const value = load[field];

  // If the source is an email, the book_link is expected to be null
  if ((value === null || value === '') && ![SOURCE_ACTION].includes(field)) return '-';

  switch (field) {
    case PICKUP_PREFIX:
    case DROPOFF_PREFIX:
      // @ts-expect-error TS objects to using a string as an index key
      const { [`${field}_start`]: start, [`${field}_end`]: end, [`${field}_timezone`]: timezone } = load;
      if (!!start) {
        if (!end) return moment(start).tz(timezone)?.format(SHORT_DATE_TIME_TZ);
        return getLocalizedIsoTimeRange(start, end, timezone);
      } return '-';
    case SOURCE_ACTION:
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {load.is_email ? (
            <>
              <MailOutlineSharp sx={{ color: emailInSourceLoads ? 'colors.semanticBlue' : 'colors.black' }} />
              {emailInSourceLoads ? (
                <Link
                  component="button"
                  onClick={() => setEmailUrl(load.email_uri)}
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  Email Details
                </Link>
              ) : (
                <Typography>Email</Typography>
              )}
            </>
          ) : (
            <>
              <OpenInNewSharp sx={{ color: 'colors.semanticBlue' }} />
              <Link
                className="source-link"
                href={value}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
                sx={{ color: 'colors.semanticBlue', textDecoration: 'underline' }}
              >
                Source Link
              </Link>
            </>
          )}
        </Box>
      );
    case LOAD_ID: 
      return (
        <CopyClick copyText={value}>
          <Typography maxWidth={100} whiteSpace="nowrap" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {value}
          </Typography>
        </CopyClick>
      );
    case LENGTH: 
      return value.length ? formatWithUnit(value, 'ft') : '-';
    case WEIGHT: 
      return formatWithUnit(value, 'lbs');
    case DROPOFF_RADIUS:
    case PICKUP_RADIUS:
    case DEADHEAD:
      return formatWithUnit(Math.round(value), 'mi');
    case RATE_PER_MILES:
      return Intl.NumberFormat('en', { style: 'currency', currency: 'USD' })?.format(value);
    case AGE_IN_MINUTES:
      const [hours, minutes] = divmod(value, 60);
      const datum = `${minutes}m`;
      if (!hours) return datum;
      if (hours < 24) {
        return `${hours}h${datum}`;
      } 
      return `${Math.floor(hours / 24)}d`;
      
    default:
      if (Array.isArray(value) && !value.length) return '-';
      if (Array.isArray(value) && value.length > 0) return value.join(', ');
      return value;
  }
};

const renderDataCell = (
  load: ExternalLoad,
  field: string,
  width: number,
  useCellTooltip: boolean,
  columns: ExternalSearchTableColumn[],
  index: number,
  setSelectedRow: Dispatch<SetStateAction<ExternalLoad | null>>,
  selectedRow: ExternalLoad | null,
  setEmailUrl: Dispatch<SetStateAction<string | null | undefined>>,
  emailInSourceLoads: boolean
) => {
  const datum = formatData(load, field, setEmailUrl, emailInSourceLoads);
  const right = columns.filter((col) => col.visible).slice(index + 1).reduce((prev, curr) => prev + curr.width, 0);
  const firstVisibleColumn = columns.find((col) => col.visible);
  return (
    <ODTableCell
      sx={{
        width: `${width}px`,
        maxWidth: `${width}px`,
        minWidth: `${width}px`,
        '&:nth-last-child(-n+4)': {
          position: 'sticky',
          pr: 0,
          right: `${right}px !important`,
          zIndex: 3,
          backgroundColor: 'inherit',
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {(field === firstVisibleColumn?.value)
          && <ODRadio onClick={() => setSelectedRow(selectedRow?.load_id === load.load_id ? null : load)} checked={selectedRow?.load_id === load.load_id} sx={{ mr: 1 }} />}
        {(useCellTooltip && datum !== '-') ? (
          <ODTooltip title={datum}>
            <div>{datum}</div>
          </ODTooltip>
        ) : datum}
      </Box>
    </ODTableCell>
  );
};

const LoadsSearchExternal = ({ lastLocationZip = '', currentRow, handleModalClose }: { lastLocationZip?: string, currentRow: DispatchingDriver, handleModalClose: () => void }) => {
  const { rowsPerPage, search, setLoading, setHasResults } = useContext(SearchContext);
  const { sessionUid } = useContext(SessionContext);
  const { noExternalLoadsLimit, emailInSourceLoads } = useFlags();
  const [results, setResults] = useState<ExternalLoad[]>([]);
  const [columns, setColumns] = useState(cloneDeep(initialSourceColumns));
  const [emailUrl, setEmailUrl] = useState<string | null | undefined>(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<ExternalLoad | null>(null);
  const [confirmAssignmentOpen, setConfirmAssignmentOpen] = useState(false);
  const [permitsDialogData, setPermitsDialogData] = useState<PermitsDialogInfo | null>(null);
  const userSettings = useSelector((state: RootState) => state.userSettings);
  const dispatch = useDispatch();
  const [_searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    if (userSettings?.loadsSearchExternalColumns) {
      setColumns(userSettings.loadsSearchExternalColumns);
    }
  }, [userSettings?.loadsSearchExternalColumns]);

  useEffect(() => {
    const searchTyped = search as Search;
    const params: SearchParams = searchTyped.params;
    if (!params) return;
    const { pickUpStart, pickUpEnd, dropOffStart, dropOffEnd } = params;

    const request = {
      driver_id: searchTyped?.driverPk,
      engine_run_id: searchTyped?.engineRunId,
      origin: params?.pickUpCity,
      origin_radius: params?.pickUpRadius,
      destination_radius: params?.dropOffRadius,
      destination: params?.dropOffCity,
      movement_id: searchTyped?.movementId,
      submove_id: searchTyped?.submoveId,
      pickup: pickUpStart?.millisecond(0)?.second(0), // query will not work if it has (m)sec
      dropoff: dropOffStart?.millisecond(0)?.second(0), // query will not work if it has (m)sec
      ...(!noExternalLoadsLimit && { limit: rowsPerPage }),
      pickup_duration: undefined as unknown as number,
      dropoff_duration: undefined as unknown as number,
      search_session_uid: sessionUid,
      ...(!!lastLocationZip && { last_location_zip: lastLocationZip }),
      ...(params?.equipmentTypes?.length > 0 && { equipment_types: params.equipmentTypes }), 
      ...(params?.minRatePerMile !== null && { min_rate_per_mile: params.minRatePerMile }),
      ...(params?.weight !== null && { max_weight_lbs: params.weight }),
      ...(params?.ageInDays !== null && { first_discovered_minutes: params.ageInDays * 1440 }),
    };

    // Duration needs to be in *minutes* so we're getting the difference in minutes
    if (!!pickUpStart && !!pickUpEnd) request['pickup_duration'] = pickUpEnd.diff(pickUpStart, 'minutes');
    if (!!dropOffStart && !!dropOffEnd) request['dropoff_duration'] = dropOffEnd.diff(dropOffStart, 'minutes');

    setLoading(true);
    instance
      .post('/loads/external/', request)
      .then((res) => {
        const response = res.data.loads;
        setResults(response);
        setHasResults(response?.length > 0);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [search]);

  const updateHeaders = (header: DispatchingHeader) => {
    const colsUpdated = columns.map((item) => {
      if (item.label === header.label) {
        item.visible = !item.visible;
      }
      return item; 
    });
    setColumns(colsUpdated);
    dispatch(UpdateUserSettings({ ...userSettings, loadsSearchExternalColumns: colsUpdated }));
  };

  const visibleColumns = columns.filter((header) => header.visible);

  const srcColStartIdx = visibleColumns.findIndex((header) => header.parent === SOURCE_DEETS);
  const loadDetailsColumns = visibleColumns.slice(0, srcColStartIdx);
  const sourceDetailsColumns = visibleColumns.slice(srcColStartIdx);

  const getAssignmentBarContents = () => (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', fontSize: '14px' }}>
      <Box
        sx={{
          width: 'auto',
          display: 'flex',
          alignItems: 'center',
          columnGap: '8px',
        }}
      >
        {currentRow?.driver?.is_sleeper ? <PeopleSharp /> : <PersonSharp />}
        <DriverIdentifier driver={currentRow?.driver} />
        <ArrowForward sx={{ mx: 1 }} />
        <SearchSharp />
        <Typography noWrap maxWidth={112}>{selectedRow?.load_id}</Typography>
      </Box>
      <Box sx={{ display: 'flex', columnGap: 2 }}>
        <ODButton
          id="view-equipment-btn"
          startIcon={<BadgeSharp />}
          onClick={() => setPermitsDialogData({ equipment: selectedRow?.equipment_types.join(', ') })}
        >
          View Equipment Check
        </ODButton>
        {selectedRow?.is_email ? (
          <ODButton
            id="view-email-details-btn"
            variant="blue"
            startIcon={<MailOutlineSharp />}
            onClick={() => setEmailUrl(selectedRow?.email_uri)}
          >
            View Email Details
          </ODButton>
        ) : (
          <>
            <ODButton
              id="external-source-btn"
              variant="blue"
              startIcon={<OpenInNewSharp />}
              onClick={() => window.open(selectedRow?.book_link, '_blank')!.focus()}
            >
              External Source Link
            </ODButton>
            <ODButton
              id="search-send-request-btn"
              variant="blue"
              startIcon={<ConnectWithoutContactSharp />}
              onClick={() => setConfirmAssignmentOpen(true)}
            >
              Send Source Request
            </ODButton>
          </>
        )}
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: 'max-content',
      }}
    >
      {!!selectedRow && (
        <Box
          sx={{
            backgroundColor: 'level1',
            border: '1px solid',
            borderColor: 'level3',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            padding: '8px 16px',
            height: '40px',
            mb: 2
          }}
        >
          {getAssignmentBarContents()}
        </Box>
      )}
      <ODTableContainer
        sx={{
          height: results?.length === 0 ? 'max-content' : selectedRow ? 'calc(100vh - 194px)' : 'calc(100vh - 121px)',
          '& .MuiTableCell-root > *': {
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          },
          '& .MuiTableRow-root > .MuiTableCell-root:nth-last-of-type(4):not([colspan])': {
            borderLeft: 1,
            borderLeftColor: 'level3',
            px: 2
          },
          '& .MuiTableRow-root > .MuiTableCell-root:last-of-type:not([colspan])': {
            borderLeft: 'none'
          },
          '& .MuiTableRow-root > :nth-last-of-type(2)': {
            pr: 'inherit'
          },
        }}
      >
        <ODTable stickyHeader>
          <ODTableHead>
            <ODTableRow>
              <ODTableCell colSpan={loadDetailsColumns.length} sx={{ position: 'sticky', left: 0 }}>Load Details</ODTableCell>
              <ODTableCell
                colSpan={sourceDetailsColumns.length}
                sx={{ pl: 2, borderLeft: 1, borderLeftColor: 'level2', position: 'sticky', right: 0, zIndex: 4 }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: '-12px' }}>
                  Source Details
                  <ODAvatar size="small" variant="transparent" onClick={() => setSettingsOpen(true)} sx={{ position: 'sticky', right: 0 }}>
                    <ViewListSharp />
                  </ODAvatar>
                </Box>
              </ODTableCell>
            </ODTableRow>
            <ODTableRow>
              {visibleColumns.map((col, index) => { 
                const right = visibleColumns.slice(index + 1).reduce((prev, curr) => prev + curr.width, 0);
                return (
                  <ODTableCell sx={{
                    width: `${col.width}px`,
                    maxWidth: `${col.width}px`,
                    minWidth: `${col.width}px`,
                    overflow: 'hidden',
                    '&:nth-last-child(-n+4)': {
                      position: 'sticky',
                      pr: 0,
                      right: `${right}px !important`,
                      zIndex: 4,
                      backgroundColor: 'inherit'
                    },
                  }}
                  >
                    {col.tooltip ? (
                      <ODTooltip title={col.tooltip}>
                        <span>{col.label}</span>
                      </ODTooltip>
                    ) : col.label}
                  </ODTableCell>
                );
              })}
            </ODTableRow>
          </ODTableHead>
          <ODTableBody>
            {results.map((load) => (
              <ODTableRow selected={selectedRow?.load_id === load?.load_id} onClick={() => setSelectedRow(selectedRow?.load_id === load.load_id ? null : load)}>
                {visibleColumns.map(
                  ({ value, width, useCellTooltip }, index) => renderDataCell(load, value, width, useCellTooltip, columns, index, setSelectedRow, selectedRow, setEmailUrl, emailInSourceLoads)
                )}
              </ODTableRow>
            ))}
          </ODTableBody>
        </ODTable>
      </ODTableContainer>
      <ColumnHeaderSettings
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        // @ts-expect-error it's complaining that a child class has insufficient overlap with the parent
        onToggle={(col) => updateHeaders(col)}
        columns={columns}
        onReset={() => setColumns(cloneDeep(initialSourceColumns))}
        sx={{ '&.MuiDrawer-root': { zIndex: 1400 } }}
      />
      {confirmAssignmentOpen && (
        <ConfirmSearchAssignment
          subject={LOAD}
          assignment={dynamicSourceBrokerageDescription([currentRow], selectedRow, DRIVER)}
          isOptimal={false}
          hasOptimal={false}
          isBrokerage={false}
          hasSource={true}
          isDriverPendingSourceRequest={false}
          onCancel={() => setConfirmAssignmentOpen(false)}
          onConfirm={() => {
            if (selectedRow) {
              const { pta_to_pickup_miles, loaded_miles, pickup_location, dropoff_location, rate_per_miles, pickup_timezone, dropoff_timezone, pickup_start, dropoff_start, pickup_zip, dropoff_zip } = selectedRow;

              const sanitizeTimestamp = (timestamp: string) => timestamp.split('.')[0];

              const source_search = {
                dropoff: dropoff_start ? sanitizeTimestamp(dropoff_start.replace('T', ' ').replace('Z', '')) : null,
                dropoff_city: dropoff_location.split(', ')[0],
                dropoff_city_state: dropoff_location,
                dropoff_state: dropoff_location.split(', ')[1],
                dropoff_tz: dropoff_timezone,
                dropff_zipcode: dropoff_zip,
                movement_type: 'custom_request_load',
                pickup: pickup_start ? sanitizeTimestamp(pickup_start.replace('T', ' ').replace('Z', '')) : null,
                pickup_city: pickup_location.split(', ')[0],
                pickup_city_state: pickup_location,
                pickup_state: pickup_location.split(', ')[1],
                pickup_tz: pickup_timezone,
                pickup_zipcode: pickup_zip,
                pta_to_pickup_miles,
                revenue: null,
                simulated_rate_per_mile: rate_per_miles,
                source_loaded_miles: loaded_miles,
                avail_for_dispatch_et: currentRow.avail_for_dispatch_et,
                avail_timezone: currentRow.avail_timezone,
                predispatch_final_loc: currentRow.avail_city
              };
              instance.post(`/dispatching/drivers/${currentRow?.driver.driver_id}/source-request/`, { source_search })
                .then(() => {
                  setSelectedRow(null);
                  handleModalClose();
                  setSearchParams({ view_type: 'needs-sourcing' });
                })
                .catch((err) => console.error(err));
            }
          }}
        />
      )}
      <PermitsAndRequirementsDialog
        open={!!permitsDialogData}
        onClose={() => setPermitsDialogData(null)}
        permitRequired={permitsDialogData?.req_permits}
        permitDriver={currentRow?.driver?.permits}
        equipment={permitsDialogData?.equipment}
        description={() => dynamicDescription(currentRow?.driver, selectedRow)}
      />
      <ODDialog fullScreen open={!!emailUrl} onClose={() => setEmailUrl(null)} title="Email Details">
        <EmailViewer emailUrl={emailUrl} />
      </ODDialog>
    </Box>
  );
};

export default LoadsSearchExternal;
