import React from 'react';
import { sum } from 'lodash';
import { Box, Typography, useTheme } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  defaults
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ACCEPTABLE_MATCHES, ASSIGNED, HOME_TIMES, NO_MATCHES, OPTIMAL, PLANNED, RELAXED_MATCHES, OTHER, SOURCE_REQUESTS } from './helpers/constants';

defaults.font.family = 'IBM Plex Sans';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AvailabilityGraph = ({ data, showPercent = false, showTotal = false }) => {
  const { fixDriverAvailabilitySummary, showSourceSuggestions } = useFlags();
  const theme = useTheme();

  const SETS = {
    [PLANNED]: {
      label: 'Planned',
      backgroundColor: theme.palette.success.main,
      stack: 'od'
    },
    [ASSIGNED]: {
      label: 'Assigned',
      backgroundColor: theme.palette.success.main,
      stack: 'od'
    },
    [OPTIMAL]: {
      label: fixDriverAvailabilitySummary && showSourceSuggestions ? 'Optimal' : 'Optimal Matches',
      backgroundColor: theme.palette.optimalblue.main,
      stack: 'od'
    },
    [OTHER]: {
      label: fixDriverAvailabilitySummary && showSourceSuggestions ? 'Review' : 'Needs Review',
      backgroundColor: theme.palette.colors.neutral4,
      stack: 'od'
    },
    ...(fixDriverAvailabilitySummary && showSourceSuggestions ? {
      [SOURCE_REQUESTS]: {
        label: 'Source',
        backgroundColor: theme.palette.colors.neutral6,
        stack: 'od'
      }
    } : {}),
    [ACCEPTABLE_MATCHES]: {
      label: 'Good or Feasible Matches',
      backgroundColor: theme.palette.colors.neutral4
    },
    [RELAXED_MATCHES]: {
      label: 'Exception Matches',
      backgroundColor: theme.palette.colors.neutral4
    },
    [NO_MATCHES]: {
      label: 'No Available Matches',
      backgroundColor: theme.palette.colors.neutral4
    },
    [HOME_TIMES]: {
      label: 'With Home Times',
      backgroundColor: theme.palette.colors.neutral4
    }
  };

  if (!data) {
    return (
      <Box sx={{ height: '176px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography sx={{ color: 'text.secondary' }}>No Metric Data Available</Typography>
      </Box>
    );
  }

  const { hours, ...lists } = data;

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    barPercentage: 1.0,
    categoryPercentage: 0.98,
    scales: {
      x: {
        title: {
          display: false
        }
      },
      y: {
        stacked: true,
        title: {
          display: false
        },
        ticks: {
          callback: (value) => {
            if (value % 1 === 0) return value;
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        mode: 'index',
        backgroundColor: '#635874',
        cornerRadius: 4,
        usePointStyle: true,
        caretSize: 0,
        caretPadding: 10,
        titleMarginBottom: 8,
        footerMarginTop: 8,
        bodySpacing: 4,
        boxPadding: 4,
        intersect: false,
        callbacks: {
          title: (context) => {
            const label = context[0].label;
            return label.includes('hrs') ? label : `${context[0].label}hrs`;
          },
          label: (context) => {
            const viewType = context.dataset.label;
            const label = `${viewType}: ${context.formattedValue}`;
            if (!showPercent) return label;

            const values = context.chart.tooltip.dataPoints.map((v) => v.raw);
            const total = sum(values);
            if (total === 0) return '0 (0%)';
            const pct = new Intl.NumberFormat('en-US', {
              style: 'percent',
            }).format(context.raw / total);
            return `${label} (${pct})`;
          },
          footer: (context) => {
            if (!showTotal) return;
            const total = sum(context.map((v) => v.raw));
            return `Total: ${total}`;
          }
        }
      },
      datalabels: {
        display: false
      }
    }
  };

  const datasets = Object.entries(lists).map(([set, dataValues]) => ({ ...SETS[set], data: dataValues }));

  return (
    <Box sx={{ height: 176 }}>
      <Bar data={{ labels: hours, datasets }} options={barChartOptions} />
    </Box>
  );
};

export default AvailabilityGraph;
