import { useFlags } from 'launchdarkly-react-client-sdk';
import keyBy from 'lodash/keyBy';

export default function useColumnData(columns) {
  const flags = useFlags();
  const columnData = keyBy(columns, 'label');

  const checkColumn = (key) => {
    if (columnData[key] && columnData[key].visible && (columnData[key].flag ? flags[columnData[key]?.flag] : true)) {
      return true; 
    } 
    return false;
  };

  return { 
    columnData,
    checkColumn
  };
}
