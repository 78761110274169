import React, { ReactElement } from 'react';
import { Typography, Box } from '@mui/material';
import StarSharp from '@mui/icons-material/StarSharp';
import StarHalfSharp from '@mui/icons-material/StarHalfSharp';
import StarOutlineSharp from '@mui/icons-material/StarOutlineSharp';
import StarsSharp from '@mui/icons-material/StarsSharp';
import { ODDialog } from '@OptimalDynamics/core-ai-common-ui';

interface StarBlurbProps {
  icon: ReactElement;
  label: string;
  description: string;
}

const StarBlurb = ({ icon, label, description }: StarBlurbProps) => (
  <Box sx={{ display: 'grid', gridTemplateColumns: '50px auto', p: '8px 0px', borderBottom: '1px solid', borderBottomColor: 'colors.neutral1' }}>
    <Box sx={{ justifySelf: 'center' }}>
      {icon}
    </Box>
    <Typography>
      <strong>{`${label} Match: `}</strong>
      {description}
    </Typography>
  </Box>
);

const StarIconInfoDialog = ({ isDriver = false, open, onClose }: { isDriver: boolean, open: boolean, onClose: () => void }) => (
  <ODDialog
    title="Star Rating"
    open={open}
    onClose={onClose}
  >
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
      <Typography>Rating represents the quality of the load to driver match. It is calculated by utilizing revenue, driver location, HOS clock, home time, and any custom set parameters. Fuller Stars indicate a better match.</Typography>
      <Box>
        <Box
          sx={{
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'background.disabled',
            borderBottom: '1px solid',
            borderBottomColor: 'colors.neutral1',
            padding: '0px 16px'
          }}
        >
          <Typography><strong>Rating Guide</strong></Typography>
        </Box>
        <StarBlurb
          icon={<StarsSharp sx={{ color: 'optimalblue.main' }} />}
          label="Optimal"
          description={`Highest quality match for this ${!isDriver ? 'driver' : 'load'} and your network.`}
        />
        <StarBlurb
          icon={<StarSharp />}
          label="Great"
          description={`High quality match for this ${!isDriver ? 'driver' : 'load'} and your network.`}
        />
        <StarBlurb
          icon={<StarHalfSharp />}
          label="Good"
          description={`Has some value for this ${!isDriver ? 'driver' : 'load'} and your network, but not as much as the Optimal Match.`}
        />
        <StarBlurb
          icon={<StarOutlineSharp />}
          label="Feasible"
          description="This match meets minimum requirements, but has low value for your network."
        />
      </Box>
    </Box>
  </ODDialog>
);

export default StarIconInfoDialog;
