import React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { Box, Typography } from '@mui/material';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { ODAvatar } from '@OptimalDynamics/core-ai-common-ui';

const SelectedOptions = (props) => {
  const {
    sx = {},
    keyBase = '',
    value = [],
    removeOptionCB,
    OptionSVGIcon,
    noOptionIcon,
    itemKey = 'value',
    label,
    optionLabelFunc = (op) => op[itemKey],
  } = props;

  const count = value?.length;
  let [singular, plural] = ['', ''];
  if (!!label) {
    if (label instanceof Array) {
      [singular, plural] = label;
    } else {
      singular = label;
      plural = `${label}s`;
    }
  }

  const fmtKeyBase = !!keyBase ? `selected-${keyBase}-` : '';
  const keys = !count ? [] : [...Array(count)].map((_, idx) => `${fmtKeyBase}uuid-${nanoid()}--idx-${idx}`);

  if (!count || (!!noOptionIcon && !OptionSVGIcon)) return null;

  return (
    <Box sx={sx}>
      {!!label && (
        <Typography fontSize={12} sx={{ color: 'text.secondary' }}>
          {`${count} ${count === 1 ? singular : plural} Selected`}
        </Typography>
      )}
      <Box
        sx={{
          width: 'calc(100% - 18px)',
          maxHeight: '162px',
          padding: '8px',
          overflowY: 'auto',
          borderRadius: '4px',
          border: '1px solid',
          borderColor: 'level3',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '2px'
        }}
      >
        {value.map((item, idx) => (
          <Box
            key={keys[idx]}
            data-key={keys[idx]}
            sx={{
              display: 'flex',
              backgroundColor: 'colors.lightBlue1',
              height: '40px',
              borderRadius: '4px',
              alignItems: 'center',
              justifyContent: 'space-between',
              pl: 2,
              pr: 0.5,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, fontSize: '14px' }}>
              {!!OptionSVGIcon && <OptionSVGIcon />}
              {optionLabelFunc(item, itemKey)}
            </Box>
            <ODAvatar size="small" variant="lightBlue" onClick={() => removeOptionCB(item)}>
              <CloseSharpIcon />
            </ODAvatar>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SelectedOptions;
