export const CITY_STATES: Record<string, Record<'city_state' | 'city' | 'code', string>[]> = {
  Georgia: [
    {
      city_state: 'Cartersville, Georgia',
      city: 'Cartersville',
      code: 'GA',
    },
    {
      city_state: 'Albany, Georgia',
      city: 'Albany',
      code: 'GA',
    },
    {
      city_state: 'Fairburn, Georgia',
      city: 'Fairburn',
      code: 'GA',
    },
    {
      city_state: 'Austell, Georgia',
      city: 'Austell',
      code: 'GA',
    },
    {
      city_state: 'Albany, Georgia',
      city: 'Albany',
      code: 'GA',
    },
    {
      city_state: 'Jonesboro, Georgia',
      city: 'Jonesboro',
      code: 'GA',
    },
    {
      city_state: 'Norcross, Georgia',
      city: 'Norcross',
      code: 'GA',
    },
    {
      city_state: 'Forest Park, Georgia',
      city: 'Forest Park',
      code: 'GA',
    },
    {
      city_state: 'The Rock, Georgia',
      city: 'The Rock',
      code: 'GA',
    },
    {
      city_state: 'Rabun Gap, Georgia',
      city: 'Rabun Gap',
      code: 'GA',
    },
    {
      city_state: 'Forest Park, Georgia',
      city: 'Forest Park',
      code: 'GA',
    },
    {
      city_state: 'Forest Park, Georgia',
      city: 'Forest Park',
      code: 'GA',
    },
    {
      city_state: 'Lithia Springs, Georgia',
      city: 'Lithia Springs',
      code: 'GA',
    },
    {
      city_state: 'Norcross, Georgia',
      city: 'Norcross',
      code: 'GA',
    },
    {
      city_state: 'Augusta, Georgia',
      city: 'Augusta',
      code: 'GA',
    },
    {
      city_state: 'Atlanta, Georgia',
      city: 'Atlanta',
      code: 'GA',
    },
    {
      city_state: 'Cartersville, Georgia',
      city: 'Cartersville',
      code: 'GA',
    },
    {
      city_state: 'Lithia Springs, Georgia',
      city: 'Lithia Springs',
      code: 'GA',
    },
    {
      city_state: 'Dacula, Georgia',
      city: 'Dacula',
      code: 'GA',
    },
    {
      city_state: 'Fairburn, Georgia',
      city: 'Fairburn',
      code: 'GA',
    },
    {
      city_state: 'Lithia Springs, Georgia',
      city: 'Lithia Springs',
      code: 'GA',
    },
    {
      city_state: 'Cartersville, Georgia',
      city: 'Cartersville',
      code: 'GA',
    },
    {
      city_state: 'Douglas, Georgia',
      city: 'Douglas',
      code: 'GA',
    },
    {
      city_state: 'Lithia Springs, Georgia',
      city: 'Lithia Springs',
      code: 'GA',
    },
    {
      city_state: 'Gainesville, Georgia',
      city: 'Gainesville',
      code: 'GA',
    },
    {
      city_state: 'Gainesville, Georgia',
      city: 'Gainesville',
      code: 'GA',
    },
    {
      city_state: 'Gainesville, Georgia',
      city: 'Gainesville',
      code: 'GA',
    },
    {
      city_state: 'Albany, Georgia',
      city: 'Albany',
      code: 'GA',
    },
    {
      city_state: 'McDonough, Georgia',
      city: 'McDonough',
      code: 'GA',
    },
    {
      city_state: 'Smyrna, Georgia',
      city: 'Smyrna',
      code: 'GA',
    },
    {
      city_state: 'McDonough, Georgia',
      city: 'McDonough',
      code: 'GA',
    },
    {
      city_state: 'LaGrange, Georgia',
      city: 'LaGrange',
      code: 'GA',
    },
    {
      city_state: 'Albany, Georgia',
      city: 'Albany',
      code: 'GA',
    },
    {
      city_state: 'Decatur, Georgia',
      city: 'Decatur',
      code: 'GA',
    },
    {
      city_state: 'Jackson, Georgia',
      city: 'Jackson',
      code: 'GA',
    },
    {
      city_state: 'Port Wentworth, Georgia',
      city: 'Port Wentworth',
      code: 'GA',
    },
    {
      city_state: 'Lithia Springs, Georgia',
      city: 'Lithia Springs',
      code: 'GA',
    },
    {
      city_state: 'McDonough, Georgia',
      city: 'McDonough',
      code: 'GA',
    },
    {
      city_state: 'Albany, Georgia',
      city: 'Albany',
      code: 'GA',
    },
    {
      city_state: 'Duluth, Georgia',
      city: 'Duluth',
      code: 'GA',
    },
    {
      city_state: 'Franklin, Georgia',
      city: 'Franklin',
      code: 'GA',
    },
    {
      city_state: 'Albany, Georgia',
      city: 'Albany',
      code: 'GA',
    },
    {
      city_state: 'Tallapoosa, Georgia',
      city: 'Tallapoosa',
      code: 'GA',
    },
    {
      city_state: 'Norcross, Georgia',
      city: 'Norcross',
      code: 'GA',
    },
    {
      city_state: 'Port Wentworth, Georgia',
      city: 'Port Wentworth',
      code: 'GA',
    },
    {
      city_state: 'Port Wentworth, Georgia',
      city: 'Port Wentworth',
      code: 'GA',
    },
    {
      city_state: 'Albany, Georgia',
      city: 'Albany',
      code: 'GA',
    },
    {
      city_state: 'Shellman, Georgia',
      city: 'Shellman',
      code: 'GA',
    },
    {
      city_state: 'Braselton, Georgia',
      city: 'Braselton',
      code: 'GA',
    },
  ],
  Mississippi: [
    {
      city_state: 'Leland, Mississippi',
      city: 'Leland',
      code: 'MS',
    },
    {
      city_state: 'Hattiesburg, Mississippi',
      city: 'Hattiesburg',
      code: 'MS',
    },
    {
      city_state: 'Brookhaven, Mississippi',
      city: 'Brookhaven',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Forest, Mississippi',
      city: 'Forest',
      code: 'MS',
    },
    {
      city_state: 'Hattiesburg, Mississippi',
      city: 'Hattiesburg',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Olive Branch, Mississippi',
      city: 'Olive Branch',
      code: 'MS',
    },
    {
      city_state: 'Clinton, Mississippi',
      city: 'Clinton',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Collins, Mississippi',
      city: 'Collins',
      code: 'MS',
    },
    {
      city_state: 'Flowood, Mississippi',
      city: 'Flowood',
      code: 'MS',
    },
    {
      city_state: 'Olive Branch, Mississippi',
      city: 'Olive Branch',
      code: 'MS',
    },
    {
      city_state: 'Hazlehurst, Mississippi',
      city: 'Hazlehurst',
      code: 'MS',
    },
    {
      city_state: 'New Augusta, Mississippi',
      city: 'New Augusta',
      code: 'MS',
    },
    {
      city_state: 'Hazlehurst, Mississippi',
      city: 'Hazlehurst',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Shannon, Mississippi',
      city: 'Shannon',
      code: 'MS',
    },
    {
      city_state: 'Clinton, Mississippi',
      city: 'Clinton',
      code: 'MS',
    },
    {
      city_state: 'Monticello, Mississippi',
      city: 'Monticello',
      code: 'MS',
    },
    {
      city_state: 'Pearl, Mississippi',
      city: 'Pearl',
      code: 'MS',
    },
    {
      city_state: 'Olive Branch, Mississippi',
      city: 'Olive Branch',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Pearl, Mississippi',
      city: 'Pearl',
      code: 'MS',
    },
    {
      city_state: 'Greenwood, Mississippi',
      city: 'Greenwood',
      code: 'MS',
    },
    {
      city_state: 'Natchez, Mississippi',
      city: 'Natchez',
      code: 'MS',
    },
    {
      city_state: 'Hazlehurst, Mississippi',
      city: 'Hazlehurst',
      code: 'MS',
    },
    {
      city_state: 'Southaven, Mississippi',
      city: 'Southaven',
      code: 'MS',
    },
    {
      city_state: 'Hazlehurst, Mississippi',
      city: 'Hazlehurst',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Canton, Mississippi',
      city: 'Canton',
      code: 'MS',
    },
    {
      city_state: 'Clinton, Mississippi',
      city: 'Clinton',
      code: 'MS',
    },
    {
      city_state: 'Gulfport, Mississippi',
      city: 'Gulfport',
      code: 'MS',
    },
    {
      city_state: 'Olive Branch, Mississippi',
      city: 'Olive Branch',
      code: 'MS',
    },
    {
      city_state: 'Southaven, Mississippi',
      city: 'Southaven',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Natchez, Mississippi',
      city: 'Natchez',
      code: 'MS',
    },
    {
      city_state: 'Hollandale, Mississippi',
      city: 'Hollandale',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Bay Springs, Mississippi',
      city: 'Bay Springs',
      code: 'MS',
    },
    {
      city_state: 'Olive Branch, Mississippi',
      city: 'Olive Branch',
      code: 'MS',
    },
    {
      city_state: 'Jackson, Mississippi',
      city: 'Jackson',
      code: 'MS',
    },
    {
      city_state: 'Byhalia, Mississippi',
      city: 'Byhalia',
      code: 'MS',
    },
    {
      city_state: 'Tupelo, Mississippi',
      city: 'Tupelo',
      code: 'MS',
    },
    {
      city_state: 'Natchez, Mississippi',
      city: 'Natchez',
      code: 'MS',
    },
    {
      city_state: 'Union, Mississippi',
      city: 'Union',
      code: 'MS',
    },
    {
      city_state: 'Jackson, Mississippi',
      city: 'Jackson',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Purvis, Mississippi',
      city: 'Purvis',
      code: 'MS',
    },
    {
      city_state: 'Redwood, Mississippi',
      city: 'Redwood',
      code: 'MS',
    },
    {
      city_state: 'Magnolia, Mississippi',
      city: 'Magnolia',
      code: 'MS',
    },
    {
      city_state: 'Redwood, Mississippi',
      city: 'Redwood',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Flowood, Mississippi',
      city: 'Flowood',
      code: 'MS',
    },
    {
      city_state: 'Redwood, Mississippi',
      city: 'Redwood',
      code: 'MS',
    },
    {
      city_state: 'Redwood, Mississippi',
      city: 'Redwood',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Jackson, Mississippi',
      city: 'Jackson',
      code: 'MS',
    },
    {
      city_state: 'Monticello, Mississippi',
      city: 'Monticello',
      code: 'MS',
    },
    {
      city_state: 'Clinton, Mississippi',
      city: 'Clinton',
      code: 'MS',
    },
    {
      city_state: 'Jackson, Mississippi',
      city: 'Jackson',
      code: 'MS',
    },
    {
      city_state: 'Clinton, Mississippi',
      city: 'Clinton',
      code: 'MS',
    },
    {
      city_state: 'Fayette, Mississippi',
      city: 'Fayette',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Jackson, Mississippi',
      city: 'Jackson',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Indianola, Mississippi',
      city: 'Indianola',
      code: 'MS',
    },
    {
      city_state: 'Redwood, Mississippi',
      city: 'Redwood',
      code: 'MS',
    },
    {
      city_state: 'Houston, Mississippi',
      city: 'Houston',
      code: 'MS',
    },
    {
      city_state: 'Ridgeland, Mississippi',
      city: 'Ridgeland',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Greenwood, Mississippi',
      city: 'Greenwood',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Star, Mississippi',
      city: 'Star',
      code: 'MS',
    },
    {
      city_state: 'Star, Mississippi',
      city: 'Star',
      code: 'MS',
    },
    {
      city_state: 'Jackson, Mississippi',
      city: 'Jackson',
      code: 'MS',
    },
    {
      city_state: 'Monticello, Mississippi',
      city: 'Monticello',
      code: 'MS',
    },
    {
      city_state: 'Magee, Mississippi',
      city: 'Magee',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Forest, Mississippi',
      city: 'Forest',
      code: 'MS',
    },
    {
      city_state: 'Olive Branch, Mississippi',
      city: 'Olive Branch',
      code: 'MS',
    },
    {
      city_state: 'Redwood, Mississippi',
      city: 'Redwood',
      code: 'MS',
    },
    {
      city_state: 'Madison, Mississippi',
      city: 'Madison',
      code: 'MS',
    },
    {
      city_state: 'Southaven, Mississippi',
      city: 'Southaven',
      code: 'MS',
    },
    {
      city_state: 'Flowood, Mississippi',
      city: 'Flowood',
      code: 'MS',
    },
    {
      city_state: 'Hattiesburg, Mississippi',
      city: 'Hattiesburg',
      code: 'MS',
    },
    {
      city_state: 'Richland, Mississippi',
      city: 'Richland',
      code: 'MS',
    },
    {
      city_state: 'Monticello, Mississippi',
      city: 'Monticello',
      code: 'MS',
    },
    {
      city_state: 'Okolona, Mississippi',
      city: 'Okolona',
      code: 'MS',
    },
    {
      city_state: 'Magnolia, Mississippi',
      city: 'Magnolia',
      code: 'MS',
    },
    {
      city_state: 'Hazlehurst, Mississippi',
      city: 'Hazlehurst',
      code: 'MS',
    },
    {
      city_state: 'Brookhaven, Mississippi',
      city: 'Brookhaven',
      code: 'MS',
    },
    {
      city_state: 'Gulfport, Mississippi',
      city: 'Gulfport',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Greenville, Mississippi',
      city: 'Greenville',
      code: 'MS',
    },
    {
      city_state: 'Purvis, Mississippi',
      city: 'Purvis',
      code: 'MS',
    },
    {
      city_state: 'Moorhead, Mississippi',
      city: 'Moorhead',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Hattiesburg, Mississippi',
      city: 'Hattiesburg',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Natchez, Mississippi',
      city: 'Natchez',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Canton, Mississippi',
      city: 'Canton',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Tupelo, Mississippi',
      city: 'Tupelo',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Byhalia, Mississippi',
      city: 'Byhalia',
      code: 'MS',
    },
    {
      city_state: 'Hazlehurst, Mississippi',
      city: 'Hazlehurst',
      code: 'MS',
    },
    {
      city_state: 'Hattiesburg, Mississippi',
      city: 'Hattiesburg',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Madison, Mississippi',
      city: 'Madison',
      code: 'MS',
    },
    {
      city_state: 'Tupelo, Mississippi',
      city: 'Tupelo',
      code: 'MS',
    },
    {
      city_state: 'Jackson, Mississippi',
      city: 'Jackson',
      code: 'MS',
    },
    {
      city_state: 'Purvis, Mississippi',
      city: 'Purvis',
      code: 'MS',
    },
    {
      city_state: 'Monticello, Mississippi',
      city: 'Monticello',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Indianola, Mississippi',
      city: 'Indianola',
      code: 'MS',
    },
    {
      city_state: 'Tupelo, Mississippi',
      city: 'Tupelo',
      code: 'MS',
    },
    {
      city_state: 'Hazlehurst, Mississippi',
      city: 'Hazlehurst',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Monticello, Mississippi',
      city: 'Monticello',
      code: 'MS',
    },
    {
      city_state: 'Brookhaven, Mississippi',
      city: 'Brookhaven',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Durant, Mississippi',
      city: 'Durant',
      code: 'MS',
    },
    {
      city_state: 'Magee, Mississippi',
      city: 'Magee',
      code: 'MS',
    },
    {
      city_state: 'Greenwood, Mississippi',
      city: 'Greenwood',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Jackson, Mississippi',
      city: 'Jackson',
      code: 'MS',
    },
    {
      city_state: 'Olive Branch, Mississippi',
      city: 'Olive Branch',
      code: 'MS',
    },
    {
      city_state: 'Fernwood, Mississippi',
      city: 'Fernwood',
      code: 'MS',
    },
    {
      city_state: 'Laurel, Mississippi',
      city: 'Laurel',
      code: 'MS',
    },
    {
      city_state: 'Southaven, Mississippi',
      city: 'Southaven',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Jackson, Mississippi',
      city: 'Jackson',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Hazlehurst, Mississippi',
      city: 'Hazlehurst',
      code: 'MS',
    },
    {
      city_state: 'Magnolia, Mississippi',
      city: 'Magnolia',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'West Point, Mississippi',
      city: 'West Point',
      code: 'MS',
    },
    {
      city_state: 'Hattiesburg, Mississippi',
      city: 'Hattiesburg',
      code: 'MS',
    },
    {
      city_state: 'Morton, Mississippi',
      city: 'Morton',
      code: 'MS',
    },
    {
      city_state: 'Columbus, Mississippi',
      city: 'Columbus',
      code: 'MS',
    },
    {
      city_state: 'Greenwood, Mississippi',
      city: 'Greenwood',
      code: 'MS',
    },
    {
      city_state: 'Clinton, Mississippi',
      city: 'Clinton',
      code: 'MS',
    },
    {
      city_state: 'Jackson, Mississippi',
      city: 'Jackson',
      code: 'MS',
    },
    {
      city_state: 'Canton, Mississippi',
      city: 'Canton',
      code: 'MS',
    },
    {
      city_state: 'Redwood, Mississippi',
      city: 'Redwood',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Olive Branch, Mississippi',
      city: 'Olive Branch',
      code: 'MS',
    },
    {
      city_state: 'Grenada1, Mississippi',
      city: 'Grenada1',
      code: 'MS',
    },
    {
      city_state: 'Starkville, Mississippi',
      city: 'Starkville',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Columbia, Mississippi',
      city: 'Columbia',
      code: 'MS',
    },
    {
      city_state: 'Newton, Mississippi',
      city: 'Newton',
      code: 'MS',
    },
    {
      city_state: 'Bruce, Mississippi',
      city: 'Bruce',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Pearl, Mississippi',
      city: 'Pearl',
      code: 'MS',
    },
    {
      city_state: 'McComb, Mississippi',
      city: 'McComb',
      code: 'MS',
    },
    {
      city_state: 'Monticello, Mississippi',
      city: 'Monticello',
      code: 'MS',
    },
    {
      city_state: 'Greenwood, Mississippi',
      city: 'Greenwood',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Madison, Mississippi',
      city: 'Madison',
      code: 'MS',
    },
    {
      city_state: 'Magee, Mississippi',
      city: 'Magee',
      code: 'MS',
    },
    {
      city_state: 'Brookhaven, Mississippi',
      city: 'Brookhaven',
      code: 'MS',
    },
    {
      city_state: 'Tupelo, Mississippi',
      city: 'Tupelo',
      code: 'MS',
    },
    {
      city_state: 'Byhalia, Mississippi',
      city: 'Byhalia',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Clinton, Mississippi',
      city: 'Clinton',
      code: 'MS',
    },
    {
      city_state: 'Jackson, Mississippi',
      city: 'Jackson',
      code: 'MS',
    },
    {
      city_state: 'Monticello, Mississippi',
      city: 'Monticello',
      code: 'MS',
    },
    {
      city_state: 'Brookhaven, Mississippi',
      city: 'Brookhaven',
      code: 'MS',
    },
    {
      city_state: 'Starkville, Mississippi',
      city: 'Starkville',
      code: 'MS',
    },
    {
      city_state: 'Madison, Mississippi',
      city: 'Madison',
      code: 'MS',
    },
    {
      city_state: 'Vicksburg, Mississippi',
      city: 'Vicksburg',
      code: 'MS',
    },
    {
      city_state: 'Redwood, Mississippi',
      city: 'Redwood',
      code: 'MS',
    },
    {
      city_state: 'Jackson, Mississippi',
      city: 'Jackson',
      code: 'MS',
    },
    {
      city_state: 'Natchez, Mississippi',
      city: 'Natchez',
      code: 'MS',
    },
    {
      city_state: 'Hazlehurst, Mississippi',
      city: 'Hazlehurst',
      code: 'MS',
    },
    {
      city_state: 'Jackson, Mississippi',
      city: 'Jackson',
      code: 'MS',
    },
    {
      city_state: 'Monticello, Mississippi',
      city: 'Monticello',
      code: 'MS',
    },
    {
      city_state: 'Natchez, Mississippi',
      city: 'Natchez',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Jackson, Mississippi',
      city: 'Jackson',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Byhalia, Mississippi',
      city: 'Byhalia',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Monticello, Mississippi',
      city: 'Monticello',
      code: 'MS',
    },
    {
      city_state: 'Pelahatchie, Mississippi',
      city: 'Pelahatchie',
      code: 'MS',
    },
    {
      city_state: 'Pearl, Mississippi',
      city: 'Pearl',
      code: 'MS',
    },
    {
      city_state: 'Natchez, Mississippi',
      city: 'Natchez',
      code: 'MS',
    },
    {
      city_state: 'Greenwood, Mississippi',
      city: 'Greenwood',
      code: 'MS',
    },
    {
      city_state: 'Clinton, Mississippi',
      city: 'Clinton',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Florence, Mississippi',
      city: 'Florence',
      code: 'MS',
    },
    {
      city_state: 'Magee, Mississippi',
      city: 'Magee',
      code: 'MS',
    },
    {
      city_state: 'Gulfport, Mississippi',
      city: 'Gulfport',
      code: 'MS',
    },
    {
      city_state: 'Purvis, Mississippi',
      city: 'Purvis',
      code: 'MS',
    },
    {
      city_state: 'Madison, Mississippi',
      city: 'Madison',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Canton, Mississippi',
      city: 'Canton',
      code: 'MS',
    },
    {
      city_state: 'West Point, Mississippi',
      city: 'West Point',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Byram, Mississippi',
      city: 'Byram',
      code: 'MS',
    },
    {
      city_state: 'Grenada, Mississippi',
      city: 'Grenada',
      code: 'MS',
    },
    {
      city_state: 'Meridian, Mississippi',
      city: 'Meridian',
      code: 'MS',
    },
    {
      city_state: 'Greenwood, Mississippi',
      city: 'Greenwood',
      code: 'MS',
    },
    {
      city_state: 'Purvis, Mississippi',
      city: 'Purvis',
      code: 'MS',
    },
  ],
  Texas: [
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Garland, Texas',
      city: 'Garland',
      code: 'TX',
    },
    {
      city_state: 'Corsicana, Texas',
      city: 'Corsicana',
      code: 'TX',
    },
    {
      city_state: 'Ennis, Texas',
      city: 'Ennis',
      code: 'TX',
    },
    {
      city_state: 'Atlanta, Texas',
      city: 'Atlanta',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Houston, Texas',
      city: 'Houston',
      code: 'TX',
    },
    {
      city_state: 'Waxahachie, Texas',
      city: 'Waxahachie',
      code: 'TX',
    },
    {
      city_state: 'Baytown, Texas',
      city: 'Baytown',
      code: 'TX',
    },
    {
      city_state: 'Hutchins, Texas',
      city: 'Hutchins',
      code: 'TX',
    },
    {
      city_state: 'Denton, Texas',
      city: 'Denton',
      code: 'TX',
    },
    {
      city_state: 'Dallas, Texas',
      city: 'Dallas',
      code: 'TX',
    },
    {
      city_state: 'Boerne, Texas',
      city: 'Boerne',
      code: 'TX',
    },
    {
      city_state: 'Dallas, Texas',
      city: 'Dallas',
      code: 'TX',
    },
    {
      city_state: 'Baytown, Texas',
      city: 'Baytown',
      code: 'TX',
    },
    {
      city_state: 'Arlington, Texas',
      city: 'Arlington',
      code: 'TX',
    },
    {
      city_state: 'Grand Prairie, Texas',
      city: 'Grand Prairie',
      code: 'TX',
    },
    {
      city_state: 'Haslet, Texas',
      city: 'Haslet',
      code: 'TX',
    },
    {
      city_state: 'Houston, Texas',
      city: 'Houston',
      code: 'TX',
    },
    {
      city_state: 'Marshall, Texas',
      city: 'Marshall',
      code: 'TX',
    },
    {
      city_state: 'Lufkin, Texas',
      city: 'Lufkin',
      code: 'TX',
    },
    {
      city_state: 'San Antonio, Texas',
      city: 'San Antonio',
      code: 'TX',
    },
    {
      city_state: 'Lancaster, Texas',
      city: 'Lancaster',
      code: 'TX',
    },
    {
      city_state: 'Stafford, Texas',
      city: 'Stafford',
      code: 'TX',
    },
    {
      city_state: 'Grand Prairie, Texas',
      city: 'Grand Prairie',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Eagle Pass, Texas',
      city: 'Eagle Pass',
      code: 'TX',
    },
    {
      city_state: 'Killeen, Texas',
      city: 'Killeen',
      code: 'TX',
    },
    {
      city_state: 'Grand Prairie, Texas',
      city: 'Grand Prairie',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Baytown, Texas',
      city: 'Baytown',
      code: 'TX',
    },
    {
      city_state: 'Tyler, Texas',
      city: 'Tyler',
      code: 'TX',
    },
    {
      city_state: 'McAllen, Texas',
      city: 'McAllen',
      code: 'TX',
    },
    {
      city_state: 'Dallas, Texas',
      city: 'Dallas',
      code: 'TX',
    },
    {
      city_state: 'Bryan, Texas',
      city: 'Bryan',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Atlanta, Texas',
      city: 'Atlanta',
      code: 'TX',
    },
    {
      city_state: 'Waco, Texas',
      city: 'Waco',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Houston, Texas',
      city: 'Houston',
      code: 'TX',
    },
    {
      city_state: 'Arlington, Texas',
      city: 'Arlington',
      code: 'TX',
    },
    {
      city_state: 'Mineral Wells, Texas',
      city: 'Mineral Wells',
      code: 'TX',
    },
    {
      city_state: 'Abilene, Texas',
      city: 'Abilene',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Jefferson, Texas',
      city: 'Jefferson',
      code: 'TX',
    },
    {
      city_state: 'Baytown, Texas',
      city: 'Baytown',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Marshall, Texas',
      city: 'Marshall',
      code: 'TX',
    },
    {
      city_state: 'Rosenberg, Texas',
      city: 'Rosenberg',
      code: 'TX',
    },
    {
      city_state: 'Baytown, Texas',
      city: 'Baytown',
      code: 'TX',
    },
    {
      city_state: 'Temple, Texas',
      city: 'Temple',
      code: 'TX',
    },
    {
      city_state: 'Grand Prairie, Texas',
      city: 'Grand Prairie',
      code: 'TX',
    },
    {
      city_state: 'Shamrock, Texas',
      city: 'Shamrock',
      code: 'TX',
    },
    {
      city_state: 'Grand Prairie, Texas',
      city: 'Grand Prairie',
      code: 'TX',
    },
    {
      city_state: 'Lancaster, Texas',
      city: 'Lancaster',
      code: 'TX',
    },
    {
      city_state: 'Dallas, Texas',
      city: 'Dallas',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Keller, Texas',
      city: 'Keller',
      code: 'TX',
    },
    {
      city_state: 'Houston, Texas',
      city: 'Houston',
      code: 'TX',
    },
    {
      city_state: 'Duncanville, Texas',
      city: 'Duncanville',
      code: 'TX',
    },
    {
      city_state: 'Arlington, Texas',
      city: 'Arlington',
      code: 'TX',
    },
    {
      city_state: 'Hutchins, Texas',
      city: 'Hutchins',
      code: 'TX',
    },
    {
      city_state: 'Grand Prairie, Texas',
      city: 'Grand Prairie',
      code: 'TX',
    },
    {
      city_state: 'Mesquite, Texas',
      city: 'Mesquite',
      code: 'TX',
    },
    {
      city_state: 'Grand Prairie, Texas',
      city: 'Grand Prairie',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Victoria, Texas',
      city: 'Victoria',
      code: 'TX',
    },
    {
      city_state: 'Irving, Texas',
      city: 'Irving',
      code: 'TX',
    },
    {
      city_state: 'Laredo, Texas',
      city: 'Laredo',
      code: 'TX',
    },
    {
      city_state: 'Huntsville, Texas',
      city: 'Huntsville',
      code: 'TX',
    },
    {
      city_state: 'Marshall, Texas',
      city: 'Marshall',
      code: 'TX',
    },
    {
      city_state: 'Queen City, Texas',
      city: 'Queen City',
      code: 'TX',
    },
    {
      city_state: 'Marshall, Texas',
      city: 'Marshall',
      code: 'TX',
    },
    {
      city_state: 'Wilmer, Texas',
      city: 'Wilmer',
      code: 'TX',
    },
    {
      city_state: 'DeSoto, Texas',
      city: 'DeSoto',
      code: 'TX',
    },
    {
      city_state: 'Mt Pleasant, Texas',
      city: 'Mt Pleasant',
      code: 'TX',
    },
    {
      city_state: 'Houston, Texas',
      city: 'Houston',
      code: 'TX',
    },
    {
      city_state: 'La Porte, Texas',
      city: 'La Porte',
      code: 'TX',
    },
    {
      city_state: 'La Porte, Texas',
      city: 'La Porte',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Dallas, Texas',
      city: 'Dallas',
      code: 'TX',
    },
    {
      city_state: 'Arlington, Texas',
      city: 'Arlington',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Kirbyville, Texas',
      city: 'Kirbyville',
      code: 'TX',
    },
    {
      city_state: 'Georgetown, Texas',
      city: 'Georgetown',
      code: 'TX',
    },
    {
      city_state: 'Sherman, Texas',
      city: 'Sherman',
      code: 'TX',
    },
    {
      city_state: 'Mt Pleasant, Texas',
      city: 'Mt Pleasant',
      code: 'TX',
    },
    {
      city_state: 'Waxahachie, Texas',
      city: 'Waxahachie',
      code: 'TX',
    },
    {
      city_state: 'Dallas, Texas',
      city: 'Dallas',
      code: 'TX',
    },
    {
      city_state: 'Grand Prairie, Texas',
      city: 'Grand Prairie',
      code: 'TX',
    },
    {
      city_state: 'Georgetown, Texas',
      city: 'Georgetown',
      code: 'TX',
    },
    {
      city_state: 'Houston, Texas',
      city: 'Houston',
      code: 'TX',
    },
    {
      city_state: 'Dallas, Texas',
      city: 'Dallas',
      code: 'TX',
    },
    {
      city_state: 'Baytown, Texas',
      city: 'Baytown',
      code: 'TX',
    },
    {
      city_state: 'Baytown, Texas',
      city: 'Baytown',
      code: 'TX',
    },
    {
      city_state: 'Victoria, Texas',
      city: 'Victoria',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Rosenberg, Texas',
      city: 'Rosenberg',
      code: 'TX',
    },
    {
      city_state: 'Laredo, Texas',
      city: 'Laredo',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Dallas, Texas',
      city: 'Dallas',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Taylor, Texas',
      city: 'Taylor',
      code: 'TX',
    },
    {
      city_state: 'Grand Prairie, Texas',
      city: 'Grand Prairie',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
    {
      city_state: 'Baytown, Texas',
      city: 'Baytown',
      code: 'TX',
    },
    {
      city_state: 'Houston, Texas',
      city: 'Houston',
      code: 'TX',
    },
    {
      city_state: 'Fort Worth, Texas',
      city: 'Fort Worth',
      code: 'TX',
    },
  ],
  Louisiana: [
    {
      city_state: 'Opelousas, Louisiana',
      city: 'Opelousas',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'DeRidder, Louisiana',
      city: 'DeRidder',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'Alexandria, Louisiana',
      city: 'Alexandria',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'Baton Rouge, Louisiana',
      city: 'Baton Rouge',
      code: 'LA',
    },
    {
      city_state: 'DeRidder, Louisiana',
      city: 'DeRidder',
      code: 'LA',
    },
    {
      city_state: 'Lafayette, Louisiana',
      city: 'Lafayette',
      code: 'LA',
    },
    {
      city_state: 'Lafayette, Louisiana',
      city: 'Lafayette',
      code: 'LA',
    },
    {
      city_state: 'Alexandria, Louisiana',
      city: 'Alexandria',
      code: 'LA',
    },
    {
      city_state: 'Lafayette, Louisiana',
      city: 'Lafayette',
      code: 'LA',
    },
    {
      city_state: 'Shreveport, Louisiana',
      city: 'Shreveport',
      code: 'LA',
    },
    {
      city_state: 'Pineville, Louisiana',
      city: 'Pineville',
      code: 'LA',
    },
    {
      city_state: 'Bossier City, Louisiana',
      city: 'Bossier City',
      code: 'LA',
    },
    {
      city_state: 'Baton Rouge, Louisiana',
      city: 'Baton Rouge',
      code: 'LA',
    },
    {
      city_state: 'Shreveport, Louisiana',
      city: 'Shreveport',
      code: 'LA',
    },
    {
      city_state: 'Baton Rouge, Louisiana',
      city: 'Baton Rouge',
      code: 'LA',
    },
    {
      city_state: 'New Orleans, Louisiana',
      city: 'New Orleans',
      code: 'LA',
    },
    {
      city_state: 'Shreveport, Louisiana',
      city: 'Shreveport',
      code: 'LA',
    },
    {
      city_state: 'Lafayette, Louisiana',
      city: 'Lafayette',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'Bogalusa, Louisiana',
      city: 'Bogalusa',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'Monroe, Louisiana',
      city: 'Monroe',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'Lafayette, Louisiana',
      city: 'Lafayette',
      code: 'LA',
    },
    {
      city_state: 'Shreveport, Louisiana',
      city: 'Shreveport',
      code: 'LA',
    },
    {
      city_state: 'Port Allen, Louisiana',
      city: 'Port Allen',
      code: 'LA',
    },
    {
      city_state: 'Baton Rouge, Louisiana',
      city: 'Baton Rouge',
      code: 'LA',
    },
    {
      city_state: 'New Orleans, Louisiana',
      city: 'New Orleans',
      code: 'LA',
    },
    {
      city_state: 'Baton Rouge, Louisiana',
      city: 'Baton Rouge',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'Hahnville, Louisiana',
      city: 'Hahnville',
      code: 'LA',
    },
    {
      city_state: 'St Francisville, Louisiana',
      city: 'St Francisville',
      code: 'LA',
    },
    {
      city_state: 'Baton Rouge, Louisiana',
      city: 'Baton Rouge',
      code: 'LA',
    },
    {
      city_state: 'Baton Rouge, Louisiana',
      city: 'Baton Rouge',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'Baton Rouge, Louisiana',
      city: 'Baton Rouge',
      code: 'LA',
    },
    {
      city_state: 'Marrero, Louisiana',
      city: 'Marrero',
      code: 'LA',
    },
    {
      city_state: 'DeRidder, Louisiana',
      city: 'DeRidder',
      code: 'LA',
    },
    {
      city_state: 'Bogalusa, Louisiana',
      city: 'Bogalusa',
      code: 'LA',
    },
    {
      city_state: 'Baton Rouge, Louisiana',
      city: 'Baton Rouge',
      code: 'LA',
    },
    {
      city_state: 'DeRidder, Louisiana',
      city: 'DeRidder',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'Monroe, Louisiana',
      city: 'Monroe',
      code: 'LA',
    },
    {
      city_state: 'Kentwood, Louisiana',
      city: 'Kentwood',
      code: 'LA',
    },
    {
      city_state: 'Monroe, Louisiana',
      city: 'Monroe',
      code: 'LA',
    },
    {
      city_state: 'Westwego, Louisiana',
      city: 'Westwego',
      code: 'LA',
    },
    {
      city_state: 'Shreveport, Louisiana',
      city: 'Shreveport',
      code: 'LA',
    },
    {
      city_state: 'Slidell, Louisiana',
      city: 'Slidell',
      code: 'LA',
    },
    {
      city_state: 'New Orleans, Louisiana',
      city: 'New Orleans',
      code: 'LA',
    },
    {
      city_state: 'New Orleans, Louisiana',
      city: 'New Orleans',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'Shreveport, Louisiana',
      city: 'Shreveport',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'Harvey, Louisiana',
      city: 'Harvey',
      code: 'LA',
    },
    {
      city_state: 'Pineville, Louisiana',
      city: 'Pineville',
      code: 'LA',
    },
    {
      city_state: 'New Orleans, Louisiana',
      city: 'New Orleans',
      code: 'LA',
    },
    {
      city_state: 'Shreveport, Louisiana',
      city: 'Shreveport',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'Ponchatoula, Louisiana',
      city: 'Ponchatoula',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'Port Allen, Louisiana',
      city: 'Port Allen',
      code: 'LA',
    },
    {
      city_state: 'Shreveport, Louisiana',
      city: 'Shreveport',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'New Orleans, Louisiana',
      city: 'New Orleans',
      code: 'LA',
    },
    {
      city_state: 'New Orleans, Louisiana',
      city: 'New Orleans',
      code: 'LA',
    },
    {
      city_state: 'Baton Rouge, Louisiana',
      city: 'Baton Rouge',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'Alexandria, Louisiana',
      city: 'Alexandria',
      code: 'LA',
    },
    {
      city_state: 'Church Point, Louisiana',
      city: 'Church Point',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'Shreveport, Louisiana',
      city: 'Shreveport',
      code: 'LA',
    },
    {
      city_state: 'Monroe, Louisiana',
      city: 'Monroe',
      code: 'LA',
    },
    {
      city_state: 'Baton Rouge, Louisiana',
      city: 'Baton Rouge',
      code: 'LA',
    },
    {
      city_state: 'Mansfield, Louisiana',
      city: 'Mansfield',
      code: 'LA',
    },
    {
      city_state: 'Monroe, Louisiana',
      city: 'Monroe',
      code: 'LA',
    },
    {
      city_state: 'Baton Rouge, Louisiana',
      city: 'Baton Rouge',
      code: 'LA',
    },
    {
      city_state: 'Scott, Louisiana',
      city: 'Scott',
      code: 'LA',
    },
    {
      city_state: 'St Rose, Louisiana',
      city: 'St Rose',
      code: 'LA',
    },
    {
      city_state: 'New Orleans, Louisiana',
      city: 'New Orleans',
      code: 'LA',
    },
    {
      city_state: 'Monroe, Louisiana',
      city: 'Monroe',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'Lafayette, Louisiana',
      city: 'Lafayette',
      code: 'LA',
    },
    {
      city_state: 'West Monroe, Louisiana',
      city: 'West Monroe',
      code: 'LA',
    },
    {
      city_state: 'Lake Charles, Louisiana',
      city: 'Lake Charles',
      code: 'LA',
    },
  ],
  'New Jersey': [
    {
      city_state: 'Berlin, New Jersey',
      city: 'Berlin',
      code: 'NJ',
    },
    {
      city_state: 'Elizabeth, New Jersey',
      city: 'Elizabeth',
      code: 'NJ',
    },
    {
      city_state: 'Berlin, New Jersey',
      city: 'Berlin',
      code: 'NJ',
    },
    {
      city_state: 'South Plainfield, New Jersey',
      city: 'South Plainfield',
      code: 'NJ',
    },
    {
      city_state: 'Parsippany, New Jersey',
      city: 'Parsippany',
      code: 'NJ',
    },
    {
      city_state: 'Berlin, New Jersey',
      city: 'Berlin',
      code: 'NJ',
    },
    {
      city_state: 'Berlin, New Jersey',
      city: 'Berlin',
      code: 'NJ',
    },
    {
      city_state: 'Swedesboro, New Jersey',
      city: 'Swedesboro',
      code: 'NJ',
    },
    {
      city_state: 'Berlin, New Jersey',
      city: 'Berlin',
      code: 'NJ',
    },
    {
      city_state: 'Phillipsburg, New Jersey',
      city: 'Phillipsburg',
      code: 'NJ',
    },
    {
      city_state: 'North Bergen, New Jersey',
      city: 'North Bergen',
      code: 'NJ',
    },
    {
      city_state: 'Newark, New Jersey',
      city: 'Newark',
      code: 'NJ',
    },
    {
      city_state: 'Berlin, New Jersey',
      city: 'Berlin',
      code: 'NJ',
    },
    {
      city_state: 'Berlin, New Jersey',
      city: 'Berlin',
      code: 'NJ',
    },
    {
      city_state: 'North Bergen, New Jersey',
      city: 'North Bergen',
      code: 'NJ',
    },
    {
      city_state: 'Phillipsburg, New Jersey',
      city: 'Phillipsburg',
      code: 'NJ',
    },
    {
      city_state: 'Newark, New Jersey',
      city: 'Newark',
      code: 'NJ',
    },
    {
      city_state: 'Berlin, New Jersey',
      city: 'Berlin',
      code: 'NJ',
    },
    {
      city_state: 'Kearny, New Jersey',
      city: 'Kearny',
      code: 'NJ',
    },
    {
      city_state: 'Swedesboro, New Jersey',
      city: 'Swedesboro',
      code: 'NJ',
    },
    {
      city_state: 'Berlin, New Jersey',
      city: 'Berlin',
      code: 'NJ',
    },
    {
      city_state: 'Berlin, New Jersey',
      city: 'Berlin',
      code: 'NJ',
    },
    {
      city_state: 'Berlin, New Jersey',
      city: 'Berlin',
      code: 'NJ',
    },
    {
      city_state: 'Berlin, New Jersey',
      city: 'Berlin',
      code: 'NJ',
    },
    {
      city_state: 'Berlin, New Jersey',
      city: 'Berlin',
      code: 'NJ',
    },
    {
      city_state: 'Cherry Hill, New Jersey',
      city: 'Cherry Hill',
      code: 'NJ',
    },
    {
      city_state: 'Berlin, New Jersey',
      city: 'Berlin',
      code: 'NJ',
    },
    {
      city_state: 'Berlin, New Jersey',
      city: 'Berlin',
      code: 'NJ',
    },
    {
      city_state: 'Phillipsburg, New Jersey',
      city: 'Phillipsburg',
      code: 'NJ',
    },
    {
      city_state: 'Burlington, New Jersey',
      city: 'Burlington',
      code: 'NJ',
    },
    {
      city_state: 'Berlin, New Jersey',
      city: 'Berlin',
      code: 'NJ',
    },
  ],
  Maine: [
    {
      city_state: 'Bangor, Maine',
      city: 'Bangor',
      code: 'ME',
    },
    {
      city_state: 'York, Maine',
      city: 'York',
      code: 'ME',
    },
    {
      city_state: 'Augusta, Maine',
      city: 'Augusta',
      code: 'ME',
    },
    {
      city_state: 'Auburn, Maine',
      city: 'Auburn',
      code: 'ME',
    },
  ],
  Tennessee: [
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Chattanooga, Tennessee',
      city: 'Chattanooga',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Counce, Tennessee',
      city: 'Counce',
      code: 'TN',
    },
    {
      city_state: 'Lebanon, Tennessee',
      city: 'Lebanon',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Charleston, Tennessee',
      city: 'Charleston',
      code: 'TN',
    },
    {
      city_state: 'Cleveland, Tennessee',
      city: 'Cleveland',
      code: 'TN',
    },
    {
      city_state: 'Charleston, Tennessee',
      city: 'Charleston',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Lawrenceburg, Tennessee',
      city: 'Lawrenceburg',
      code: 'TN',
    },
    {
      city_state: 'Milan, Tennessee',
      city: 'Milan',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Covington, Tennessee',
      city: 'Covington',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Cleveland, Tennessee',
      city: 'Cleveland',
      code: 'TN',
    },
    {
      city_state: 'Knoxville, Tennessee',
      city: 'Knoxville',
      code: 'TN',
    },
    {
      city_state: 'Lawrenceburg, Tennessee',
      city: 'Lawrenceburg',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Mountain City, Tennessee',
      city: 'Mountain City',
      code: 'TN',
    },
    {
      city_state: 'Rossville, Tennessee',
      city: 'Rossville',
      code: 'TN',
    },
    {
      city_state: 'Jackson, Tennessee',
      city: 'Jackson',
      code: 'TN',
    },
    {
      city_state: 'Cleveland, Tennessee',
      city: 'Cleveland',
      code: 'TN',
    },
    {
      city_state: 'Trenton, Tennessee',
      city: 'Trenton',
      code: 'TN',
    },
    {
      city_state: 'Charleston, Tennessee',
      city: 'Charleston',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Charleston, Tennessee',
      city: 'Charleston',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Milan, Tennessee',
      city: 'Milan',
      code: 'TN',
    },
    {
      city_state: 'Charleston, Tennessee',
      city: 'Charleston',
      code: 'TN',
    },
    {
      city_state: 'Shelbyville, Tennessee',
      city: 'Shelbyville',
      code: 'TN',
    },
    {
      city_state: 'Knoxville, Tennessee',
      city: 'Knoxville',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Greeneville, Tennessee',
      city: 'Greeneville',
      code: 'TN',
    },
    {
      city_state: 'Lawrenceburg, Tennessee',
      city: 'Lawrenceburg',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Brownsville, Tennessee',
      city: 'Brownsville',
      code: 'TN',
    },
    {
      city_state: 'Charleston, Tennessee',
      city: 'Charleston',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Jackson, Tennessee',
      city: 'Jackson',
      code: 'TN',
    },
    {
      city_state: 'Rossville, Tennessee',
      city: 'Rossville',
      code: 'TN',
    },
    {
      city_state: 'La Vergne, Tennessee',
      city: 'La Vergne',
      code: 'TN',
    },
    {
      city_state: 'Milan, Tennessee',
      city: 'Milan',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Manchester, Tennessee',
      city: 'Manchester',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Somerville, Tennessee',
      city: 'Somerville',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
    {
      city_state: 'Henderson, Tennessee',
      city: 'Henderson',
      code: 'TN',
    },
    {
      city_state: 'Louisville, Tennessee',
      city: 'Louisville',
      code: 'TN',
    },
    {
      city_state: 'Memphis, Tennessee',
      city: 'Memphis',
      code: 'TN',
    },
  ],
  Kentucky: [
    {
      city_state: 'Walton, Kentucky',
      city: 'Walton',
      code: 'KY',
    },
    {
      city_state: 'Augusta, Kentucky',
      city: 'Augusta',
      code: 'KY',
    },
    {
      city_state: 'Bowling Green, Kentucky',
      city: 'Bowling Green',
      code: 'KY',
    },
    {
      city_state: 'Owensboro, Kentucky',
      city: 'Owensboro',
      code: 'KY',
    },
    {
      city_state: 'Versailles, Kentucky',
      city: 'Versailles',
      code: 'KY',
    },
    {
      city_state: 'Hebron, Kentucky',
      city: 'Hebron',
      code: 'KY',
    },
    {
      city_state: 'London, Kentucky',
      city: 'London',
      code: 'KY',
    },
    {
      city_state: 'Franklin, Kentucky',
      city: 'Franklin',
      code: 'KY',
    },
    {
      city_state: 'Louisville, Kentucky',
      city: 'Louisville',
      code: 'KY',
    },
    {
      city_state: 'Bowling Green, Kentucky',
      city: 'Bowling Green',
      code: 'KY',
    },
    {
      city_state: 'London, Kentucky',
      city: 'London',
      code: 'KY',
    },
    {
      city_state: 'Clermont, Kentucky',
      city: 'Clermont',
      code: 'KY',
    },
    {
      city_state: 'Bowling Green, Kentucky',
      city: 'Bowling Green',
      code: 'KY',
    },
    {
      city_state: 'Frankfort, Kentucky',
      city: 'Frankfort',
      code: 'KY',
    },
  ],
  'North Carolina': [
    {
      city_state: 'Conover, North Carolina',
      city: 'Conover',
      code: 'NC',
    },
    {
      city_state: 'Charlotte, North Carolina',
      city: 'Charlotte',
      code: 'NC',
    },
    {
      city_state: 'Henderson, North Carolina',
      city: 'Henderson',
      code: 'NC',
    },
    {
      city_state: 'Conover, North Carolina',
      city: 'Conover',
      code: 'NC',
    },
    {
      city_state: 'Goldsboro, North Carolina',
      city: 'Goldsboro',
      code: 'NC',
    },
    {
      city_state: 'Sanford, North Carolina',
      city: 'Sanford',
      code: 'NC',
    },
    {
      city_state: 'Monroe, North Carolina',
      city: 'Monroe',
      code: 'NC',
    },
    {
      city_state: 'Belmont, North Carolina',
      city: 'Belmont',
      code: 'NC',
    },
    {
      city_state: 'Pineville, North Carolina',
      city: 'Pineville',
      code: 'NC',
    },
    {
      city_state: 'Reidsville, North Carolina',
      city: 'Reidsville',
      code: 'NC',
    },
    {
      city_state: 'Charlotte, North Carolina',
      city: 'Charlotte',
      code: 'NC',
    },
    {
      city_state: 'Charlotte, North Carolina',
      city: 'Charlotte',
      code: 'NC',
    },
    {
      city_state: 'Charlotte, North Carolina',
      city: 'Charlotte',
      code: 'NC',
    },
    {
      city_state: 'Wake Forest, North Carolina',
      city: 'Wake Forest',
      code: 'NC',
    },
    {
      city_state: 'Charlotte, North Carolina',
      city: 'Charlotte',
      code: 'NC',
    },
    {
      city_state: 'Mebane, North Carolina',
      city: 'Mebane',
      code: 'NC',
    },
    {
      city_state: 'Clarkton, North Carolina',
      city: 'Clarkton',
      code: 'NC',
    },
    {
      city_state: 'Newton, North Carolina',
      city: 'Newton',
      code: 'NC',
    },
    {
      city_state: 'Raleigh, North Carolina',
      city: 'Raleigh',
      code: 'NC',
    },
    {
      city_state: 'Hickory, North Carolina',
      city: 'Hickory',
      code: 'NC',
    },
    {
      city_state: 'Clarkton, North Carolina',
      city: 'Clarkton',
      code: 'NC',
    },
    {
      city_state: 'Charlotte, North Carolina',
      city: 'Charlotte',
      code: 'NC',
    },
    {
      city_state: 'Greensboro, North Carolina',
      city: 'Greensboro',
      code: 'NC',
    },
    {
      city_state: 'Gastonia, North Carolina',
      city: 'Gastonia',
      code: 'NC',
    },
    {
      city_state: 'Halifax, North Carolina',
      city: 'Halifax',
      code: 'NC',
    },
    {
      city_state: 'Clarkton, North Carolina',
      city: 'Clarkton',
      code: 'NC',
    },
  ],
  Virginia: [
    {
      city_state: 'Independence, Virginia',
      city: 'Independence',
      code: 'VA',
    },
    {
      city_state: 'Winchester, Virginia',
      city: 'Winchester',
      code: 'VA',
    },
    {
      city_state: 'Stephenson, Virginia',
      city: 'Stephenson',
      code: 'VA',
    },
    {
      city_state: 'Elkton, Virginia',
      city: 'Elkton',
      code: 'VA',
    },
    {
      city_state: 'Abingdon, Virginia',
      city: 'Abingdon',
      code: 'VA',
    },
    {
      city_state: 'Winchester, Virginia',
      city: 'Winchester',
      code: 'VA',
    },
    {
      city_state: 'Gainesville, Virginia',
      city: 'Gainesville',
      code: 'VA',
    },
    {
      city_state: 'Roanoke, Virginia',
      city: 'Roanoke',
      code: 'VA',
    },
    {
      city_state: 'Staunton, Virginia',
      city: 'Staunton',
      code: 'VA',
    },
    {
      city_state: 'Stephenson, Virginia',
      city: 'Stephenson',
      code: 'VA',
    },
    {
      city_state: 'Henrico, Virginia',
      city: 'Henrico',
      code: 'VA',
    },
    {
      city_state: 'Fredericksburg, Virginia',
      city: 'Fredericksburg',
      code: 'VA',
    },
    {
      city_state: 'Winchester, Virginia',
      city: 'Winchester',
      code: 'VA',
    },
    {
      city_state: 'Stephenson, Virginia',
      city: 'Stephenson',
      code: 'VA',
    },
    {
      city_state: 'Mechanicsville, Virginia',
      city: 'Mechanicsville',
      code: 'VA',
    },
    {
      city_state: 'Stephenson, Virginia',
      city: 'Stephenson',
      code: 'VA',
    },
    {
      city_state: 'Richmond, Virginia',
      city: 'Richmond',
      code: 'VA',
    },
    {
      city_state: 'Culpeper, Virginia',
      city: 'Culpeper',
      code: 'VA',
    },
    {
      city_state: 'Waynesboro, Virginia',
      city: 'Waynesboro',
      code: 'VA',
    },
    {
      city_state: 'Stephenson, Virginia',
      city: 'Stephenson',
      code: 'VA',
    },
    {
      city_state: 'Chesapeake, Virginia',
      city: 'Chesapeake',
      code: 'VA',
    },
    {
      city_state: 'Elkton, Virginia',
      city: 'Elkton',
      code: 'VA',
    },
    {
      city_state: 'Franklin, Virginia',
      city: 'Franklin',
      code: 'VA',
    },
    {
      city_state: 'Salem, Virginia',
      city: 'Salem',
      code: 'VA',
    },
    {
      city_state: 'Winchester, Virginia',
      city: 'Winchester',
      code: 'VA',
    },
  ],
  Arkansas: [
    {
      city_state: 'Dumas, Arkansas',
      city: 'Dumas',
      code: 'AR',
    },
    {
      city_state: 'Fort Smith, Arkansas',
      city: 'Fort Smith',
      code: 'AR',
    },
    {
      city_state: 'Fayetteville, Arkansas',
      city: 'Fayetteville',
      code: 'AR',
    },
    {
      city_state: 'Fort Smith, Arkansas',
      city: 'Fort Smith',
      code: 'AR',
    },
    {
      city_state: 'Russellville, Arkansas',
      city: 'Russellville',
      code: 'AR',
    },
    {
      city_state: 'Magnolia, Arkansas',
      city: 'Magnolia',
      code: 'AR',
    },
    {
      city_state: 'Hot Springs National Park, Arkansas',
      city: 'Hot Springs National Park',
      code: 'AR',
    },
    {
      city_state: 'Jonesboro, Arkansas',
      city: 'Jonesboro',
      code: 'AR',
    },
    {
      city_state: 'Dumas, Arkansas',
      city: 'Dumas',
      code: 'AR',
    },
    {
      city_state: 'Rogers, Arkansas',
      city: 'Rogers',
      code: 'AR',
    },
    {
      city_state: 'Russellville, Arkansas',
      city: 'Russellville',
      code: 'AR',
    },
    {
      city_state: 'Rogers, Arkansas',
      city: 'Rogers',
      code: 'AR',
    },
    {
      city_state: 'Rogers, Arkansas',
      city: 'Rogers',
      code: 'AR',
    },
    {
      city_state: 'Marianna, Arkansas',
      city: 'Marianna',
      code: 'AR',
    },
    {
      city_state: 'Rogers, Arkansas',
      city: 'Rogers',
      code: 'AR',
    },
    {
      city_state: 'Russellville, Arkansas',
      city: 'Russellville',
      code: 'AR',
    },
    {
      city_state: 'Dumas, Arkansas',
      city: 'Dumas',
      code: 'AR',
    },
    {
      city_state: 'Norman, Arkansas',
      city: 'Norman',
      code: 'AR',
    },
    {
      city_state: 'Russellville, Arkansas',
      city: 'Russellville',
      code: 'AR',
    },
    {
      city_state: 'North Little Rock, Arkansas',
      city: 'North Little Rock',
      code: 'AR',
    },
    {
      city_state: 'Camden, Arkansas',
      city: 'Camden',
      code: 'AR',
    },
  ],
  Missouri: [
    {
      city_state: 'Liberty, Missouri',
      city: 'Liberty',
      code: 'MO',
    },
    {
      city_state: 'St. Louis, Missouri',
      city: 'St. Louis',
      code: 'MO',
    },
    {
      city_state: 'Salem, Missouri',
      city: 'Salem',
      code: 'MO',
    },
    {
      city_state: 'West Plains, Missouri',
      city: 'West Plains',
      code: 'MO',
    },
    {
      city_state: 'St Charles, Missouri',
      city: 'St Charles',
      code: 'MO',
    },
    {
      city_state: 'St. Louis, Missouri',
      city: 'St. Louis',
      code: 'MO',
    },
    {
      city_state: 'Jonesburg, Missouri',
      city: 'Jonesburg',
      code: 'MO',
    },
    {
      city_state: 'Pacific, Missouri',
      city: 'Pacific',
      code: 'MO',
    },
    {
      city_state: 'Carthage, Missouri',
      city: 'Carthage',
      code: 'MO',
    },
  ],
  Utah: [
    {
      city_state: 'Ogden, Utah',
      city: 'Ogden',
      code: 'UT',
    },
    {
      city_state: 'Salt Lake City, Utah',
      city: 'Salt Lake City',
      code: 'UT',
    },
  ],
  Alabama: [
    {
      city_state: 'Tanner, Alabama',
      city: 'Tanner',
      code: 'AL',
    },
    {
      city_state: 'Fort Payne, Alabama',
      city: 'Fort Payne',
      code: 'AL',
    },
    {
      city_state: 'Brewton, Alabama',
      city: 'Brewton',
      code: 'AL',
    },
    {
      city_state: 'Pennington, Alabama',
      city: 'Pennington',
      code: 'AL',
    },
    {
      city_state: 'Brewton, Alabama',
      city: 'Brewton',
      code: 'AL',
    },
    {
      city_state: 'Brewton, Alabama',
      city: 'Brewton',
      code: 'AL',
    },
    {
      city_state: 'Mobile, Alabama',
      city: 'Mobile',
      code: 'AL',
    },
    {
      city_state: 'Brewton, Alabama',
      city: 'Brewton',
      code: 'AL',
    },
    {
      city_state: 'Cullman, Alabama',
      city: 'Cullman',
      code: 'AL',
    },
    {
      city_state: 'Tuscaloosa, Alabama',
      city: 'Tuscaloosa',
      code: 'AL',
    },
    {
      city_state: 'Cottondale, Alabama',
      city: 'Cottondale',
      code: 'AL',
    },
    {
      city_state: 'Bessemer, Alabama',
      city: 'Bessemer',
      code: 'AL',
    },
    {
      city_state: 'Loxley, Alabama',
      city: 'Loxley',
      code: 'AL',
    },
    {
      city_state: 'Mobile, Alabama',
      city: 'Mobile',
      code: 'AL',
    },
    {
      city_state: 'Russellville, Alabama',
      city: 'Russellville',
      code: 'AL',
    },
    {
      city_state: 'Huntsville, Alabama',
      city: 'Huntsville',
      code: 'AL',
    },
    {
      city_state: 'Athens, Alabama',
      city: 'Athens',
      code: 'AL',
    },
    {
      city_state: 'Mobile, Alabama',
      city: 'Mobile',
      code: 'AL',
    },
    {
      city_state: 'Russellville, Alabama',
      city: 'Russellville',
      code: 'AL',
    },
    {
      city_state: 'Brewton, Alabama',
      city: 'Brewton',
      code: 'AL',
    },
    {
      city_state: 'McCalla, Alabama',
      city: 'McCalla',
      code: 'AL',
    },
    {
      city_state: 'Brewton, Alabama',
      city: 'Brewton',
      code: 'AL',
    },
    {
      city_state: 'Demopolis, Alabama',
      city: 'Demopolis',
      code: 'AL',
    },
    {
      city_state: 'Bessemer, Alabama',
      city: 'Bessemer',
      code: 'AL',
    },
  ],
  Indiana: [
    {
      city_state: 'New Carlisle, Indiana',
      city: 'New Carlisle',
      code: 'IN',
    },
    {
      city_state: 'Indianapolis, Indiana',
      city: 'Indianapolis',
      code: 'IN',
    },
    {
      city_state: 'Fort Wayne, Indiana',
      city: 'Fort Wayne',
      code: 'IN',
    },
    {
      city_state: 'Mooresville, Indiana',
      city: 'Mooresville',
      code: 'IN',
    },
    {
      city_state: 'New Albany, Indiana',
      city: 'New Albany',
      code: 'IN',
    },
    {
      city_state: 'Lebanon, Indiana',
      city: 'Lebanon',
      code: 'IN',
    },
    {
      city_state: 'Geneva, Indiana',
      city: 'Geneva',
      code: 'IN',
    },
    {
      city_state: 'Noblesville, Indiana',
      city: 'Noblesville',
      code: 'IN',
    },
    {
      city_state: 'New Albany, Indiana',
      city: 'New Albany',
      code: 'IN',
    },
    {
      city_state: 'Alexandria, Indiana',
      city: 'Alexandria',
      code: 'IN',
    },
    {
      city_state: 'Geneva, Indiana',
      city: 'Geneva',
      code: 'IN',
    },
    {
      city_state: 'Alexandria, Indiana',
      city: 'Alexandria',
      code: 'IN',
    },
    {
      city_state: 'Indianapolis, Indiana',
      city: 'Indianapolis',
      code: 'IN',
    },
    {
      city_state: 'New Carlisle, Indiana',
      city: 'New Carlisle',
      code: 'IN',
    },
    {
      city_state: 'Geneva, Indiana',
      city: 'Geneva',
      code: 'IN',
    },
  ],
  California: [
    {
      city_state: 'Vista, California',
      city: 'Vista',
      code: 'CA',
    },
    {
      city_state: 'Jurupa Valley, California',
      city: 'Jurupa Valley',
      code: 'CA',
    },
    {
      city_state: 'Baldwin Park, California',
      city: 'Baldwin Park',
      code: 'CA',
    },
    {
      city_state: 'San Diego, California',
      city: 'San Diego',
      code: 'CA',
    },
    {
      city_state: 'Baldwin Park, California',
      city: 'Baldwin Park',
      code: 'CA',
    },
    {
      city_state: 'Moreno Valley, California',
      city: 'Moreno Valley',
      code: 'CA',
    },
    {
      city_state: 'Long Beach, California',
      city: 'Long Beach',
      code: 'CA',
    },
    {
      city_state: 'Ontario, California',
      city: 'Ontario',
      code: 'CA',
    },
    {
      city_state: 'La Puente, California',
      city: 'La Puente',
      code: 'CA',
    },
    {
      city_state: 'Baldwin Park, California',
      city: 'Baldwin Park',
      code: 'CA',
    },
    {
      city_state: 'Jurupa Valley, California',
      city: 'Jurupa Valley',
      code: 'CA',
    },
    {
      city_state: 'Baldwin Park, California',
      city: 'Baldwin Park',
      code: 'CA',
    },
    {
      city_state: 'La Mirada, California',
      city: 'La Mirada',
      code: 'CA',
    },
    {
      city_state: 'Chino, California',
      city: 'Chino',
      code: 'CA',
    },
    {
      city_state: 'Baldwin Park, California',
      city: 'Baldwin Park',
      code: 'CA',
    },
    {
      city_state: 'San Diego, California',
      city: 'San Diego',
      code: 'CA',
    },
    {
      city_state: 'Rancho Cucamonga, California',
      city: 'Rancho Cucamonga',
      code: 'CA',
    },
    {
      city_state: 'Modesto, California',
      city: 'Modesto',
      code: 'CA',
    },
    {
      city_state: 'Huntington Beach, California',
      city: 'Huntington Beach',
      code: 'CA',
    },
    {
      city_state: 'Baldwin Park, California',
      city: 'Baldwin Park',
      code: 'CA',
    },
  ],
  Nevada: [
    {
      city_state: 'Reno, Nevada',
      city: 'Reno',
      code: 'NV',
    },
    {
      city_state: 'Las Vegas, Nevada',
      city: 'Las Vegas',
      code: 'NV',
    },
  ],
  Arizona: [
    {
      city_state: 'Tolleson, Arizona',
      city: 'Tolleson',
      code: 'AZ',
    },
    {
      city_state: 'Tolleson, Arizona',
      city: 'Tolleson',
      code: 'AZ',
    },
    {
      city_state: 'Phoenix, Arizona',
      city: 'Phoenix',
      code: 'AZ',
    },
    {
      city_state: 'Waddell, Arizona',
      city: 'Waddell',
      code: 'AZ',
    },
  ],
  Pennsylvania: [
    {
      city_state: 'Croydon, Pennsylvania',
      city: 'Croydon',
      code: 'PA',
    },
    {
      city_state: 'Mertztown, Pennsylvania',
      city: 'Mertztown',
      code: 'PA',
    },
    {
      city_state: 'Allentown, Pennsylvania',
      city: 'Allentown',
      code: 'PA',
    },
    {
      city_state: 'Hazleton, Pennsylvania',
      city: 'Hazleton',
      code: 'PA',
    },
    {
      city_state: 'Latrobe, Pennsylvania',
      city: 'Latrobe',
      code: 'PA',
    },
    {
      city_state: 'Latrobe, Pennsylvania',
      city: 'Latrobe',
      code: 'PA',
    },
    {
      city_state: 'Latrobe, Pennsylvania',
      city: 'Latrobe',
      code: 'PA',
    },
    {
      city_state: 'Allentown, Pennsylvania',
      city: 'Allentown',
      code: 'PA',
    },
    {
      city_state: 'Philadelphia, Pennsylvania',
      city: 'Philadelphia',
      code: 'PA',
    },
    {
      city_state: 'Latrobe, Pennsylvania',
      city: 'Latrobe',
      code: 'PA',
    },
    {
      city_state: 'Mertztown, Pennsylvania',
      city: 'Mertztown',
      code: 'PA',
    },
    {
      city_state: 'York, Pennsylvania',
      city: 'York',
      code: 'PA',
    },
    {
      city_state: 'Breinigsville, Pennsylvania',
      city: 'Breinigsville',
      code: 'PA',
    },
    {
      city_state: 'Mertztown, Pennsylvania',
      city: 'Mertztown',
      code: 'PA',
    },
    {
      city_state: 'Allentown, Pennsylvania',
      city: 'Allentown',
      code: 'PA',
    },
    {
      city_state: 'Allentown, Pennsylvania',
      city: 'Allentown',
      code: 'PA',
    },
    {
      city_state: 'Schnecksville, Pennsylvania',
      city: 'Schnecksville',
      code: 'PA',
    },
    {
      city_state: 'Latrobe, Pennsylvania',
      city: 'Latrobe',
      code: 'PA',
    },
    {
      city_state: 'Philadelphia, Pennsylvania',
      city: 'Philadelphia',
      code: 'PA',
    },
    {
      city_state: 'Breinigsville, Pennsylvania',
      city: 'Breinigsville',
      code: 'PA',
    },
    {
      city_state: 'Allentown, Pennsylvania',
      city: 'Allentown',
      code: 'PA',
    },
    {
      city_state: 'Allentown, Pennsylvania',
      city: 'Allentown',
      code: 'PA',
    },
    {
      city_state: 'Latrobe, Pennsylvania',
      city: 'Latrobe',
      code: 'PA',
    },
    {
      city_state: 'Croydon, Pennsylvania',
      city: 'Croydon',
      code: 'PA',
    },
    {
      city_state: 'Mertztown, Pennsylvania',
      city: 'Mertztown',
      code: 'PA',
    },
    {
      city_state: 'Allentown, Pennsylvania',
      city: 'Allentown',
      code: 'PA',
    },
    {
      city_state: 'Ruffs Dale, Pennsylvania',
      city: 'Ruffs Dale',
      code: 'PA',
    },
    {
      city_state: 'Allentown, Pennsylvania',
      city: 'Allentown',
      code: 'PA',
    },
    {
      city_state: 'Latrobe, Pennsylvania',
      city: 'Latrobe',
      code: 'PA',
    },
    {
      city_state: 'Allentown, Pennsylvania',
      city: 'Allentown',
      code: 'PA',
    },
    {
      city_state: 'Reading, Pennsylvania',
      city: 'Reading',
      code: 'PA',
    },
    {
      city_state: 'Allentown, Pennsylvania',
      city: 'Allentown',
      code: 'PA',
    },
    {
      city_state: 'Pittsburgh, Pennsylvania',
      city: 'Pittsburgh',
      code: 'PA',
    },
    {
      city_state: 'Allentown, Pennsylvania',
      city: 'Allentown',
      code: 'PA',
    },
    {
      city_state: 'Allentown, Pennsylvania',
      city: 'Allentown',
      code: 'PA',
    },
    {
      city_state: 'Hazleton, Pennsylvania',
      city: 'Hazleton',
      code: 'PA',
    },
    {
      city_state: 'Honey Brook, Pennsylvania',
      city: 'Honey Brook',
      code: 'PA',
    },
    {
      city_state: 'Royersford, Pennsylvania',
      city: 'Royersford',
      code: 'PA',
    },
    {
      city_state: 'Breinigsville, Pennsylvania',
      city: 'Breinigsville',
      code: 'PA',
    },
    {
      city_state: 'Philadelphia, Pennsylvania',
      city: 'Philadelphia',
      code: 'PA',
    },
    {
      city_state: 'Allentown, Pennsylvania',
      city: 'Allentown',
      code: 'PA',
    },
    {
      city_state: 'Mertztown, Pennsylvania',
      city: 'Mertztown',
      code: 'PA',
    },
  ],
  Illinois: [
    {
      city_state: 'Elgin, Illinois',
      city: 'Elgin',
      code: 'IL',
    },
    {
      city_state: 'Farmer City, Illinois',
      city: 'Farmer City',
      code: 'IL',
    },
    {
      city_state: 'Chicago, Illinois',
      city: 'Chicago',
      code: 'IL',
    },
    {
      city_state: 'Monee, Illinois',
      city: 'Monee',
      code: 'IL',
    },
    {
      city_state: 'Plainfield, Illinois',
      city: 'Plainfield',
      code: 'IL',
    },
    {
      city_state: 'Cicero, Illinois',
      city: 'Cicero',
      code: 'IL',
    },
    {
      city_state: 'Plainfield, Illinois',
      city: 'Plainfield',
      code: 'IL',
    },
    {
      city_state: 'Galesburg, Illinois',
      city: 'Galesburg',
      code: 'IL',
    },
    {
      city_state: 'Chicago, Illinois',
      city: 'Chicago',
      code: 'IL',
    },
    {
      city_state: 'Wilmington, Illinois',
      city: 'Wilmington',
      code: 'IL',
    },
    {
      city_state: 'Posen, Illinois',
      city: 'Posen',
      code: 'IL',
    },
    {
      city_state: 'Posen, Illinois',
      city: 'Posen',
      code: 'IL',
    },
    {
      city_state: 'East Alton, Illinois',
      city: 'East Alton',
      code: 'IL',
    },
    {
      city_state: 'Mt Olive, Illinois',
      city: 'Mt Olive',
      code: 'IL',
    },
    {
      city_state: 'Carol Stream, Illinois',
      city: 'Carol Stream',
      code: 'IL',
    },
    {
      city_state: 'Chicago, Illinois',
      city: 'Chicago',
      code: 'IL',
    },
    {
      city_state: 'Posen, Illinois',
      city: 'Posen',
      code: 'IL',
    },
    {
      city_state: 'Wilmington, Illinois',
      city: 'Wilmington',
      code: 'IL',
    },
    {
      city_state: 'Monee, Illinois',
      city: 'Monee',
      code: 'IL',
    },
    {
      city_state: 'Chicago, Illinois',
      city: 'Chicago',
      code: 'IL',
    },
    {
      city_state: 'Romeoville, Illinois',
      city: 'Romeoville',
      code: 'IL',
    },
    {
      city_state: 'Olney, Illinois',
      city: 'Olney',
      code: 'IL',
    },
    {
      city_state: 'Itasca, Illinois',
      city: 'Itasca',
      code: 'IL',
    },
    {
      city_state: 'Centralia, Illinois',
      city: 'Centralia',
      code: 'IL',
    },
    {
      city_state: 'Romeoville, Illinois',
      city: 'Romeoville',
      code: 'IL',
    },
    {
      city_state: 'Plainfield, Illinois',
      city: 'Plainfield',
      code: 'IL',
    },
    {
      city_state: 'Elgin, Illinois',
      city: 'Elgin',
      code: 'IL',
    },
    {
      city_state: 'Mt Vernon, Illinois',
      city: 'Mt Vernon',
      code: 'IL',
    },
    {
      city_state: 'Wilmington, Illinois',
      city: 'Wilmington',
      code: 'IL',
    },
    {
      city_state: 'Wilmington, Illinois',
      city: 'Wilmington',
      code: 'IL',
    },
    {
      city_state: 'Niles, Illinois',
      city: 'Niles',
      code: 'IL',
    },
    {
      city_state: 'Crest Hill, Illinois',
      city: 'Crest Hill',
      code: 'IL',
    },
  ],
  Vermont: [
    {
      city_state: 'West Berlin, Vermont',
      city: 'West Berlin',
      code: 'VT',
    },
  ],
  Oregon: [
    {
      city_state: 'Hillsboro, Oregon',
      city: 'Hillsboro',
      code: 'OR',
    },
    {
      city_state: 'Portland, Oregon',
      city: 'Portland',
      code: 'OR',
    },
    {
      city_state: 'Portland, Oregon',
      city: 'Portland',
      code: 'OR',
    },
    {
      city_state: 'Hood River, Oregon',
      city: 'Hood River',
      code: 'OR',
    },
    {
      city_state: 'Hood River, Oregon',
      city: 'Hood River',
      code: 'OR',
    },
    {
      city_state: 'Portland, Oregon',
      city: 'Portland',
      code: 'OR',
    },
  ],
  'North Dakota': [
    {
      city_state: 'Bismarck, North Dakota',
      city: 'Bismarck',
      code: 'ND',
    },
  ],
  Florida: [
    {
      city_state: 'Miami, Florida',
      city: 'Miami',
      code: 'FL',
    },
    {
      city_state: 'Winter Haven, Florida',
      city: 'Winter Haven',
      code: 'FL',
    },
    {
      city_state: 'Ocala, Florida',
      city: 'Ocala',
      code: 'FL',
    },
    {
      city_state: 'Miami, Florida',
      city: 'Miami',
      code: 'FL',
    },
    {
      city_state: 'Fort Myers, Florida',
      city: 'Fort Myers',
      code: 'FL',
    },
    {
      city_state: 'Hialeah, Florida',
      city: 'Hialeah',
      code: 'FL',
    },
    {
      city_state: 'Hurlburt Field, Florida',
      city: 'Hurlburt Field',
      code: 'FL',
    },
    {
      city_state: 'Jacksonville, Florida',
      city: 'Jacksonville',
      code: 'FL',
    },
    {
      city_state: 'Orlando, Florida',
      city: 'Orlando',
      code: 'FL',
    },
    {
      city_state: 'Palatka, Florida',
      city: 'Palatka',
      code: 'FL',
    },
    {
      city_state: 'Tupelo, Florida',
      city: 'Tupelo',
      code: 'FL',
    },
    {
      city_state: 'Auburndale, Florida',
      city: 'Auburndale',
      code: 'FL',
    },
    {
      city_state: 'Milton, Florida',
      city: 'Milton',
      code: 'FL',
    },
    {
      city_state: 'Miami, Florida',
      city: 'Miami',
      code: 'FL',
    },
    {
      city_state: 'Winter Haven, Florida',
      city: 'Winter Haven',
      code: 'FL',
    },
    {
      city_state: 'Tampa, Florida',
      city: 'Tampa',
      code: 'FL',
    },
    {
      city_state: 'Miami, Florida',
      city: 'Miami',
      code: 'FL',
    },
    {
      city_state: 'Fort Myers, Florida',
      city: 'Fort Myers',
      code: 'FL',
    },
  ],
  Ohio: [
    {
      city_state: 'Ashland, Ohio',
      city: 'Ashland',
      code: 'OH',
    },
    {
      city_state: 'Waverly, Ohio',
      city: 'Waverly',
      code: 'OH',
    },
    {
      city_state: 'Solon, Ohio',
      city: 'Solon',
      code: 'OH',
    },
    {
      city_state: 'Xenia, Ohio',
      city: 'Xenia',
      code: 'OH',
    },
    {
      city_state: 'Columbus, Ohio',
      city: 'Columbus',
      code: 'OH',
    },
    {
      city_state: 'Wapakoneta, Ohio',
      city: 'Wapakoneta',
      code: 'OH',
    },
    {
      city_state: 'Columbus, Ohio',
      city: 'Columbus',
      code: 'OH',
    },
    {
      city_state: 'Milan, Ohio',
      city: 'Milan',
      code: 'OH',
    },
    {
      city_state: 'Fairfield, Ohio',
      city: 'Fairfield',
      code: 'OH',
    },
    {
      city_state: 'Lewis Center, Ohio',
      city: 'Lewis Center',
      code: 'OH',
    },
    {
      city_state: 'Solon, Ohio',
      city: 'Solon',
      code: 'OH',
    },
    {
      city_state: 'Elyria, Ohio',
      city: 'Elyria',
      code: 'OH',
    },
    {
      city_state: 'Fairfield, Ohio',
      city: 'Fairfield',
      code: 'OH',
    },
    {
      city_state: 'Northwood, Ohio',
      city: 'Northwood',
      code: 'OH',
    },
    {
      city_state: 'Grove City, Ohio',
      city: 'Grove City',
      code: 'OH',
    },
    {
      city_state: 'Groveport, Ohio',
      city: 'Groveport',
      code: 'OH',
    },
    {
      city_state: 'Baltimore, Ohio',
      city: 'Baltimore',
      code: 'OH',
    },
  ],
  Michigan: [
    {
      city_state: 'Grand Rapids, Michigan',
      city: 'Grand Rapids',
      code: 'MI',
    },
    {
      city_state: 'Frankfort, Michigan',
      city: 'Frankfort',
      code: 'MI',
    },
    {
      city_state: 'Grand Rapids, Michigan',
      city: 'Grand Rapids',
      code: 'MI',
    },
    {
      city_state: 'Ypsilanti, Michigan',
      city: 'Ypsilanti',
      code: 'MI',
    },
  ],
  Iowa: [
    {
      city_state: 'Camanche, Iowa',
      city: 'Camanche',
      code: 'IA',
    },
    {
      city_state: 'Camanche, Iowa',
      city: 'Camanche',
      code: 'IA',
    },
    {
      city_state: 'Camanche, Iowa',
      city: 'Camanche',
      code: 'IA',
    },
    {
      city_state: 'Camanche, Iowa',
      city: 'Camanche',
      code: 'IA',
    },
    {
      city_state: 'Camanche, Iowa',
      city: 'Camanche',
      code: 'IA',
    },
    {
      city_state: 'Ottumwa, Iowa',
      city: 'Ottumwa',
      code: 'IA',
    },
    {
      city_state: 'Newton, Iowa',
      city: 'Newton',
      code: 'IA',
    },
  ],
  Massachusetts: [
    {
      city_state: 'Chelmsford, Massachusetts',
      city: 'Chelmsford',
      code: 'MA',
    },
    {
      city_state: 'Stoughton, Massachusetts',
      city: 'Stoughton',
      code: 'MA',
    },
    {
      city_state: 'Littleton, Massachusetts',
      city: 'Littleton',
      code: 'MA',
    },
    {
      city_state: 'Northampton, Massachusetts',
      city: 'Northampton',
      code: 'MA',
    },
    {
      city_state: 'Hanover, Massachusetts',
      city: 'Hanover',
      code: 'MA',
    },
    {
      city_state: 'North Billerica, Massachusetts',
      city: 'North Billerica',
      code: 'MA',
    },
    {
      city_state: 'Stoneham, Massachusetts',
      city: 'Stoneham',
      code: 'MA',
    },
    {
      city_state: 'Chelmsford, Massachusetts',
      city: 'Chelmsford',
      code: 'MA',
    },
    {
      city_state: 'Canton, Massachusetts',
      city: 'Canton',
      code: 'MA',
    },
    {
      city_state: 'Lowell, Massachusetts',
      city: 'Lowell',
      code: 'MA',
    },
    {
      city_state: 'Everett, Massachusetts',
      city: 'Everett',
      code: 'MA',
    },
  ],
  'South Carolina': [
    {
      city_state: 'Gaffney, South Carolina',
      city: 'Gaffney',
      code: 'SC',
    },
    {
      city_state: 'Richburg, South Carolina',
      city: 'Richburg',
      code: 'SC',
    },
    {
      city_state: 'Gaffney, South Carolina',
      city: 'Gaffney',
      code: 'SC',
    },
    {
      city_state: 'Gaffney, South Carolina',
      city: 'Gaffney',
      code: 'SC',
    },
    {
      city_state: 'Greenville, South Carolina',
      city: 'Greenville',
      code: 'SC',
    },
    {
      city_state: 'Hanahan, South Carolina',
      city: 'Hanahan',
      code: 'SC',
    },
    {
      city_state: 'Spartanburg, South Carolina',
      city: 'Spartanburg',
      code: 'SC',
    },
    {
      city_state: 'West Columbia, South Carolina',
      city: 'West Columbia',
      code: 'SC',
    },
    {
      city_state: 'Pageland, South Carolina',
      city: 'Pageland',
      code: 'SC',
    },
    {
      city_state: 'Prosperity, South Carolina',
      city: 'Prosperity',
      code: 'SC',
    },
    {
      city_state: 'Gaffney, South Carolina',
      city: 'Gaffney',
      code: 'SC',
    },
    {
      city_state: 'Sumter, South Carolina',
      city: 'Sumter',
      code: 'SC',
    },
    {
      city_state: 'Gaston, South Carolina',
      city: 'Gaston',
      code: 'SC',
    },
    {
      city_state: 'Greer, South Carolina',
      city: 'Greer',
      code: 'SC',
    },
    {
      city_state: 'Greer, South Carolina',
      city: 'Greer',
      code: 'SC',
    },
    {
      city_state: 'Greenville, South Carolina',
      city: 'Greenville',
      code: 'SC',
    },
    {
      city_state: 'Charleston, South Carolina',
      city: 'Charleston',
      code: 'SC',
    },
  ],
  Wisconsin: [
    {
      city_state: 'Janesville, Wisconsin',
      city: 'Janesville',
      code: 'WI',
    },
    {
      city_state: 'Watertown, Wisconsin',
      city: 'Watertown',
      code: 'WI',
    },
    {
      city_state: 'La Crosse, Wisconsin',
      city: 'La Crosse',
      code: 'WI',
    },
    {
      city_state: 'La Crosse, Wisconsin',
      city: 'La Crosse',
      code: 'WI',
    },
  ],
  'New York': [
    {
      city_state: 'Rochester, New York',
      city: 'Rochester',
      code: 'NY',
    },
    {
      city_state: 'Rochester, New York',
      city: 'Rochester',
      code: 'NY',
    },
    {
      city_state: 'Guilderland, New York',
      city: 'Guilderland',
      code: 'NY',
    },
    {
      city_state: 'Chester, New York',
      city: 'Chester',
      code: 'NY',
    },
    {
      city_state: 'Latham, New York',
      city: 'Latham',
      code: 'NY',
    },
    {
      city_state: 'Niagara Falls, New York',
      city: 'Niagara Falls',
      code: 'NY',
    },
    {
      city_state: 'Liverpool, New York',
      city: 'Liverpool',
      code: 'NY',
    },
    {
      city_state: 'Wolcott, New York',
      city: 'Wolcott',
      code: 'NY',
    },
    {
      city_state: 'Tully, New York',
      city: 'Tully',
      code: 'NY',
    },
  ],
  Kansas: [
    {
      city_state: 'Lecompton, Kansas',
      city: 'Lecompton',
      code: 'KS',
    },
  ],
  Colorado: [
    {
      city_state: 'Denver, Colorado',
      city: 'Denver',
      code: 'CO',
    },
    {
      city_state: 'Commerce City, Colorado',
      city: 'Commerce City',
      code: 'CO',
    },
  ],
  Washington: [
    {
      city_state: 'Kent, Washington',
      city: 'Kent',
      code: 'WA',
    },
    {
      city_state: 'Tacoma, Washington',
      city: 'Tacoma',
      code: 'WA',
    },
    {
      city_state: 'Kent, Washington',
      city: 'Kent',
      code: 'WA',
    },
  ],
  Minnesota: [
    {
      city_state: 'Shakopee, Minnesota',
      city: 'Shakopee',
      code: 'MN',
    },
    {
      city_state: 'St Paul, Minnesota',
      city: 'St Paul',
      code: 'MN',
    },
    {
      city_state: 'St Paul, Minnesota',
      city: 'St Paul',
      code: 'MN',
    },
    {
      city_state: 'Elk River, Minnesota',
      city: 'Elk River',
      code: 'MN',
    },
    {
      city_state: 'Cold Spring, Minnesota',
      city: 'Cold Spring',
      code: 'MN',
    },
    {
      city_state: 'St Paul, Minnesota',
      city: 'St Paul',
      code: 'MN',
    },
    {
      city_state: 'Cold Spring, Minnesota',
      city: 'Cold Spring',
      code: 'MN',
    },
  ],
  Maryland: [
    {
      city_state: 'Rosedale, Maryland',
      city: 'Rosedale',
      code: 'MD',
    },
    {
      city_state: 'California, Maryland',
      city: 'California',
      code: 'MD',
    },
    {
      city_state: 'Rosedale, Maryland',
      city: 'Rosedale',
      code: 'MD',
    },
  ],
  'West Virginia': [
    {
      city_state: 'Charleston, West Virginia',
      city: 'Charleston',
      code: 'WV',
    },
  ],
  Idaho: [
    {
      city_state: 'Meridian, Idaho',
      city: 'Meridian',
      code: 'ID',
    },
  ],
  Oklahoma: [
    {
      city_state: 'Lindsay, Oklahoma',
      city: 'Lindsay',
      code: 'OK',
    },
    {
      city_state: 'Lawton, Oklahoma',
      city: 'Lawton',
      code: 'OK',
    },
    {
      city_state: 'Norman, Oklahoma',
      city: 'Norman',
      code: 'OK',
    },
  ],
  Wyoming: [
    {
      city_state: 'Gillette, Wyoming',
      city: 'Gillette',
      code: 'WY',
    },
  ],
  Connecticut: [
    {
      city_state: 'Cheshire, Connecticut',
      city: 'Cheshire',
      code: 'CT',
    },
    {
      city_state: 'Cromwell, Connecticut',
      city: 'Cromwell',
      code: 'CT',
    },
  ],
};

export const STATE_NAMES = [
  'Georgia',
  'Mississippi',
  'Texas',
  'Louisiana',
  'New Jersey',
  'Maine',
  'Tennessee',
  'Kentucky',
  'North Carolina',
  'Virginia',
  'Arkansas',
  'Missouri',
  'Utah',
  'Alabama',
  'Indiana',
  'California',
  'Nevada',
  'Arizona',
  'Pennsylvania',
  'Illinois',
  'Vermont',
  'Oregon',
  'North Dakota',
  'Florida',
  'Ohio',
  'Michigan',
  'Iowa',
  'Massachusetts',
  'South Carolina',
  'Wisconsin',
  'New York',
  'Kansas',
  'Colorado',
  'Washington',
  'Minnesota',
  'Maryland',
  'West Virginia',
  'Idaho',
  'Oklahoma',
  'Wyoming',
  'Connecticut',
];

export const STATES = Object.freeze([
  'GA',
  'MS',
  'TX',
  'LA',
  'NJ',
  'ME',
  'TN',
  'KY',
  'NC',
  'VA',
  'AR',
  'MO',
  'UT',
  'AL',
  'IN',
  'CA',
  'NV',
  'AZ',
  'PA',
  'IL',
  'VT',
  'OR',
  'ND',
  'FL',
  'OH',
  'MI',
  'IA',
  'MA',
  'SC',
  'WI',
  'NY',
  'KS',
  'CO',
  'WA',
  'MN',
  'MD',
  'WV',
  'ID',
  'OK',
  'WY',
  'CT',
]);
