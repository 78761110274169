import { env } from '../env';

class EnvConfig {
  static getConfig() {
    return {
      apiUrl: env.REACT_APP_OPERATIONAL_API_BASE_URL,
      env: env.REACT_APP_ENV,
      clientSideId: env.REACT_APP_CLIENT_SIDE_ID,
      sdkKey: 'N/A',
      domain: 'core-ai.io',
      filesAPIInterval: 30000,
      loadAcceptanceApiInterval: 600000,
      isTestAPI: false,        
    };
  }
}
  
export default EnvConfig;
