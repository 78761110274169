import React from 'react';
import { TabContext, TabPanel } from '@mui/lab';
import { useOutletContext } from 'react-router-dom';
import DispatchingByDrivers from './drivers/DispatchingByDrivers';
import DispatchingByLoads from './loads/DispatchingByLoads';
import DispatchingContainer from './DispatchingContainer';
import { BY_DRIVER, BY_LOAD } from './helpers/constants';

const DispatchingTableView = () => {
  const {
    tabValue,
    rightDrawerOpen
  } = useOutletContext();

  return (
    <>
      <TabContext value={tabValue || BY_DRIVER}>
        <DispatchingContainer open={rightDrawerOpen}>
          <TabPanel value={BY_DRIVER} sx={{ '&.MuiTabPanel-root': { padding: 0 } }}>
            <DispatchingByDrivers />
          </TabPanel>
          <TabPanel value={BY_LOAD} sx={{ '&.MuiTabPanel-root': { padding: 0 } }}>
            <DispatchingByLoads />
          </TabPanel>
        </DispatchingContainer>
      </TabContext>
    </>
  );
};

export default DispatchingTableView;
