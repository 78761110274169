/* eslint-disable react/forbid-prop-types */
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { useAuth0 } from '@auth0/auth0-react';
import { ODButton } from '@OptimalDynamics/core-ai-common-ui';
import { Helmet } from 'react-helmet';
import draftStore from '../../utils/draftStore';

const Login = ({ errorMessages: errors, validOrg }: { errorMessages: string[], validOrg: boolean }) => {
  const [errorMessages] = useState(errors || []);
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const location = useLocation();

  const handleLoginClick = async (event: SyntheticEvent) => {
    const from = location.state?.from || '/';
    event.preventDefault();
    await loginWithRedirect({ appState: { target: from } });
  };

  useEffect(() => {
    draftStore.remove('menuOpen');
  }, []);

  if (isLoading) return null;

  if (isAuthenticated && validOrg) return <Navigate to={location?.state?.from || '/'} replace />;

  return (
    <StyledEngineProvider injectFirst>
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Execute</title>
        </Helmet>
        <CssBaseline />
        <Grid container>
          <Grid item md={4} sx={{ height: '100vh', backgroundColor: 'level1' }}>
            <AppBar position="static" sx={{ backgroundColor: 'level3' }}>
              <Toolbar style={{ paddingLeft: '56px' }}>
                <img
                  alt="logo"
                  src="assets/images/New_ODLogo_Black.png"
                  width="200px"
                  height="20px"
                />
              </Toolbar>
            </AppBar>
            <Paper
              sx={{
                alignItems: 'center',
                padding: '56px 56px 32px',
                boxShadow: 'none',
                background: 'level1'
              }}
            >
              <Typography
                component="h1"
                style={{ fontSize: '2rem', fontWeight: 600, color: 'text.primary' }}
              >
                Sign In
              </Typography>
              <form style={{ width: '100%', marginTop: '8px' }}>
                <ODButton
                  data-testid="execute-sign-in-button"
                  variant="slideout900"
                  disabled={!!errorMessages.length}
                  endIcon={<ArrowForward />}
                  sx={{ mt: 5 }}
                  onClick={handleLoginClick}
                >
                  Sign In
                </ODButton>
              </form>
              <Typography
                sx={{
                  color: 'alert.main',
                  fontWeight: 600
                }}
              >
                {errorMessages.join('. ')}
              </Typography>
            </Paper>
            <footer
              style={{
                bottom: '40px',
                padding: '40px 56px 32px',
                fontSize: '14px',
                color: 'text.primary'
              }}
            >
              <b>Warning:&nbsp;</b>
              Use of this System is Restricted to Authorized Users.
            </footer>
          </Grid>
          <Grid
            item
            md={8}
            sx={{
              backgroundImage: 'url(\'assets/images/signin-background.png\')',
              height: '100vh',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}  
          />
        </Grid>
      </>
    </StyledEngineProvider>
  );
};

export default Login;
