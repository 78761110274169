import instance from '../../utils/axios_instance';
import { checkForNewTrailerData } from '../actions/trailerAssignmentActions';
import * as types from '../constants/actionTypes';

const dispatchingWare = (store) => (next) => (action) => {
  const result = next(action);

  switch (action.type) {
    case types.TRAILER_POLLING_UPDATE:
      instance
        .get('/load-trailers/data-updated')
        .then((res) => store.dispatch(checkForNewTrailerData(res.data.result.latest_run_updated, res.data.result.task_id)));
      break;
    default:
      return result;
  }
};

export default dispatchingWare;
