import React from 'react';
import moment from 'moment-timezone';
import ConnectWithoutContactSharp from '@mui/icons-material/ConnectWithoutContactSharp';
import { DetailCardTitle, DetailCardAccordion } from '../DetailCard';
import { IconWrapper } from '../DetailCard/DetailCardAccordion';
import { mapSourceSearchToLoadOption } from '../helpers/mappingSourceToLoadObject';
import { DO_NOT_LINK, SOURCE } from '../helpers/constants';
import { DispatchingDriver, SourceSearch } from '../types';
import { LoadsSlideoutCardTimeline } from './LoadsSlideoutCardTimeline';
import { DEFAULT_TIMEZONE } from '../../../utils/datetimes';

interface SourceLoadDetailCardProps {
  searchSuggestion?: SourceSearch;
  driver: DispatchingDriver;
}

const SourceLoadDetailCard = ({ searchSuggestion, driver }: SourceLoadDetailCardProps) => {
  if (!searchSuggestion) return <></>;
  const sourceLoad = mapSourceSearchToLoadOption(searchSuggestion);

  return (
    <DetailCardTitle
      key="driver-source-request"
      id="driver-event-source-request"
      rating={undefined}
      isRelaxed={false}
      hasSourceRequest={true}
      isCurrentRow={true}
      isSource={true}
      title="Source Request Details"
      sourceHours={!!sourceLoad ? moment.tz(sourceLoad?.source_pickup, DEFAULT_TIMEZONE).diff(moment(), 'hours') : undefined}
      active={true}
      selected={true}
      location={SOURCE}
    >
      <DetailCardAccordion
        key="driver-source-accordion"
        id="driver-event-source-accordion"
        identifier={{ 
          label: 'Source Request', 
          description: '', 
          icon: <IconWrapper fullColor color="colors.neutral6"><ConnectWithoutContactSharp sx={{ color: 'text.inverse' }} /></IconWrapper> 
        }}
        type={DO_NOT_LINK}
        selected={true}
        sourceSearch={driver}
      >
        <LoadsSlideoutCardTimeline
          currentDriver={driver}
          loadOption={sourceLoad}
          selected={true}
          showAvailable={true}
        />
      </DetailCardAccordion>
    </DetailCardTitle>
  );
};

export default SourceLoadDetailCard;
