import {
  GET_CONTRACTS,
  SET_COMMITMENT_MANAGER_VIEW,
  UPDATE_CONTRACTS_VIEW_SELECTION,
  GET_SHIPPER_ACCOUNT_ID,
  GET_SHIPPER_ACCOUNT_LIST,
  GET_AGGREGATED_SHIPPER_ACCOUNT,
  GET_AGGREGATED_COMMITMENT_ACCOUNTID,
  GET_AGGREGATED_COMMITMENT_LANES,
  SET_DEFAULT_CONTRACT,
  SET_CURRENT_CONTRACT_INFO,
  SET_CURRENT_CONTRACT_COMMITMENTS,
  EDIT_ACCOUNT,
  UPDATE_COMMITMENT_SUCCESS,
  UPDATE_COMMITMENT_FAILURE,
  SHOW_CREATE_FORM,
  CREATE_COMMITMENT_FAILURE,
  CREATE_COMMITMENT_SUCCESS,
  SET_ERROR_MESSAGE,
} from '../constants/actionTypes';

const initialState = {
  contracts: [],
  commitmentManagerView: 'shipper',
  shipperAccount: [],
  shipperAccountIds: [],
  aggregatedShipperAccount: [],
  aggregatedAccountIds: null,
  aggregatedCommitmentLanes: null,
  currentEditedContractInfo: {},
  currentEditedCommitments: [],
  isCommitmentFormUpdate: false,
  formError: null,
  showCreateForm: false,
  refreshDataOnUpdate: false,
  finalCommitmentData: [],
};

const setContracts = (state, action) => ({
  ...state,
  contracts: action.contracts,
  finalCommitmentData: action.contracts,
});

const updateContractViewSelection = (state, action) => {
  const updatedContracts = state.contracts;
  updatedContracts.forEach((element) => {
    element.isSelected = false;
    if (element.id === action.contract.id) {
      element.isSelected = true;
    }
  });

  return {
    ...state,
    contracts: updatedContracts,
  };
};

const setCommitmentManagerView = (state, action) => ({
  ...state,
  commitmentManagerView: action.commitmentManagerView,
});

const setShipperAccountList = (state, action) => ({
  ...state,
  shipperAccount: action.shipperAccount,
});

const setShipperAccountId = (state, action) => ({
  ...state,
  shipperAccountIds: action.shipperAccountIds,
});

const setAggregatedShipperAccount = (state, action) => ({
  ...state,
  aggregatedShipperAccount: action.aggregatedShipperAccount,
});

const setAggregatedShipperAccountId = (state, action) => ({
  ...state,
  aggregatedAccountIds: action.aggregatedAccountIds,
});

const setAggregatedCommitmentLanes = (state, action) => ({
  ...state,
  aggregatedCommitmentLanes: action.aggregatedCommitmentLanes,
  refreshDataOnUpdate: false,
});

const setDefaultContractData = (state, _action) => ({
  ...state,
  contracts: defaultContractCreate,
  isCommitmentFormUpdate: false,
  formError: null,
});

const setCurrentContractCommitment = (state, action) => {
  const currentCommitments = [...state.currentEditedCommitments];
  const currentEditedCommitmentId = action.commitments.commitmentId;
  const isNewCommitment = currentCommitments.filter((item) => item.id === currentEditedCommitmentId).length === 0;
  let updatedCommitment = [];
  if (isNewCommitment) {
    updatedCommitment = currentCommitments;
    updatedCommitment.push(action.commitments.commitment);
  } else {
    updatedCommitment = [];
    const commitment = currentCommitments.map((element) => {
      if (element.id === currentEditedCommitmentId) {
        element = action.commitments.commitment;
      }
      return element;
    });
    updatedCommitment = commitment;
  }
  const finalCommitmentData = updateFinalCommitmentData(
    state,
    currentEditedCommitmentId,
    action.commitments.commitment
  );
  return {
    ...state,
    currentEditedCommitments: updatedCommitment,
    finalCommitmentData,
  };
};

const updateFinalCommitmentData = (state, currentCommitmentId, commitment) => {
  const tempdata = [...state.finalCommitmentData];
  const isExistingData = tempdata.filter((item) => item.id === currentCommitmentId).length > 0;
  if (isExistingData) {
    for (let i = 0; i < tempdata.length; i++) {
      const item = tempdata[i];
      if (item.id === currentCommitmentId) {
        tempdata[i] = commitment;
      }
    }
  } else {
    tempdata.push(commitment);
  }
  return tempdata;
};

const setCurrentContractInfo = (state, action) => ({
  ...state,
  currentEditedContractInfo: action.contractInfo,
});

const editAccount = (state, action) => {
  const editableContracts = state.contracts.map((item) => {
    item.isReadOnly = action.isEdit;
    return item;
  });
  return {
    ...state,
    contracts: editableContracts,
    isCommitmentFormUpdate: true,
  };
};

const onUpdateCommitmentSuccess = (state, _action) => ({
  ...state,
  isCommitmentFormUpdate: false,
  updateError: null,
  showCreateForm: false,
  refreshDataOnUpdate: true,
  currentEditedCommitments: [],
  currentEditedContractInfo: {},
  finalCommitmentData: [],
});

const onUpdateCommitmentFailure = (state, action) => ({
  ...state,
  isCommitmentFormUpdate: true,
  formError: action.upddateError,
  currentEditedCommitments: [],
});

const showCreateForm = (state, action) => ({
  ...state,
  showCreateForm: action.show,
  currentEditedCommitments: [],
  finalCommitmentData: [],
  currentEditedContractInfo: {},
});

const onCreateCommitmentFailure = (state, action) => ({
  ...state,
  formError: action.error,
  currentEditedCommitments: [],
});

const onCreateCommitmentSuccess = (state, _action) => ({
  ...state,
  formError: null,
  showCreateForm: false,
  currentEditedCommitments: [],
  finalCommitmentData: [],
  currentEditedContractInfo: {},
});

const setErrorMsg = (state, action) => ({
  ...state,
  formError: action.errorMsg,
  currentEditedCommitments: [],
});

const contractReducer = (state = initialState, action = null) => {
  if (!action) return state;
  switch (action.type) {
    case GET_CONTRACTS:
      return setContracts(state, action);
    case UPDATE_CONTRACTS_VIEW_SELECTION:
      return updateContractViewSelection(state, action);
    case SET_COMMITMENT_MANAGER_VIEW:
      return setCommitmentManagerView(state, action);
    case GET_SHIPPER_ACCOUNT_LIST:
      return setShipperAccountList(state, action);
    case GET_SHIPPER_ACCOUNT_ID:
      return setShipperAccountId(state, action);
    case GET_AGGREGATED_SHIPPER_ACCOUNT:
      return setAggregatedShipperAccount(state, action);
    case GET_AGGREGATED_COMMITMENT_ACCOUNTID:
      return setAggregatedShipperAccountId(state, action);
    case GET_AGGREGATED_COMMITMENT_LANES:
      return setAggregatedCommitmentLanes(state, action);
    case SET_DEFAULT_CONTRACT:
      return setDefaultContractData(state, action);
    case SET_CURRENT_CONTRACT_INFO:
      return setCurrentContractInfo(state, action);
    case SET_CURRENT_CONTRACT_COMMITMENTS:
      return setCurrentContractCommitment(state, action);
    case EDIT_ACCOUNT:
      return editAccount(state, action);
    case UPDATE_COMMITMENT_SUCCESS:
      return onUpdateCommitmentSuccess(state, action);
    case UPDATE_COMMITMENT_FAILURE:
      return onUpdateCommitmentFailure(state, action);
    case SHOW_CREATE_FORM:
      return showCreateForm(state, action);
    case CREATE_COMMITMENT_FAILURE:
      return onCreateCommitmentFailure(state, action);
    case CREATE_COMMITMENT_SUCCESS:
      return onCreateCommitmentSuccess(state, action);
    case SET_ERROR_MESSAGE:
      return setErrorMsg(state, action);
    default:
      return state;
  }
};

export default contractReducer;

const defaultContractCreate = [
  {
    id: Math.random(),
    lanes: [
      {
        laneId: 0,
      },
    ],
    account_commitment_day_volume: [],
    account_id: {
      id: 0,
      shipper_account: {},
      account_name: '',
    },
    commitment_start_date: null,
    commitment_end_date: null,
    rate: null,
    rate_type: null,
    can_use_brokerage: null,
    time_span: 'daily',
    time_span_numeric: null,
    notes: null,
    volume: 0,
    isSelected: true,
    reset_detail: 1,
    reset_detail_choice: null,
  },
];
