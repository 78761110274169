import React, { useState } from 'react';
import { Autocomplete, InputLabel, TextField, useTheme } from '@mui/material';
import CloseSharp from '@mui/icons-material/CloseSharp';
import LocationOnSharp from '@mui/icons-material/LocationOnSharp';
import SearchSharp from '@mui/icons-material/SearchSharp';
import { ODCheckbox } from '@OptimalDynamics/core-ai-common-ui';

const SearchAutoComplete = (props) => {
  const {
    options = [],
    startAdornment = null,
    AutoCompleteProps = {},
    onInputChange,
    fmtRenderOptionsTxt,
    groupBy,
    inputValue,
    searchDimension,
    selectedLocations,
    setSelectedLocations,
    handleSearchChange,
    label,
    getOptionLabel,
    inputSelectorId,
    multiple = true
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const renderOptions = (renderProps, option, { selected }) => (
    <li
      key={option.id}
      style={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        flexWrap: 'nowrap',
        fontSize: '14px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        columnGap: '16px',
        backgroundColor: selected ? theme.palette.level3 : ''
      }}
      {...renderProps}
    >
      {multiple && <ODCheckbox checked={selected} />}
      {fmtRenderOptionsTxt(searchDimension, option)}
    </li>
  );

  return (
    <div>
      <InputLabel
        sx={{
          fontSize: '12px',
          lineHeight: '18px',
          color: 'text.unselected',
          pb: '4px'
        }}
      >
        {label}
      </InputLabel>
      <Autocomplete 
        {...AutoCompleteProps}
        filterOptions={(x) => x}
        open={isOpen}
        options={options}
        onChange={handleSearchChange}
        onInputChange={onInputChange}
        inputValue={inputValue}
        openOnFocus
        getOptionLabel={getOptionLabel}
        autoComplete
        multiple={multiple}
        disablePortal={true}
        value={selectedLocations}
        renderOption={renderOptions}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        disableCloseOnSelect
        noOptionsText={inputValue.length ? 'No options' : 'Type to view options'}
        isOptionEqualToValue={(option, selectedValue) => option[searchDimension] === selectedValue[searchDimension]}
        sx={{
          '.MuiPaper-root': { margin: 0 },
          '.MuiListbox-root': { padding: 0 }
        }}
        groupBy={groupBy}
        id={inputSelectorId}
        renderInput={(params) => {
          let displayValue = 'Search';
          let color = 'text.unselected';
          if (selectedLocations.length) {
            displayValue = `(${selectedLocations.length}) Location${selectedLocations.length === 1 ? '' : 's'} Selected`;
            color = 'text.primary';
          }

          return (
            <TextField
              {...params}
              variant="standard"
              id="driver-rule-location-search"
              sx={{ backgroundColor: isOpen ? 'level2' : 'level1' }}
              placeholder={displayValue}
              InputLabelProps={{ sx: { '&.Mui-focused': { color: 'text.disabled' } } }}
              InputProps={{
                ...params.InputProps,
                sx: {
                  input: {
                    fontSize: '14px',
                    '::placeholder': { color, opacity: 1 }
                  },
                  '&.MuiInput-root': { pl: startAdornment === false && '16px', pr: '16px !important' }
                },
                startAdornment: startAdornment === false ? null : startAdornment || <LocationOnSharp style={{ padding: '16px' }} />,
                endAdornment: (
                  <>
                    {selectedLocations.length > 0 && (
                      <CloseSharp
                        sx={{
                          color: isOpen || selectedLocations.length > 0 ? 'text.primary' : 'text.unselected',
                          cursor: 'pointer',
                          mr: '16px'
                        }}
                        aria-label="clear-all-location-selections"
                        id="clear-all-driver-rule-location-selections"
                        onClick={() => setSelectedLocations([])}
                      />
                    )}
                    <SearchSharp
                      sx={{
                        color: isOpen || selectedLocations.length > 0 ? 'text.primary' : 'text.unselected',
                        cursor: 'pointer',
                        padding: 0,
                        
                      }}
                    />
                  </>
                )
              }}
            />
          );
        }}
      />
    </div>
  );
};

export default SearchAutoComplete;
