import React from 'react';
import moment from 'moment-timezone';
import { startCase } from 'lodash';
import { Autocomplete, Box, FormControl, FormHelperText, MenuItem, Select, TextField, ListItem, useTheme, Popper, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CalendarTodaySharp from '@mui/icons-material/CalendarTodaySharp';
import ChevronLeftSharp from '@mui/icons-material/ChevronLeftSharp';
import ChevronRightSharp from '@mui/icons-material/ChevronRightSharp';
import ExpandMoreSharp from '@mui/icons-material/ExpandMoreSharp';
import { ODCheckbox } from '@OptimalDynamics/core-ai-common-ui';
import { SHORT_DATE, SHORT_DATE_TIME } from '../utils/datetimes';
import { PopperSlotProps as DateTimePopperProps } from './ODDatePicker';

export const SELECT = 'select';
export const SEARCHABLE_MULTISELECT = 'searchableMultiselect';
export const NUMBER_RANGE = 'numberRange';
export const DATE_RANGE = 'dateRange';

const numRegex = /^[+-]?(?:\d+\.?\d*|\.\d+)(?:[eE][+-]?\d+)?$/;

/**
 * @typedef {{ allowMinEmpty: boolean; allowMaxEmpty: boolean; minFilterValue: number; maxFilterValue: number; setMinFilter: ((v: number) => void); setMaxFilter: ((v: number) => void); }} NumberRangeHeader
 * @typedef {{ startCalendarOpen: boolean; setStartCalendarOpen: (v: boolean) => void; endCalendarOpen: boolean; setEndCalendarOpen: (v: boolean) => void; startFilterValue: any; setStartFilterValue: ((value: any) => void) | undefined; endFilterValue: any; setEndFilterValue: ((value: any) => void); startHelperText: string; endHelperText: string; }} DateRangeHeader
 * @typedef {{ currentValue: any; onChange: ((event: ChangeEvent<HTMLInputElement> | null | undefined, value: string[] | number) => void) | ((value: string[] | number) => void); }} SharedSelectHeader
 * @typedef {{ onBlur: () => void }} MultiSelectHeader
 * @typedef {{ type: string; disabled: boolean; sortKey: string; filterOptionsKey: string; filtersDisabled: boolean; }} BaseHeader
 * @typedef {Record<string, any>} FilterValues
 * @typedef {Record<string, any>} FilterChoices
 * @typedef {Partial<BaseHeader & DateRangeHeader & MultiSelectHeader & SharedSelectHeader & NumberRangeHeader>} Header
 */

/** @type {(args: { header: Header; filterValues: FilterValues; searchTerm?: any; filterChoices?: FilterChoices; }) => React.JSX.Element} */
export const getHeaderFilter = ({ header, filterValues, searchTerm = null, filterChoices = null }) => {
  switch (header.type) {
    case SELECT:
      const disabled = header.disabled || (header.sortKey === 'tms_assignment' && !searchTerm);
      return (
        <FormControl size="small">
          <Select
            sx={{
              border: 1,
              borderColor: 'level3',
              height: 24,
              fontSize: 10,
              borderRadius: 0,
              '& .MuiSelect-icon': { mr: '0', top: 'auto', color: 'text.secondary' },
              '& .MuiSelect-select': {
                color: !!header.currentValue ? 'text.primary' : 'text.secondary',
              },
              '&.Mui-disabled': { backgroundColor: 'level3', color: 'text.disabled' },
            }}
            inputProps={{ 'aria-label': 'Search' }}
            variant="outlined"
            menuOptions={filterValues?.[header.filterOptionsKey]}
            onChange={(e) => header.onChange(e.target.value)}
            value={header.currentValue}
            disabled={disabled}
            displayEmpty
            IconComponent={disabled ? null : ExpandMoreSharp}
            renderValue={(selected) => startCase(selected) || `Options (${filterValues[header.filterOptionsKey]?.length || 0})`}
            MenuProps={{
              elevation: 0,
              MenuListProps: { disablePadding: true },
              sx: {
                '& .MuiMenu-paper': {
                  backgroundColor: 'level2',
                  borderRadius: 0,
                  '& .MuiButtonBase-root': {
                    fontSize: 10,
                    height: 24,
                    backgroundColor: 'level2',
                    '&:hover': { backgroundColor: 'hover' },
                    '&.Mui-selected': {
                      backgroundColor: 'colors.lightBlue1',
                      '&:hover': { backgroundColor: 'colors.lightBlue2' },
                    }
                  },
                },
              },
            }}
          >
            {filterValues?.[header.filterOptionsKey]?.map((item) => (
              <MenuItem key={item} value={item}>{startCase(item)}</MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ ml: 0, fontSize: 8, mr: 0, display: 'flex', justifyContent: 'space-between' }}>
            <Box>Search</Box>
            <Box
              sx={{ display: !header.currentValue ? 'none' : 'inherit', cursor: 'pointer', color: 'optimalblue.main' }}
              onClick={() => header.onChange('')}
            >
              Clear
            </Box>
          </FormHelperText>
        </FormControl>
      );
    case DATE_RANGE:
      return (
        <FormControl
          size="small"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: 0.25,
            '.MuiPickersPopper-root > .MuiPaper-root': {
              minHeight: 'unset',
              minWidth: 'unset',
              height: 'unset',
              width: 'unset'
            }
          }}
        >
          <ODDateTimePicker
            open={header.startCalendarOpen}
            setOpen={header.setStartCalendarOpen}
            value={header.startFilterValue}
            setValue={header.setStartFilterValue}
            helperText={header.startHelperText || 'Min'}
            maximum={header.endFilterValue}
            isTableHeader
            disabled={header.filtersDisabled}
          />
          <ODDateTimePicker
            open={header.endCalendarOpen}
            setOpen={header.setEndCalendarOpen}
            value={header.endFilterValue}
            setValue={header.setEndFilterValue}
            minimum={header.startFilterValue}
            helperText={header.endHelperText || 'Max'}
            isTableHeader
            disabled={header.filtersDisabled}
          />
        </FormControl>
      );
    case NUMBER_RANGE:
      return <ODNumberRange header={header} />;
    case SEARCHABLE_MULTISELECT:
      return <SearchableMultiSelect header={header} filterValues={filterValues} filterChoices={filterChoices} />;
    default:
      return null;
  }
};

/** @type {(args: { header: Header; filterValues: FilterValues; filterChoices: FilterChoices; }) => React.JSX.Element} */
const SearchableMultiSelect = ({ header, filterValues, filterChoices }) => {
  const [inputValue, setInputValue] = React.useState('');
  const [clearCount, setClearCount] = React.useState(0);
  const isValidValue = header.isValidValue ?? ((_v) => true);
  const theme = useTheme();
  // eslint-disable-next-line
  const PopperOverride = (props) => (
    <Popper
      {...props}
      style={{ width: 'fit-content' }}
      placement="bottom-start" 
    />
  );

  const handleInputChange = (event, value) => {
    if (!isValidValue(value)) {
      setInputValue('');
    } else setInputValue(value);
  };

  return (
    <FormControl size="small" sx={{ width: 'max-content' }}>
      <Autocomplete
        key={`${clearCount}`}
        PopperComponent={PopperOverride}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        size="small"
        ListboxProps={{ sx: {
          fontSize: 12,
          minWidth: '250px !important',
          maxHeight: '240px !important',
          margin: '0px !important',
          padding: '0px !important',
        } }}
        sx={{
          border: 1,
          borderColor: 'level3',
          ...(header.filtersDisabled ? {
            backgroundColor: 'level3',
          } : {}),
          height: 22,
          fontSize: 10,
          width: 100,
          marginBottom: (filterChoices?.[header.filterOptionsKey] || []).length === 0 ? '16px' : '0px',
          '& .MuiOutlinedInput-root': {
            paddingTop: '0px !important',
            paddingLeft: '0px !important',
          },
          '& .MuiButtonBase-root': {
            marginRight: '0px !important',
            marginBottom: '10px !important',
            paddingLeft: '4px !important',
            color: `${theme.palette.text.secondary} !important`,
          },
          '& .MuiInputBase-input': {
            color: !!header.currentValue ? 'text.primary' : 'text.secondary',
            fontSize: '10px',
            padding: 0,
            margin: 0,
          },
        }}
        componentsProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [-10, -10]
                }
              }
            ]
          }
        }}
        options={filterValues?.[header.filterOptionsKey] || []}
        getOptionLabel={(option) => option}
        defaultValue={filterChoices?.[header.filterOptionsKey] || []}
        onChange={(event, value) => header.onChange(event, value)}
        onBlur={(event) => header.onBlur?.(event)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search"
            sx={{ height: 30 }}
            size="small"
          />
        )}
        multiple
        disableCloseOnSelect
        disabled={header.filtersDisabled}
        renderTags={(tags) => tags.filter((tag) => !!tag).length > 0 ? '*' : ''}
        renderOption={(props, option, _metadata) => (
          <ListItem {...props}>
            <ODCheckbox
              style={{ marginRight: 8 }}
              checked={(filterChoices?.[header.filterOptionsKey] || []).find((el) => el === props.key)}
            />
            {option}
          </ListItem>
        )}
      />
      <FormHelperText sx={{ ml: 0, fontSize: 8, mr: 0, display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          sx={{ display: (filterChoices?.[header.filterOptionsKey] || []).length === 0 ? 'none' : 'inherit', cursor: 'pointer', color: 'optimalblue.main', marginLeft: 'auto' }}
          onClick={() => {
            header.onChange(null, []);
            setClearCount((cc) => cc + 1);
          }}
        >
          Clear
        </Typography>
      </FormHelperText>
    </FormControl>
  );
};

const ODDateTimePicker = ({ open, value, setOpen, setValue, helperText, minimum, maximum, disabled }) => (
  <Box>
    <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
      <DateTimePicker
        disabled={disabled}
        open={open}
        onClose={() => setOpen(false)}
        value={value}
        timezone="system"
        onAccept={setValue}
        format={`${SHORT_DATE_TIME} [${moment().tz(moment.tz.guess()).format('z')}]`}
        ampm={false}
        minDate={minimum}
        maxDate={maximum}
        slotProps={{
          inputAdornment: { sx: { display: 'none' } },
          desktopPaper: {
            square: true,
            variant: 'outlined'
          },
          popper: DateTimePopperProps,
          textField: {
            variant: 'standard',
            sx: {
              '& .MuiInputBase-root': {
                fontSize: 10,
                px: 1,
                border: 1,
                borderColor: 'level3',
                ...(disabled ? { backgroundColor: 'level3', } : {}),
                '&.MuiInputBase-input': {
                  color: !!value ? 'text.primary' : 'text.secondary',
                }
              },
            },
            inputProps: { placeholder: `${SHORT_DATE} HH:MM ${moment().tz(moment.tz.guess()).format('z')}` },
            onClick: () => setOpen(true),
            focused: open
          }
        }}
        slots={{
          openPickerIcon: CalendarTodaySharp,
          leftArrowIcon: ChevronLeftSharp,
          rightArrowIcon: ChevronRightSharp,
          switchViewIcon: ExpandMoreSharp
        }}
      />
    </LocalizationProvider>
    <FormHelperText sx={{ ml: 0, fontSize: 8, mr: 0, display: 'flex', justifyContent: 'space-between' }}>
      <span>{helperText}</span>
      <Typography
        sx={{ display: !value ? 'none' : 'inherit', cursor: 'pointer', color: 'optimalblue.main', fontSize: 8 }}
        onClick={() => setValue(null)}
      >
        Clear
      </Typography>
    </FormHelperText>
  </Box>
);

/** @type {(args: { header: Header; }) => React.JSX.Element} */
const ODNumberRange = ({ header }) => {
  const isMinEmpty = !!header.allowMinEmpty && !header.minFilterValue;
  const isMaxEmpty = !!header.allowMaxEmpty && !header.maxFilterValue;
  const isValidValue = (v) => Boolean(v.match(/^[-+]?[0-9]*\.?[0-9]*$/g));
  return (
    <FormControl
      size="small"
      sx={{ display: 'flex',
        flexDirection: 'row',
        columnGap: 0.25,
        '.MuiPickersPopper-root > .MuiPaper-root': {
          minHeight: 'unset',
          minWidth: 'unset',
          height: 'unset',
          width: 'unset'
        } }}
    >
      <Box>
        <TextField
          value={header.minFilterValue ?? ''}
          onChange={(e) => {
            const value = e.target.value;
            if (!isValidValue(value)) return;
            header.setMinFilter(value);
          }}
          variant="outlined"
          error={!isMinEmpty && !(numRegex).test(header.minFilterValue)}
          sx={{
            '& .Mui-error': { border: 1, borderColor: 'alert.main' },
            '& .MuiInputBase-root': {
              height: 24,
              width: 72,
              fontSize: 10,
              '& input': { px: 1, color: 'text.disabled' },
              borderRadius: 'inherit'
            },
            border: 1,
            borderColor: 'level3',
          }}
        />
        <FormHelperText sx={{ ml: 0, fontSize: 8, mr: 0, display: 'flex', justifyContent: 'space-between' }}>
          <span>{isMinEmpty || (numRegex).test(header.minFilterValue) ? 'Min' : 'Numbers Only'}</span>
          <Typography
            sx={{ display: !header.minFilterValue ? 'none' : 'inherit', cursor: 'pointer', color: 'optimalblue.main' }}
            onClick={() => header.setMinFilter('')}
          >
            Clear
          </Typography>
        </FormHelperText>
      </Box>
      <Box>
        <TextField
          value={header.maxFilterValue ?? ''}
          onChange={(e) => {
            const value = e.target.value;
            if (!isValidValue(value)) return;
            header.setMaxFilter(value);
          }}
          variant="outlined"
          error={!isMaxEmpty && !(numRegex).test(header.maxFilterValue)}
          sx={{
            '& .Mui-error': {
              border: 1,
              borderColor: 'alert.main' },
            '& .MuiInputBase-root': {
              height: 24,
              width: 72,
              fontSize: 10,
              '& input': { px: 1, color: 'text.disabled' },
              borderRadius: 'inherit' 
            },
            border: 1,
            borderColor: 'level3',
          }}
        />
        <FormHelperText sx={{ ml: 0, fontSize: 8, mr: 0, display: 'flex', justifyContent: 'space-between' }}>
          <span>{isMaxEmpty || (numRegex).test(header.maxFilterValue) ? 'Max' : 'Numbers Only'}</span>
          <Typography
            sx={{ display: !header.maxFilterValue ? 'none' : 'inherit', cursor: 'pointer', color: 'optimalblue.main' }}
            onClick={() => {
              header.setMaxFilter('');
            }}
          >
            Clear
          </Typography>
        </FormHelperText>
      </Box>
    </FormControl>
  );
};
