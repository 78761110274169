/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect } from 'react';
import { ODTablePagination } from '@OptimalDynamics/core-ai-common-ui/';
import { Grid, Typography, Link, styled, Box } from '@mui/material';
import Loop from '@mui/icons-material/Loop';
import PublishedWithChangesSharp from '@mui/icons-material/PublishedWithChangesSharp';
import SyncProblemSharp from '@mui/icons-material/SyncProblemSharp';
import { useDispatch, useSelector } from 'react-redux';
import { STOP_POLLING_DISPATCHING, STOP_POLLING_LOAD_ACCEPTANCE, STOP_POLLING_TRAILER } from '../store/constants/actionTypes';
import { getLocalizedDataUpdateDatetime } from '../utils/datetimes';
import { CopyClick } from './CopyClick';

const FooterContainer = styled('div', {
  shouldForwardProp: (props) => props,
  name: 'Footer Container'
})(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  '&.version-0': {
    justifyContent: 'flex-end',
  }
}));

const DetailedTableFooter = ({ 
  count = 0,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  idPrefix,
  lastSynced = '',
  taskId = null,
  onLastSyncedClick = null,
  paginationSx = {},
  updatingText = 'Optimizing Matches',
  refreshError = false, 
}) => {
  const dispatch = useDispatch();
  const isPollingDataUpdating = useSelector((state) => state.dispatchingReducer?.isPollingDataUpdating);
  const isTrailerDataUpdating = useSelector((state) => state.trailerAssignmentReducer?.isPollingDataUpdating);
  const isLoadAcceptancePollingDataUpdating = useSelector((state) => state.loads?.isPollingDataUpdating);
  const isEngineRunUpdating = useSelector((state) => state.notificationReducer?.isEngineRunUpdating);
  const isUpdating = isPollingDataUpdating || isEngineRunUpdating || isLoadAcceptancePollingDataUpdating || isTrailerDataUpdating;
  const STOP_POLLING_TIMEOUT = 10000; // enforce 10 sec timeout
  let dispatchTimeout; 
  let laTimeout;
  let trailerTimeout;

  useLayoutEffect(() => {
    if (isPollingDataUpdating) {
      dispatchTimeout = setTimeout(() => {
        dispatch({ type: STOP_POLLING_DISPATCHING });
      }, STOP_POLLING_TIMEOUT);
    }
    return () => clearTimeout(dispatchTimeout); 
  }, [isPollingDataUpdating]);

  useLayoutEffect(() => {
    if (isTrailerDataUpdating) {
      trailerTimeout = setTimeout(() => {
        dispatch({ type: STOP_POLLING_TRAILER });
      }, STOP_POLLING_TIMEOUT);
    }
    return () => clearTimeout(trailerTimeout); 
  }, [isTrailerDataUpdating]);

  useLayoutEffect(() => {
    if (isLoadAcceptancePollingDataUpdating) {
      laTimeout = setTimeout(() => {
        dispatch({ type: STOP_POLLING_LOAD_ACCEPTANCE });
      }, STOP_POLLING_TIMEOUT);
    }
    return () => clearTimeout(laTimeout); 
  }, [isLoadAcceptancePollingDataUpdating]);

  return (
    <FooterContainer className={!lastSynced && 'version-0'}>
      <Grid
        item
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          textAlign: 'right',
          p: 0,
          height: 40,
          display: 'flex',
          overflow: 'hidden'
        }}
      >
        {isUpdating && (
        <>
          <Loop
            sx={{
              animation: 'spin 2s linear infinite',
              '@keyframes spin': {
                '0%': {
                  transform: 'rotate(360deg)',
                },
                '100%': {
                  transform: 'rotate(0deg)',
                },
              },
              width: 16,
              height: 16,
              color: 'optimalblue.main',
              transformOrigin: 'center'
            }}
          />
          <Typography sx={{ pl: 2 }}>
            {updatingText}
          </Typography>
        </>
        )}
        {!!lastSynced && !isUpdating && (
          <>
            {refreshError ? (
              <SyncProblemSharp sx={{ color: 'alert.main' }} /> 
            ) : (
              <PublishedWithChangesSharp sx={{ color: 'success.main' }} />
            )}
            <Box sx={{ pl: 2, display: 'flex', flexDirection: 'column' }}>
              <Link
                onClick={onLastSyncedClick}
                component="button"
                variant={!onLastSyncedClick ? 'disabled' : 'secondary'}
                data-testid="data-updated-timestamp"
                sx={{ textAlign: 'left' }}
              >
                {`Updated: ${getLocalizedDataUpdateDatetime(lastSynced)}`}
              </Link>
              {!!taskId && (
                <CopyClick copyText={taskId}>
                  <Typography>{`Task ID: ${taskId}`}</Typography>
                </CopyClick>
              )}
            </Box>
          </>
        )}
      </Grid>
      <ODTablePagination
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        selectId={`${idPrefix}-page-select`}
        slotProps={{
          actions: {
            nextButton: { id: `${idPrefix}-page-next` },
            previousButton: { id: `${idPrefix}-page-previous` }
          }
        }}
        sx={{ overflow: 'hidden', ...paginationSx }}
      />
    </FooterContainer>
  );
};

export default DetailedTableFooter;
