/* eslint-disable arrow-body-style */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useReducer, useMemo } from 'react';
import instance from '../axios_instance';
import { objToQueryParams } from '../helpers';
import { CITY_STATES, STATES } from '../mocks';

const LANE_DETAIL_REGION_ACTIONS = Object.freeze({
  SET_STEPPER_REGION_LOD: 'SET_STEPPER_REGION_LOD',
  SET_STEPPER_REGIONS_LIST: 'SET_STEPPER_REGIONS_LIST',
  SET_STEPPER_REGION_INDEX: 'SET_STEPPER_REGION_INDEX',
  SET_CURRENT_REGIONS_LIST: 'SET_CURRENT_REGIONS_LIST',
  CLEAR_CURRENT_REGIONS_LIST: 'CLEAR_CURRENT_REGIONS_LIST',
  SET_CURRENT_REGION: 'SET_CURRENT_REGION',
  SET_CURRENT_REGIONS_INDEX: 'SET_CURRENT_REGIONS_INDEX',
  SET_CURRENT_REGION_OUTBOUND: 'SET_CURRENT_REGION_OUTBOUND',
  SET_CURRENT_REGION_INBOUND: 'SET_CURRENT_REGION_INBOUND',
  CLEAR_CURRENT_REGION_OUTBOUND: 'CLEAR_CURRENT_REGION_OUTBOUND',
  CLEAR_CURRENT_REGION_INBOUND: 'CLEAR_CURRENT_REGION_INBOUND',
});

/**
 * @constant ['city-state, 'state', 'zipcode']
 */
export const REGION_LOD_TYPES = Object.freeze(['city-state', 'state', 'zipcode']);

const {
  SET_STEPPER_REGION_LOD,
  SET_STEPPER_REGIONS_LIST,
  SET_STEPPER_REGION_INDEX,
  SET_CURRENT_REGIONS_LIST,
  CLEAR_CURRENT_REGIONS_LIST,
  SET_CURRENT_REGION,
  SET_CURRENT_REGIONS_INDEX,
  SET_CURRENT_REGION_OUTBOUND,
  CLEAR_CURRENT_REGION_OUTBOUND,
  SET_CURRENT_REGION_INBOUND,
  CLEAR_CURRENT_REGION_INBOUND,
} = LANE_DETAIL_REGION_ACTIONS;

const initialState = {
  stepRegionLoD: REGION_LOD_TYPES[1],
  stepRegionsList: null,
  stepRegionIndex: 0,
  currentRegionsIndex: 0, // when on state view (multiple city-state accordions), this determines the first/initial city-state accordion to open
  currentRegionsList: null, // " " "-", this is the current list of city-states reflected in the accordions seen on screen in slideout
  currentRegion: null, // only used for solo city-state view
  currentRegionInbound: [],
  currentRegionOutbound: [],
  isMocking: false,
};

const getInitialState = ({ 
  isMocking, 
  stepRegionLoD, 
  currentRegionsList, 
  currentRegionsIndex, 
  stepRegionsList, 
  stepRegionIndex, 
  currentRegionInbound,
  currentRegionOutbound,
  currentRegion }) => {
  if (!isMocking) {
    return {
      stepRegionLoD,
      stepRegionsList,
      stepRegionIndex,
      currentRegionsIndex,
      currentRegionsList,
      currentRegion,
      currentRegionInbound,
      currentRegionOutbound,
      isMocking
    };
  } 

  const stateLocation = STATES[stepRegionIndex];
  
  return {
    stepRegionLoD,
    stepRegionsList: stepRegionLoD === REGION_LOD_TYPES[0] 
      ? CITY_STATES[stateLocation] 
      : STATES.map((stateItem) => ({ state: stateItem, city: null })),
    stepRegionIndex,
    currentRegionsIndex,
    currentRegionsList: stepRegionLoD === REGION_LOD_TYPES[0] ? null : CITY_STATES[stateLocation],
    currentRegion: stepRegionLoD === REGION_LOD_TYPES[0] ? CITY_STATES[stateLocation][0] : '',
    isMocking
  };
};

const setCurrentRegionOutbound = (state, payload) => (
  {
    ...state, 
    currentRegionOutbound: [...state.currentRegionOutbound, ...payload]
  }
);

const setCurrentRegionInbound = (state, payload) => (
  {
    ...state, 
    currentRegionInbound: [...state.currentRegionInbound, ...payload]
  }
);

const clearCurrentRegionOutbound = (state) => (
  {
    ...state, 
    currentRegionOutbound: []
  }
);

const clearCurrentRegionInbound = (state) => (
  {
    ...state, 
    currentRegionInbound: []
  }
);

const clearCurrentRegionsList = (state) => (
  {
    ...state,
    currentRegionsList: null
  }
);

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_STEPPER_REGION_LOD:
      return { ...state, stepRegionLoD: payload };

    case SET_STEPPER_REGIONS_LIST:
      return { ...state, stepRegionsList: payload };

    case SET_STEPPER_REGION_INDEX:
      return { ...state, stepRegionIndex: payload };

    case SET_CURRENT_REGIONS_INDEX:
      return { ...state, currentRegionsIndex: payload };

    case SET_CURRENT_REGIONS_LIST:
      return { ...state, currentRegionsList: payload };

    case CLEAR_CURRENT_REGIONS_LIST:
      return clearCurrentRegionsList(state);

    case SET_CURRENT_REGION:
      return { ...state, currentRegion: payload };

    case SET_CURRENT_REGION_OUTBOUND:
      return setCurrentRegionOutbound(state, payload);

    case CLEAR_CURRENT_REGION_OUTBOUND:
      return clearCurrentRegionOutbound(state);

    case CLEAR_CURRENT_REGION_INBOUND:
      return clearCurrentRegionInbound(state);

    case SET_CURRENT_REGION_INBOUND:
      return setCurrentRegionInbound(state, payload);

    default:
      return state;
  }
};

export const LaneDetailRegionCtx = createContext({
  laneDetailRegionState: {},
  setLaneDetailRegionState: {},
});

export const LaneDetailRegionCtxWrapper = (props) => {
  const myInitialState = getInitialState(initialState);
  const [state, dispatch] = useReducer(reducer, myInitialState);

  const stepToNextRegion = () => {
    const { stepRegionIndex, stepRegionsList } = state;

    if (stepRegionsList.length - 1 > stepRegionIndex) {
      dispatch({ type: SET_CURRENT_REGION, payload: stepRegionsList[stepRegionIndex + 1] });
      dispatch({ type: SET_STEPPER_REGION_INDEX, payload: stepRegionIndex + 1 });
    }
  };

  const stepToPreviousRegion = () => {
    const { stepRegionIndex, stepRegionsList } = state;
    if (stepRegionIndex - 1 > -1) {
      dispatch({ type: SET_CURRENT_REGION, payload: stepRegionsList[stepRegionIndex - 1] });
      dispatch({ type: SET_STEPPER_REGION_INDEX, payload: stepRegionIndex - 1 });
    }
  };

  const setStepRegionsList = (payload) => {
    dispatch({ type: SET_STEPPER_REGIONS_LIST, payload });
  };

  const setCurrentRegionsListByFilter = async (filterParams) => {
    const { dateFilter: search_date, region, searchBy: search_by } = filterParams;
    const queryParams = objToQueryParams({ search_date, region, search_by });
    await instance
      .get(`/child_tactical_procurement/${queryParams}`)
      .then((res) => {
        const payload = Object.values(res.data.result).map(((datum, _idx) => {
          // !_idx && console.dir(datum);
          const { location } = datum;
          const locales = location.split(', ');

          return { ...datum, city: locales[0], state: locales[1] };
        }));
        // console.dir(payload);
        dispatch({ type: SET_CURRENT_REGIONS_LIST, payload });
      })
      .catch((err) => console.error(err));
  };

  const setLaneDetailRegionState = {
    stepToNextRegion,
    stepToPreviousRegion,
    setStepRegionToStates: () => dispatch({ type: SET_STEPPER_REGION_LOD, payload: REGION_LOD_TYPES[1] }),
    setStepRegionToCityStates: () => dispatch({ type: SET_STEPPER_REGION_LOD, payload: REGION_LOD_TYPES[0] }),
    setStepRegionToZipCode: () => dispatch({ type: SET_STEPPER_REGION_LOD, payload: REGION_LOD_TYPES[2] }),
    setStepRegionsList: (payload) => setStepRegionsList(payload),
    setStepRegionIndex: (payload) => dispatch({ type: SET_STEPPER_REGION_INDEX, payload }),
    setCurrentRegionsListByFilter: (filterParams) => setCurrentRegionsListByFilter(filterParams),
    setCurrentRegionsList: (payload) => dispatch({ type: SET_CURRENT_REGIONS_LIST, payload }),
    clearCurrentRegionsList: () => dispatch({ type: CLEAR_CURRENT_REGIONS_LIST }),
    setCurrentRegion: (payload) => dispatch({ type: SET_CURRENT_REGION, payload }),
    setCurrentRegionInboundLoads: (payload) => dispatch({ type: SET_CURRENT_REGION_INBOUND, payload }),
    setCurrentRegionOutboundLoads: (payload) => dispatch({ type: SET_CURRENT_REGION_OUTBOUND, payload }),
  };

  const memoLaneDetailRegionStateValue = useMemo(
    () => ({ laneDetailRegionState: state, setLaneDetailRegionState }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return (
    <LaneDetailRegionCtx.Provider value={memoLaneDetailRegionStateValue}>
      {props.children}
    </LaneDetailRegionCtx.Provider>
  );
};

export const withLaneDetailRegionStateAsPropsHOC = (WrappedComponent) => (props) =>
  (
    <LaneDetailRegionCtx.Consumer>
      {({ laneDetailRegionState, setLaneDetailRegionState }) => (
        <WrappedComponent
          {...props}
          laneDetailRegionState={laneDetailRegionState}
          setLaneDetailRegionState={setLaneDetailRegionState}
        >
          {props.children}
        </WrappedComponent>
      )}
    </LaneDetailRegionCtx.Consumer>
  );
