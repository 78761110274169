import React from 'react';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import PriorityHighSharp from '@mui/icons-material/PriorityHighSharp';
import { Typography } from '@mui/material';
import { DISABLE_BROKERAGE, DISABLE_HOME_TIME, DISABLE_TMS_NOT_READY } from '../helpers/constants';
import ODInfoBlurb from '../../../common/ODInfoBlurb';
import { SWITCH_TO_ASSET } from '../../loadAcceptance/loadAcceptanceConstants';

const AssignmentWarning = ({ reason = '', bypassDispatchingChecks = false }) => {
  let icon = <InfoOutlined />;
  let text = '';

  switch (reason) {
    case DISABLE_BROKERAGE:
      text = 'Brokerage loads are not yet able to dispatch in the tool, please dispatch these in your TMS.';
      break;
    case DISABLE_TMS_NOT_READY:
      if (bypassDispatchingChecks) return null;
      icon = <PriorityHighSharp />;
      text = 'We don’t have enough data for this load to send the assignment to your TMS. Please assign directly in your TMS.';
      break;
    case DISABLE_HOME_TIME:
      text = 'Send the driver home, wait for more loads to be matched, or manually book a load for this driver to minimize deadhead along the route to home.';
      break;
    case SWITCH_TO_ASSET:
      text = 'This load is currently assigned in your TMS as a brokerage load. By assigning this load, it will automatically be changed from brokerage to asset.';
      break;
    default:
      text = 'Select a "Top Load" Assignment for each selected driver to enable the "Assign Load(s) to Driver(s)" button in the blue action bar.';
  }

  if (!text) return null;

  return (
    <>
      {(reason === DISABLE_HOME_TIME || reason === SWITCH_TO_ASSET) ? (
        <Typography sx={{ whiteSpace: 'normal' }}>{text}</Typography>
      ) : (
        <ODInfoBlurb variant="warning" icon={icon}>
          {text}
        </ODInfoBlurb>
      )}
    </>
  );
};

export default AssignmentWarning;
