import { ShowScenarioLoader } from '.';
import instance from '../../utils/axios_instance';
import { regionalList as mockList } from '../../utils/mocks';
import { objToQueryParams } from '../../utils/helpers';
import { 
  SET_REGIONAL_LIST, 
  SET_CHILD_CITY_STATE_LIST, 
  REMOVE_SELECTED_LOAD_IDS, 
  REMOVE_SELECTED_LOAD_ID, 
  ADD_SELECTED_LOAD_IDS, 
  ADD_SELECTED_LOAD_ID,
  REMOVE_ASSIGNED_LOAD_IDS,
  ADD_ASSIGNED_LOAD_IDS,
  CLEAR_ASSIGNED_LOAD_IDS,
  ADD_TO_LOAD_IDS_TABLE,
  ADD_ENTRIES_TO_LOAD_IDS_TABLE,
  CLEAR_LOAD_IDS_TABLE,
  ADD_TO_SELECTED_LOADS_AUDITOR,
  CLEAR_SELECTED_LOADS_AUDITOR,
} from '../constants/actionTypes';

export const setRegionalList = (regions) => ({
  type: SET_REGIONAL_LIST,
  regions,
});

export const fetchRegionalList = (params) => async (dispatch) => {
  const { _isMocking = null, ...restParams } = params;
  dispatch(ShowScenarioLoader(true));

  if (!!_isMocking) {
    dispatch(setRegionalList(Object.values(mockList.data.result)));
  } else {
    const queryParams = objToQueryParams(restParams);
    // console.info(queryParams);
    await instance
      .get(`/tactical_procurement/location/${queryParams}`)
      .then((res) => {
        dispatch(setRegionalList(Object.values(res.data.result).sort((a, b) => a.location > b.location ? 1 : -1)));
      })
      .catch((err) => console.error(err));
  }

  dispatch(ShowScenarioLoader(false));
};

export const setChildCityStateList = (childCityStateList) => ({
  type: SET_CHILD_CITY_STATE_LIST,
  childCityStateList
});

export const fetchChildCityStateList = (params) => async (dispatch) => {
  dispatch(ShowScenarioLoader(true));
  await instance
    .get('/child_tactical_procurement/', { params })
    .then((res) => {
      dispatch(setChildCityStateList(Object.values(res.data.result)));
    })
    .catch((err) => console.error(err));
  dispatch(ShowScenarioLoader(false));
};

export const addSelectedLoadIDs = (payload) => (
  {
    type: ADD_SELECTED_LOAD_IDS,
    payload,
  }
);

export const addSelectedLoadID = (payload) => (
  {
    type: ADD_SELECTED_LOAD_ID,
    payload,
  }
);

export const removeSelectedLoadIDs = (payload) => (
  {
    type: REMOVE_SELECTED_LOAD_IDS,
    payload,
  }
);

export const removeSelectedLoadID = (payload) => (
  {
    type: REMOVE_SELECTED_LOAD_ID,
    payload,
  }
);

export const selectLoadIDs = (loadIDs) => (dispatch) => {
  dispatch(addSelectedLoadIDs(loadIDs));
};

export const selectLoadID = (loadID) => (dispatch) => {
  dispatch(addSelectedLoadID(loadID));
};

export const removeLoadIDs = (loadIDs) => (dispatch) => {
  dispatch(removeSelectedLoadIDs(loadIDs));
};

export const addAssignedLoadIds = (payload) => (
  {
    type: ADD_ASSIGNED_LOAD_IDS,
    payload
  }
);

export const removeAssignedLoadIds = (payload) => (
  {
    type: REMOVE_ASSIGNED_LOAD_IDS,
    payload
  }
);

export const removeLoadID = (loadID) => (dispatch) => {
  dispatch(removeSelectedLoadID(loadID));
};

export const addToAssignedLoadIDs = (loadIDs) => (dispatch) => {
  dispatch(addAssignedLoadIds(loadIDs));
};

export const removeFromAssignedLoadIDs = (loadIDs) => (dispatch) => {
  dispatch(removeAssignedLoadIds(loadIDs));
};

export const clearAssignedLoadIDs = () => (
  { type: CLEAR_ASSIGNED_LOAD_IDS }
);

export const clearAllAssignedLoadIDs = () => (dispatch) => {
  dispatch(clearAssignedLoadIDs());
};

export const addToLoadIDsTable = (payload) => (
  {
    type: ADD_TO_LOAD_IDS_TABLE,
    payload
  }
);

export const assignLoadIDToTable = ({ id, load_id, direction, region }) => (dispatch) => {
  dispatch(addToLoadIDsTable({ id, load_id, direction, region }));
};

export const clearLoadIdsTable = () => (
  {
    type: CLEAR_LOAD_IDS_TABLE
  }
);

export const clearLoadsFromTable = () => (dispatch) => {
  dispatch(clearLoadIdsTable());
};

export const addEntriesToLoadIDsTable = (payload) => (
  {
    type: ADD_ENTRIES_TO_LOAD_IDS_TABLE,
    payload,
  }
);

export const addLoadIDEntriesToTable = (payload) => (dispatch) => {
  dispatch(addEntriesToLoadIDsTable(payload));
};

export const addToSelectedLoadsAuditor = (payload) => (
  {
    type: ADD_TO_SELECTED_LOADS_AUDITOR,
    payload
  }
);

export const clearSelectedLoadsAuditor = () => (
  {
    type: CLEAR_SELECTED_LOADS_AUDITOR,
  }
);

export const addToLoadsAuditor = (payload) => (dispatch) => {
  dispatch(addToSelectedLoadsAuditor(payload));
};

export const clearLoadsAuditor = () => (dispatch) => dispatch(clearSelectedLoadsAuditor());
