export const regionalList = {
  data: {
    result: {
      Maryland: {
        location: 'Maryland',
        code: 'MD',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Virginia: {
        location: 'Virginia',
        code: 'VA',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Tennessee: {
        location: 'Tennessee',
        code: 'TN',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Mississippi: {
        location: 'Mississippi',
        code: 'MS',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      'North Carolina': {
        location: 'North Carolina',
        code: 'NC',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Arkansas: {
        location: 'Arkansas',
        code: 'AR',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Utah: {
        location: 'Utah',
        code: 'UT',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Georgia: {
        location: 'Georgia',
        code: 'GA',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      'South Carolina': {
        location: 'South Carolina',
        code: 'SC',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Louisiana: {
        location: 'Louisiana',
        code: 'LA',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Florida: {
        location: 'Florida',
        code: 'FL',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      'New York': {
        location: 'New York',
        code: 'NY',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Indiana: {
        location: 'Indiana',
        code: 'IN',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Pennsylvania: {
        location: 'Pennsylvania',
        code: 'PA',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Texas: {
        location: 'Texas',
        code: 'TX',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Kentucky: {
        location: 'Kentucky',
        code: 'KY',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Minnesota: {
        location: 'Minnesota',
        code: 'MN',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Wisconsin: {
        location: 'Wisconsin',
        code: 'WI',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Alabama: {
        location: 'Alabama',
        code: 'AL',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      'New Jersey': {
        location: 'New Jersey',
        code: 'NJ',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Maine: {
        location: 'Maine',
        code: 'ME',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Arizona: {
        location: 'Arizona',
        code: 'AZ',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      California: {
        location: 'California',
        code: 'CA',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Illinois: {
        location: 'Illinois',
        code: 'IL',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Kansas: {
        location: 'Kansas',
        code: 'KS',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Michigan: {
        location: 'Michigan',
        code: 'MI',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Missouri: {
        location: 'Missouri',
        code: 'MO',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Nebraska: {
        location: 'Nebraska',
        code: 'NE',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Iowa: {
        location: 'Iowa',
        code: 'IA',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Ohio: {
        location: 'Ohio',
        code: 'OH',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Washington: {
        location: 'Washington',
        code: 'WA',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      'North Dakota': {
        location: 'North Dakota',
        code: 'ND',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Massachusetts: {
        location: 'Massachusetts',
        code: 'MA',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Vermont: {
        location: 'Vermont',
        code: 'VT',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      'New Mexico': {
        location: 'New Mexico',
        code: 'NM',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      'West Virginia': {
        location: 'West Virginia',
        code: 'WV',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Delaware: {
        location: 'Delaware',
        code: 'DE',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      'Rhode Island': {
        location: 'Rhode Island',
        code: 'RI',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Connecticut: {
        location: 'Connecticut',
        code: 'CT',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      'New Hampshire': {
        location: 'New Hampshire',
        code: 'NH',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      'South Dakota': {
        location: 'South Dakota',
        code: 'SD',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Oklahoma: {
        location: 'Oklahoma',
        code: 'OK',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Colorado: {
        location: 'Colorado',
        code: 'CO',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
      Oregon: {
        location: 'Oregon',
        code: 'OR',
        inbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
        outbound: {
          committed: 0,
          active: 0,
          primary: '0',
          other: '0',
          status: null,
        },
      },
    },
  },
};
