import React, { useState, createContext, useMemo } from 'react';

export const SettingsContext = createContext();
export const SettingsContextWrapper = ({ children }) => {
  const [showSettings, setShowSettings] = useState(false);
  const memoSettings = useMemo(() => ({ showSettings, setShowSettings }), [showSettings]);
  
  return (
    <SettingsContext.Provider
      value={memoSettings}
    >
      { children }
    </SettingsContext.Provider>
  );
};
