/* eslint-disable no-return-await */
import { laneDetailDrawerLoads, loadDetailCommitmentData } from './mocks';
import cookies from './cookies';
import { randomIntInterval } from './math';
import { mapLoadsTimeWindows } from './processLoadData';
import { objToQueryParams, pickOne } from './helpers';

const stubAPICall = ({ response, delay }) => 
  new Promise((resolve) => {
    setTimeout(() => resolve(response), delay || 250);
  });

const stubAPI = {
  get: 
    async ({ url, response, delay, DEBUGGING = false }) => {
      DEBUGGING && console.info(url);
      const newResponse = { ...response, responseURL: url, status: 200 };
      return await stubAPICall({ response: newResponse, delay });
    },
  post: 
    async ({
      url, postBody, response, delay, DEBUGGING = false 
    }) => {
      if (DEBUGGING) {
        // eslint-disable-next-line
        console.info(url);
        // eslint-disable-next-line
        console.dirxml(postBody);
      }
      const responseObject = { ...response, responseURL: url, status: 200 };
      const res = await stubAPICall({ response: responseObject, delay });
      return res;
    },
};

/**
 * API stub that simulates calling for a load detail's commitment data
 * @param {number|string} contractId either a number of number as string, corresponds to contract_id attribute of load
 * @param {number} delay in milliseconds, enter 1500 if you want to wait 1.5secs
 * @returns a mocked response object
 */
const getLoadDetailCommitment = (contractId, delay = 250) => stubAPI.get({
  url: `/contracts/account-commitment/${cookies.get('org_id')}/${contractId}`, 
  response: { 
    data: loadDetailCommitmentData,
  },
  delay
});

const genLoadsByDirection = ({ city, state, direction = 'outbound' }, _delay = 300) => {
  const resultsCount = randomIntInterval(2, 5);

  const isOutbound = direction.toLowerCase() === 'outbound';

  const baseLoads = !!isOutbound 
    ? laneDetailDrawerLoads.slice().reverse().slice(0, resultsCount)
    : laneDetailDrawerLoads.slice(0, resultsCount);

  return baseLoads.map((load) => {
    const prefix = !!isOutbound ? 'destination' : 'origin';
    
    load[`${prefix}_city`] = city;
    load[`${prefix}_state`] = state;
    return load;
  }).map(mapLoadsTimeWindows);
};

/**
 * Stubbed API call that returns either inbound or outbound loads for active-recommended loads that have not yet been committed to
 * @param {Object} param0 
 * @param {string} param0.city
 * @param {string} param0.state
 * @param {'inbound'|'outbound'} param0.direction
 * @param {number} delay 
 * @returns 
 */
const getLoadsByDirection = ({ city, state, direction = 'outbound' }, delay = 300) => {
  const loadsData = genLoadsByDirection({ city, state, direction });
  const locationType = {
    outbound: 'destination',
    inbound: 'origin',
  };

  const data = { 
    [direction]: loadsData, 
    [locationType[direction]]: `${city}, ${state}` 
  };

  return stubAPI.post({
    url: '/tactical-procurement/loads/',
    responseBody: { city, state, direction },
    response: { data },
    delay
  });
};

/**
 * Stubbed API call that returns both inbound and outbound loads for active-recommended loads that have not yet been committed to
 * @param {Object} param0 
 * @param {string} param0.city
 * @param {string} param0.state
 * @param {number} delay 
 * @returns 
 */
const getViewAllLoads = ({ city, state, delay = 300 }) => {
  const outbound = genLoadsByDirection({ city, state, direction: 'outbound' });
  const inbound = genLoadsByDirection({ city, state, direction: 'inbound' });
  const data = [...inbound, ...outbound];
  return stubAPI.post({
    url: '/tactical-procurement/loads/',
    postBody: { city, state, direction: null },
    response: { data },
    delay,
  }).then((res) => res.data);
};

const genShipperLoadByDirection = ({ city, state, count, direction }) => 
  [...Array(count)].map((_, index) => {
    const loadIdx = direction === 'inbound' ? index : (laneDetailDrawerLoads.length - 1 - index);
    const { shipper_id, committed_flag, origin_city, origin_state, destination_city, destination_state } = laneDetailDrawerLoads[loadIdx];
    const startRange = randomIntInterval(1, 3);
    const endRange = randomIntInterval(startRange + 1, startRange + 3);
    let item = {};
    item = { ...item, shipper_id, committed_flag, origin_city, origin_state, destination_city, destination_state };

    if (direction === 'inbound') {
      item.destination = `${city}, ${state}`;
      item.origin = `${origin_city}, ${origin_state}`;
      item.destination_state = state;
      item.destination_city = city;
    } else {
      item.origin = `${city}, ${state}`;
      item.destination = `${destination_city}, ${destination_state}`;
      item.origin_state = state;
      item.origin_city = city;
    }

    item.committed = randomIntInterval(1, 4);
    item.active = randomIntInterval(1, 4);
    item.contract_id = Math.floor(Math.random() * 10) % 2 === 0 ? randomIntInterval(111, 999) : null;
    item.recomm_forecasting = Math.floor(Math.random() * 10) % 2 === 0 ? `${startRange}-${endRange}` : null;
    return item;
  });

const getViewAllShipperLoads = ({ city, state, searchDate: search_date, delay = 300 }, isPrimary, _DEBUGGING = false) => {
// const getViewAllShipperLoads = ({ city, state, delay = 300 }) => {
  const type = pickOne(['inbound', 'outbound']);
  const queryParams = objToQueryParams({ city, state, search_date, type });
  _DEBUGGING && console.info('queryParams would be:');
  _DEBUGGING && console.info(queryParams);
  _DEBUGGING && console.info('isPrimary: ', isPrimary);
  const inboundCount = randomIntInterval(2, 5);
  const outboundCount = randomIntInterval(2, 5);
  const outbound = genShipperLoadByDirection({ city, state, direction: 'outbound', count: outboundCount });
  const inbound = genShipperLoadByDirection({ city, state, direction: 'inbound', count: inboundCount });

  const data = [...inbound, ...outbound];
  return stubAPI.get({
    url: `/tactical_procurement/shippers?city=${city}&state=${state}`,
    response: { data },
    delay,
  }).then((res) => res.data);
};

export { 
  getLoadDetailCommitment,
  getLoadsByDirection,
  getViewAllShipperLoads,
  getViewAllLoads,
  stubAPI,
};
