import React, { createContext, useState, useMemo, ReactNode } from 'react';
/**
 * @constant ['active-recommended', 'primary', 'other']
 */
export const LANE_DETAIL_TABS = Object.freeze(['active-recommended', 'primary', 'other']);

export const LaneDetailTabCtx = createContext({
  tabValue: LANE_DETAIL_TABS[0],
  setTabValue: {}
});

export const LaneDetailTabCtxWrapper = ({ children }: { children: ReactNode }) => {
  const [tabValue, _setTabValue] = useState(LANE_DETAIL_TABS[0]);

  const setTabValue = ({
    [LANE_DETAIL_TABS[0]]: () => _setTabValue(LANE_DETAIL_TABS[0]),
    [LANE_DETAIL_TABS[1]]: () => _setTabValue(LANE_DETAIL_TABS[1]),
    [LANE_DETAIL_TABS[2]]: () => _setTabValue(LANE_DETAIL_TABS[2]),
  });

  const memoLaneDetailTabCtxValue = useMemo(() => ({ tabValue, setTabValue }), [tabValue]);

  return (
    <LaneDetailTabCtx.Provider
      value={memoLaneDetailTabCtxValue}
    >
      { children }
    </LaneDetailTabCtx.Provider>
  );
};
