import React, { createContext, useMemo, useState } from 'react';

export const SessionContext = createContext({ sessionUid: '', setSessionUid: (_sessionUid) => {} });

export const SessionContextWrapper = ({ children }) => {
  const [sessionUid, setSessionUid] = useState('');
  const memoSessionCtx = useMemo(() => ({
    sessionUid,
    setSessionUid,
  }), [
    sessionUid,
    setSessionUid,
  ]);
  return (
    <SessionContext.Provider value={memoSessionCtx}>
      {children}
    </SessionContext.Provider>
  );
};
