import React, { ReactElement, SyntheticEvent } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import BetaChip from './BetaChip';

export interface TabData {
  icon: ReactElement;
  label: string;
  value: string;
  isBeta?: boolean;
}

export interface ODTabsProps {
  tabOptions: TabData[];
  value: string;
  setValue: (_event: SyntheticEvent, _value: string) => void;
  fullWidth?: boolean;
  condense?: boolean;
  unselectedShouldDim?: boolean;
}

const ODTabs = ({ tabOptions, value, setValue, fullWidth = false, condense = false, unselectedShouldDim = false }: ODTabsProps) => (
  <Tabs
    value={value}
    onChange={setValue}
    TabIndicatorProps={{ sx: { backgroundColor: 'optimalblue.main' } }}
    sx={{
      '.MuiTabs-flexContainer': { columnGap: condense ? '0px' : '16px' },
      'button.MuiTab-root': {
        textTransform: 'none',
        fontSize: '14px',
        color: 'text.secondary',
        flexDirection: 'row',
        flexGrow: fullWidth ? 1 : 'unset',
        columnGap: '16px',
        minHeight: '40px',
        py: 1,
        my: 1,
        borderRadius: '4px',
        ...(unselectedShouldDim && { '> svg': { opacity: 0.55 } }),
        '> .MuiTab-iconWrapper': { margin: 0 },
        ':hover': {
          backgroundColor: 'colors.neutral2',
          color: 'text.primary'
        },
        '&.Mui-selected': {
          color: 'text.primary',
          ':hover': {
            backgroundColor: 'transparent'
          },
          ...(unselectedShouldDim && { '> svg': { opacity: 1 } }),
        },
      }
    }}
  >
    {tabOptions.map(((type) => (
      <Tab
        key={type.value}
        className={`view-tab-${type.value}`}
        {...type}
        label={(
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <strong>{type.label}</strong>
            {type.isBeta && <BetaChip />}
          </Box>
        )}
      />
    )))}
  </Tabs>
);

export default ODTabs;
