import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { loadAcceptanceWare, notificationsWare, dispatchingWare, trailerWare } from './middleware';
import { env } from '../env';

const makeComposeEnhancers = () => {
  const isLocal = window?.location?.port === '3000' && window?.location?.hostname === 'localhost';
  // NOTE: BELOW may change - works for chrome ver. 90
  const inIncognitoMode = !(window.RequestFileSystem || window.webkitRequestFileSystem);

  // console.debug('inIncognitoMode: ', inIncognitoMode);
  if (isLocal && !env.REACT_APP_DOCKER_RUNNING && !inIncognitoMode) {
    return (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) || (compose && compose({ trace: true, traceLimit: 25 })); 
  }

  return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
};

const composeEnhancers = makeComposeEnhancers();

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(
    thunk,
    loadAcceptanceWare,
    notificationsWare,
    dispatchingWare, 
    trailerWare
  )),
);
export default store;
