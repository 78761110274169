import React from 'react';
import { Box, Typography } from '@mui/material';
import PersonSharp from '@mui/icons-material/PersonSharp';
import { ODLaneIcon, ODDialog } from '@OptimalDynamics/core-ai-common-ui';
import SlideoutLineItem from '../../../common/SlideoutLineItem';

interface PermitsAndRequirementsDialogProps {
  open: boolean;
  onClose: () => void;
  permitDriver: string[] | string | undefined;
  permitRequired: string | undefined;
  equipment: string | undefined;
  description: () => string;
}

const PermitsAndRequirementsDialog = ({ 
  open,
  onClose,
  permitDriver,
  permitRequired,
  equipment,
  description 
}: PermitsAndRequirementsDialogProps) => {
  let driverPermits = '';
  if (Array.isArray(permitDriver)) driverPermits = permitDriver.length > 0 ? permitDriver.join(', ') : 'None';
  else driverPermits = permitDriver || 'None';

  return (
    <ODDialog
      title="Permits & Requirements"
      open={open}
      onClose={onClose}
      sx={{ zIndex: '1500 !important' }}
    >
      <Box
        sx={{
          padding: 0,
          margin: '8px 0 16px 0px',
          height: '40px',
          borderRadius: '4px',
        }}
      >
        {description()}
      </Box>
      <Box sx={{ borderBottom: '1px solid', borderBottomColor: 'level2' }}>
        <Box
          sx={{
            backgroundColor: 'colors.neutral0',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            margin: '16px 0 0',
            height: '40px'
          }}
        >
          <PersonSharp sx={{ pr: '8px' }} />
          <Typography>Permits</Typography>
        </Box>
        <SlideoutLineItem title="Permit Type:" content={driverPermits} />
      </Box>
      <Box sx={{ borderBottom: '1px solid', borderBottomColor: 'level2' }}>
        <Box
          sx={{
            backgroundColor: 'colors.neutral0',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            margin: '16px 0 0',
            height: '40px'
          }}
        >
          <ODLaneIcon sx={{ pr: '8px' }} />
          <Typography>Requirements</Typography>
        </Box>
        <SlideoutLineItem title="Permit Type:" content={permitRequired ?? 'None'} />
        <SlideoutLineItem title="Equipment:" content={equipment ?? 'N/A'} />
      </Box>
    </ODDialog>
  ); 
};

export default PermitsAndRequirementsDialog;
