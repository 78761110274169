import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from '../components/login/login';

const WaitForLDIdentify = ({ children }) => {
  const ldauth = useSelector((state) => state.auth.launchDarklyUserIdentified);
  return ldauth !== null ? children : <></>;
};

const ProtectedRoute = ({ requiredRoles, children }) => {
  const { isAuthenticated, user } = useAuth0();
  const { pathname } = useLocation();

  let isAuthorized = true;
  if (!!requiredRoles) {
    isAuthorized = requiredRoles.every((role) => user['https://core-ai.io/roles'].includes(role));
  }
  if (!isAuthorized) {
    return <Navigate to={<Login />} />;
  }
  return isAuthenticated ? <WaitForLDIdentify>{children}</WaitForLDIdentify> : <Navigate to="/login" replace state={{ from: pathname }} />;
};

export default ProtectedRoute;
