import React from 'react';
import CalendarTodaySharp from '@mui/icons-material/CalendarTodaySharp';
import DoNotDisturbOnSharp from '@mui/icons-material/DoNotDisturbOnSharp';
import FilterAltSharp from '@mui/icons-material/FilterAltSharp';
import ReportProblemOutlined from '@mui/icons-material/ReportProblemOutlined';
import CompareArrowsSharp from '@mui/icons-material/CompareArrowsSharp';
import LibraryBooksSharp from '@mui/icons-material/LibraryBooksSharp';
import TimelineSharp from '@mui/icons-material/TimelineSharp';
import { ODLaneIcon } from '@OptimalDynamics/core-ai-common-ui';
import { TacticalProcurementIcon, TrailerIcon } from './common/icons';

export const REVIEW = 'review';
export const DETAILS = 'details';
export const MATCH_DETAILS = 'match_details';
export const ALL = 'All';
export const CUSTOM_FILTER = 'Custom Filter';
export const DATA_ISSUE = 'Data Issue';
export const NOT_RELEVANT = 'Not Relevant';
export const PLANNING_HORIZON = 'Planning Horizon';

export const exclusionCategoryOptions = [
  { label: 'Viewing All', value: ALL },
  {
    label: (
      <>
        <ReportProblemOutlined sx={{ mr: 2 }} />
        {DATA_ISSUE}
      </>
    ),
    value: DATA_ISSUE
  },
  { 
    label: (
      <>
        <FilterAltSharp sx={{ mr: 2 }} />
        {CUSTOM_FILTER}
      </>
    ),
    value: CUSTOM_FILTER
  },
  { 
    label: (
      <>
        <DoNotDisturbOnSharp sx={{ mr: 2 }} />
        {NOT_RELEVANT}
      </>
    ),
    value: NOT_RELEVANT
  },
  {
    label: (
      <>
        <CalendarTodaySharp sx={{ mr: 2 }} />
        {PLANNING_HORIZON}
      </>
    ),
    value: PLANNING_HORIZON
  }
];

export const LOAD_DEMAND_FORECAST = 'load-demand-forecast';
export const TACTICAL_PROCUREMENT = 'tactical-procurement';
export const LOAD_ACCEPTANCE = 'load-acceptance';
export const DISPATCHING = 'dispatching';
export const TRAILER_ASSIGNMENTS = 'trailer-assignments';
export const COMMITMENT_MANAGER = 'commitment-manager';

export const APP_SUBPAGES = {
  [LOAD_DEMAND_FORECAST]: { title: 'Load Demand Forecast', icon: <TimelineSharp />, flag: 'showForecastingUiPage', defaultRank: 4 },
  [TACTICAL_PROCUREMENT]: { title: 'Tactical Procurement', icon: <TacticalProcurementIcon />, flag: 'showTpUiPage', defaultRank: 3 },
  [LOAD_ACCEPTANCE]: { title: 'Load Acceptance', icon: <ODLaneIcon />, flag: 'showLaUiPage', defaultRank: 1 },
  [DISPATCHING]: { title: 'Dispatching', icon: <CompareArrowsSharp />, flag: 'showDispatchingUiPage', defaultRank: 2 },
  [TRAILER_ASSIGNMENTS]: { title: 'Trailer Assignments', icon: <TrailerIcon />, flag: 'showTrailerAssignmentsUiPage', defaultRank: 5 },
  [COMMITMENT_MANAGER]: { title: 'Commitment Manager', icon: <LibraryBooksSharp />, flag: 'showCommitmentsUiPage', defaultRank: 6 }
};

export const APP_PAGE_FLAGS = Object.values(APP_SUBPAGES).map((p) => p.flag);
