import React, { useReducer, useMemo } from 'react';

const initialGlobalAlertsState = {
  notificationsTitle: 'Notifications',
  notificationLevel: 'information',
  showNotification: false,
  sideDrawer: null,
  selectedLoadId: null,
};

export const GlobalAlertsDrawerContext = React.createContext({
  drawerInfo: { ...initialGlobalAlertsState },
  setDrawerInfo: () => {}
});

const GLOBAL_ALERTS_ACTION_TYPES = Object.freeze({ 
  SET_TITLE: 'SET_TITLE',
  SET_LEVEL: 'SET_LEVEL',
  SET_VISIBLE: 'SET_VISIBLE',
  SET_SIDE_DRAWER: 'SET_SIDE_DRAWER',
  SET_SELECTED_LOAD_ID: 'SET_SELECTED_LOAD_ID',
});

const {
  SET_TITLE, SET_LEVEL, SET_VISIBLE, SET_SIDE_DRAWER, SET_SELECTED_LOAD_ID 
} = GLOBAL_ALERTS_ACTION_TYPES;

const globalAlertReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_TITLE:
      return { ...state, notificationsTitle: payload };

    case SET_LEVEL:
      return { ...state, notificationLevel: payload };
    
    case SET_VISIBLE:
      return { ...state, showNotification: payload };
    
    case SET_SIDE_DRAWER:
      return { ...state, sideDrawer: payload };
    
    case SET_SELECTED_LOAD_ID:
      return { ...state, selectedLoadId: payload };

    default:
      throw new Error('action.type not recognized');
  }
};

export const GlobalAlertsDrawerContextWrapper = ({ children }) => {
  const [state, dispatch] = useReducer(globalAlertReducer, initialGlobalAlertsState);

  const setDrawerInfo = ({
    sideDrawer, notificationsTitle, notificationLevel, showNotification, selectedLoadId = null 
  }) => {
    (typeof sideDrawer !== 'undefined') && dispatch({ type: SET_SIDE_DRAWER, payload: sideDrawer });
    !!notificationsTitle && dispatch({ type: SET_TITLE, payload: notificationsTitle });
    !!notificationLevel && dispatch({ type: SET_LEVEL, payload: notificationLevel });
    dispatch({ type: SET_SELECTED_LOAD_ID, payload: selectedLoadId });
    dispatch({ type: SET_VISIBLE, payload: !!showNotification });
  };

  const memoGlobalAlertValue = useMemo(() => ({ drawerInfo: state, setDrawerInfo }), [state]);

  return (
    <GlobalAlertsDrawerContext.Provider 
      value={memoGlobalAlertValue}
    >
      { children }
    </GlobalAlertsDrawerContext.Provider>
  );
};
