const baseDriverNames = [
  'AIDLERU',
  'ALDAMN',
  'AMORTSLIN',
  'ANTHONYG',
  'AUDREVE',
  'BECKITTE',
  'BEETONR',
  'BETHOLA',
  'BETTENEYK',
  'BISHELLBRA',
  'BOATWRIGI',
  'BODICUMG',
  'BOLDINGTA',
  'BOUDO',
  'CAINH',
  'CANTN',
  'CELEZE',
  'CHOMLEYF',
  'CLERETA',
  'CLEWSCH',
  'CLOUGHCO',
  'COADYJE',
  'COULDWELLCOO',
  'COUROYD',
  'CRITCHLOCOL',
  'CUMMIG',
  'DANIELSG',
  'DEBL',
  'DECHA',
  'DEMKEM',
  'DINNINGTODO',
  'DIWELLQ',
  'EMBLINF',
  'FOURCADEA',
  'FURLONGEREM',
  'GEHRTSW',
  'GIRAUDH',
  'GRIFFITTRYU',
  'GROTTYCOL',
  'GROUTA',
  'HALTKRY',
  'HEINZLAU',
  'HOLDEYEM',
  'HOLLINDAP',
  'HOWDENKYL',
  'IVANK',
  'JANOSCY',
  'JEREMAESFE',
  'KAREST',
  'KNAPPECAR',
  'LABBSA',
  'LACR',
  'LAIRDCRAFR',
  'LAPSLEYBE',
  'LAVARAAR',
  'LEAVRYNIC',
  'LEPAG',
  'MANGENOTA',
  'MCWILLIAMC',
  'MOLYNEUXJ',
  'MUNSLOWTRO',
  'MYHANSK',
  'NEHLDO',
  'NEWITTN',
  'OCAHERNYJ',
  'PASCOWAL',
  'PASQUROS',
  'PONDECO',
  'POPWORTHNE',
  'POURVSP',
  'QUALTROSA',
  'RATIECOR',
  'RENSHALLH',
  'REUSSSAU',
  'ROBBEKEAL',
  'ROLLINSS',
  'ROSLINGBA',
  'ROWLERJOE',
  'RYMOUROZZ',
  'SEDWORTHTAN',
  'SIMKINE',
  'SLAINEYC',
  'SLOTC',
  'SMITTBLA',
  'SOLOMOEDM',
  'STEPTOPIE',
  'STRIDEOLY',
  'STUDF',
  'SULLERCO',
  'TAMBUROEA',
  'TATHERRI',
  'TATTOOKH',
  'TERZORAF',
  'TIBBSKA',
  'TREADTI',
  'UVEDALB',
  'WARDHAUGHGOR',
  'WATMANANI',
  'WHITLAWMA',
  'YVENKAR',
];

// TODO: write a func that pumps out random # of them

export default baseDriverNames;
