import instance from '../../utils/axios_instance';
import { SET_USER_SETTINGS, GET_USER_SETTINGS } from '../constants/actionTypes';

export const FetchUserSettings = () => async (dispatch) => {
  await instance
    .get('/user-settings/')
    .then((response) => dispatch(SetUserSettings({ ...response.data, userSettingsDataLoaded: true })))
    .catch((err) => console.warn(err));
};

export const SetUserSettings = (settings) => ({
  type: SET_USER_SETTINGS,
  settings,
});

export const GetUserSettings = () => ({
  type: GET_USER_SETTINGS
});

export const UpdateUserSettings = (settings) => async (dispatch) => {
  await instance
    .post('/user-settings/', settings)
    .then((response) => dispatch(SetUserSettings(response.data)))
    .catch((err) => console.warn(err));
};
