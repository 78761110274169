import React, { createContext, useReducer, useMemo } from 'react';
import API from '../axios_instance';
import cookies from '../cookies';
import * as stub from '../stubs';
import { randomIntInterval } from '../math';

const initialLoadsCommitmentState = {
  commitmentLoads: [],
  currentCommitment: null,
  currentIndex: null,
  count: null,
  showCommitmentDrawer: false,
  useStubs: false, // set to true to force contract/commitment icon and fake outputs...
  isMultiView: false,
  loadDetails: {},
};

const LOAD_COMMITMENTS_ACTION_TYPES = Object.freeze({
  GET_LOAD_COMMITMENTS: 'GET_LOAD_COMMITMENTS',
  SET_COMMITMENT_LOADS: 'SET_COMMITMENT_LOADS',
  SET_CURRENT_LOAD_COMMITMENT: 'SET_CURRENT_LOAD_COMMITMENT',
  SET_COMMITMENT_DRAWER_VISIBLE: 'SET_COMMITMENT_DRAWER_VISIBLE',
  SET_COMMITMENT_MULTI_VIEW: 'SET_COMMITMENT_MULTI_VIEW',
  SET_LOAD_DETAILS: 'SET_LOAD_DETAILS',
  SET_INDEX: 'SET_INDEX',
  SET_LOAD_COMMITMENTS: 'SET_LOAD_COMMITMENTS',
  SET_STUBS: 'SET_STUBS',
});

const {
  GET_LOAD_COMMITMENTS,
  SET_LOAD_COMMITMENTS,
  SET_STUBS,
  SET_CURRENT_LOAD_COMMITMENT,
  SET_COMMITMENT_DRAWER_VISIBLE,
  SET_COMMITMENT_MULTI_VIEW,
  SET_LOAD_DETAILS,
  SET_COMMITMENT_LOADS,
  SET_INDEX,
} = LOAD_COMMITMENTS_ACTION_TYPES;

const placeHolderFn = (state, payload = null) => {
  // eslint-disable-next-line
  console.dirxml(payload);
  return state;
};

const setCommitmentSliderVisibility = (state, payload) => ({ ...state, showCommitmentDrawer: payload });
const setCurrentCommitment = (state, payload) => ({ ...state, currentCommitment: payload });
const setMultiViewTo = (state, payload) => ({ ...state, isMultiView: payload });
const setCommitmentLoadDetails = (state, payload) => ({ ...state, loadDetails: payload });
const setCommitmentLoads = (state, payload) => ({ ...state, commitmentLoads: payload, count: payload.length });
const setCommitmentLoadsIndex = (state, payload) => ({ ...state, currentIndex: payload });
const setStubs = (state, payload) => ({ ...state, useStubs: payload });

const loadsCommitmentReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_LOAD_COMMITMENTS:
      return placeHolderFn(state, payload);
    
    case SET_STUBS:
      return setStubs(state, payload);

    case SET_INDEX:
      return setCommitmentLoadsIndex(state, payload);

    case SET_COMMITMENT_LOADS:
      return setCommitmentLoads(state, payload);

    case SET_LOAD_DETAILS:
      return setCommitmentLoadDetails(state, payload);
          
    case SET_COMMITMENT_MULTI_VIEW:
      return setMultiViewTo(state, payload);

    case SET_CURRENT_LOAD_COMMITMENT:
      return setCurrentCommitment(state, payload);

    case SET_COMMITMENT_DRAWER_VISIBLE:
      return setCommitmentSliderVisibility(state, payload);

    default:
      throw new Error('Invalid action.type passed. See function loadsCommitmentReducer for details.');
  }
};

export const LoadsCommitmentContext = createContext({
  loadsCommitmentState: { ...initialLoadsCommitmentState },
  setLoadsCommitmentState: () => {},
});

export const LoadsCommitmentContextWrapper = ({ children }) => {
  const [state, dispatch] = useReducer(loadsCommitmentReducer, initialLoadsCommitmentState);

  const getCurrentCommitmentAsync = async (contractId, { isMocking, isMultiView }) => {
    const { useStubs } = state;
    const stubDelay = Math.floor(randomIntInterval(1, 5) * 100);

    const response = (!!useStubs || !!isMocking)
      ? await stub.getLoadDetailCommitment(randomIntInterval(100, 999), stubDelay)
      : await API.get(`/contracts/account-commitment/${cookies.get('org_id')}/${contractId}/`); // use 413 for testing API...
    
    const payload = response.data;
    dispatch({ type: SET_CURRENT_LOAD_COMMITMENT, payload });
    dispatch({ type: SET_COMMITMENT_MULTI_VIEW, payload: isMultiView });
    dispatch({ type: SET_COMMITMENT_DRAWER_VISIBLE, payload: true });
  };

  const preFilterSetLoads = (loads, { isMocking }) => {
    const { useStubs } = state;
    const payload = (!!useStubs || !!isMocking) ? loads : loads.filter((load) => !!load?.contract_id);
    dispatch({ type: SET_COMMITMENT_LOADS, payload });
  };

  const setIndexByLoadID = (loadID) => {
    const { commitmentLoads } = state;
    const currIndex = commitmentLoads.findIndex((load) => load.load_id === loadID);
    dispatch({ type: SET_INDEX, payload: currIndex });
  };
  
  const traverseCommitments = (direction, { isMocking = false } = {}) => {
    if (Math.abs(direction) !== 1) throw new Error('Parameter "direction" needs have an absolute value of 1 (1 or -1).');
    if (!Number.isInteger(direction)) throw new TypeError('Parameter "direction" needs to be a typeof Number and an Integer.');

    const { currentIndex, commitmentLoads } = state;
    const nextIndex = currentIndex + direction;
    const nextLoad = commitmentLoads[nextIndex];
    getCurrentCommitmentAsync(nextLoad?.contract_id, { isMultiView: true, isMocking });
    dispatch({ type: SET_INDEX, payload: nextIndex });
    dispatch({ type: SET_LOAD_DETAILS, payload: nextLoad });
  };

  const getPrevCommitment = ({ isMocking }) => traverseCommitments(-1, { isMocking });
  const getNextCommitment = ({ isMocking }) => traverseCommitments(1, { isMocking });

  const setLoadsCommitmentState = () => ({
    getMultiple: () => dispatch({ type: GET_LOAD_COMMITMENTS }),
    setShowCommitmentSlider: (payload) => dispatch({ type: SET_COMMITMENT_DRAWER_VISIBLE, payload }),
    getCommitmentAsync: (contractId, { isMocking = false, isMultiView = false } = {}) => getCurrentCommitmentAsync(contractId, { isMocking, isMultiView }),
    setCommitment: (payload) => dispatch({ type: SET_CURRENT_LOAD_COMMITMENT, payload }),
    setLoadDetails: (payload) => dispatch({ type: SET_LOAD_DETAILS, payload }),
    setCommitmentLoads: (loads, { isMocking = false } = {}) => preFilterSetLoads(loads, { isMocking }),
    clearCommitmentLoads: () => dispatch({ type: SET_COMMITMENT_LOADS, payload: [] }),
    setIndexByLoadID,
    getPrevCommitment,
    getNextCommitment,
    turnMockingOn: () => dispatch({ type: SET_STUBS, payload: true }),
    turnMockingOff: () => dispatch({ type: SET_STUBS, payload: false }),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoCommitmentValue = useMemo(() => ({ loadsCommitmentState: state, setLoadsCommitmentState }), [state]);

  return (
    <LoadsCommitmentContext.Provider
      value={memoCommitmentValue}
    >
      {children}
    </LoadsCommitmentContext.Provider>
  );
};

export const withLoadsCommitmentCtx = (WrappedComponent) => (props) => (
  <>
    <LoadsCommitmentContext.Consumer>
      {({ loadsCommitmentState, setLoadsCommitmentState }) => (
        <WrappedComponent
          {...props}
          loadsCommitmentState={loadsCommitmentState}
          setLoadsCommitmentState={setLoadsCommitmentState}
        />
      )}
    </LoadsCommitmentContext.Consumer>
  </>
);
