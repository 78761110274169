/* eslint-disable no-console */
import {
  GET_CONTRACTS,
  GET_SHIPPER_ACCOUNT_ID,
  GET_SHIPPER_ACCOUNT_LIST,
  SET_COMMITMENT_MANAGER_VIEW,
  UPDATE_CONTRACTS_VIEW_SELECTION,
  GET_AGGREGATED_SHIPPER_ACCOUNT,
  GET_AGGREGATED_COMMITMENT_ACCOUNTID,
  GET_AGGREGATED_COMMITMENT_LANES,
  SET_DEFAULT_CONTRACT,
  SET_CURRENT_CONTRACT_INFO,
  SET_CURRENT_CONTRACT_COMMITMENTS,
  CREATE_COMMITMENT_SUCCESS,
  EDIT_ACCOUNT,
  UPDATE_COMMITMENT_SUCCESS,
  UPDATE_COMMITMENT_FAILURE,
  SHOW_CREATE_FORM,
  CREATE_COMMITMENT_FAILURE,
  SET_ERROR_MESSAGE,
} from '../constants/actionTypes';

import API from '../../utils/axios_instance';
import cookies from '../../utils/cookies';
import { ShowScenarioLoader } from './loads';

const onGetContracts = (contracts) => ({
  type: GET_CONTRACTS,
  contracts,
});
export const GetContracts = (id) => async (dispatch) => {
  try {
    const response = await API.get(`/contracts/account-commitment/${cookies.get('org_id')}/${id}/`);
    const contractData = response.data;
    contractData.isSelected = true;
    contractData.isReadOnly = true;
    dispatch(onGetContracts([contractData]));
  } catch (err) {
    if (err && err.response) {
      return dispatch(onGetContracts([]));
    }
    dispatch(onGetContracts([]));
  }
};

export const UpdateContractViewSelection = (contract) => ({
  type: UPDATE_CONTRACTS_VIEW_SELECTION,
  contract,
});

export const SetCommitmentManagerView = (commitmentManagerView) => ({
  type: SET_COMMITMENT_MANAGER_VIEW,
  commitmentManagerView,
});

export const onGetShipperAccountList = (shipperAccount) => ({
  type: GET_SHIPPER_ACCOUNT_LIST,
  shipperAccount,
});
export const GetShipperAccountList = () => async (dispatch) => {
  try {
    const response = await API.get(`/forecasting/commitment-manager-shipper/${cookies.get('org_id')}/`);
    const { shipper_ids } = response.data;
    const shipper_ids_option = [{ id: 'Create New Shipper Account', name: 'Create New Shipper Account' }];
    shipper_ids.forEach((element) => {
      shipper_ids_option.push({ id: element, name: element });
    });

    dispatch(onGetShipperAccountList(shipper_ids_option));
  } catch (err) {
    if (err && err.response) {
      return dispatch(onGetShipperAccountList([]));
    }
    dispatch(onGetShipperAccountList([]));
  }
};

const onGetShipperAccountIds = (shipperAccountIds) => ({
  type: GET_SHIPPER_ACCOUNT_ID,
  shipperAccountIds,
});
export const GetShipperAccountIds = (shipper) => async (dispatch) => {
  try {
    const response = await API.get(`/contracts/account-id-shipper/${cookies.get('org_id')}/${shipper}/`);
    const accountids = response.data.results;

    dispatch(onGetShipperAccountIds(accountids));
  } catch (err) {
    if (err && err.response) {
      return dispatch(onGetShipperAccountIds([]));
    }
    dispatch(onGetShipperAccountIds([]));
  }
};

// GRids
const onGetAggregatedShipperAccount = (aggregatedShipperAccount) => ({
  type: GET_AGGREGATED_SHIPPER_ACCOUNT,
  aggregatedShipperAccount,
});
export const GetAggregatedShipperAccount = () => async (dispatch) => {
  dispatch(ShowScenarioLoader(true));
  try {
    const response = await API.get(`/contracts/commitments-shipper-account/${cookies.get('org_id')}/`);
    if (
      response
      && response.data
      && response.data.error
      && response.data.success === false
    ) {
      dispatch(onGetAggregatedShipperAccount([]));
    } else dispatch(onGetAggregatedShipperAccount(response.data.results));
  } catch (err) {
    if (err && err.response) {
      return dispatch(onGetAggregatedShipperAccount([]));
    }
    dispatch(onGetAggregatedShipperAccount([]));
  }
  dispatch(ShowScenarioLoader(false));
};

const onGetAggregatedShipperAccountIds = (aggregatedAccountIds) => ({
  type: GET_AGGREGATED_COMMITMENT_ACCOUNTID,
  aggregatedAccountIds,
});
export const GetAggregatedShipperAccountIds = (account) => async (dispatch) => {
  dispatch(ShowScenarioLoader(true));
  dispatch(onGetAggregatedShipperAccountIds([]));
  try {
    const response = await API.get(`/contracts/commitments-shipper-account/${cookies.get('org_id')}/${account}/`);
    if (
      response
      && response.data
      && response.data.error
      && response.data.success === false
    ) {
      dispatch(onGetAggregatedShipperAccountIds([]));
    } else dispatch(onGetAggregatedShipperAccountIds(response.data));
  } catch (err) {
    if (err && err.response) {
      return dispatch(onGetAggregatedShipperAccountIds([]));
    }
    dispatch(onGetAggregatedShipperAccountIds([]));
  }
  dispatch(ShowScenarioLoader(false));
};

const onGetAggregatedCommitmentLanes = (aggregatedCommitmentLanes) => ({
  type: GET_AGGREGATED_COMMITMENT_LANES,
  aggregatedCommitmentLanes,
});
export const GetAggregatedCommitmentLanes = (accountId) => async (dispatch) => {
  dispatch(ShowScenarioLoader(true));
  dispatch(onGetAggregatedCommitmentLanes([]));
  try {
    const response = await API.get(`/contracts/commitments-account-lanes/${cookies.get('org_id')}/${accountId}/`);
    if (
      response
      && response.data
      && response.data.error
      && response.data.success === false
    ) {
      dispatch(onGetAggregatedCommitmentLanes([]));
    } else dispatch(onGetAggregatedCommitmentLanes(response.data));
  } catch (err) {
    if (err && err.response) {
      return dispatch(onGetAggregatedCommitmentLanes([]));
    }
    dispatch(onGetAggregatedCommitmentLanes([]));
  }
  dispatch(ShowScenarioLoader(false));
};

export const SetDefaultContractData = () => ({
  type: SET_DEFAULT_CONTRACT,
});

export const SetCurrentEditedContractInfo = (contractInfo) => ({
  type: SET_CURRENT_CONTRACT_INFO,
  contractInfo,
});
export const SetCurrentEditedCommitments = (commitments) => ({
  type: SET_CURRENT_CONTRACT_COMMITMENTS,
  commitments,
});

const onCreateCommitmentSuccess = () => ({
  type: CREATE_COMMITMENT_SUCCESS,
});
const onCreateCommitmentFailure = (error) => ({
  type: CREATE_COMMITMENT_FAILURE,
  error,
});
export const CreateCommitment = (data) => async (dispatch) => {
  try {
    const response = await API.post(
      `/contracts/account-commitment/${cookies.get('org_id')}/`,
      data
    );
    if (
      response
      && response.data
      && response.data.errors
      && response.data.errors.length > 0
    ) {
      dispatch(onCreateCommitmentFailure('Some error occured'));
    } else {
      dispatch(GetAggregatedShipperAccount());
      dispatch(onCreateCommitmentSuccess(response.data));
    }
  } catch (err) {
    console.log(err);
  }
};

export const EditAccount = (isEdit) => ({
  type: EDIT_ACCOUNT,
  isEdit,
});

const onUpdateCommitmentSuccess = () => ({
  type: UPDATE_COMMITMENT_SUCCESS,
});
const onUpdateCommitmentFailure = (upddateError) => ({
  type: UPDATE_COMMITMENT_FAILURE,
  upddateError,
});
export const UpdateCommitment = (accountId, data) => async (dispatch) => {
  try {
    const response = await API.put(
      `/contracts/account-commitment/optimaldynamics/${accountId}/`,
      data
    );
    if (
      response
      && response.data
      && response.data.errors
      && response.data.errors.length > 0
    ) {
      dispatch(onUpdateCommitmentFailure('some error occured'));
    } else {
      dispatch(onUpdateCommitmentSuccess(response.data));
    }
  } catch (err) {
    console.log(err);
    dispatch(onUpdateCommitmentFailure('some error occured'));
  }
};

export const ShowCreateForm = (show) => ({
  type: SHOW_CREATE_FORM,
  show,
});

export const SetErrorMsg = (errorMsg) => ({
  type: SET_ERROR_MESSAGE,
  errorMsg,
});

export const DeleteLane = (data) => async () => {
  try {
    await API.delete(`/contracts/lane/${cookies.get('org_id')}/`, {
      data,
    });
  } catch (err) {
    console.log(err);
  }
};
