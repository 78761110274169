import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import SearchAutoComplete from './SearchAutoComplete';
import { STATE_ABBREVIATIONS_NAME_TABLE, STATE_TO_ABBREVIATIONS_TABLE } from '../utils/data/statesNameAbbr';
import instance from '../utils/axios_instance';

// const locationTypes = Object.freeze(['zip_codes', 'states', 'city_state']);

// const initialLocationsForDisplay = {
//   // zip_codes: [],
//   states: [],
//   city_state: []
// };

// const initialCreatePayload = Object.freeze({
//   driver_id: null,
//   exclude_location: {
//     states: {
//       rule_id: null,
//       action_id: null,
//       location: []
//     },
//     zip_codes: {
//       rule_id: null,
//       action_id: null,
//       location: []
//     },
//   }
// });

const LocationSearch = ({ label, selectedLocations, setSelectedLocations, inputSelectorId, multiple = true, sx = undefined }) => {
  const [input, setInput] = useState('');
  // const [selectedAutocompleteLocations, setSelectedAutocompleteLocations] = useState([]);
  const [_allowAutocompleteLocationsUpdate, setAllowAutocompleteLocationsUpdate] = useState(true);
  const [locationOptions, setLocationOptions] = useState([]);
  const [searchDimension, setSearchDimension] = useState('');
  // const [locationsForDisplay, setLocationsForDisplay] = useState(initialCreatePayload);
  const stateAbbreviationsList = Object.keys(STATE_ABBREVIATIONS_NAME_TABLE);
  const stateNamesList = Object.keys(STATE_TO_ABBREVIATIONS_TABLE);

  // const selectBoxElRef = useRef(null);

  // const getNoOptionsText = (inputArg) => (
  //   'No options'
  //   const { driver_id = null } = selectedDriver ?? {};
  //   const onlyZipCodes = !canSearchByStateFlag && !canSearchByCityFlag;
  //   const otherTypesAvailable = (canSearchByStateFlag || canSearchByCityFlag);
  //   const noDriverFound = driver_id === null;
  
  //   switch (true) {
  //     case (onlyZipCodes && !inputArg):
  //       return 'Search by zip code';
  
  //     case (otherTypesAvailable):
  //       return 'No Options';
  
  //     case (noDriverFound):
  //       return 'No Options without driver first selected';
  
  //     default:
  //       return 'Type to view options';
  //   }
  // );
  
  const handleInputChange = (evt, val, reason) => {
    // prevents MUI Autocomplete from resetting input when an option is selected
    if ((!evt || evt.type === 'click') && reason === 'reset') return;
    setInput(val);
  };
  
  const onSearchAutoCompleteChange = (event, value) => {
    // if (shouldOptionsClose(value)) {
    //   const lastEl = value[value.length - 1];
    //   const key = Object.keys(lastEl)[0];
    //   const compStr = lastEl[key];
  
    //   setInput('');
    //   setSelectedLocations(() => value.reduce((filterArr, nextLoc) => {
    //     const removeEl = nextLoc[key] && nextLoc !== lastEl && (nextLoc[key].startsWith(compStr));
  
    //     if (!removeEl) {
    //       filterArr.push(nextLoc);
    //     }
  
    //     return filterArr;
    //   }, [])); 
    //   selectBoxElRef.current.focus();
    // } else {
    //   setSelectedLocations(value);
    // }
    setSelectedLocations(value);

    setAllowAutocompleteLocationsUpdate(true);
  };
  
  // const shouldOptionsClose = (value) => {
  //   if (!value.length) return false;
  
  //   const lastVal = value[value.length - 1];
  //   return lastVal.zip_codes && lastVal.zip_codes.length === 3;
  // };
  
  // const getOptionLabel = (option) => {
  //   return Object.keys(option)[0] === 'zip_codes' ? Object.values(option)[0][0] : Object.values(option);
  // };
  
  const formatOptionsTxt = (searchType, option) => {
    if (searchType === 'zip_codes') {
      const zipVal = option[searchType];
  
      return zipVal.length === 3 
        ? `Select all ${zipVal} zip codes`
        : zipVal;
    }
  
    return option[searchType];
  };
  
  // const handleClearSelectionsByLocationType = (locationType) => {
  //   setAllowAutocompleteLocationsUpdate(true);
  //   const filtered = selectedAutocompleteLocations.filter((loc) => Object.keys(loc)[0] !== locationType);
  //   setSelectedAutocompleteLocations(filtered);
  // };
  
  // const handleClearSelectionsByLocation = (location) => {
  //   setAllowAutocompleteLocationsUpdate(true);
  //   const filtered = selectedAutocompleteLocations.filter((loc) => Object.values(loc)[0] !== location);
  //   setSelectedAutocompleteLocations(filtered);
  // };
  
  const handleSearchInput = async () => {
    setLocationOptions([]);
    let searchObj = {};
    let searchByState = [];
    let searchByStateName = [];
    let tempSearchDimension;
  
    const inputIsNumber = !Number.isNaN(+input);
    // const inputIsZipCode = input.length > 2 && inputIsNumber;
    const inputIsStateTerritory = input.length === 2 && !inputIsNumber;
    const inputIsCity = input.length > 2 && !inputIsNumber;
    // const onlyZipCodeAvailable = !canSearchByCityFlag && !canSearchByStateFlag;
    // const noValidInput = (!inputIsZipCode) || false;
  
    if (!input.length) {
      return;
    }
  
    if (inputIsStateTerritory) {
      let list = [];
      // console.assert(!canSearchByStateFlag, 'canSearchByStateFlag is true for some reason...');
      searchByState = stateAbbreviationsList.filter((abbr) => abbr.toLowerCase().startsWith(input.toLowerCase()));
      searchByStateName = stateNamesList.filter((name) => name.toLowerCase().startsWith(input.toLowerCase()));
  
      if (searchByStateName.length) {
        tempSearchDimension = 'state';
        list = list.concat(searchByStateName);
      }
  
      if (searchByState.length) {
        tempSearchDimension = 'state';
        list = list.concat(searchByState);
      }
  
      list = list.map((state) => {
        const temp = STATE_TO_ABBREVIATIONS_TABLE[state] ?? state;
        return temp;
      });
  
      const setList = new Set(list);
      list = [];
  
      setList.forEach((setVal) => {
        list.push({ state: setVal });
      });
      setSearchDimension(tempSearchDimension);
      setLocationOptions(list);
    }
  
    // if (inputIsZipCode) {
    //   tempSearchDimension = 'zip_codes';
    //   searchObj = { zip_code: input };
    //   setSearchDimension(tempSearchDimension);
    // }

    if (inputIsCity) {
      let city = input;
      let state = null;
      if (input.includes(', ')) {
        const splitArray = input.split(', ');
        city = splitArray[0];
        state = splitArray[1];
      } else if (input.includes(',')) {
        city = city.replace(',', '');
      }
      tempSearchDimension = 'city_state';
      searchObj = { city };
      if (state) searchObj.state = state;
      setSearchDimension(tempSearchDimension);
    }
  
    // if (noValidInput) return;

    if (Object.keys(searchObj).length) {
      fetchLocationOptions(tempSearchDimension, searchObj);
    }
  };
  
  // const getFlatZipArr = (zipDataOpts) => {
  //   let baseZipTable = null;
  
  //   const { statesList, cityZips } = Object.entries(zipDataOpts)
  //     .reduce((acc, [tempState, tempZips]) => {
  //       acc.statesList.push(tempState);
  
  //       // prefix city name property w/ state initial, avoids collisions
  //       const stateCityZipTable = Object.entries(tempZips)
  //         .reduce((zipTable, [city, zipArr]) => {
  //           zipTable[`${tempState}--${city}`] = zipArr;
  //           return zipTable;
  //         }, {});
  
  //       acc.cityZips.push(stateCityZipTable);
  //       return acc;
  //     }, { statesList: [], cityZips: [] });
  
  //   baseZipTable = cityZips[0];
  
  //   if (statesList.length > 1) {
  //     cityZips.forEach((cityZip) => {
  //       baseZipTable = { ...baseZipTable, ...cityZip };
  //     });
  //   }
  
  //   return Object.values(baseZipTable).flat();
  // };
  
  const fetchLocationOptions = async (searchCategory, queryObject) => {
    await instance
      .post('/dispatching/location-options/', { ...queryObject })
      .then((res) => {
        const result = res.data.options;
        // const tripleZips = [];
        // if (searchCategory === 'zip_codes') {
        //   const selectedZipsTable = {};
  
        //   selectedLocations.forEach((pick) => {
        //     if (pick.zip_codes) {
        //       selectedZipsTable[pick.zip_codes] = 1;
  
        //       if (pick.zip_codes.length === 3) {
        //         tripleZips.push(pick.zip_codes);
        //       }
        //     }
        //   });
  
        //   const zipLocationOptions = getFlatZipArr(result)
        //     .filter((zip) => !tripleZips.some((trip) => zip.startsWith(trip)))
        //     .sort((zipCodeA, zipCodeB) => (+zipCodeA) - (+zipCodeB)) // sort ASC
        //     .filter((zip) => !selectedZipsTable[zip]) // remove preselected
        //     .map((zip) => ({ zip_codes: zip })); // options object shape
          
        //   setLocationOptions(zipLocationOptions);
        // } else {
        const cities = [];
        const states = Object.keys(result);
        states.forEach((state) => Object.keys(result[state]).forEach((city) => cities.push({ city_state: `${city}, ${state}` })));
        setLocationOptions(cities);
      })
      .catch((err) => console.warn(err));
  };
  
  const timeout = useRef(null);
  useEffect(() => {
    clearTimeout(timeout.current);
  
    timeout.current = setTimeout(() => handleSearchInput(), 500);
  
    return () => clearTimeout(timeout.current);
  }, [input]);

  return (
    <Box sx={{ width: '100%' }}>
      <SearchAutoComplete
        label={label}
        // AutoCompleteProps={{ noOptionsText: getNoOptionsText(input) }}
        selectedLocations={selectedLocations}
        setSelectedLocations={setSelectedLocations}
        handleSearchChange={onSearchAutoCompleteChange}
        onInputChange={handleInputChange}
        getOptionLabel={(option) => option[searchDimension]}
        options={locationOptions}
        fmtRenderOptionsTxt={formatOptionsTxt}
        searchDimension={searchDimension}
        inputValue={input}
        startAdornment={false}
        setAllowAutocompleteLocationsUpdate={setAllowAutocompleteLocationsUpdate}
        inputSelectorId={inputSelectorId}
        multiple={multiple}
        sx={sx}
      />
    </Box>
  );
};

export default LocationSearch;
