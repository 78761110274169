import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ExpandMoreSharp from '@mui/icons-material/ExpandMoreSharp';
import { ODAvatar, ODNoDriverIcon, ODNoLoadIcon } from '@OptimalDynamics/core-ai-common-ui';
import { NoAvailableMatchesAccordionProps } from '../types';
import { DRIVER, LOAD } from '../helpers/constants';

export const NoAvailableMatchesAccordion = ({ type }: NoAvailableMatchesAccordionProps) => {
  const [expanded, setExpanded] = useState(true);

  let typeSet = {
    icon: <ODNoDriverIcon />,
    singular: DRIVER,
    verb: 'become available',
    pronoun: 'them'
  };
  if (type === DRIVER) {
    typeSet = {
      icon: <ODNoLoadIcon />,
      singular: LOAD,
      verb: 'be booked',
      pronoun: 'it'
    };
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', borderRadius: '4px', border: 1, borderColor: 'level2', }}>
      <Box
        sx={{ display: 'grid', grid: '48px / 18px auto 32px', alignItems: 'center', columnGap: '16px', padding: '0px 16px' }}
        onClick={() => setExpanded(!expanded)}
      >
        <Box sx={{ height: '32px', width: '32px', '> svg': { margin: '7px' } }}>{typeSet.icon}</Box>
        <Typography sx={{ ml: 2 }}>
          <strong>No Available Matches</strong>
        </Typography>
        <ODAvatar variant="transparent" size="small" onClick={() => setExpanded(!expanded)}>
          <ExpandMoreSharp sx={expanded ? { transform: 'rotate(0.5turn)' } : {}} />
        </ODAvatar>
      </Box>
      {expanded && (
        <Box sx={{ borderTop: 1, borderColor: 'level2', padding: '16px', whiteSpace: 'normal' }}>
          <Typography>
            {`There is no feasible ${typeSet.singular} match available in your network. Wait for more ${typeSet.singular}s to ${typeSet.verb}, or manually book a ${typeSet.singular} if there is an immediate need to have ${typeSet.pronoun} planned.`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
