import React, { useEffect, useRef, useState } from 'react';
import { ODSlideOut, ODToggle } from '@OptimalDynamics/core-ai-common-ui';
import { Box } from '@mui/material';
import SupervisorAccountSharp from '@mui/icons-material/SupervisorAccountSharp';
import { useSelector, useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FilterAccordion from '../../../common/FilterAccordion';
import AutoCompleteBox from '../../../common/AutoCompleteBox';
import SelectedOptions from '../../../common/SelectedOptions';
import { UpdateUserSettings } from '../../../store/actions';
import LocationSearch from '../../../common/LocationSearch';
import { TMS_REGION, CITY_STATE, STATE } from '../helpers/constants';

const DispatchingByDriversFilters = (props) => {
  const { open, onClose, setFilters, filters = null, filterOptions = {}, setAppliedFilterCount, setSelectedRows, driversList } = props;
  const [selectedDriverTypes, setSelectedDriverTypes] = useState([]);
  const [selectedDomiciles, setSelectedDomiciles] = useState([]);
  const [selectedDriverIds, setSelectedDriverIds] = useState([]);
  const [selectedDispatchLocations, setSelectedDispatchLocations] = useState([]);
  const [selectedLocationType, setSelectedLocationType] = useState(CITY_STATE);
  const [selectedRegionCodes, setSelectedRegionCodes] = useState([]);
  const [selectedTractorIds, setSelectedTractorIds] = useState([]);
  const [selectedDriverManagers, setSelectedDriverManagers] = useState([]);
  const userSettings = useSelector((state) => state.userSettings);
  const dispatchingManagers = useSelector((state) => state.dispatchingReducer.driverManagerOptions);
  const dispatch = useDispatch();
  const { useRegionCodesDispatching, showDispatchingOpsManagerFilter } = useFlags();

  // applies updated filters
  useEffect(() => {
    if (!userSettings?.userSettingsDataLoaded || !open) return;
    updateAppliedFilters();
  }, [
    selectedDriverTypes,
    selectedDomiciles,
    selectedDriverIds,
    selectedDispatchLocations,
    selectedTractorIds,
    selectedLocationType,
    selectedRegionCodes,
    selectedDriverManagers
  ]);

  // waits for disptaching filters prop to have a non-null value, then sets the filter values just once
  const firstDispatchingByDriversFiltersUpdate = useRef(true);  
  useEffect(() => {
    if (firstDispatchingByDriversFiltersUpdate.current && !!filters) {
      firstDispatchingByDriversFiltersUpdate.current = false;
      if (!!filters) {
        setSelectedDriverTypes(getDriverTypeFilterValues());
        setSelectedDomiciles(getDomicileFilterValues());
        setSelectedDriverIds(getDriverIdFilterValues());
        setSelectedDispatchLocations(getDispatchLocationFilterValues());
        setSelectedLocationType(getLocationTypeFilterValue());
        setSelectedRegionCodes(getRegionCodesFilterValues());
        setSelectedTractorIds(getTractorIdFilterValues());
        setSelectedDriverManagers(getDriverManagerFilterValues());
      }
    }
  }, [filters]);

  const updateAppliedFilters = () => {
    const updatedStoredFilters = {};
    let filterCountAdjustment = 0;
    if (!!selectedDriverTypes.length) {
      updatedStoredFilters.driver_type = selectedDriverTypes.map((type) => type.value);
    }
    if (!!selectedDomiciles.length) {
      updatedStoredFilters.home_domicile = selectedDomiciles.map((type) => type.value);
    }
    if (!!selectedDriverIds.length) {
      updatedStoredFilters.driver_id = selectedDriverIds.map((type) => type.driver_id);
    }
    if (!!selectedDriverManagers.length) {
      updatedStoredFilters.driver_managers = selectedDriverManagers.map((type) => type.driver_manager);
    }
    if (!useRegionCodesDispatching && !!selectedDispatchLocations.length) {
      const tempDispatchStates = selectedDispatchLocations.filter((loc) => Object.keys(loc)[0] === STATE).map((loc) => Object.values(loc)[0]);
      if (!!tempDispatchStates.length) {
        updatedStoredFilters.dispatch_state = tempDispatchStates;
      }
      const tempDispatchCities = selectedDispatchLocations.filter((loc) => Object.keys(loc)[0] === CITY_STATE).map((loc) => Object.values(loc)[0]);
      if (!!tempDispatchCities.length) {
        updatedStoredFilters.dispatch_location = tempDispatchCities;
      }
      if (!!tempDispatchStates.length && !!tempDispatchCities.length) filterCountAdjustment += 1;
    }
    if (useRegionCodesDispatching) {
      updatedStoredFilters.location_type = selectedLocationType;
      filterCountAdjustment += 1;
      if ((selectedLocationType === TMS_REGION) && !!selectedRegionCodes.length) {
        updatedStoredFilters.tms_region_codes = selectedRegionCodes.map((type) => type.value);
      } else if ((selectedLocationType === CITY_STATE) && !!selectedDispatchLocations.length) {
        const tempDispatchLocations = selectedDispatchLocations.map((loc) => loc?.state || loc?.city_state);
        updatedStoredFilters.dispatch_states_and_locations = tempDispatchLocations;
      }
    }
    if (!!selectedTractorIds.length) {
      updatedStoredFilters.tractor_id = selectedTractorIds.map((type) => type.value);
    }
    setSelectedRows([]);
    setFilters(updatedStoredFilters);
    dispatch(UpdateUserSettings({ ...userSettings, driverDispatchingFilters: updatedStoredFilters }));
    setAppliedFilterCount(Object.keys(updatedStoredFilters).length - filterCountAdjustment || 0);
  };

  const getLocationTypeFilterValue = () => filters?.location_type || selectedLocationType;

  const getRegionCodesFilterValues = () => filters?.tms_region_codes?.map((id) => ({ value: id })) || [];

  const getDriverTypeFilterValues = () => filters?.driver_type?.map((type) => ({ value: type })) || [];

  const getDomicileFilterValues = () => filters?.home_domicile?.map((domicile) => ({ value: domicile })) || [];

  const getDriverIdFilterValues = () => filters?.driver_id?.map((id) => ({ driver_id: id })) || [];
  
  const getDriverManagerFilterValues = () => filters?.driver_managers?.map((id) => ({ driver_manager: id })) || [];

  const getDispatchLocationFilterValues = () => {
    if (!useRegionCodesDispatching) {
      const cities = filters?.dispatch_location?.map((loc) => ({ city_state: loc })) || [];
      const states = filters?.dispatch_state?.map((loc) => ({ state: loc })) || [];
      return [...cities, ...states];
    }

    const locations = filters?.dispatch_states_and_locations;
    return locations?.map((loc) => {
      if (/^[A-Z]{2}$/.test(loc)) return { state: loc };
      return { city_state: loc };
    }) || [];
  };

  const getTractorIdFilterValues = () => filters?.tractor_id?.map((id) => ({ value: id })) || [];

  const onReset = () => {
    setSelectedDomiciles([]);
    setSelectedDriverTypes([]);
    setSelectedDriverIds([]);
    setSelectedDispatchLocations([]);
    setSelectedTractorIds([]);
    setSelectedLocationType(CITY_STATE);
    setSelectedRegionCodes([]);
    setSelectedDriverManagers([]);
    setFilters({});
    dispatch(UpdateUserSettings({ ...userSettings, driverDispatchingFilters: {} }));
    setAppliedFilterCount(0);
    setSelectedRows([]);
  };

  const removeSelection = (selectedValues, valuesSetter, key, value) => {
    const updatedArray = selectedValues.filter((selectedValue) => selectedValue[key] !== value[key]);
    valuesSetter(updatedArray);
  };

  return (
    <ODSlideOut
      title="Filters"
      sx={{ zIndex: 1300 }}
      open={open}
      onClose={onClose}
      actionText="Reset"
      onAction={onReset}
    >
      {showDispatchingOpsManagerFilter && (
        <Box sx={{ width: '100%' }}>
          <FilterAccordion
            title="Operations Manager"
            defaultExpanded={!!getDriverManagerFilterValues().length}
            sx={{ width: '100%', '& .MuiAccordionDetails-root': { mb: 2 }, '& .MuiAutocomplete-root': { mb: 2 } }}
          >
            <AutoCompleteBox
              title="Select Operations Manager"
              handleChange={setSelectedDriverManagers}
              value={getDriverManagerFilterValues()}
              autoCompleteList={dispatchingManagers}
              style={{ width: '100%' }}
              placeholder="Select Options"
              onClear={() => setSelectedDriverManagers([])}
              optionLabel="driver_manager"
              multiple
              startAdornment={false}
            />
            <SelectedOptions
              keyBase="manager-ids"
              value={getDriverManagerFilterValues()}
              itemKey="driver_manager"
              OptionSVGIcon={SupervisorAccountSharp}
              label="Operations Manager"
              removeOptionCB={(val) => removeSelection(getDriverManagerFilterValues(), setSelectedDriverManagers, 'driver_manager', val)}
            />
          </FilterAccordion>
        </Box>
      )}

      <Box sx={{ width: '100%' }}>
        <FilterAccordion
          title="Driver"
          defaultExpanded={!!getDriverIdFilterValues().length}
          sx={{ width: '100%', '& .MuiAccordionDetails-root': { mb: 2 }, '& .MuiAutocomplete-root': { mb: 2 } }}
        >
          <AutoCompleteBox
            title="Select Driver"
            handleChange={setSelectedDriverIds}
            value={getDriverIdFilterValues()}
            autoCompleteList={driversList}
            style={{ width: '100%' }}
            placeholder="Select Options"
            onClear={() => setSelectedDriverIds([])}
            optionLabel="driver_id"
            startAdornment={false}
          />
          <SelectedOptions
            keyBase="driver-ids"
            value={getDriverIdFilterValues()}
            itemKey="driver_id"
            label="Driver ID"
            removeOptionCB={(val) => removeSelection(getDriverIdFilterValues(), setSelectedDriverIds, 'driver_id', val)}
          />
        </FilterAccordion>
      </Box>

      <Box sx={{ width: '100%' }}>
        <FilterAccordion
          title="Tractor ID"
          defaultExpanded={!!getTractorIdFilterValues().length}
          sx={{ width: '100%', '& .MuiAccordionDetails-root': { mb: 2 }, '& .MuiAutocomplete-root': { mb: 2 } }}
        >
          <AutoCompleteBox
            title="Select Tractor Id"
            handleChange={setSelectedTractorIds}
            value={getTractorIdFilterValues()}
            autoCompleteList={filterOptions?.tractor_ids}
            style={{ width: '100%' }}
            placeholder="Select Tractor ID"
            onClear={() => setSelectedTractorIds([])}
            optionLabel="value"
            startAdornment={false}
          />
          <SelectedOptions
            keyBase="tractor-ids"
            value={getTractorIdFilterValues()}
            label="Tractor ID"
            removeOptionCB={(val) => removeSelection(getTractorIdFilterValues(), setSelectedTractorIds, 'value', val)}
          />
        </FilterAccordion>
      </Box>

      <Box sx={{ width: '100%' }}>
        <FilterAccordion
          title="Driver Type"
          sx={{ width: '100%', '& .MuiAccordionDetails-root': { mb: 2 }, '& .MuiAutocomplete-root': { mb: 2 } }}
          defaultExpanded={!!getDriverTypeFilterValues().length}
        >
          <AutoCompleteBox
            title="Select Driver Types"
            value={getDriverTypeFilterValues()}
            handleChange={setSelectedDriverTypes}
            autoCompleteList={filterOptions?.driver_types}
            placeholder="Select Options"
            onClear={() => setSelectedDriverTypes([])}
            optionLabel="value"
            startAdornment={false}
          />
          <SelectedOptions
            keyBase="driver-types"
            value={getDriverTypeFilterValues()}
            label="Driver Type"
            removeOptionCB={(val) => removeSelection(getDriverTypeFilterValues(), setSelectedDriverTypes, 'value', val)}
          />
        </FilterAccordion>
      </Box>

      <Box sx={{ width: '100%' }}>
        <FilterAccordion
          title="Home Location"
          sx={{ width: '100%', '& .MuiAccordionDetails-root': { mb: 2 }, '& .MuiAutocomplete-root': { mb: 2 } }}
          defaultExpanded={!!getDomicileFilterValues().length}
        >
          <AutoCompleteBox
            title="Select Home Location"
            value={getDomicileFilterValues()}
            handleChange={setSelectedDomiciles}
            autoCompleteList={filterOptions?.domiciles}
            placeholder="Select Options"
            onClear={() => setSelectedDomiciles([])}
            optionLabel="value"
            startAdornment={false}
          />
          <SelectedOptions
            keyBase="domiciles"
            value={getDomicileFilterValues()}
            label="Location"
            removeOptionCB={(val) => removeSelection(getDomicileFilterValues(), setSelectedDomiciles, 'value', val)}
          />
        </FilterAccordion>
      </Box>

      <Box sx={{ width: '100%' }}>
        <FilterAccordion
          title="Dispatch Location"
          sx={{ width: '100%', '& .MuiAccordionDetails-root': { mb: 2 }, '& .MuiAutocomplete-root': { mb: 2 } }}
          defaultExpanded={!!getDispatchLocationFilterValues().length || !!getRegionCodesFilterValues().length}
        >
          {useRegionCodesDispatching && (
            <ODToggle
              prompt="Select Location Type"
              selected={getLocationTypeFilterValue()}
              onChange={(_e, v) => setSelectedLocationType(v)}
              options={[
                { value: TMS_REGION, label: 'TMS Region' },
                { value: CITY_STATE, label: 'City / State' }
              ]}
              groupSx={{ marginBottom: '16px' }}
            />
          )}
          {selectedLocationType === TMS_REGION ? (
            <>
              <AutoCompleteBox
                title="Select TMS Region"
                value={getRegionCodesFilterValues()}
                handleChange={setSelectedRegionCodes}
                autoCompleteList={filterOptions?.region_codes}
                placeholder="Select Regions"
                onClear={() => setSelectedRegionCodes([])}
                optionLabel="value"
                startAdornment={false}
              />
              <SelectedOptions
                keyBase="dispatch-state"
                value={getRegionCodesFilterValues()}
                itemKey="value"
                label="Region"
                removeOptionCB={(val) => removeSelection(getRegionCodesFilterValues(), setSelectedRegionCodes, 'value', val)}
              />
            </>
          ) : (
            <>
              <LocationSearch
                label="Select Dispatch City or State"
                inputSelectorId="dispatch-location-autocomplete-box"
                selectedLocations={getDispatchLocationFilterValues()}
                setSelectedLocations={setSelectedDispatchLocations}
              />
              <SelectedOptions
                keyBase="dispatch-state"
                value={getDispatchLocationFilterValues().filter((loc) => Object.keys(loc)[0] === STATE) || []}
                itemKey={STATE}
                label="State"
                removeOptionCB={(val) => removeSelection(getDispatchLocationFilterValues(), setSelectedDispatchLocations, STATE, val)}
              />
              <SelectedOptions
                keyBase="dispatch-city"
                value={getDispatchLocationFilterValues().filter((loc) => Object.keys(loc)[0] === CITY_STATE) || []}
                itemKey={CITY_STATE}
                label={['City', 'Cities']}
                sx={{ marginTop: '16px' }}
                removeOptionCB={(val) => removeSelection(getDispatchLocationFilterValues(), setSelectedDispatchLocations, CITY_STATE, val)}
              />
            </>
          )}
          
        </FilterAccordion>
      </Box>
    </ODSlideOut>
  );
};

export default DispatchingByDriversFilters;
