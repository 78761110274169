import { SET_ALL_STATES } from '../constants/actionTypes';

const initialState = {
  allStates: [],
  statesDictByAbbr: {},
  statesDictByName: {},
};

const setStates = (state, { allStates }) => {
  const stateTables = allStates.reduce((dict, stateItem) => {
    const { statesDictByAbbr, statesDictByName } = dict;
    const { state: stateName, code: stateInitial } = stateItem;
    statesDictByAbbr[stateInitial] = stateName;
    statesDictByName[stateName] = stateInitial;
    return { ...dict, statesDictByAbbr, statesDictByName };
  }, {
    statesDictByAbbr: {},
    statesDictByName: {},
  });

  const { statesDictByAbbr, statesDictByName } = stateTables;

  return {
    ...state,
    statesDictByAbbr,
    statesDictByName,
    allStates
  };
};

const generalReducer = (state = initialState, action = null) => {
  if (!action) return state;
  switch (action.type) {
    case SET_ALL_STATES:
      return setStates(state, action);

    default:
      return state;
  }
};

export default generalReducer;
