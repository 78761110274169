import React, { useContext, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useMatch, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTheme, Box, Grid, Paper, Typography } from '@mui/material';
import InfoSharp from '@mui/icons-material/InfoSharp';
import Close from '@mui/icons-material/Close';
import Add from '@mui/icons-material/Add';
import ArrowForward from '@mui/icons-material/ArrowForward';
import LibraryAddCheck from '@mui/icons-material/LibraryAddCheck';
import PersonSharp from '@mui/icons-material/PersonSharp';
import { ODButton, ODLaneIcon, ODAlertBar } from '@OptimalDynamics/core-ai-common-ui/';
import { EmptyMoveIcon } from '../../../common/icons';
import AssignLoadsDialog from '../shared/AssignLoadsDialog';
import DriverLoadDetailSlideout from '../shared/DriverLoadDetailSlideout';
import DispatchingByDriversFilters from './DispatchingByDriversFilters';
import {
  fetchDispatchingDriverStatus,
  fetchDriverDispatchingFilterOptions,
  addLoadAssignment,
  clearLoadAssignments,
  setDriverStatus,
  fetchDriversList,
  fetchDispatchingDriverManagerFilterOptions,
  fetchDriversDispatchingData
} from '../../../store/actions/dispatchingAction';
import instance from '../../../utils/axios_instance';
import { DriverLoadDetailContext } from '../../../utils/context/driverLoadDetailContext';
import DetailedTableFooter from '../../../common/DetailedTableFooter';
import EngineRunAlert from '../../../common/EngineRunAlert';
import {
  PLANNED,
  AVAILABLE,
  EMPTY_TO_HOME,
  EMPTY_TO_LOADED,
  LOADED,
  MATCHES,
  OPTIMAL,
  DRIVER_AVAILABILITY,
  LOAD_AVAILABILITY,
  NON_OPTIMAL,
  REVENUE_PER_DRIVER,
  EMPTY_MILEAGE_PERCENT,
  LOADED_MILES_PER_DRIVER,
  BY_DRIVER,
  ASSIGNED,
  HOME_TIMES,
  ACCEPTABLE_MATCHES,
  RELAXED_MATCHES,
  NO_MATCHES,
  PROFIT_PER_DRIVER,
  NEEDS_SOURCING
} from '../helpers/constants';
import { DriverIdentifier } from '../helpers/dynamicDescription';
import { TabsGraphAccordion } from '../TabsGraphAccordion';
import DispatchingDriversTable from './DispatchingDriversTable';
import LoadsSlideout from './LoadsSlideout';
import { UpdateUserSettings } from '../../../store/actions';

const pollingDataFetchDelay = 4000;

function DispatchingByDrivers() {
  const {
    appliedFilterCount,
    closeOneWayBanner,
    showOneWayBanner,
    showRefreshErrorBanner,
    setRulesForDriverId,
    setDriverRulesSlideOutOpen,
    selectedByDriversTableHeaders,
    setRightDrawerOpen,
    dataUpdated,
    taskId,
    setHOSStatusData,
    topLoadsSlideoutOpen,
    setTopLoadsSlideoutOpen,
    filterPanelOpen,
    setFilterPanelOpen,
    setAppliedFilterCount,
    timeHorizonHours,
    tabsGraphAccordionExpanded,
    setExplainPtaDialogOpen
  } = useOutletContext();
  
  const {
    showDispatchingOpsManagerFilter,
    enableUiEngineRunAlert,
    macroShowProfit,
    showDispatchTotalProfit,
  } = useFlags();

  const theme = useTheme();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { driverId } = useParams();
  const dispatchingList = useSelector((state) => state.dispatchingReducer?.driversDispatchingList);
  const dispatchingListCount = useSelector((state) => state.dispatchingReducer?.driversDispatchingListCount);
  const driverStatus = useSelector((state) => state.dispatchingReducer?.driverStatus);
  const driverFilterOptions = useSelector((state) => state.dispatchingReducer?.driverFilterOptions);
  const driversList = useSelector((state) => state.dispatchingReducer?.driversList);
  const selectDriverIDs = useSelector((state) => state.dispatchingReducer?.selectDriverIDs);
  const selectDriverLoadTable = useSelector((state) => state.dispatchingReducer?.selectDriverLoadTable);
  const selectMovementTable = useSelector((state) => state.dispatchingReducer?.selectMovementTable);
  const userSettings = useSelector((state) => state.userSettings);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [selectedDriverId, setSelectedDriverId] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(null);
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [ordering, setOrdering] = useState('avail_datetime');
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [assignDialogOrigin, setAssignDialogOrigin] = useState(''); // 'main' | 'slideout'
  const [_, setRefreshTopLoads] = useState(false);
  const [refreshDashboard, setRefreshDashboard] = useState(false);
  const [loadStatus, _setLoadStatus] = useState(AVAILABLE);
  const viewType = searchParams.get('view_type') || OPTIMAL;
  const matchesDriver = useMatch('/dispatching/by-driver/:driverId');

  const { driverLoadDetailStatusObj, setDriverLoadDetailStatusObj } = useContext(DriverLoadDetailContext);

  useEffect(() => {
    setSearchParams({ view_type: OPTIMAL }, { state: location.state });
  }, []);

  const getHours = () => {
    const parsedHours = parseInt(timeHorizonHours, 10);
    if ((Number.isNaN(parsedHours)) || loadStatus === PLANNED) {
      return 960;
    }
    return parsedHours;
  };

  const getTabStatus = () => {
    switch (viewType) {
      case OPTIMAL:
        return { dispatch: OPTIMAL };
      case NON_OPTIMAL:
        return { dispatch: NON_OPTIMAL };
      case ASSIGNED:
        return {
          is_missing: false,
          add_missing_horizon: true
        };
      case NEEDS_SOURCING:
        return { has_source_request: true };
      default:
        return {};
    }
  };

  const loadMainDispatchingList = () => {
    dispatch(fetchDriversDispatchingData(
      rowsPerPage,
      (page * rowsPerPage),
      driverId ? ({ driver_id: [driverId] }) : ({
        ordering,
        hours: getHours(),
        ...getTabStatus(),
        ...filters
      }),
    ));
  };

  useEffect(() => {
    if (filtersLoaded && !!timeHorizonHours) {
      loadMainDispatchingList();
    }
  }, [dispatch, page, rowsPerPage, ordering, filters, timeHorizonHours, loadStatus, filtersLoaded, driverId, viewType]);

  useEffect(() => {
    if (!!driverId && rows.length === 1 && !!rows[0].active) {
      setSearchParams({ ...Object.fromEntries([...searchParams]), slideout_view_type: MATCHES }, { state: location.state });
      setSelectedDriverId(driverId);
    }
  }, [driverId, rows]);

  useEffect(() => {
    if (!refreshDashboard) return;
    if (!!dataUpdated) {
      loadMainDispatchingList();
    }
    setRefreshDashboard(false);
  }, [refreshDashboard]);

  useEffect(() => {
    setSelectedRows([]);
  }, [ordering]);

  useEffect(() => {
    dispatch(fetchDriverDispatchingFilterOptions());
    dispatch(fetchDriversList());
    if (showDispatchingOpsManagerFilter) {
      dispatch(fetchDispatchingDriverManagerFilterOptions());
    }
  }, [dispatch, showDispatchingOpsManagerFilter]);

  const firstDataUpdated = useRef(true);
  useEffect(() => {
    if (!dataUpdated) return;
    if (firstDataUpdated.current) {
      firstDataUpdated.current = false;
      return;
    }
    setTimeout(() => {
      setRefreshDashboard(true);
      setSelectedRows([]);
    }, pollingDataFetchDelay);
  }, [dataUpdated]);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (!filters || !timeHorizonHours) return;
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setPage(0);
    searchParams.delete('slideout_view_type');
    setSearchParams(searchParams, { state: location.state });
  }, [filters, timeHorizonHours, viewType]);

  useEffect(() => {
    if (!!driverLoadDetailStatusObj) {
      dispatch(setDriverStatus([]));
      dispatch(fetchDispatchingDriverStatus(driverLoadDetailStatusObj.driver_id));
    }
  }, [driverLoadDetailStatusObj, dispatch]);

  useEffect(() => {
    if (!userSettings?.userSettingsDataLoaded) return;
    const userAppliedFilters = userSettings?.driverDispatchingFilters || {};
    const extraLocationTypeFilterAdjustment = !!userAppliedFilters?.location_type ? 1 : 0;
    setAppliedFilterCount(Object.keys(userAppliedFilters).length - extraLocationTypeFilterAdjustment);
    setFilters({ ...userAppliedFilters });
    setFiltersLoaded(true);

    setRowsPerPage(userSettings.dispatchingRowsPerPage || 50);
    setOrdering(userSettings.dispatchingDriversSorting || 'avail_datetime');
  }, [userSettings.userSettingsDataLoaded]);

  useEffect(() => {
    if (viewType === NEEDS_SOURCING) {
      setOrdering('optimized_dispatch');
    } else {
      setOrdering(userSettings.dispatchingDriversSorting || 'avail_datetime');
    }
  }, [viewType]);

  const handleAssignLoadsClose = () => {
    setOpenAssignDialog(false);
  };

  const canShowAssignModal = (origin) => {
    if (origin === 'main') {
      const willShowAgain = userSettings.showAgain['dispatch-load-assign-confirmation'] ?? true;
      return willShowAgain;
    }

    return true;
  };

  const openLoadAssignModal = (origin = '') => {
    setAssignDialogOrigin(origin);
    setOpenAssignDialog(true);
  };

  useEffect(() => {
    setRows(dispatchingList);
  }, [dispatchingList]);

  const handleSelectedDriverStepThrough = (increment) => {
    const newDriverIndex = rows.findIndex((row) => row.driver.driver_id === selectedDriverId) + increment;
    const newDriverObj = rows[newDriverIndex].driver;
    if (!!driverLoadDetailStatusObj) {
      dispatch(setDriverStatus([]));
      setDriverLoadDetailStatusObj(newDriverObj);
    }
    setSelectedDriverId(newDriverObj.driver_id);
  };

  const getAssignments = () => selectDriverIDs.map((driver_id) => ({
    id: selectMovementTable[driver_id],
    load_id: selectDriverLoadTable[driver_id],
    driver_id,
  }));

  const postLoadAssignments = async () => {
    const assignments = getAssignments();
    const response = await instance.post('/dispatching/driver/load-assign/', { assignments });
    
    if (response && response.status === 200) {
      dispatch(clearLoadAssignments());
      setSelectedRows([]);
      dispatch(fetchDriversDispatchingData(
        rowsPerPage,
        (page * rowsPerPage),
        {
          ordering,
          hours: timeHorizonHours,
          ...filters
        },
      ));
    } else {
      console.error('Could not post to "/dispatching/driver/load-assign/". See postBody below: %o', { assignments });
    }
  };

  const handleMainDashAssignment = (assignmentRows) => {
    assignmentRows.forEach((row) => {
      const { movement_option_id: movementID, load_id: loadID, driver: { driver_id: driverID } } = row;
      const myLoadID = loadID === 'nan' ? '' : loadID;

      dispatch(addLoadAssignment(driverID, myLoadID, movementID));
    });

    openLoadAssignModal('main');
    
    if (!canShowAssignModal('main')) {
      postLoadAssignments();
    }
  };

  const handleAssignClick = () => {
    handleMainDashAssignment(selectedRows);
    setSelectedRows([]);
  };

  const markHeldRows = (row) => {
    row['*held'] = /h(e|o)ld/i.test(row['first_recommend_movement_type']);
    return row;
  };

  return (
    <>
      <AssignLoadsDialog 
        open={openAssignDialog}
        handleClose={handleAssignLoadsClose} 
        dialogOrigin={assignDialogOrigin}
        setRefreshTopLoads={setRefreshTopLoads}
        setTopLoadSlideoutOpen={setTopLoadsSlideoutOpen}
        setRefreshDashboard={setRefreshDashboard}
        clearSelectedRows={() => setSelectedRows([])}
      />
      <DriverLoadDetailSlideout
        open={!!driverLoadDetailStatusObj}
        handleClose={() => {
          setDriverLoadDetailStatusObj(null);
        }}
        driverDetail={driverStatus}
        driverName={<DriverIdentifier driver={driverLoadDetailStatusObj} />}
      />
      <DispatchingByDriversFilters
        open={filterPanelOpen}
        onClose={() => setFilterPanelOpen(false)}
        filters={filters}
        setFilters={setFilters}
        filterOptions={driverFilterOptions}
        setSelectedRows={setSelectedRows}
        setAppliedFilterCount={setAppliedFilterCount}
        driversList={driversList}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: `calc(100vh - ${matchesDriver ? 129 : 185}px)` }}>
        {(!driverId) && (
          <TabsGraphAccordion
            dispatchMethod={BY_DRIVER}
            viewType={viewType}
            expanded={tabsGraphAccordionExpanded}
            chartList={viewType === NON_OPTIMAL ?
              [DRIVER_AVAILABILITY, LOAD_AVAILABILITY, ACCEPTABLE_MATCHES, RELAXED_MATCHES, NO_MATCHES, HOME_TIMES]
              : [DRIVER_AVAILABILITY, LOAD_AVAILABILITY, macroShowProfit && showDispatchTotalProfit ? PROFIT_PER_DRIVER : REVENUE_PER_DRIVER, EMPTY_MILEAGE_PERCENT, LOADED_MILES_PER_DRIVER]}
          />
        )}
        <Box
          sx={{
            backgroundColor: 'colors.lightBlue0',
            p: 2,
            pb: 0,
            flex: '1 1 auto',
            display: 'flex',
            flexFlow: 'column',
            height: '100%',
            overflow: 'auto'
          }}
        >
          <Box sx={{ flex: '0 1 auto' }}>
            {showOneWayBanner && (
              <ODAlertBar icon={<InfoSharp sx={{ color: 'text.inverse' }} />} sx={{ '&.MuiAlert-root': { minHeight: 40, height: 'inherit', whiteSpace: 'normal', backgroundColor: 'optimalblue.main', mb: 2, '& .MuiAlert-message': { justifyContent: 'space-between' } } }}>
                Please assign these loads to the driver within your TMS to complete the dispatch & to clear them from this list. If load is not assigned in TMS within 30 mins, it will be added back as available.
                <Close sx={{ color: 'colors.white', mr: 2, cursor: 'pointer' }} onClick={() => closeOneWayBanner()} />
              </ODAlertBar>
            )}
            {enableUiEngineRunAlert && showRefreshErrorBanner && (
              <EngineRunAlert dataUpdated={dataUpdated} />
            )}
          </Box>
          <Grid container spacing={0} sx={{ flex: '1 1 auto', overflowY: 'auto', width: '100%' }}>
            <Grid item xs={12} style={{ padding: '0px' }}>
              <Paper elevation={0} sx={{ borderRadius: 0 }}>
                <DispatchingDriversTable
                  appliedFilterCount={appliedFilterCount}
                  headers={selectedByDriversTableHeaders}
                  drivers={rows.map(markHeldRows)}
                  onSelectRow={setSelectedDriverId}
                  ordering={ordering}
                  setOrdering={setOrdering}
                  syncTime={dataUpdated}
                  setDriverRulesSlideOutOpen={() => setDriverRulesSlideOutOpen(true)}
                  setRulesForDriverId={setRulesForDriverId}
                  selectedDriverId={selectedDriverId}
                />
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ borderTop: 1, borderColor: 'level3', flex: '0 1 56px', px: 2 }}>
          <DetailedTableFooter
            count={dispatchingListCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => {
              setPage(newPage);
              setTopLoadsSlideoutOpen(false);
              setRightDrawerOpen(false);
              document.querySelector('#dispatching-drivers-table-container').scrollTop = 0;
            }}
            onRowsPerPageChange={(e) => {
              setPage(0);
              setRowsPerPage(e.target.value);
              dispatch(UpdateUserSettings({ ...userSettings, dispatchingRowsPerPage: e.target.value }));
            }}
            idPrefix="dispatching-drivers"
            lastSynced={dataUpdated}
            taskId={taskId}
            refreshError={showRefreshErrorBanner}
          />
        </Box>
        {!!selectedRows.length && (
          <Box sx={{ position: 'absolute', bottom: 56, left: 0, width: topLoadsSlideoutOpen ? 'calc(100% - 471px)' : '100%' }}>
            <ODAlertBar
              variant="listAction"
              icon={selectedRows.length === 1 ? <PersonSharp /> : <LibraryAddCheck />}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {selectedRows.length === 1 ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography fontWeight={600}>{selectedRows[0].driver.driver_id}</Typography>
                      {!selectedRows.some((row) => !row.active) && (
                        <Typography fontWeight={600} sx={{ display: 'flex', alignItems: 'center' }}>
                          <ArrowForward style={{ paddingLeft: '16px' }} />
                          {selectedRows[0]?.first_recommend_movement_type === LOADED
                            ? <ODLaneIcon style={{ padding: '0 16px' }} />
                            : <EmptyMoveIcon style={{ fill: 'white', padding: '0 16px' }} />}
                          {selectedRows[0]?.first_recommend_movement_type === LOADED && selectedRows[0]?.load_id}
                          {selectedRows[0]?.first_recommend_movement_type === EMPTY_TO_HOME && 'ETH'}
                          {selectedRows[0]?.first_recommend_movement_type === EMPTY_TO_LOADED && 'ETL'}
                        </Typography>
                      )}
                    </div>
                  ) : (
                    <Typography fontWeight={600}>
                      {`(${selectedRows.length}) Drivers Selected`}
                    </Typography>
                  )}
                </div>
                <div>
                  {selectedRows.length === 1 && (
                    <ODButton
                      variant="700"
                      endIcon={<Add />}
                      onClick={() => {
                        setRulesForDriverId(selectedRows[0].driver.driver_id);
                        setDriverRulesSlideOutOpen(true);
                        setSelectedRows([]);
                      }}
                    >
                      Create Driver Rule
                    </ODButton>
                  )}
                  {selectedRows.some((row) => !row.active) ? (
                    <ODButton
                      variant="blue"
                      endIcon={<ArrowForward color={theme.palette.text.disabled} />}
                      onClick={() => console.warn('This functionality has been removed!')}
                      sx={{ ml: '8px' }}
                    >
                      {selectedRows.length === 1 ? 'Assign Load to Driver' : 'Assign Loads to Drivers'}
                    </ODButton>
                  ) : (
                    <ODButton
                      variant="blue"
                      endIcon={<ArrowForward sx={{ color: 'colors.white' }} />}
                      onClick={() => handleAssignClick()}
                      sx={{ ml: '8px' }}
                    >
                      {selectedRows.length === 1 ? 'Assign Load to Driver' : 'Assign Loads to Drivers'}
                    </ODButton>
                  )}
                </div>
              </div>
            </ODAlertBar>
          </Box>
        )}

        <LoadsSlideout
          index={rows.findIndex((row) => row.driver.driver_id === selectedDriverId)}
          currentDriver={rows.find((row) => row.driver.driver_id === selectedDriverId)}
          onClose={() => {
            searchParams.delete('slideout_view_type');
            setSearchParams(searchParams);
            setSelectedDriverId(null);
          }}
          handleStepThrough={handleSelectedDriverStepThrough}
          count={rows.length}
          setRefreshDashboard={setRefreshDashboard}
          setHOSStatusData={setHOSStatusData}
          setExplainPtaDialogOpen={setExplainPtaDialogOpen}
        />
      </Box>
    </>
  );
}

export default DispatchingByDrivers;
