import {
  SIGNIN_START,
  SIGNIN_SUCCESS,
  SIGNIN_FAIL,
  SIGNOUT,
  USER_DATA,
  LAUNCH_DARKLY_USER_IDENTIFIED
} from '../constants/actionTypes';
import instance from '../../utils/axios_instance';
import cookies from '../../utils/cookies';
import draftStore from '../../utils/draftStore';

export const SignInStart = () => ({
  type: SIGNIN_START,
});

export const SignInSuccess = ({ token, error }) => ({
  type: SIGNIN_SUCCESS,
  token,
  error,
});

export const SignInFail = ({ error }) => ({
  type: SIGNIN_FAIL,
  error,
});

export const tokenExpired = () => ({
  type: SIGNIN_FAIL,
  error: 'Your login session expired.',
});

export const signOut = () => async (dispatch) => {
  dispatch({ type: SIGNOUT });
};

export const signIn = (username, password) => async (dispatch) => {
  dispatch(SignInStart());
  try {
    const res = await instance.post('/token/', { username, password });
    dispatch(SignInSuccess({ token: res.data.token, error: null }));
    cookies.set('token', res.data.token);
  } catch (err) {
    if (err && err.response) {
      return dispatch(SignInFail({
        error: 'Unable to log in with the provided credentials',
      }));
    }
    dispatch(SignInFail({ error: 'Error with login endpoint' }));
  }
};

export const SetUserData = (userData) => ({
  type: USER_DATA,
  userData,
});

export const GetUserData = () => async (dispatch) => {
  try {
    const response = await instance.get('/user-data/');
    dispatch(SetUserData(response.data.user));
  } catch (err) { console.error(err); }
};

export const GetUserSettings = () => async (_dispatch) => {
  try {
    const localUserSettings = draftStore.get('user-settings');
    const response = await instance.get('/user-settings/');
    draftStore.set('user-settings', {
      ...response.data,
      ...localUserSettings,
    });
  } catch (err) { console.error(err); }
};

export const OldUpdateUserSettings = () => async (_dispatch) => {
  try {
    const settings = draftStore.get('user-settings');
    await instance.post('/user-settings/', settings);
  } catch (err) { console.error(err); }
};

export const SetLaunchDarklyUserIdentified = (isIdentified) => ({
  type: LAUNCH_DARKLY_USER_IDENTIFIED,
  isIdentified
});
