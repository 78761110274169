import { onGetEngineRun, onGetEngineRunUpdate } from '../actions/notificationAction';
import { GET_ENGINE_RUN_UPDATE } from '../constants/actionTypes';
import API from '../../utils/axios_instance';
import * as types from '../constants/actionTypes';

const ENGINE_RUN_UPDATE_TIMEOUT = 4000; 

const notificationsWare = (store) => (next) => (action) => {
  const result = next(action);
  switch (action.type) {
    case GET_ENGINE_RUN_UPDATE:
      setTimeout(() => {
        store.dispatch({ type: types.CLEAR_ENGINE_RUN_UPDATE_LOADING });
      }, ENGINE_RUN_UPDATE_TIMEOUT);
      API.post(`/load-acceptance/?limit=1`, {
        limit: 1,
        load_status: 'RECOM'
      }).then((response) => {
        let engineRunId = 1;
        let engineRunUpdate = null;
        if (response.data.results.length > 0) {
          engineRunId = response.data.results[0].engine_run.id;
          engineRunUpdate = response.data.results[0].engine_run.updated_at;
        }
        store.dispatch(onGetEngineRun(engineRunId));
        store.dispatch(onGetEngineRunUpdate(engineRunUpdate));
      }).catch(() => {
        store.dispatch(onGetEngineRun(0));
      });
      break;
    default:
      return result;
  }
};

export default notificationsWare;
