/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { createAction } from '@reduxjs/toolkit';
import {
  LOADS_SUCCESS,
  GET_LOAD_BY_ID_SUCCESS,
  GET_PICKUP_TYPE,
  GET_DELIVERY_TYPE,
  GET_CUSTOMER_ID,
  GET_LOAD_TYPE,
  GET_SCENARIO_LIST,
  GET_LAZY_LOAD_ACCEPTANCE,
  GET_ENGINE_RULES,
  UPDATE_ENGINE_RULE_ERROR,
  SHOW_SCENARIO_LOADER,
  GET_ENGINE_RULE_BY_RULE_ID,
  SET_SHOW_DRIVER_MOVEMENT,
  GET_PICKUP_STATE_LIST,
  GET_DESTINATION_STATE_LIST,
  SET_EXTENDED_TOUR_DATA,
  GET_FILTER_OPTIONS_LOAD_IDS,
  GET_FILTER_OPTIONS_REGION_CODES,
  CHECK_OR_SET_LA_DATA,
  LOAD_ACCEPTANCE_POLLING,
  SET_IS_FETCHING_LOAD_COUNTS,
  SET_LA_VIEW_TYPES,
  SET_OPERATIONS_USER_FILTER_OPTIONS,
} from '../constants/actionTypes';
import instance from '../../utils/axios_instance';
import { LOADS_TO_CHANGE, viewtypeOptionsDefault, viewtypeOptionsTender } from '../../components/loadAcceptance/loadAcceptanceConstants';

export const loadsSuccess = (data) => ({
  type: LOADS_SUCCESS,
  data,
});

export const fetchLoadAcceptance = (filter, orgUseTenderedLaWorkflow16) => async (dispatch) => {
  dispatch(loadsSuccess({
    loadAcceptance: null,
  }));
  const { geographic_filter } = filter;
  delete filter.geographic_filter;
  const params = { ...filter, ...geographic_filter };
  dispatch(fetchLoadListCounts(params, orgUseTenderedLaWorkflow16));
  await instance.post(
    `/load-acceptance/loads/?limit=${filter.limit}&offset=${filter.offset}`,
    params
  ).then((response) => {
    for (let i = 0; i < response.data.results.length; i++) {
      response.data.results[i].selected = false;
    }
    dispatch(loadsSuccess({
      loadAcceptance: response.data.results,
      loadCount: response.data.count,
      engineRunId: response.data.results?.[0]?.engine_run
    }));
  }).catch((err) => console.error(err));
};

const setIsFetchingCounts = (boolean) => ({
  type: SET_IS_FETCHING_LOAD_COUNTS,
  isFetchingLoadCounts: boolean
});

const setViewTypeCounts = (viewTypes) => ({
  type: SET_LA_VIEW_TYPES,
  viewTypes
});

export const fetchLoadListCounts = (params, orgUseTenderedLaWorkflow16) => async (dispatch) => {
  dispatch(setIsFetchingCounts(true));
  await instance
    .post('/load-acceptance/loads/list-metadata/', params)
    .then((res) => {
      const listMetadata = res.data;
      const viewTypeObj = orgUseTenderedLaWorkflow16 ? viewtypeOptionsTender : viewtypeOptionsDefault;
      Object.entries(listMetadata).forEach(([vkey, loadCountObj]) => {
        if (!loadCountObj) return;
        if (!('action-recommended' in loadCountObj) || !('total' in loadCountObj)) return;

        if (!!viewTypeObj[vkey]) {
          viewTypeObj[vkey].figureA = listMetadata[vkey]['action-recommended'];
          if (vkey !== LOADS_TO_CHANGE) {
            viewTypeObj[vkey].figureB = listMetadata[vkey].total; 
          }
        }
      });
      dispatch(setViewTypeCounts(viewTypeObj));
    })
    .catch((err) => console.error(err));
  dispatch(setIsFetchingCounts(false));
};

export const fetchMapBoxKey = () => async (dispatch) => {
  try {
    const res = await instance.get('/map/mapbox-key/');
    if (res && res.status === 200) {
      dispatch(loadsSuccess({
        mapBoxKey: res.data.key,
      }));
    }
  } catch (error) {
    dispatch(loadsSuccess({
      mapBoxKey: '',
    }));
  }
};

const getLoadDataByLoadIdSuccess = (load) => ({
  type: GET_LOAD_BY_ID_SUCCESS,
  load,
});

export const getLoadDataByLoadId = (loadId) => async (dispatch) => {
  dispatch(ShowScenarioLoader(true));
  try {
    const response = await instance.get(`/load-acceptance/load-detail/${loadId}/`);
    dispatch(getLoadDataByLoadIdSuccess(response.data));
  } catch (error) {
    console.error(error);
  }
  dispatch(ShowScenarioLoader(false));
};

const GetPickupTypeSuccess = (pickupType) => ({
  type: GET_PICKUP_TYPE,
  pickupType,
});

export const GetPickupType = () => async (dispatch) => {
  try {
    const response = await instance.get(`load-acceptance/locations/origin-citystates?limit=1000&offset=0`);
    const origin = [];
    response.data.results.forEach((item) => {
      origin.push({ title: `${item.city}, ${item.state}` });
    });
    dispatch(GetPickupTypeSuccess(origin));
  } catch (error) {
    if (error && error.response) {
      dispatch(GetPickupTypeSuccess([]));
    } else {
      dispatch(GetPickupTypeSuccess([]));
    }
  }
};

const GetDeliverySuccess = (deliveryType) => ({
  type: GET_DELIVERY_TYPE,
  deliveryType,
});

export const GetDeliveryType = () => async (dispatch) => {
  try {
    const response = await instance.get(`load-acceptance/locations/destination-citystates?limit=1000&offset=0`);
    const destination = [];
    response.data.results.forEach((item) => {
      destination.push({ title: `${item.city}, ${item.state}` });
    });
    dispatch(GetDeliverySuccess(destination));
  } catch (error) {
    if (error && error.response) {
      dispatch(GetDeliverySuccess([]));
    } else {
      dispatch(GetDeliverySuccess([]));
    }
  }
};

const GetCustomerIdsSuccess = (customerIds) => ({
  type: GET_CUSTOMER_ID,
  customerIds,
});

export const GetCustomerIds = () => async (dispatch) => {
  try {
    const response = await instance.get(`load-acceptance/customer-ids`);
    dispatch(GetCustomerIdsSuccess(response.data.results));
  } catch (error) {
    if (error && error.response) {
      dispatch(GetCustomerIdsSuccess([]));
    } else {
      dispatch(GetCustomerIdsSuccess([]));
    }
  }
};

const GetLoadTypeSuccess = (loadType) => ({
  type: GET_LOAD_TYPE,
  loadType,
});

export const GetLoadType = () => async (dispatch) => {
  try {
    const response = await instance.get(`load-acceptance/load-types`);
    dispatch(GetLoadTypeSuccess(response.data.results));
  } catch (error) {
    if (error && error.response) {
      dispatch(GetLoadTypeSuccess([]));
    } else {
      dispatch(GetLoadTypeSuccess([]));
    }
  }
};

export const GetScenarioListSuccess = (scenarios) => ({
  type: GET_SCENARIO_LIST,
  scenarios,
});

export const GetScenarioList = (params) => async (dispatch) => {
  dispatch(ShowScenarioLoader(true));
  try {
    let selectedScenario = null;
    const response = await instance.get(`/load_detail_scenarios/scenario-data/${params.engine_run}/?load_id=${params.load_id}`);
    if (params.scenario_key) {
      response.data.scenarios.forEach((element) => {
        if (
          element.tours.length > 0
            && element.tours[0].scenario_key === params.scenario_key
        ) {
          selectedScenario = element;
          element.selected = true;
          element.showDriverMovement = params.showDriverMovement;
          element.disabled = false;
        } else {
          element.selected = false;
          element.showDriverMovement = false;
          element.disabled = params.showDriverMovement;
        }
      });
    } else {
      response.data.scenarios.forEach((element, idx) => {
        if (idx === 0) {
          element.selected = true;
          element.showDriverMovement = false;
          element.disabled = false;
        } else {
          element.selected = false;
          element.showDriverMovement = false;
          element.disabled = params.showDriverMovement;
        }
      });
    }
    dispatch(GetScenarioListSuccess({
      scenarios: response.data.scenarios,
      showDriverMovement: params.showDriverMovement,
      selectedScenario,
    }));
  } catch (error) {
    dispatch(GetScenarioListSuccess({
      scenarios: [],
      showDriverMovement: false,
    }));
  }
  dispatch(ShowScenarioLoader(false));
};

export const setExtendedTourData = (data) => ({
  type: SET_EXTENDED_TOUR_DATA,
  data,
});

export const fetchExtendedTourData = (engineRun, loadId, scenarioKey) => async (dispatch) => {
  dispatch(ShowScenarioLoader(true));
  await instance
    .get(`/load_detail_scenarios/driver-tour/${engineRun}/?load_id=${loadId}&scenario_key=${scenarioKey}`)
    .then((res) => dispatch(setExtendedTourData(res.data)))
    .catch(() => setExtendedTourData(null));
  dispatch(ShowScenarioLoader(false));
};

export const SetShowDriverMovement = (show) => ({
  type: SET_SHOW_DRIVER_MOVEMENT,
  show,
});

export const onGetLazyLoadAcceptance = (loads) => ({
  type: GET_LAZY_LOAD_ACCEPTANCE,
  loads,
});

export const GetLazyLoadAcceptance = (filter) => async (dispatch) => {
  dispatch(ShowScenarioLoader(true));
  try {
    const response = await instance.post(
      `/load-acceptance/?limit=${filter.limit}&offset=${filter.offset}`,
      filter
    );
    for (let i = 0; i < response.data.results.length; i++) {
      response.data.results[i].selected = false;
    }
    dispatch(onGetLazyLoadAcceptance(response.data.results));
  } catch (error) {
    dispatch(onGetLazyLoadAcceptance([]));
  }
  dispatch(ShowScenarioLoader(false));
};

export const GetEngineRulesSuccess = (engineRules) => ({
  type: GET_ENGINE_RULES,
  engineRules,
});

export const GetEngineRules = (ruleId = null) => async (dispatch) => {
  dispatch(ShowScenarioLoader(true));
  try {
    let url = '/load-acceptance/load-acceptance-engine-rules';
    if (ruleId && ruleId > 0) {
      url = `/load-acceptance/load-acceptance-engine-rules/${ruleId}`;
    }
    const response = await instance.get(url);
    dispatch(GetEngineRulesSuccess(response.data.results));
  } catch (error) {
    dispatch(GetEngineRulesSuccess([]));
  }
  dispatch(ShowScenarioLoader(false));
};

export const UpdateEngineRuleError = (error) => ({
  type: UPDATE_ENGINE_RULE_ERROR,
  error,
});

export const UpdateEngineRules = (id, data) => async (dispatch) => {
  try {
    const response = await instance.put(
      `/load-acceptance/load-acceptance-engine-rules/${id}`,
      data
    );

    if (response && response.error) {
      dispatch(UpdateEngineRuleError('Error'));
    }
    dispatch(GetEngineRules());
  } catch (error) {
    dispatch(UpdateEngineRuleError('The rule you’re about to turn on is in direct conflict of another active rule, it will automatically be turned off when you activate your current selection.'));
  }
};

export const DeleteEngineRules = (id) => async (dispatch) => {
  try {
    await instance.delete(`/load-acceptance/load-acceptance-engine-rules/${id}`);
    dispatch(GetEngineRules());
  } catch (error) {
    dispatch(UpdateEngineRuleError('You do not have permission to delete the rule'));
  }
};

export const ShowScenarioLoader = (show) => ({
  type: SHOW_SCENARIO_LOADER,
  show,
});

export const GetEngineRuleByIdSuccess = (engineRule) => ({
  type: GET_ENGINE_RULE_BY_RULE_ID,
  engineRule,
});

export const GetEngineRuleById = (ruleId) => async (dispatch) => {
  try {
    const response = await instance.get(`/load-acceptance/load-acceptance-engine-rules/${ruleId}`);
    dispatch(GetEngineRuleByIdSuccess(response.data));
  } catch (error) {
    dispatch(GetEngineRuleByIdSuccess({}));
  }
};

export const GetPickupStateSuccess = (pickupStates) => ({
  type: GET_PICKUP_STATE_LIST,
  pickupStates,
});

export const GetPickupStates = () => async (dispatch) => {
  try {
    const response = await instance.get('/load-acceptance/pickup-states-dropdown');
    const pickupStates = [];
    response.data.results.forEach((item) => {
      const newItem = { title: item.origin_state };
      pickupStates.push(newItem);
    });
    dispatch(GetPickupStateSuccess(pickupStates));
  } catch (error) {
    if (error && error.response) {
      dispatch(GetPickupStateSuccess([]));
    } else {
      dispatch(GetPickupStateSuccess([]));
    }
  }
};

export const GetDestinationStateSuccess = (destinationStates) => ({
  type: GET_DESTINATION_STATE_LIST,
  destinationStates,
});

export const GetDestinationStates = (originState = []) => async (dispatch) => {
  try {
    const response = await instance.post(
      '/load-acceptance/destination-states-dropdown',
      {
        origin_state: originState,
      }
    );
    const deliveryStates = [];
    response.data.results.forEach((item) => {
      const newItem = { title: item.destination_state };
      deliveryStates.push(newItem);
    });
    dispatch(GetDestinationStateSuccess(deliveryStates));
  } catch (error) {
    if (error && error.response) {
      dispatch(GetDestinationStateSuccess([]));
    } else {
      dispatch(GetDestinationStateSuccess([]));
    }
  }
};

export const initDropDownFiltersLA = createAction('LA_GET_DROPDOWN_FILTERS_INIT');

export const getFilterOptionLoadIDs = (payload) => ({
  type: GET_FILTER_OPTIONS_LOAD_IDS,
  payload
});

export const fetchLoadIDFilterOptions = (loadStatus = 'RECOM') => async (dispatch) => {
  instance.get(`/load-acceptance/load-id-dropdown?load_status=${loadStatus}`)
    .then((res) => dispatch(getFilterOptionLoadIDs(res.data.results.map((id) => ({ value: id })))))
    .catch((err) => {
      console.error(err);
      dispatch(getFilterOptionLoadIDs([`ERROR OCCURRED:\n${err}`]));
    });
};

export const GetRegionCodesSuccess = (regionCodesFilterOptions) => ({
  type: GET_FILTER_OPTIONS_REGION_CODES,
  regionCodesFilterOptions
});

export const GetRegionCodes = () => async (dispatch) => {
  instance.get('/load-acceptance/region-codes-dropdown')
    .then((res) => dispatch(GetRegionCodesSuccess(res.data.results.region_codes.map((id) => ({ value: id })))))
    .catch((err) => {
      console.error(err);
      dispatch(GetRegionCodesSuccess([`ERROR OCCURRED:\n${err}`]));
    });
};

export const pollLoadAcceptance = () => ({
  type: LOAD_ACCEPTANCE_POLLING
});

export const checkForNewLoadAcceptanceData = (dataUpdatedObj) => ({
  type: CHECK_OR_SET_LA_DATA,
  dataUpdatedObj
});

export const setOperationsUserFilterOptions = (options) => ({
  type: SET_OPERATIONS_USER_FILTER_OPTIONS,
  options
});

export const fetchOperationsUserFilterOptions = () => async (dispatch) => {
  await instance
    .get('/load-acceptance/dropdown-ops-user-options')
    .then((res) => {
      const asObject = res.data.results.map((mgr) => ({ operations_user: mgr }));
      dispatch(setOperationsUserFilterOptions(asObject));
    })
    .catch((err) => console.error(err));
};
