import orderBy from 'lodash/orderBy';
import { viewtypeOptionsDefault } from '../../components/loadAcceptance/loadAcceptanceConstants';
import {
  LOADS_SUCCESS,
  LOADS_CLEAR,
  GET_LOAD_BY_ID_SUCCESS,
  CLEAR_LOAD_DETAI_BY_ID,
  GET_PICKUP_TYPE,
  GET_DELIVERY_TYPE,
  GET_CUSTOMER_ID,
  GET_LOAD_TYPE,
  GET_SCENARIO_LIST,
  GET_LAZY_LOAD_ACCEPTANCE,
  GET_ENGINE_RULES,
  UPDATE_ENGINE_RULE_ERROR,
  SHOW_SCENARIO_LOADER,
  GET_ENGINE_RULE_BY_RULE_ID,
  SET_DRIVER_TOUR,
  SET_SHOW_DRIVER_MOVEMENT,
  GET_DESTINATION_STATE_LIST,
  GET_PICKUP_STATE_LIST,
  SHOW_CREATE_RULE,
  SET_EXTENDED_TOUR_DATA,
  GET_FILTER_OPTIONS_LOAD_IDS,
  GET_FILTER_OPTIONS_REGION_CODES,
  CHECK_OR_SET_LA_DATA,
  STOP_POLLING_LOAD_ACCEPTANCE,
  SET_LA_VIEW_TYPES,
  SET_IS_FETCHING_LOAD_COUNTS,
  SET_OPERATIONS_USER_FILTER_OPTIONS
} from '../constants/actionTypes';

const initialState = {
  loads: [],
  loadAcceptance: [],
  load: {},
  error: null,
  loading: false,
  mapBoxKey: null,
  pickupType: [],
  loadType: [],
  customerIds: [],
  deliveryType: [],
  scenarios: [],
  lazyLoadAcceptance: [],
  engineRules: [],
  ruleError: null,
  showScenarioLoader: false,
  ruleStatus: null,
  engineRule: null,
  driverTour: null,
  showDriverMovement: false,
  destinationStates: [],
  pickupStates: [],
  selectedScenario: null,
  extendedTour: null,
  selectedLoads: [],
  loadIDFilterOptions: [],
  regionCodesFilterOptions: [],
  isPollingDataUpdating: false,
  dataUpdated: null,
  taskId: null,
  hasSyncing: false,
  isFetchingLoadCounts: false,
  viewTypes: viewtypeOptionsDefault,
  operationsUserOptions: [],
  engineRunId: null
};

const loadsSuccess = (state, action) => ({
  ...state,
  loading: false,
  error: null,
  ...action.data,
  isPollingDataUpdating: false
});

const loadsClear = (state) => ({ ...state, ...initialState });

const getLoadDataByLoadIdSuccess = (state, action) => ({
  ...state,
  load: action.load,
});

const clearLoadDetalById = (state) => ({
  ...state,
  load: null,
});

const getPickupType = (state, action) => {
  const sortedData = orderBy(action.pickupType, 'city');
  return {
    ...state,
    pickupType: sortedData,
  };
};

const getLoadType = (state, action) => ({
  ...state,
  loadType: action.loadType,
});

const getCustomerIds = (state, action) => {
  const sortedData = orderBy(action.customerIds, 'shipper_id');
  return {
    ...state,
    customerIds: sortedData,
  };
};

const getDeliveryType = (state, action) => {
  const sortedData = orderBy(action.deliveryType, 'city');
  return {
    ...state,
    deliveryType: sortedData,
  };
};

const setScenarioList = (state, action) => ({
  ...state,
  scenarios: action.scenarios.scenarios,
  showDriverMovement: action.scenarios.showDriverMovement,
  selectedScenario: action.scenarios.selectedScenario,
});

const setLazyLoadAcceptance = (state, action) => {
  const updateddata = [...state.lazyLoadAcceptance, ...action.loads];
  return {
    ...state,
    lazyLoadAcceptance: updateddata,
  };
};

const setEngineRules = (state, action) => ({
  ...state,
  engineRules: action.engineRules,
});

const updateEngineRuleError = (state, action) => ({
  ...state,
  ruleError: action.error,
});

const setEngineRuleById = (state, action) => ({
  ...state,
  engineRule: action.engineRule,
});

const setDriverTour = (state, action) => ({
  ...state,
  driverTour: action.driverTour,
});

const setShowDriverMovement = (state, action) => ({
  ...state,
  showDriverMovement: action.show,
});

const setPickupStateList = (state, action) => ({
  ...state,
  pickupStates: action.pickupStates,
});

const setDestinationStateList = (state, action) => ({
  ...state,
  destinationStates: action.destinationStates,
});

const setShowCreateRule = (state) => ({
  ...state,
  ruleError: null,
});

const setExtendedTourData = (state, { data }) => ({
  ...state,
  extendedTour: data
});

const setFilterOptionLoadIds = (state, { payload: loadIDFilterOptions }) => ({
  ...state,
  loadIDFilterOptions
});

const setFilterOptionRegionCodes = (state, { regionCodesFilterOptions }) => ({
  ...state,
  regionCodesFilterOptions
});

const checkOrSetData = (state, { dataUpdatedObj }) => {
  const { dataUpdated: prevUpdate } = state;

  if (prevUpdate === dataUpdatedObj.recommendations_updated) {
    return state;
  }

  return {
    ...state,
    dataUpdated: dataUpdatedObj.recommendations_updated,
    hasSyncing: dataUpdatedObj.has_syncing,
    taskId: dataUpdatedObj.task_id,
    isPollingDataUpdating: true
  };
};

const stopPollingLA = (state) => ({
  ...state,
  isPollingDataUpdating: false,
});

const setIsFetchingLoadCounts = (state, { isFetchingLoadCounts }) => ({
  ...state,
  isFetchingLoadCounts
});

const setLaViewTypes = (state, { viewTypes }) => ({
  ...state,
  viewTypes
});

const setOperationsUserFilterOptions = (state, { options }) => ({
  ...state,
  operationsUserOptions: options
});

const loadAcceptanceReducer = (state = initialState, action = null) => {
  if (!action) return state;
  switch (action.type) {
    case LOADS_SUCCESS:
      return loadsSuccess(state, action);
    case LOADS_CLEAR:
      return loadsClear(state, action);
    case GET_LOAD_BY_ID_SUCCESS:
      return getLoadDataByLoadIdSuccess(state, action);
    case CLEAR_LOAD_DETAI_BY_ID:
      return clearLoadDetalById(state, action);
    case GET_PICKUP_TYPE:
      return getPickupType(state, action);
    case GET_LOAD_TYPE:
      return getLoadType(state, action);
    case GET_CUSTOMER_ID:
      return getCustomerIds(state, action);
    case GET_DELIVERY_TYPE:
      return getDeliveryType(state, action);
    case GET_SCENARIO_LIST:
      return setScenarioList(state, action);
    case GET_LAZY_LOAD_ACCEPTANCE:
      return setLazyLoadAcceptance(state, action);
    case GET_ENGINE_RULES:
      return setEngineRules(state, action);
    case UPDATE_ENGINE_RULE_ERROR:
      return updateEngineRuleError(state, action);
    case SHOW_SCENARIO_LOADER:
      return { ...state, showScenarioLoader: action.show };
    case GET_ENGINE_RULE_BY_RULE_ID:
      return setEngineRuleById(state, action);
    case SET_DRIVER_TOUR:
      return setDriverTour(state, action);
    case SET_SHOW_DRIVER_MOVEMENT:
      return setShowDriverMovement(state, action);
    case GET_DESTINATION_STATE_LIST:
      return setDestinationStateList(state, action);
    case GET_PICKUP_STATE_LIST:
      return setPickupStateList(state, action);
    case SHOW_CREATE_RULE:
      return setShowCreateRule(state, action);
    case SET_EXTENDED_TOUR_DATA:
      return setExtendedTourData(state, action);
    case GET_FILTER_OPTIONS_LOAD_IDS:
      return setFilterOptionLoadIds(state, action);
    case GET_FILTER_OPTIONS_REGION_CODES:
      return setFilterOptionRegionCodes(state, action);
    case CHECK_OR_SET_LA_DATA:
      return checkOrSetData(state, action);
    case STOP_POLLING_LOAD_ACCEPTANCE:
      return stopPollingLA(state);
    case SET_IS_FETCHING_LOAD_COUNTS:
      return setIsFetchingLoadCounts(state, action);
    case SET_LA_VIEW_TYPES:
      return setLaViewTypes(state, action);
    case SET_OPERATIONS_USER_FILTER_OPTIONS:
      return setOperationsUserFilterOptions(state, action);
    default:
      return state;
  }
};

export default loadAcceptanceReducer;
