import axios from 'axios';
import EnvConfig from '../config/config';
import cookies from './cookies';

const instance = axios.create({
  baseURL: EnvConfig.getConfig().apiUrl,
  timeout: 100000,
  headers: { 'Content-Type': 'application/json' },
});

instance.interceptors.request.use(
  (config) => {
    const subdomain = window.location.hostname === 'localhost' ? '' : `${window.location.hostname.split('.')[0]}_`;
    if (config.url) {
      if (!['/token-logout/', '/token/'].includes(config.url)) { 
        config.headers.Authorization = `Bearer ${cookies.get(`${subdomain}token`)}`; 
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) { window.location.pathname = '/login'; }
    return Promise.reject(error);
  }
);

export default instance;
