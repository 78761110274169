import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FlagSharp from '@mui/icons-material/FlagSharp';
import { useSearchParams } from 'react-router-dom';
import {
  DetailCardTimeline,
  AvailableTimeRow,
  HomeRow,
  LocationRow,
  StopRow,
  Mileage,
  generateTimestamp
} from '../DetailCard/DetailCardTimeline';
import { getLocalizedTimeRange } from '../../../utils/datetimes';
import {
  DROPOFF,
  EMPTY_TO_HOME,
  HOME,
  LOADED,
  PICKUP,
  RECOMMENDED_HOME,
  SCHEDULED_HOME,
  SOURCE,
  STOP
} from '../helpers/constants';
import { getOriginLabel, getDestinationLabel } from '../helpers/dispatchingScripts';
import { LoadsSlideoutCardTimelineProps, Submove } from '../types';

export const LoadsSlideoutCardTimeline = ({ selected, currentDriver, loadOption, showAvailable, openHosDialog, openExplainPtaDialog }: LoadsSlideoutCardTimelineProps) => {
  const { showSourceSuggestions, usePtaAtTimeOfRequest } = useFlags();
  const [searchParams] = useSearchParams();
  const slideoutViewType = searchParams.get('slideout_view_type');

  if (!currentDriver) return null;
  const isSource = showSourceSuggestions && loadOption.has_source;
  const sourceSubmove = isSource ? loadOption?.submoves?.find((move) => !!move?.source_search)?.source_search : null;

  const renderSubmoves = (submoves: Submove[]) => (
    <>
      {submoves[0].type === LOADED && (
        <LocationRow
          label={getOriginLabel(loadOption.orig_live_flag, isSource)}
          start={loadOption.pickup_start_time}
          end={loadOption.pickup_end_time}
          estimates={isSource
            ? {
              EPU: generateTimestamp(sourceSubmove?.pickup, sourceSubmove?.pickup_tz)
            } : {
              ELT: getLocalizedTimeRange(loadOption.pickup_est_loading_start_time, loadOption.pickup_est_loading_end_time, loadOption.pickup_timezone),
              ETD: generateTimestamp(loadOption.est_pickup_time, loadOption.pickup_timezone)
            }}
          timezone={loadOption.pickup_timezone}
          location={`${loadOption.pickup_city}, ${loadOption.pickup_state}`}
          mileage={loadOption.deadhead_miles}
          hideAppointment={isSource}
        />
      )}
      {submoves.map((move) => {
        switch (move.end_location_type) {
          case PICKUP:
            return (
              <LocationRow
                label={getOriginLabel(loadOption.orig_live_flag, isSource)}
                start={loadOption.pickup_start_time}
                end={loadOption.pickup_end_time}
                estimates={isSource ? {
                  EPU: generateTimestamp(sourceSubmove?.pickup, sourceSubmove?.pickup_tz)
                } : {
                  ELT: getLocalizedTimeRange(loadOption.pickup_est_loading_start_time, loadOption.pickup_est_loading_end_time, move.end_timezone),
                  ETD: generateTimestamp(loadOption.est_pickup_time, move.end_timezone)
                }}
                timezone={move.end_timezone}
                location={move.end_city_state}
                mileage={move.distance}
                hideAppointment={isSource}
              />
            );
          case DROPOFF:
            return (
              <LocationRow
                label={getDestinationLabel(loadOption.dest_drop_flag, isSource)}
                start={loadOption.dropoff_start_time}
                end={loadOption.dropoff_end_time}
                estimates={isSource ? {
                  EDO: generateTimestamp(sourceSubmove?.dropoff, sourceSubmove?.dropoff_tz)
                } : {
                  ETA: generateTimestamp(loadOption.est_dropoff_time, move.end_timezone),
                  EULT: getLocalizedTimeRange(loadOption.dropoff_est_unloading_start_time, loadOption.dropoff_est_unloading_end_time, move.end_timezone)
                }}
                timezone={move.end_timezone}
                location={move.end_city_state}
                mileage={move.distance}
                icon={<FlagSharp />}
                hideAppointment={isSource}
              />
            );
          case HOME:
          case SCHEDULED_HOME:
          case RECOMMENDED_HOME:
            if (move.type.includes(HOME)) {
              const type = move.end_location_type;
              return (
                <HomeRow
                  label={`${type === SCHEDULED_HOME ? 'Scheduled ' : type === RECOMMENDED_HOME ? 'Recommended ' : ''}Home Time`}
                  time={move.start_time}
                  timezone={move.start_timezone}
                  location={move.start_city_state}
                  timeWindow={isSource
                    ? getLocalizedTimeRange(sourceSubmove?.pickup, sourceSubmove?.dropoff, sourceSubmove?.pickup_tz)
                    : getLocalizedTimeRange(move.start_time, move.end_time, move.start_timezone)}
                />
              );
            }
            return <Mileage mileage={move.distance} />;
          case STOP:
            return (
              <StopRow
                estimate={move.end_time}
                timezone={move.end_timezone}
                location={move.end_city_state}
                mileage={move.distance}
              />
            );
          default:
            return <></>;
        }
      })}
    </>
  );

  const renderClassic = () => {
    if (loadOption.movement_type === EMPTY_TO_HOME) {
      let location = '';
      if (loadOption.destination_city && loadOption.destination_state) {
        location = `${loadOption.destination_city}, ${loadOption.destination_state}`;
      }

      return (
        <>
          <Mileage mileage={loadOption.deadhead_miles} />
          <HomeRow
            label="Home Time"
            time={loadOption.est_dropoff_time}
            timezone={loadOption.dropoff_timezone}
            location={location}
          />
        </>
      );
    }

    return (
      <>
        <LocationRow
          label={getOriginLabel(loadOption.orig_live_flag, isSource)}
          start={loadOption.pickup_start_time}
          end={loadOption.pickup_end_time}
          estimates={isSource ? {
            EPU: generateTimestamp(loadOption.source_pickup, loadOption.pickup_timezone)
          } : {
            ETD: generateTimestamp(loadOption.est_pickup_time, loadOption.pickup_timezone)
          }}
          timezone={loadOption.pickup_timezone}
          location={`${loadOption.pickup_city}, ${loadOption.pickup_state}`}
          mileage={showAvailable ? loadOption.deadhead_miles : undefined}
          hideAppointment={isSource}
        />
        <LocationRow
          label={getDestinationLabel(loadOption.dest_drop_flag, isSource)}
          start={loadOption.dropoff_start_time}
          end={loadOption.dropoff_end_time}
          estimates={isSource ? {
            EDO: generateTimestamp(loadOption.source_dropoff, loadOption.dropoff_timezone)
          } : {
            ETA: generateTimestamp(loadOption.est_dropoff_time, loadOption.dropoff_timezone)
          }}
          timezone={loadOption.dropoff_timezone}
          location={`${loadOption.destination_city}, ${loadOption.destination_state}`}
          mileage={loadOption.loaded_miles}
          icon={<FlagSharp />}
          hideAppointment={isSource}
        />
      </>
    );
  };

  return (
    <DetailCardTimeline selected={selected} isSource={isSource}>
      {showAvailable && (
        <AvailableTimeRow
          available={
            (usePtaAtTimeOfRequest && !!loadOption.avail_for_dispatch_et) && slideoutViewType === SOURCE
              ? loadOption.avail_for_dispatch_et
              : currentDriver.avail_for_dispatch_et
            }
          timezone={
            (usePtaAtTimeOfRequest && !!loadOption.avail_timezone) && slideoutViewType === SOURCE
              ? loadOption.avail_timezone
              : currentDriver.avail_timezone
            }
          hos={isSource ? undefined : currentDriver.hos_status}
          openHosDialog={openHosDialog}
          openExplainPtaDialog={openExplainPtaDialog}
          location={
            (usePtaAtTimeOfRequest && !!loadOption.predispatch_final_loc) && slideoutViewType === SOURCE
              ? loadOption.predispatch_final_loc
              : currentDriver.predispatch_final_loc
            }
          isSource={isSource && slideoutViewType === SOURCE}
        />
      )}

      {loadOption?.submoves?.length > 0 ? renderSubmoves(loadOption.submoves) : renderClassic()}
    </DetailCardTimeline>
  );
};
