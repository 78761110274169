/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import {
  CLEAR_NOTIFICATION,
  GET_ALERT,
  GET_NOTIFICATION_BY_FILTER,
  SET_ENGINE_RUN,
  SET_LA_VIEW,
  GET_LOADS_NOTIFICATION_BY_FILTER,
  SET_ENGINE_RUN_UPDATE,
  GET_ENGINE_RUN_UPDATE
} from '../constants/actionTypes';

import API from '../../utils/axios_instance';

const onGetAlerts = (alert) => ({
  type: GET_ALERT,
  alert,
});
export const GetAlerts = (engineRun) => async (dispatch) => {
  try {
    const response = await API.get(`/notification/?notification_level=alert&engine_run=${engineRun}&notification_read=false`);
    dispatch(onGetAlerts(response.data));
  } catch (err) {
    if (err && err.response) {
      return dispatch(onGetAlerts({ results: [], count: 0 }));
    }
    dispatch(onGetAlerts({ results: [], count: 0 }));
  }
};

const onGetNotificationByFilter = (notification) => ({
  type: GET_NOTIFICATION_BY_FILTER,
  notification,
});
export const GetNotificationByFilter = (filter) => async (dispatch) => {
  try {
    dispatch(ClearNotifications());
    const query = [];
    for (const key in filter) query.push(`${key}=${filter[key]}`);
    const response = await API.get(`/notification/?${query.join('&')}`);
    dispatch(onGetNotificationByFilter(response.data));
  } catch (err) {
    if (err && err.response) {
      return dispatch(onGetNotificationByFilter({ results: [], count: 0 }));
    }
    dispatch(onGetNotificationByFilter({ results: [], count: 0 }));
  }
};
export const ClearNotifications = () => ({
  type: CLEAR_NOTIFICATION,
});

export const onGetEngineRun = (engineRun) => ({
  type: SET_ENGINE_RUN,
  engineRun,
});

export const onGetEngineRunUpdate = (engineRunUpdate) => ({
  type: SET_ENGINE_RUN_UPDATE,
  engineRunUpdate,
});

export const GetEngineRun = () => ({
  type: GET_ENGINE_RUN_UPDATE
});

export const SetLoadAcceptanceView = (colorMode) => ({
  type: SET_LA_VIEW,
  colorMode,
});

export const SetNotificationStatus = (id) => async () => {
  await API.put(`/notification/${id}/`, {
    notification_read: true,
  });
};

const onGetLoadsNotificationByFilter = (notification) => ({
  type: GET_LOADS_NOTIFICATION_BY_FILTER,
  notification,
});
export const GetLoadsNotificationByFilter = (filter) => async (dispatch) => {
  try {
    dispatch(ClearNotifications());
    const query = [];
    for (const key in filter) query.push(`${key}=${filter[key]}`);
    const response = await API.get(`/notification/?${query.join('&')}`);
    dispatch(onGetLoadsNotificationByFilter(response.data));
  } catch (err) {
    if (err && err.response) {
      return dispatch(onGetLoadsNotificationByFilter({ results: [], count: 0 }));
    }
    dispatch(onGetLoadsNotificationByFilter({ results: [], count: 0 }));
  }
};
