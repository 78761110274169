import React from 'react';
import CheckCircleOutlineSharp from '@mui/icons-material/CheckCircleOutlineSharp';
import CheckCircleSharp from '@mui/icons-material/CheckCircleSharp';

export enum TopLevelTabType {
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED',
}

export const TopLevelTabChoices = [TopLevelTabType.UNASSIGNED, TopLevelTabType.ASSIGNED];

export interface TopLevelTabMetaData {
  numAssigned: number | null;
  numUnassigned: number | null;
}

export const TOP_LEVEL_TAB_LIST = (tabMetaData: TopLevelTabMetaData) => [
  { value: TopLevelTabType.ASSIGNED, label: `(${tabMetaData.numAssigned == null ? '-' : tabMetaData.numAssigned}) Assigned`, icon: <CheckCircleSharp sx={{ color: 'success.main' }} /> },
  { value: TopLevelTabType.UNASSIGNED, label: `(${tabMetaData.numUnassigned == null ? '-' : tabMetaData.numUnassigned}) Unassigned`, icon: <CheckCircleOutlineSharp sx={{ color: 'optimalblue.main' }} /> }
];
