import React from 'react';
import { Box } from '@mui/material';
import InfoSharp from '@mui/icons-material/InfoSharp';
import ODInfoBlurb from './ODInfoBlurb';
import { getLocalizedDataUpdateDatetime } from '../utils/datetimes';

export const ENGINE_RUN_ERROR_THRESHOLD_MS = 14400000;
const ENGINE_RUN_ERROR_THRESHOLD = '4 hours';

const EngineRunAlert = ({ dataUpdated }: { dataUpdated: string }) => (
  <Box sx={{ mb: 2 }}>
    <ODInfoBlurb variant="error" icon={<InfoSharp />}>
      {`Results below have not been updated since ${getLocalizedDataUpdateDatetime(dataUpdated)}. No new data or optimal decisions have been provided in over ${ENGINE_RUN_ERROR_THRESHOLD}. The issue is known and a fix is in progress.`}
    </ODInfoBlurb>
  </Box>
);

export default EngineRunAlert;
