import React, { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { ODLoader } from '@OptimalDynamics/core-ai-common-ui';
import { SetLaunchDarklyUserIdentified } from '../store/actions/auth';

const LDUserConfig = () => {
  const client = useLDClient();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const { userId, organization, userEmail } = authState;
  const showScenarioLoader = useSelector((state) => state.loads?.showScenarioLoader);
  
  // sets user with launch darkly when that data becomes available
  useEffect(() => {
    if (userId && organization && userEmail) {
      client.identify({
        key: userId,
        email: userEmail,
        custom: { organization }
      }, null, (err) => {
        if (!err) {
          dispatch(SetLaunchDarklyUserIdentified(true));
        } else {
          dispatch(SetLaunchDarklyUserIdentified(false));
        }
      });
    }
  }, [userId, organization, userEmail, client]);

  // sets user with full story when that data becomes available
  useEffect(() => {
    if (userEmail) {
      window.FS.identify(userId, {
        email: userEmail
      });
    }
  }, [userEmail]);

  return <ODLoader open={showScenarioLoader} text="Please wait, loading..." sx={{ zIndex: 2000 }} />;
};

export default LDUserConfig;
