/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useContext, useRef } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box, Grid, Paper, Typography } from '@mui/material';
import InfoSharp from '@mui/icons-material/InfoSharp';
import Close from '@mui/icons-material/Close';
import ArrowForward from '@mui/icons-material/ArrowForward';
import LibraryAddCheck from '@mui/icons-material/LibraryAddCheck';
import Person from '@mui/icons-material/Person';
import { ODLaneIcon, ODButton, ODAlertBar } from '@OptimalDynamics/core-ai-common-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useMatch, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import {
  fetchLoadDispatchingFilterOptions,
  fetchLoadsDispatchingData,
  batchAddAssignByLoad,
  setDriverStatus,
  fetchDropdownLoadOptions,
  fetchDispatchingDriverStatus
} from '../../../store/actions/dispatchingAction';
import DriverLoadDetailSlideout from '../shared/DriverLoadDetailSlideout';
import DispatchingByLoadsFilters from './DispatchingByLoadsFilters';
import AssignDriversDialogContainer from '../shared/AssignDriversDialog';
import DispatchingLoadsTable from './DispatchingLoadsTable';
import { TabsGraphAccordion } from '../TabsGraphAccordion';
import { useLocalStorage } from '../../../utils/hooks';
import DriversSlideout from './DriversSlideout';
import draftStore from '../../../utils/draftStore';
import { DriverLoadDetailContext } from '../../../utils/context/driverLoadDetailContext';
import LoadDetailsSlideout from '../shared/LoadDetailsSlideout';
import DetailedTableFooter from '../../../common/DetailedTableFooter';
import {
  MATCHES,
  OPTIMAL,
  NON_OPTIMAL,
  DRIVER_AVAILABILITY,
  LOAD_AVAILABILITY,
  AVG_RATE_PER_MILE,
  TOTAL_LOADED_MILES,
  TOTAL_LINE_HAUL_REVENUE,
  BY_LOAD,
  ACCEPTABLE_MATCHES,
  RELAXED_MATCHES,
  NO_MATCHES,
  ASSIGNED,
  TOTAL_PROFIT
} from '../helpers/constants';
import { DriverIdentifier } from '../helpers/dynamicDescription';
import EngineRunAlert from '../../../common/EngineRunAlert';
import { UpdateUserSettings } from '../../../store/actions';

export const useTempDispatchLoadFilters = () => useLocalStorage('tempDispatchLoadFilters', null);
const pollingDataFetchDelay = 4000;

function DispatchingByLoads() {
  const {
    selectedByLoadsTableHeaders,
    dataUpdated,
    taskId,
    closeOneWayBanner,
    showOneWayBanner,
    showRefreshErrorBanner,
    setRightDrawerOpen,
    setHOSStatusData,
    setExplainPtaDialogOpen,
    topDriversSlideoutOpen,
    setTopDriversSlideoutOpen,
    filterPanelOpen,
    setFilterPanelOpen,
    setAppliedFilterCount,
    timeHorizonHours,
    tabsGraphAccordionExpanded,
    appliedFilterCount
  } = useOutletContext();

  const { enableUiEngineRunAlert, macroShowProfit, showDispatchTotalProfit } = useFlags();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { loadId } = useParams();
  const engineRunId = useSelector((state) => state.dispatchingReducer?.engineRunId);
  const userSettings = useSelector((state) => state.userSettings);
  const driverStatus = useSelector((state) => state.dispatchingReducer?.driverStatus);
  const dispatchingList = useSelector((state) => state.dispatchingReducer?.loadsDispatchingList);
  const dispatchingListCount = useSelector((state) => state.dispatchingReducer?.loadsDispatchingListCount);

  const [offeredLoadDetails, setOfferedLoadDetails] = useState({});
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(null);
  const [ordering, setOrdering] = useState('pickup_start_et');
  const [refreshDashboard, setRefreshDashboard] = useState(false);
  const [selectedLoadId, setSelectedLoadId] = useState(null);
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [assignDialogOrigin, setAssignDialogOrigin] = useState(''); // 'main' | 'slideout'
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const viewType = searchParams.get('view_type') || OPTIMAL;
  const matchesLoad = useMatch('/dispatching/by-load/:loadId');

  const memoEnableAssignments = useMemo(() => selectedRows.some((row) => !row.assigned), [selectedRows]);

  const { driverLoadDetailStatusObj, setDriverLoadDetailStatusObj } = useContext(DriverLoadDetailContext);
  
  useEffect(() => {
    setSearchParams({ view_type: OPTIMAL }, { state: location.state });
  }, []);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (!filters || !timeHorizonHours) return;
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setPage(0);
  }, [filters, timeHorizonHours, viewType]);

  useEffect(() => {
    if (!!driverLoadDetailStatusObj) {
      dispatch(setDriverStatus([]));
      dispatch(fetchDispatchingDriverStatus(driverLoadDetailStatusObj.driver_id));
    }
  }, [driverLoadDetailStatusObj, dispatch]);

  useEffect(() => {
    if (!userSettings?.userSettingsDataLoaded) return;
    const userAppliedFilters = userSettings?.loadDispatchingFilters || {};
    const sessionFilters = draftStore.getSession('dispatchingLoadFilters') || {};
    const filterCount = () => {
      let count = 0;
      const keys = Object.keys({ ...userAppliedFilters, ...sessionFilters });
      if (!!keys.filter((key) => key.includes('pickup') || key.includes('dropoff')).length) {
        count += 1;
      }
      count += keys.filter((key) => !key.includes('pickup') && !key.includes('dropoff') && !key.includes('type')).length;
      return count;
    };
    setAppliedFilterCount(filterCount());
    setFilters({ ...userAppliedFilters, ...sessionFilters });
    setFiltersLoaded(true);

    setRowsPerPage(userSettings.dispatchingRowsPerPage || 50);
    setOrdering(userSettings.dispatchingLoadsSorting || 'pickup_start_et');
  }, [userSettings.userSettingsDataLoaded]);

  const getHours = () => {
    const parsedHours = parseInt(timeHorizonHours, 10);
    if (Number.isNaN(parsedHours)) {
      return 960;
    }
    return parsedHours;
  };

  const loadMainDispatchingList = () => {
    dispatch(fetchLoadsDispatchingData(
      rowsPerPage,
      (page * rowsPerPage),
      loadId ? ({ load_id: [loadId] }) : ({
        ordering,
        hours: getHours(),
        ...(viewType !== ASSIGNED ? { dispatch: viewType } : {}),
        ...filters
      })
    ));
  };

  useEffect(() => {
    if (filtersLoaded && !!timeHorizonHours) {
      loadMainDispatchingList();
    }
  }, [dispatch, page, rowsPerPage, ordering, filters, timeHorizonHours, filtersLoaded, loadId, viewType]);

  useEffect(() => {
    if (!!loadId && rows.length === 1) {
      if (!rows[0]?.assigned) {
        setSearchParams({ slideout_view_type: MATCHES }, { state: location.state });
      }
      setSelectedLoadId(loadId);
    }
  }, [loadId, rows]);

  const firstRefreshDashboard = useRef(true);
  useEffect(() => {
    if (firstRefreshDashboard.current) {
      firstRefreshDashboard.current = false;
      return;
    }
    if (!refreshDashboard) return;
    if (!!dataUpdated) {
      loadMainDispatchingList();
    }
    setRefreshDashboard(false);
  }, [refreshDashboard]);

  const firstDataUpdated = useRef(true);
  useEffect(() => {
    if (firstDataUpdated.current) {
      firstDataUpdated.current = false;
      return;
    }
    if (!dataUpdated) return;
    setTimeout(() => {
      setRefreshDashboard(true);
      setSelectedRows([]);
    }, pollingDataFetchDelay);
  }, [dataUpdated]);

  useEffect(() => {
    setRows(() => {
      // eslint-disable-next-line
      dispatchingList[0]?.hasOwnProperty('loaded_miles') && console.info('Ready to complete ticket ODOT-1927 using the "loaded_miles" property');
      return dispatchingList;
    });
  }, [dispatchingList]);

  useEffect(() => {
    if (engineRunId) {
      dispatch(fetchLoadDispatchingFilterOptions(engineRunId));
      dispatch(fetchDropdownLoadOptions());
    }
  }, [dispatch, engineRunId]);

  const handleLoadStepThrough = (unit) => {
    const newLoadIndex = rows.findIndex((row) => row.load_id === selectedLoadId) + unit;
    const newLoadId = rows[newLoadIndex].load_id;
    setSelectedLoadId(newLoadId);
  };

  const isSelected = (row) => selectedRows.findIndex((selectedRow) => selectedRow.load_id === row.load_id) !== -1;

  const handleOpenAssignDialog = (allow) => {
    if (!allow) return; 
    setSelectedRows([]);

    const movementIds = [];
    const driverIds = [];
    const loadIds = [];

    selectedRows.forEach((row) => {
      const { movement_option_id, load_id, driver: { driver_id } } = row;

      loadIds.push(load_id);
      movementIds.push(movement_option_id);
      driverIds.push(driver_id);
    });
    setAssignDialogOrigin('main');
    setOpenAssignDialog(true);
    dispatch(batchAddAssignByLoad(loadIds, driverIds, movementIds));
  };

  return (
    <>
      <LoadDetailsSlideout 
        open={!!Object.keys(offeredLoadDetails)?.length}
        load={offeredLoadDetails}
        onClose={() => setOfferedLoadDetails({})}
      />
      <DispatchingByLoadsFilters
        open={filterPanelOpen}
        onClose={() => setFilterPanelOpen(false)}
        filters={filters}
        setFilters={setFilters}
        setSelectedRows={setSelectedRows}
        setAppliedFilterCount={setAppliedFilterCount}
      />
      <DriverLoadDetailSlideout
        open={!!driverLoadDetailStatusObj}
        handleClose={() => setDriverLoadDetailStatusObj(null)}
        driverDetail={driverStatus}
        driverName={<DriverIdentifier driver={driverLoadDetailStatusObj} />}
      />
      <AssignDriversDialogContainer
        open={openAssignDialog}
        handleClose={() => setOpenAssignDialog(false)}
        clearSelectedRows={() => setSelectedRows([])}
        setRefreshDashboard={setRefreshDashboard}
        dialogOrigin={assignDialogOrigin}
        setTopDriverSlideoutOpen={setTopDriversSlideoutOpen}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: `calc(100vh - ${matchesLoad ? 129 : 185}px)` }}>
        {(!loadId) && (
          <TabsGraphAccordion
            dispatchMethod={BY_LOAD}
            viewType={viewType}
            expanded={tabsGraphAccordionExpanded}
            chartList={viewType === NON_OPTIMAL ?
              [LOAD_AVAILABILITY, DRIVER_AVAILABILITY, ACCEPTABLE_MATCHES, RELAXED_MATCHES, NO_MATCHES]
              : [LOAD_AVAILABILITY, DRIVER_AVAILABILITY, AVG_RATE_PER_MILE, TOTAL_LOADED_MILES, macroShowProfit && showDispatchTotalProfit ? TOTAL_PROFIT : TOTAL_LINE_HAUL_REVENUE]}
          />
        )}
        <Box
          sx={{
            backgroundColor: 'colors.lightBlue0',
            p: 2,
            pb: 0,
            flex: '1 1 auto',
            display: 'flex',
            flexFlow: 'column',
            height: '100%',
            overflow: 'auto'
          }}
        >
          <Box sx={{ flex: '0 1 auto' }}>
            {showOneWayBanner && (
              <ODAlertBar icon={<InfoSharp sx={{ color: 'text.inverse' }} />} sx={{ '&.MuiAlert-root': { height: 40, backgroundColor: 'optimalblue.main', mb: 2, '& .MuiAlert-message': { justifyContent: 'space-between' } } }}>
                Please assign these loads to the driver within your TMS to complete the dispatch & to clear them from this list. If load is not assigned in TMS within 30 mins, it will be added back as available.
                <Close sx={{ color: 'colors.white', mr: 2, cursor: 'pointer' }} onClick={() => closeOneWayBanner()} />
              </ODAlertBar>
            )}
            {enableUiEngineRunAlert && showRefreshErrorBanner && (
              <EngineRunAlert dataUpdated={dataUpdated} />
            )}
          </Box>
          <Grid container spacing={0} sx={{ flex: '1 1 auto', overflowY: 'auto' }}>
            <Grid item xs={12} style={{ padding: '0px' }}>
              <Paper elevation={0} sx={{ borderRadius: 0 }}>
                <DispatchingLoadsTable
                  loads={rows}
                  appliedFilterCount={appliedFilterCount}
                  headers={selectedByLoadsTableHeaders}
                  ordering={ordering}
                  setOrdering={setOrdering}
                  onSelectRow={setSelectedLoadId}
                  selectedLoadId={selectedLoadId}
                  onClickLoad={(load) => {
                    setOfferedLoadDetails({
                      load_id: load.load_id,
                      load_data: {
                        revenue: load.revenue,
                        pickup_appt_type: load.pickup_appt_type,
                        dropoff_appt_type: load.dropoff_appt_type
                      }
                    });
                  }}
                  isSelected={isSelected}
                />
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ borderTop: 1, borderColor: 'level3', flex: '0 1 56px', px: 2 }}>
          <DetailedTableFooter
            count={dispatchingListCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => {
              setPage(newPage);
              setTopDriversSlideoutOpen(false);
              setRightDrawerOpen(false);
              document.querySelector('#dispatching-loads-table-container').scrollTop = 0;
            }}
            onRowsPerPageChange={(e) => {
              setPage(0);
              setRowsPerPage(e.target.value);
              dispatch(UpdateUserSettings({ ...userSettings, dispatchingRowsPerPage: e.target.value }));
            }}
            idPrefix="dispatching-loads"
            lastSynced={dataUpdated}
            taskId={taskId}
            refreshError={showRefreshErrorBanner}
          />
        </Box>
        {!!selectedRows.length && (
          <Box sx={{ position: 'absolute', bottom: 16, left: 0, width: topDriversSlideoutOpen ? 'calc(100% - 471px)' : '100%' }}>
            <ODAlertBar
              variant="listAction" 
              icon={selectedRows.length === 1 ? (<ODLaneIcon />) : (<LibraryAddCheck />)}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Typography fontWeight={600}>
                  {selectedRows.length === 1 ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {selectedRows[0].load_id}
                      <ArrowForward style={{ paddingLeft: '16px' }} />
                      <Person style={{ padding: '0 16px' }} />
                      {selectedRows[0].driver.driver_id}
                    </div>
                  ) : `(${selectedRows.length}) Loads Selected`}
                </Typography>

                <ODButton
                  variant="blue"
                  disabled={!memoEnableAssignments}
                  endIcon={<ArrowForward />}
                  onClick={() => handleOpenAssignDialog(memoEnableAssignments)}
                >
                  {selectedRows.length === 1 ? 'Assign Driver to Load' : 'Assign Drivers to Loads'}
                </ODButton>
              </div>
            </ODAlertBar>
          </Box>
        )}

        <DriversSlideout
          index={rows.findIndex((row) => row.load_id === selectedLoadId)}
          currentLoad={rows.find((row) => row.load_id === selectedLoadId)}
          handleStepThrough={handleLoadStepThrough}
          count={rows.length}
          handleClose={() => {
            searchParams.delete('slideout_view_type');
            setSearchParams(searchParams);
            setSelectedLoadId(null);
          }}
          setRefreshDashboard={setRefreshDashboard}
          setHOSStatusData={setHOSStatusData}
          setExplainPtaDialogOpen={setExplainPtaDialogOpen}
        />
      </Box>
    </>
  );
}

export default DispatchingByLoads;
