import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { ODTableHead, ODTableRow, ODAvatar, ODTableBody, ODTableActionsMenu, ODTableContainer, ODTableCell } from '@OptimalDynamics/core-ai-common-ui';
import { Box, Link, Table, TableSortLabel, Typography, useTheme } from '@mui/material';
import GradingSharp from '@mui/icons-material/GradingSharp';
import PeopleSharp from '@mui/icons-material/PeopleSharp';
import PersonSharp from '@mui/icons-material/PersonSharp';
import ViewListSharp from '@mui/icons-material/ViewListSharp';
import CheckCircleSharp from '@mui/icons-material/CheckCircleSharp';
import ConnectWithoutContactSharp from '@mui/icons-material/ConnectWithoutContactSharp';
import { useSearchParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import useColumnData from '../helpers/useColumnData';
import { getSyncTimeDiff } from '../../../utils/datetimes';
import { SettingsContext } from '../../../utils/context';
import { DETAILS, MATCH_DETAILS } from '../../../AppConstants';
import DriverDispatchMatchCell from './DriverDispatchMatchCell';
import { CopyClick } from '../../../common/CopyClick';
import { DRIVER_HOLD, EVENTS, MATCHES, PLANNED, PLANNEDONLY, RELAXED, SOURCE } from '../helpers/constants';
import { DispatchingDriver, DispatchingDriverTableProps } from '../types';
import { UpdateUserSettings } from '../../../store/actions';
import { RootState } from '../../../store/reducers';
import { DriverIdentifier } from '../helpers/dynamicDescription';

const DispatchingDriversTable = ({
  headers,
  drivers = [],
  onSelectRow,
  ordering,
  setOrdering,
  setRulesForDriverId,
  setDriverRulesSlideOutOpen,
  syncTime,
  selectedDriverId, 
  appliedFilterCount
}: DispatchingDriverTableProps) => {
  const userSettings = useSelector((state: RootState) => state.userSettings);
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setShowSettings } = useContext(SettingsContext);
  const [rows, setRows] = useState(drivers);
  const [lastDetailColumn, setLastDetailColumn] = useState<number>(0);
  const {
    dispatchingUnassign,
    linkDriverIds,
    showRelaxedMatches,
    orgUseRelaxedDispatching,
    addDriverNameUi,
    showSourceSuggestions,
    emptyTableTextWhenFilterApplied,
  } = useFlags();
  const dispatch = useDispatch();

  const { checkColumn } = useColumnData(headers);

  useEffect(() => {
    setRows(drivers);
  }, [drivers]);

  useEffect(() => {
    setLastDetailColumn(headers.filter((header) => header.visible && header.parent === 'details').length);
  }, [headers]);

  const handleSlideoutOpen = (row: DispatchingDriver, defaultViewType: string) => {
    setSearchParams({ ...Object.fromEntries([...searchParams]), slideout_view_type: !row.active && dispatchingUnassign ? PLANNEDONLY : defaultViewType });
    onSelectRow(row.driver.driver_id);
  };

  const handleActionMenuClick = (driverId: string) => {
    const updatedRows = rows.map((row) => {
      if (row.driver.driver_id === driverId) {
        row.suspended = !row.suspended ?? true;
      } else {
        row.suspended = false;
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleSortClick = (value: string) => {
    if (ordering.includes(value)) {
      const newOrdering = ordering.startsWith('-') ? ordering.substring(1) : `-${ordering}`;
      setOrdering(newOrdering);
      dispatch(UpdateUserSettings({ ...userSettings, dispatchingDriversSorting: newOrdering }));
    } else {
      setOrdering(value);
      dispatch(UpdateUserSettings({ ...userSettings, dispatchingDriversSorting: value }));
    }
  };

  const handleDriverIdClick = (event: SyntheticEvent, row: DispatchingDriver) => {
    event.stopPropagation();
    handleSlideoutOpen(row, PLANNED);
  };

  const handleNextEventLocationClick = (event: SyntheticEvent, row: DispatchingDriver) => {
    event.stopPropagation();
    handleSlideoutOpen(row, EVENTS);
  };

  const handleAssignedLoadsClick = (event: SyntheticEvent, row: DispatchingDriver) => {
    event.stopPropagation();
    handleSlideoutOpen(row, PLANNED);
  };

  const getColSpan = (group: string) => headers.filter((header) => header.visible && header.parent === group).length;

  const getTableCells = (column: string, data: DispatchingDriver) => {
    if (!checkColumn(column)) return;
    switch (column) {
      case 'Driver Name':
        return (
          <ODTableCell data-col-match-label="Driver Name">
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
              {(showSourceSuggestions && data.has_source_request) ? <ConnectWithoutContactSharp /> : data.driver.is_sleeper ? <PeopleSharp /> : <PersonSharp />}
              {linkDriverIds ? (
                <Link onClick={(e: SyntheticEvent) => handleDriverIdClick(e, data)} variant="default" component="button">
                  <DriverIdentifier driver={data.driver} />
                </Link>
              ) : <DriverIdentifier driver={data.driver} />}
            </div>
          </ODTableCell>
        );
      case 'Driver ID':
      case 'Driver':
        const driverNameVisible = addDriverNameUi && checkColumn('Driver Name');
        return (
          <ODTableCell
            data-col-match-label={driverNameVisible ? 'Driver ID' : 'Driver'}
            sx={{ '&:hover': { cursor: 'pointer', backgroundColor: `${theme.palette.level3} !important` } }}
          >
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
              {!driverNameVisible && (data.driver.is_sleeper ? <PeopleSharp /> : <PersonSharp />)}
              <CopyClick copyText={data.driver.driver_id}>
                {driverNameVisible ? <Typography>{data.driver.driver_id}</Typography> : (
                  <Link
                    onClick={(e: SyntheticEvent) => handleDriverIdClick(e, data)}
                    variant="default"
                    component="button"
                  >
                    {data.driver.driver_id}
                  </Link>
                )}
              </CopyClick>
            </div>
          </ODTableCell>
        );
      case 'Driver Type':
        return (
          <ODTableCell data-col-match-label="Driver Type">
            {data.driver.driver_type}
          </ODTableCell>
        );
      case 'Tractor ID':
        return (
          <ODTableCell data-col-match-label="Tractor ID">
            {data.driver.tractor_id || '-'}
          </ODTableCell>
        );
      case 'Current Location':
        return (
          <ODTableCell data-col-match-label="Current Location">
            {data.current_location}
          </ODTableCell>
        );
      case 'Home Location':
        return (
          <ODTableCell data-col-match-label="Home Location">
            {`${data.driver.home_city}, ${data.driver.home_state}`}
          </ODTableCell>
        );
      case 'Next Event Location':
        return (
          <ODTableCell data-col-match-label="Next Event Location">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link onClick={(e: SyntheticEvent) => handleNextEventLocationClick(e, data)} component="button" variant={data.next_event_location ? 'default' : 'disabled'}>
                {data.next_event_location || '-'}
              </Link>
            </div>
          </ODTableCell>
        );
      case 'Assigned Loads':
        return (
          <ODTableCell data-col-match-label="Assigned Loads">
            <>
              {data.assigned_loads ? (
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  <CheckCircleSharp sx={{ color: 'success.main' }} />
                  <Link onClick={(e: SyntheticEvent) => handleAssignedLoadsClick(e, data)} component="button" variant="default">
                    {data.assigned_loads}
                  </Link>
                </div>
              )
                : '-'}
            </>
          </ODTableCell>
        );
      case 'Dispatch Location':
        return (
          <ODTableCell data-col-match-label="Dispatch Location">
            {data.predispatch_final_loc || '-'}
          </ODTableCell>
        );
      case 'Available for Dispatch':
        return (
          <ODTableCell data-col-match-label="Available for Dispatch">
            {getSyncTimeDiff(data.avail_datetime, syncTime, data.avail_timezone)}
          </ODTableCell>
        );
      case 'TMS Region':
        return (
          <ODTableCell data-col-match-label="TMS Region">
            {data.driver_available_region_code}
          </ODTableCell>
        );
      case 'Match Type':
        return (
          <ODTableCell data-col-match-label="Match Type">
            <DriverDispatchMatchCell row={data} />
          </ODTableCell>
        );
      case 'Match Action':
        return (
          <ODTableCell data-col-match-label="Match Action">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link component="button" variant="blue">
                {(() => {
                  if (showSourceSuggestions && data.has_source_request) return 'SOURCE LOAD'; 
                  if ((!data.active)
                    || (!showRelaxedMatches && data.is_relaxed)
                    || (data.first_recommend_movement_type === DRIVER_HOLD && data.preferred_options_count <= 1)
                  ) return 'Review';
                  return 'View Details';
                })()}
              </Link>
            </div>
          </ODTableCell>
        );
      default:
        return null;
    }
  };

  const emptyTableDataMessage =   
    emptyTableTextWhenFilterApplied
      ? appliedFilterCount > 0
        ? 'No data was found for applied filters. Please review and adjust them as needed.'
        : 'There are no results to display.'
      : 'There are no results to display.';

  return (
    <ODTableContainer
      stickyFirst
      stickyLast
      sx={{ overflow: 'initial', '&.MuiPaper-root': { overflow: 'initial' } }}
      id="dispatching-drivers-table-container"
    >
      <Table stickyHeader>
        <ODTableHead>
          <ODTableRow>
            <ODTableCell colSpan={getColSpan(DETAILS)}>Driver Details</ODTableCell>
            <ODTableCell colSpan={getColSpan(MATCH_DETAILS) + 1} sx={{ pl: 2, borderLeft: `1px solid ${theme.palette.level3}` }}>
              Match Details
            </ODTableCell>
          </ODTableRow>
          <ODTableRow
            sx={{
              [`& .MuiTableCell-root:nth-of-type(${lastDetailColumn + 1})`]: {
                borderLeft: `1px solid ${theme.palette.level3}`, pl: 2
              }
            }}
          >
            {headers.map((header) => (
              <>
                {checkColumn(header.label) && (
                  <ODTableCell key={`dispatching-drivers-header-${header.label}`}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TableSortLabel
                        active={ordering.includes(header.value)}
                        hideSortIcon={!header.sortable}
                        direction={ordering.startsWith('-') ? 'desc' : 'asc'}
                        onClick={() => handleSortClick(header.value)}
                        classes={{ icon: 'sort-icon-lite' }}
                        id={`${header.value}-sorting-Arrow`}
                      >
                        {header.label}
                      </TableSortLabel>
                    </Box>
                  </ODTableCell>
                )}
              </>
            ))}
            <ODTableCell sx={{ width: 40, padding: 0 }}>
              <ODAvatar variant="transparent" sx={{ m: 0.5 }} size="small" onClick={() => setShowSettings(true)}>
                <ViewListSharp />
              </ODAvatar>
            </ODTableCell>  
          </ODTableRow>
        </ODTableHead>
        <ODTableBody
          data-testid="dispatching-drivers-table-body"
          sx={{
            [`& .MuiTableCell-root:nth-of-type(${lastDetailColumn + 1})`]: {
              borderLeft: `1px solid ${theme.palette.level3}`, pl: 2
            }
          }}
        >
          {!!rows.length ? (
            <>
              {rows.map((row, index) => (
                <ODTableRow
                  key={`dispatching-drivers-table-row-${row.driver.driver_id}-${index}`}
                  id={`dispatching-drivers-table-row-${index}`}
                  suspended={row.suspended || false}
                  selected={row.driver.driver_id === selectedDriverId}
                  onClick={() => {
                    if (showSourceSuggestions && row.has_source_request) {
                      handleSlideoutOpen(row, SOURCE);
                      return;
                    }
                    if (row.active) {
                      let targetTab = MATCHES;
                      if (showRelaxedMatches && orgUseRelaxedDispatching && row.is_relaxed) targetTab = RELAXED;
                      handleSlideoutOpen(row, targetTab);
                    } else {
                      if (!row.top_option_load_count) handleSlideoutOpen(row, PLANNED);
                      else setSearchParams((prev) => ([...prev.entries(), ['show_history', 'true']]));
                    }
                  }}
                  sx={{ '&:hover': { cursor: 'pointer', backgroundColor: 'level2' } }}
                >
                  {headers.map((header) => getTableCells(header.label, row))}

                  <ODTableCell sx={{ display: 'flex', maxWidth: 40, padding: 0, alignItems: 'center', justifyContent: 'center' }}>
                    <ODTableActionsMenu
                      contents={[
                        {
                          icon: <GradingSharp />,
                          onClick: () => {
                            setRulesForDriverId(row?.driver?.driver_id);
                            setDriverRulesSlideOutOpen(true);
                            handleActionMenuClick(row.driver.driver_id);
                          },
                          tooltip: 'Edit Driver Rules',
                          disabled: false
                        }
                      ]}
                      onToggleOpen={() => handleActionMenuClick(row.driver.driver_id)}
                    />
                  </ODTableCell>
                </ODTableRow>
              ))}
            </>
          ) : (
            <ODTableRow sx={{ '&:hover': { background: 'inherit' } }}>
              <ODTableCell colSpan={headers.filter((header) => header.visible).length + 1} sx={{ textAlign: 'center', borderBottom: 'none' }}>
                {emptyTableDataMessage}
              </ODTableCell>
            </ODTableRow>
          )}
        </ODTableBody>
      </Table>
    </ODTableContainer>
  );
};

export default DispatchingDriversTable;
