import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParams, useSearchParams } from 'react-router-dom';
import { sortBy, debounce } from 'lodash';
import { Box, useTheme } from '@mui/material';
import AddSharp from '@mui/icons-material/AddSharp';
import PeopleSharp from '@mui/icons-material/PeopleSharp';
import PersonSharp from '@mui/icons-material/PersonSharp';
import SearchSharp from '@mui/icons-material/SearchSharp';
import CheckSharp from '@mui/icons-material/CheckSharp';
import StarSharp from '@mui/icons-material/StarSharp';
import CheckCircleSharp from '@mui/icons-material/CheckCircleSharp';
import ConnectWithoutContactSharp from '@mui/icons-material/ConnectWithoutContactSharp';
import { ODButton } from '@OptimalDynamics/core-ai-common-ui';
import { RootState } from '../../../store/reducers';
import instance from '../../../utils/axios_instance';
import { SHORT_DATE_TIME } from '../../../utils/datetimes';
import ODTabs from '../../../common/ODTabs';
import { TuneOffIcon } from '../../../common/icons';
import { DispatchingSummaryTitle } from '../DispatchingSummary';
import { DetailCardTitle, DetailCardAccordion, NoAvailableMatchesAccordion } from '../DetailCard';
import PermitsAndRequirementsDialog from '../shared/PermitsAndRequirementsDialog';
import LoadDetailsSlideout from '../shared/LoadDetailsSlideout';
import SearchDialog from '../shared/SearchDialog';
import ConfirmAssignment from '../shared/ConfirmAssignment';
import SelectedLoadCard from './SelectedLoadCard';
import DriversSearch from './DriversSearch';
import { DriversSlideoutCardTimeline } from './DriversSlideoutCardTimeline';
import { DispatchingLoad, DriverOption, DriversSlideoutProps, Selection } from '../types';
import RightDrawer from '../components/RightDrawer';
import ODLoaderLabeled from '../components/ODLoader';
import { dynamicDescription, driverNameHelper, DriverDetails } from '../helpers/dynamicDescription';
import transformTopOptions from '../helpers/transformTopOptions';
import {
  BROKERAGE,
  LOADED,
  DRIVER,
  MATCHES,
  RELAXED,
  SELECT_ICON_RADIO,
  SELECT_ICON_CHECKBOX,
  LOAD,
  ASSIGNED,
  TMS
} from '../helpers/constants';
import { getTimeLabel, RelativeTime } from '../drivers/DriverPlanDetailCard';
import { IconWrapper, dynamicSourceBrokerageDescription } from '../helpers/dynamicSourceBrokerageDescription';

const DriversSlideout = ({
  handleClose,
  index,
  count,
  currentLoad = {},
  handleStepThrough,
  setRefreshDashboard,
  setHOSStatusData,
  setExplainPtaDialogOpen
}: DriversSlideoutProps) => {
  const theme = useTheme();
  const {
    orgUse2WayDispatching,
    showRelaxedMatches,
    orgUseRelaxedDispatching,
    disableDispatchingAssignment,
    addDriverNameUi,
    showAllDispatchMatches,
    assignBrokerageLoads,
    showSourceSuggestions,
    disallowExceptionAsOptimal, 
    bypassDispatchingChecks
  } = useFlags();
  const pointers = useRef<HTMLDivElement[] | never[]>([]);
  const [randomization, setRandomization] = useState(nanoid());
  const [totalOptionsCount, setTotalOptionsCount] = useState(0);
  const [topOptions, setTopOptions] = useState<DriverOption[][] | never[]>([]);
  const [selection, setSelection] = useState<Selection | null>(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openFinder, setOpenFinder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [permitsDialogData, setPermitsDialogData] = useState<DriverOption | null>(null);
  const [offeredLoadDetails, setOfferedLoadDetails] = useState({});
  const [optimalMatch, setOptimalMatch] = useState<DriverOption | undefined>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loadId } = useParams();
  const engineRunId = useSelector((state: RootState) => state.dispatchingReducer?.engineRunId);
  const dataUpdated = useSelector((state: RootState) => state.dispatchingReducer?.dataUpdated);
  const viewType = searchParams.get('slideout_view_type');
  const optimalNode = useRef(false as ReactElement | boolean);

  const isBrokerage = assignBrokerageLoads && currentLoad?.internal_source === BROKERAGE;

  useEffect(() => {
    if (!!Object.keys(currentLoad).length) {
      getTopMatches();
      setTopOptions([]);
      setSelection(null);
      setRandomization(nanoid());
      optimalNode.current = false;
    } else {
      handleClose();
    }
  }, [currentLoad?.movement_option_id, viewType, showAllDispatchMatches, dataUpdated]);

  useEffect(() => {
    if (disallowExceptionAsOptimal) {
      if (currentLoad?.driver?.driver_id === sortBy(topOptions[0], (o) => o.load_second_rank)[0]?.driver_id) {
        setOptimalMatch(sortBy(topOptions[0], (o) => o.load_second_rank)[0]);
      } else if (optimalMatch?.driver_id !== currentLoad?.driver?.driver_id) {
        setOptimalMatch(undefined);
      }
    }
  }, [viewType, topOptions, currentLoad]);

  const getTopMatches = () => {
    const { load_id, engine_run_id } = currentLoad;
    let url = `dispatching/load-top-options/${engine_run_id}/${load_id}/?offset=0&limit=${showAllDispatchMatches ? '12' : '4'}`;
    if (viewType === RELAXED) url = `${url}&is_relaxed=true`;
    setLoading(true);

    instance.get(url)
      .then((res) => {
        const transformedOptions: DriverOption[][] | never[] = transformTopOptions(res.data.results, 'load_rank', 'load_second_rank');
        setTotalOptionsCount(res.data.total_options);
        setTopOptions(transformedOptions);
        if (!!transformedOptions.length) {
          const firstIdx = transformedOptions.findIndex((opt) => opt[0].active);
          if (firstIdx > -1) {
            const firstActive = transformedOptions[firstIdx];
            setSelection({ index: firstIdx, length: firstActive.length });
          }
        }
        pointers.current[0]?.scrollIntoView({ behavior: 'auto', block: 'start' });
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const getMoreMatches = () => {
    const { load_id, engine_run_id } = currentLoad;
    let url = `dispatching/load-top-options/${engine_run_id}/${load_id}/?offset=${topOptions.length}&limit=4`;
    if (viewType === RELAXED) url = `${url}&is_relaxed=true`;
    setLoading(true);

    instance.get(url)
      .then((res) => {
        const transformedOptions: DriverOption[][] = transformTopOptions(res.data.results, 'load_rank', 'load_second_rank');
        setTopOptions((prevOptions: DriverOption[][]) => prevOptions.concat(transformedOptions));
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const option = !!selection ? topOptions[selection?.index]?.slice(0, selection.length) : [];
  const selectionHasSourceRequest = showSourceSuggestions && option.some((d) => d?.has_source_request);

  const debouncedConfirmation = debounce(async (confirmationDecision: boolean) => {
    if (confirmationDecision) {
      const assignments = option.filter((driver) => !!driver.active).map((driver) => (
        {
          driver_id: driver.driver_id,
          load_id: currentLoad?.load_id,
          movement_option_id: driver.movement_option_id,
          overall_rating: driver.overall_rating
        }
      ));
      await instance.post('/dispatching/drivers/loads-assignments/', { assignments, engine_run_id: engineRunId })
        .then((response) => {
          if (response && response.status === 200) {
            handleClose();
            setRefreshDashboard(true);
          }
        })
        .catch((response) => console.error(response.data.errors))
        .finally(() => setOpenConfirmation(false));
      // delete source request
      if (showSourceSuggestions && selectionHasSourceRequest) {
        Promise.all(
          option.filter((d) => d.has_source_request).map(async (driver) => {
            instance.delete(`/dispatching/drivers/${driver.driver_id}/source-request/`)
              .then((response) => {
                if (response && response.status === 200) {
                  handleClose();
                  setRefreshDashboard(true);
                }
              })
              .catch((response) => console.error(response.data.errors));
          })).finally(() => setOpenConfirmation(false));
      }
    }
    setOpenConfirmation(false);
  }, 500);

  const handleSelectDriver = (driverArr: DriverOption[], refIdx: number) => {
    setSelection({ index: refIdx, length: driverArr.length });
    pointers.current[refIdx].scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const renderInternalSearchTable = () => (
    <DriversSearch
      currentLoad={currentLoad}
      optimalMatch={disallowExceptionAsOptimal ? optimalMatch : sortBy(topOptions[0], (o) => o.load_second_rank)[0]}
      optimalMatchNode={optimalNode}
      onClean={() => {
        setOpenFinder(false);
        handleClose();
        setRefreshDashboard(true);
      }}
    />
  );

  const checkIsDisabled = () => {
    if (disableDispatchingAssignment) return true;
    if (!assignBrokerageLoads && orgUse2WayDispatching && currentLoad?.internal_source === BROKERAGE) return true;
    if (!bypassDispatchingChecks && orgUse2WayDispatching && currentLoad?.tms_ready === false) return true;
    if (!selection) return true;
    if (!option || option?.every((opt: DriverOption) => !opt.active)) return true;
    return false;
  };

  const openLoadDetailsModal = () => {
    setOfferedLoadDetails({
      load_id: currentLoad.load_id,
      load_data: {
        revenue: currentLoad.revenue,
        pickup_appt_type: currentLoad.pickup_appt_type,
        dropoff_appt_type: currentLoad.dropoff_appt_type
      }
    });
  };

  let noAvailNode = <></>;
  if (!loading && topOptions.length === 0 && viewType !== ASSIGNED) {
    noAvailNode = <NoAvailableMatchesAccordion type={LOAD} />;
    optimalNode.current = noAvailNode;
  }

  return (
    <>
      <RightDrawer open={!!viewType}>
        <Box sx={{ display: 'flex', flexFlow: 'column', height: '100%', backgroundColor: theme.palette.colors.white }}>
          <DispatchingSummaryTitle
            isDriver
            onClose={handleClose}
            onClickPrev={(!loadId && index > 0) ? (() => handleStepThrough(-1)) : null}
            onClickNext={(!loadId && index < count - 1) ? (() => handleStepThrough(1)) : null}
          />
          <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', borderStyle: 'solid', borderWidth: '1px 0px', borderColor: 'level3' }}>
            <SelectedLoadCard currentLoad={currentLoad as DispatchingLoad} openLoadDetailsModal={openLoadDetailsModal} />
          </Box>
          {showRelaxedMatches && viewType !== ASSIGNED && (
            <Box sx={{ px: 2, borderBottom: '1px solid', borderColor: 'level3' }}>
              <ODTabs
                value={viewType ?? MATCHES}
                fullWidth={true}
                setValue={(_e, v) => {
                  searchParams.set('slideout_view_type', v);
                  setSearchParams(searchParams);
                }}
                tabOptions={[
                  { value: MATCHES,
                    label: `(${currentLoad?.preferred_options_count ?? 0}) Business Match${currentLoad?.preferred_options_count !== 1 ? 'es' : ''}`,
                    icon: <StarSharp /> },
                  { value: RELAXED,
                    label: `(${currentLoad?.relaxed_options_count ?? 0}) Exception Match${currentLoad?.relaxed_options_count !== 1 ? 'es' : ''}`,
                    icon: <TuneOffIcon /> }
                ]}
              />
            </Box>
          )}
          {viewType === ASSIGNED && (
            <Box sx={{ borderBottom: '1px solid', borderColor: 'level3', display: 'flex', alignItems: 'center', minHeight: 56 }}>
              <CheckCircleSharp sx={{ color: 'success.main', mx: 2 }} />
              <strong>Assigned Driver</strong>
            </Box>
          )}
          {(loading && topOptions.length === 0) && (
            <Box sx={{ width: '100%', height: '85%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <ODLoaderLabeled />
            </Box>
          )}
          <Box
            sx={{
              overflow: 'auto',
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '16px',
              flex: '1 1 auto',
              height: '100%'
            }}
          >
            {topOptions.length > 0 && topOptions.map((topLevelDriver: DriverOption[], i: number) => {
              const isTour = topLevelDriver.length > 1;

              const sampleDriver = topLevelDriver[0];
              const optionSelected = selection?.index === i;

              const isTourWithConflict = () => orgUse2WayDispatching && isTour && topLevelDriver.some((sample) => sample.internal_source === BROKERAGE || (sample.movement_type === LOADED && sample.tms_ready === false));

              const isActive = sampleDriver?.active;

              const node = (
                <DetailCardTitle
                  key={`top-level-driver-${randomization}-${i}`}
                  id={`assign-loads-slideout-recommendation-${i}`}
                  ref={(opt: HTMLDivElement) => { pointers.current[i] = opt; }}
                  title="Driver Match"
                  rating={sampleDriver?.overall_rating}
                  secondary_rating={sampleDriver?.load_rank}
                  hasSourceRequest={showSourceSuggestions && topLevelDriver?.some((d) => d?.has_source_request)}
                  active={isActive}
                  selected={optionSelected}
                  handleClick={() => handleSelectDriver(topLevelDriver, i)}
                  isTourWithConflict={isTourWithConflict()}
                  isRelaxed={showRelaxedMatches && orgUseRelaxedDispatching && sampleDriver?.is_relaxed}
                >
                  {topLevelDriver.map((driver, j) => {
                    const identifier = {
                      icon: (showSourceSuggestions && driver?.has_source_request) ? 
                        <IconWrapper bgColor="colors.neutral6"><ConnectWithoutContactSharp sx={{ color: 'text.inverse' }} /></IconWrapper> : 
                        driver?.is_sleeper ? <PeopleSharp /> : <PersonSharp />,
                      label: driverNameHelper(addDriverNameUi, driver),
                      copyText: driver.driver_id,
                      description: addDriverNameUi ? <DriverDetails driver={driver} /> : driver.driver_type
                    };

                    const driverSelected = optionSelected && (j < (selection?.length ?? 0));

                    const selectToggle = () => setSelection({ index: i, length: driverSelected ? j : j + 1 });

                    return (
                      <DetailCardAccordion
                        key={`subdriver-${j}`}
                        id={`assign-loads-slideout-sub-recommendation-${j}`}
                        type={DRIVER}
                        driver={driver}
                        identifier={identifier}
                        sequenceNumber={isTour ? j + 1 : 0}
                        selected={driverSelected && driver.active}
                        hasPrerequisite={driver.has_prerequisite}
                        onOpenPermit={() => setPermitsDialogData(driver)}
                        defaultCollapse={showAllDispatchMatches}
                        selectIcon={isActive ? (j === 0 ? SELECT_ICON_RADIO : SELECT_ICON_CHECKBOX) : undefined}
                        onClickSelect={() => {
                          if (!sampleDriver?.active) return;
                          if (j === 0) handleSelectDriver(topLevelDriver, i);
                          else selectToggle();
                        }}
                      >
                        <DriversSlideoutCardTimeline
                          currentLoad={currentLoad}
                          driverOption={driver}
                          selected={driverSelected && driver.active}
                          showAvailable={j === 0}
                          openHosDialog={() => setHOSStatusData(driver)}
                          openExplainPtaDialog={() => setExplainPtaDialogOpen(true)}
                        />
                        {j > 0 && driver.active && (
                          <ODButton
                            className="detail-card-accordion-button"
                            variant="slideout"
                            sx={{ marginTop: '16px', justifyContent: 'center' }}
                            onClick={selectToggle}
                          >
                            {`${optionSelected ? 'Deselect' : 'Add'} Driver No. ${j + 1}`}
                          </ODButton>
                        )}
                      </DetailCardAccordion>
                    );
                  })}
                </DetailCardTitle>
              );

              if (!optimalNode.current) optimalNode.current = node;
              return node;
            })}
            {(!showAllDispatchMatches && !loading && topOptions.length !== totalOptionsCount) && (
              <ODButton
                id="top-drivers-slideout-see-more"
                variant="slideout"
                endIcon={<AddSharp />}
                sx={{ justifyContent: 'center', minHeight: '40px' }}
                onClick={getMoreMatches}
              >
                See More Matches
              </ODButton>
            )}
            {(loading && topOptions.length > 0) && <ODLoaderLabeled />}
            {noAvailNode}
            {viewType === ASSIGNED && (
              <DetailCardTitle
                id="assign-loads-slideout-plan"
                title="Match"
                location={TMS}
                rating={undefined}
                active
                selected
                relativeTime={(
                  <RelativeTime
                    label={getTimeLabel(currentLoad.pickup_start_et, currentLoad.dropoff_end_et, true, null, false, SHORT_DATE_TIME, SHORT_DATE_TIME)}
                    selected
                  />
                )}
              >
                <DetailCardAccordion
                  id="assign-loads-slideout-assigned"
                  type={DRIVER}
                  identifier={{
                    icon: currentLoad?.driver?.is_sleeper ? <PeopleSharp /> : <PersonSharp />,
                    label: `${currentLoad?.driver?.driver_last_name}, ${currentLoad?.driver?.driver_first_name}`,
                    description: `${currentLoad?.driver?.driver_id} / ${currentLoad?.driver?.tractor_id} / ${currentLoad?.driver?.driver_type}`
                  }}
                  selected
                >
                  {null}
                </DetailCardAccordion>
              </DetailCardTitle>
            )}
          </Box>
          {viewType !== ASSIGNED && (
            <Box sx={{ height: 40, px: 2, py: 1, borderTop: 1, borderColor: 'level3', display: 'flex', flex: '0 1 auto', columnGap: '16px' }}>
              <ODButton
                id="search-for-driver"
                startIcon={<SearchSharp />}
                sx={{ justifyContent: 'center', width: '50%' }}
                onClick={() => setOpenFinder(true)}
              >
                Search for Driver
              </ODButton>
              <ODButton
                data-action="assign-driver"
                data-testid="dispatching-assign-load-button"
                sx={{ justifyContent: 'center', width: '50%' }}
                variant="blue"
                disabled={checkIsDisabled()}
                onClick={() => setOpenConfirmation(true)}
                startIcon={<CheckSharp />}
              >
                Assign Driver
              </ODButton>
            </Box>
          )}
        </Box>
      </RightDrawer>
      <ConfirmAssignment
        title="Confirm Assignment"
        open={openConfirmation}
        handleClick={debouncedConfirmation}
        location="drivers-slideout"
        isBrokerage={isBrokerage}
        pendingSourceRequest={selectionHasSourceRequest}
        isNewSourceRequest={false}
        assignment={(!!selection && (isBrokerage || selectionHasSourceRequest))
          ? dynamicSourceBrokerageDescription(option, currentLoad, DRIVER)
          : dynamicDescription(option, currentLoad, DRIVER)}
      />
      <PermitsAndRequirementsDialog
        open={!!permitsDialogData}
        permitRequired={currentLoad?.req_permits}  
        permitDriver={permitsDialogData?.driver_permits}
        equipment={currentLoad?.equipment} 
        onClose={() => setPermitsDialogData(null)}
        description={() => dynamicDescription(permitsDialogData, currentLoad)}
      />
      <LoadDetailsSlideout 
        open={!!Object.keys(offeredLoadDetails)?.length}
        load={offeredLoadDetails}
        onClose={() => setOfferedLoadDetails({})}
      />
      {openFinder && (
        <SearchDialog
          subject={DRIVER}
          onClose={() => setOpenFinder(false)}
          currentRow={currentLoad as DispatchingLoad}
          currentCard={<SelectedLoadCard currentLoad={currentLoad as DispatchingLoad} openLoadDetailsModal={openLoadDetailsModal} />}
          internalSearchTable={renderInternalSearchTable()}
          defaultSearch={null}
          setRefreshDashboard={setRefreshDashboard}
        />
      )}
    </>
  );
};

export default DriversSlideout;
