import React, { useState, useEffect } from 'react';
import { Letter } from 'react-letter';
import { extract } from 'letterparser';
import { ODLoader } from '@OptimalDynamics/core-ai-common-ui';

interface EmailViewerProps {
  emailUrl: string | null | undefined;
}

const EmailViewer: React.FC<EmailViewerProps> = ({ emailUrl }) => {
  const [htmlState, setEmailHtmlState] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!emailUrl) return;
    const fetchEmailContent = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(emailUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const htmlContent = await response.text();
        const { html, text } = extract(htmlContent);
        setEmailHtmlState(html || text || '');
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
          console.error('Error fetching the email: ', err.message);
        } else {
          setError('An unknown error occurred');
          console.error('An unknown error occurred', err);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchEmailContent();
  }, [emailUrl]);

  if (isLoading) {
    return <ODLoader open={isLoading} text="Loading email preview..." />;
  }

  if (error) {
    return (
      <div style={{ padding: '32px' }}>
        Error loading email:&nbsp;
        {error}
      </div>
    );
  }

  return (
    <div style={{ padding: '32px', overflowY: 'auto' }}>
      <Letter html={htmlState} />
    </div>
  );
};

export default EmailViewer;
