import React from 'react';
import { ODChip } from '@OptimalDynamics/core-ai-common-ui';

const BetaChip = () => (
  <ODChip
    label={<strong>BETA</strong>}
    active={true}
    sx={{ '> span': { px: '4px' } }}
  />
);

export default BetaChip;
