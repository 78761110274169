import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { pick } from 'lodash';
import { Box, Link, Typography } from '@mui/material';
import InsightsSharp from '@mui/icons-material/InsightsSharp';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { ODSlideOut } from '@OptimalDynamics/core-ai-common-ui';
import {
  ACCEPTABLE_MATCHES,
  ASSIGNED,
  AVG_RATE_PER_MILE,
  BY_DRIVER,
  BY_LOAD,
  DRIVER_AVAILABILITY,
  EMPTY_MILEAGE_PERCENT,
  HOME_TIMES,
  LOADED_MILES_PER_DRIVER,
  LOAD_AVAILABILITY,
  NO_MATCHES,
  OPTIMAL,
  OTHER,
  PLANNED,
  PROFIT_PER_DRIVER,
  RELAXED_MATCHES,
  REVENUE_PER_DRIVER,
  SOURCE_REQUESTS,
  TOTAL_LINE_HAUL_REVENUE,
  TOTAL_LOADED_MILES,
  TOTAL_PROFIT
} from './helpers/constants';
import { ChartCard, AvailabilityLegend, PositiveMetricsCard } from './NetworkStatusGraphs';
import ChartInfoSwitchboard from './shared/ChartInfoDialog';
import AvailabilityGraph from './AvailabilityGraph';
import { RootState } from '../../store/reducers';
import ODAccordion from '../../common/ODAccordion';

const HOURS = 'hours';

interface Formatted {
  value: string;
  plusOptimal: string;
  forecast: string;
}

export const centsAbbreviate = (num?: number) => new Intl.NumberFormat('en-us', {
  style: 'currency',
  currency: 'USD',
}).format(num ?? 0);

const kAbbreviate = (num?: number) => new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short'
}).format(num ?? 0);

const formatData = (
  isPercent: boolean,
  isMoney: boolean,
  abbreviate: (_n: number) => string,
  values?: Values,
) => {
  if (!values) return { value: '', plusOptimal: '', forecast: '' };

  const unit = (value: string) => `${isMoney ? '$' : ''}${value}${isPercent ? '%' : ''}`;

  const asn = abbreviate(values.assigned);
  const apo = abbreviate(values.assigned_and_optimal);
  const low = abbreviate(values.rolling_days_low);
  const high = abbreviate(values.rolling_days_high);

  return {
    value: unit(asn),
    plusOptimal: unit(apo),
    forecast: `${unit(low)} - ${unit(high)}`
  };
};

interface Values {
  assigned: number;
  assigned_and_optimal: number;
  rolling_days_low: number;
  rolling_days_high: number;
}

interface Metrics {
  rev_per_driver?: Values;
  profit_per_driver?: Values;
  empty_mile_percent?: Values;
  loaded_mile_per_driver?: Values;
  rate_per_mile?: Values;
  total_loaded_miles?: Values;
  line_haul_rev?: Values;
  total_profit?: Values;
}

interface TabsGraphAccordionProps {
  dispatchMethod: 'by-load' | 'by-driver';
  viewType: string;
  setViewType: (_s: string) => void;
  chartList: string[];
  expanded: boolean;
}

export const TabsGraphAccordion = ({
  dispatchMethod, viewType, chartList, expanded
}: TabsGraphAccordionProps) => {
  const { showRelaxedMatches, macroShowProfit, showDispatchTotalProfit, fixDriverAvailabilitySummary, showSourceSuggestions } = useFlags();
  const [searchParams] = useSearchParams();
  const driverData = useSelector((state: RootState) => state.dispatchingReducer?.driverAvailabilityData);
  const loadData = useSelector((state: RootState) => state.dispatchingReducer?.loadAvailabilityData);
  const kpiChartsData = useSelector((state: RootState) => state.dispatchingReducer?.kpiChartsData);
  const engineRunId = useSelector((state: RootState) => state.dispatchingReducer?.engineRunId);
  const [openInfo, setOpenInfo] = useState(-1);
  const [definitionsOpen, setDefinitionsOpen] = useState(false);

  const slideoutOpen = !!searchParams.get('slideout_view_type');

  if (!expanded || slideoutOpen) return <></>;

  let activeData = {} as Metrics;
  if (!!kpiChartsData) {
    if (dispatchMethod === BY_DRIVER) activeData = kpiChartsData.driver_kpis as Metrics;
    if (dispatchMethod === BY_LOAD) activeData = kpiChartsData.load_kpis as Metrics;
  }

  let subject = 'Loads';
  let dataSource = loadData;
  if (dispatchMethod === BY_DRIVER) {
    subject = 'Drivers';
    dataSource = driverData;
  }

  return (
    <Box sx={{ flex: '1 0 auto' }}>
      <Box sx={{ padding: '8px 16px', display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', columnGap: '16px', alignItems: 'center' }}>
          <Box sx={{ height: '40px', width: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <InsightsSharp />
          </Box>
          <Typography>Current Network Status</Typography>
        </Box>
        <Box sx={{ display: 'flex', columnGap: '16px', alignItems: 'center' }}>
          <InfoOutlined sx={{ color: 'text.secondary' }} />
          <Link component="button" variant="secondary" onClick={() => setDefinitionsOpen(true)}>
            Metric Definitions
          </Link>
          <Box sx={{ height: '32px', borderRight: '1px solid', borderColor: 'level3' }} />
          <InfoOutlined sx={{ color: 'text.secondary' }} />
          <Link component="button" variant="secondary" onClick={() => setOpenInfo(0)}>
            Network Charts Guide
          </Link>
        </Box>
      </Box>
      <Box sx={{ px: 2, pb: 2, borderBottom: '1px solid', borderColor: 'level3', display: 'flex', columnGap: '16px', overflowX: 'scroll' }}>
        {chartList?.map((type, index) => {
          const onClickInfo = () => setOpenInfo(index);
          let values = {} as Formatted;
          let title = '';
          const datasets = [OPTIMAL, OTHER];

          switch (type) {
            // Standard Charts
            case DRIVER_AVAILABILITY:
              if (fixDriverAvailabilitySummary && showSourceSuggestions) {
                datasets.push(SOURCE_REQUESTS);
              }
              datasets.unshift(PLANNED);
              return (
                <ChartCard
                  title="Current Driver Availability"
                  onClickInfo={onClickInfo}
                  footer={<AvailabilityLegend datasets={datasets} />}
                  key={`chart-card-${type}-${engineRunId}-${index}`}
                >
                  <AvailabilityGraph data={pick(driverData, HOURS, ...datasets)} showPercent showTotal />
                </ChartCard>
              );
            case LOAD_AVAILABILITY:
              datasets.unshift(ASSIGNED);
              return (
                <ChartCard
                  title="Current Load Availability"
                  onClickInfo={onClickInfo}
                  footer={<AvailabilityLegend datasets={datasets} />}
                  key={`chart-card-${type}-${engineRunId}-${index}`}
                >
                  <AvailabilityGraph data={pick(loadData, HOURS, ...datasets)} showTotal />
                </ChartCard>
              );

            // Needs Review Charts
            case ACCEPTABLE_MATCHES:
              return (
                <ChartCard
                  title={`${subject} With Good or Feasible Matches`}
                  onClickInfo={onClickInfo}
                  footer={<AvailabilityLegend datasets={[ACCEPTABLE_MATCHES]} />}
                  key={`chart-card-${type}-${engineRunId}-${index}`}
                >
                  <AvailabilityGraph data={pick(dataSource, HOURS, ACCEPTABLE_MATCHES)} />
                </ChartCard>
              );
            case RELAXED_MATCHES:
              if (!showRelaxedMatches) return <></>;
              return (
                <ChartCard
                  title={`${subject} With Exception Matches`}
                  onClickInfo={onClickInfo}
                  footer={<AvailabilityLegend datasets={[RELAXED_MATCHES]} />}
                  key={`chart-card-${type}-${engineRunId}-${index}`}
                >
                  <AvailabilityGraph data={pick(dataSource, HOURS, RELAXED_MATCHES)} />
                </ChartCard>
              );
            case NO_MATCHES:
              return (
                <ChartCard
                  title={`${subject} With No Available Matches`}
                  onClickInfo={onClickInfo}
                  footer={<AvailabilityLegend datasets={[NO_MATCHES]} />}
                  key={`chart-card-${type}-${engineRunId}-${index}`}
                >
                  <AvailabilityGraph data={pick(dataSource, HOURS, NO_MATCHES)} />
                </ChartCard>
              );
            case HOME_TIMES:
              return (
                <ChartCard
                  title="Drivers With Home Times"
                  onClickInfo={onClickInfo}
                  footer={<AvailabilityLegend datasets={[HOME_TIMES]} />}
                  key={`chart-card-${type}-${engineRunId}-${index}`}
                >
                  <AvailabilityGraph data={pick(driverData, HOURS, HOME_TIMES)} />
                </ChartCard>
              );

            // Positive By Driver Charts
            case REVENUE_PER_DRIVER:
              title = 'Revenue Per Driver';
              values = formatData(false, true, kAbbreviate, activeData?.rev_per_driver);
              break;
            case PROFIT_PER_DRIVER:
              title = 'Profit Per Driver';
              values = formatData(false, true, kAbbreviate, activeData?.profit_per_driver);
              break;
            case EMPTY_MILEAGE_PERCENT:
              title = 'Empty Mileage %';
              values = formatData(true, false, kAbbreviate, activeData?.empty_mile_percent);
              break;
            case LOADED_MILES_PER_DRIVER:
              title = 'Total Loaded Miles Per Driver';
              values = formatData(false, false, kAbbreviate, activeData?.loaded_mile_per_driver);
              break;

            // Positive By Load Charts
            case AVG_RATE_PER_MILE:
              title = 'Avg. Rate Per Mile';
              values = formatData(false, false, centsAbbreviate, activeData?.rate_per_mile);
              break;
            case TOTAL_LOADED_MILES:
              title = 'Total Loaded Miles';
              values = formatData(false, false, kAbbreviate, activeData?.total_loaded_miles);
              break;
            case TOTAL_LINE_HAUL_REVENUE:
              title = 'Total Line Haul Revenue';
              values = formatData(false, true, kAbbreviate, activeData?.line_haul_rev);
              break;
            case TOTAL_PROFIT:
              title = 'Total Profit';
              values = formatData(false, true, kAbbreviate, activeData?.total_profit);
              break;

            default:
              return <></>;
          }

          return (
            <PositiveMetricsCard
              title={title}
              onClickInfo={onClickInfo}
              {...values}
              highlightOptimal={viewType === OPTIMAL}
              dispatchMethod={dispatchMethod}
              key={`positive-metrics-card-${engineRunId}-${index}`}
            />
          );
        })}
      </Box>
      <ChartInfoSwitchboard
        dispatchMethod={dispatchMethod}
        infoIndex={openInfo}
        setInfoIndex={setOpenInfo}
        chartList={chartList}
        viewType={viewType}
      />
      <ODSlideOut
        title="Metric Definitions"
        open={definitionsOpen}
        onClose={() => setDefinitionsOpen(false)}
        sx={{ zIndex: 1400, '.MuiAccordionDetails-root > p': { px: 2 } }}
        elevation={0}
      >
        <>
          <ODAccordion summary={macroShowProfit && showDispatchTotalProfit ? 'Average Profit per Driver' : 'Average Line Haul Revenue Per Driver'}>
            <Typography>{macroShowProfit && showDispatchTotalProfit ? 'Total Profit divided by the count of drivers on the Assign by Driver tab.' : 'Total Line Haul Revenue divided by the count of drivers on the Assign by Driver tab.'}</Typography>
          </ODAccordion>
          <ODAccordion summary="Empty Mileage %">
            <Typography>The % of empty miles that the drivers on the Assign by Driver tab will drive to load pickups, out of the total miles. Empty to home miles are not included.</Typography>
          </ODAccordion>
          <ODAccordion summary="Average Total Loaded Miles Per Driver">
            <Typography>Total Loaded Miles divided by the count of drivers on the Assign by Driver tab.</Typography>
          </ODAccordion>
          <ODAccordion summary="Average Rate Per Mile">
            <Typography>Total Line Haul Revenue divided by the Total Loaded miles, for the loads on the Assign by Load tab.</Typography>
          </ODAccordion>
          <ODAccordion summary="Total Loaded Miles">
            <Typography>Total Loaded Miles of the loads on the Assign by Load tab.</Typography>
          </ODAccordion>
          <ODAccordion summary={macroShowProfit && showDispatchTotalProfit ? 'Total Profit' : 'Total Line Haul Revenue'}>
            <Typography>{macroShowProfit && showDispatchTotalProfit ? 'Total Profit of the loads on the Assign by Load tab.' : 'Total Line Haul Revenue of the loads on the Assign by Load tab.'}</Typography>
          </ODAccordion>
        </>
      </ODSlideOut>
    </Box>
  );
};
