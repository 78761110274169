import React, { useState, createContext, useMemo } from 'react';
import { selectedLoadMidPoint, getBoundingBox } from '../math';

const myInitialViewState = {
  longitude: -84.148715,
  latitude: 37.677939,
  zoom: 5,
};

export const ViewPortContext = createContext();
export const ViewPortContextWrapper = ({ children }) => {
  const [viewPort, _setViewPort] = useState(myInitialViewState);
  const [boundingBox, _setBoundingBox] = useState(null);

  const setViewPort = (load) => {
    if (load === null) {
      _setViewPort(null);
      setBoundingBox(null);
    } else {
      const [longitude, latitude] = selectedLoadMidPoint(load);
      setBoundingBox(load);
      _setViewPort((preViewPort) => {
        const newViewPort = { ...preViewPort, longitude, latitude };
        return newViewPort;
      });
    }
  };

  const setBoundingBox = (load) => {
    if (load === null) {
      _setBoundingBox(null);
    } else {
      const newBoundingBox = getBoundingBox(load);
      _setBoundingBox(newBoundingBox);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoViewPortValue = useMemo(() => ({ viewPort, setViewPort, boundingBox }), [viewPort, boundingBox]);

  return (
    <ViewPortContext.Provider
      value={memoViewPortValue}
    >
      {children}
    </ViewPortContext.Provider>
  );
};
