import React from 'react';
import { Slide, SlideProps } from '@mui/material';

const ODTransition = React.forwardRef((props: SlideProps, ref) => {
  const { children, ...rest } = props;

  return (
    <Slide direction="down" ref={ref} {...rest}>
      {children}
    </Slide>
  );
});

export default ODTransition;
